import { REQUEST_LEGAL_DECLARATION_SUCCESS } from './constants'
import initialState from '../../initialState'

function legalDeclarationReducer(
  state = initialState.legalDeclaration,
  action,
) {
  switch (action.type) {
    case REQUEST_LEGAL_DECLARATION_SUCCESS:
      return {
        ...state,
        ShortText: action.legalDeclaration.SummaryDeclarationString,
        FullText: action.legalDeclaration.DeclarationText,
        DisallowedOccupations: action.legalDeclaration.DisallowedOccupations,
        PolicyWordingUrl: action.legalDeclaration.PolicyWordingUrl,
        InsuranceProductInformationUrl:
          action.legalDeclaration.InsuranceProductInformationUrl,
      }
    default:
      return state
  }
}

export default legalDeclarationReducer
