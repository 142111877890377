import React from 'react'
import { connect } from 'react-redux'
import Moment from 'moment'
import { bindActionCreators } from 'redux'
import pluralize from 'pluralize'
import { Grid, GridClear } from 'unsemantic'
import './style.css'
import {
  updateRiskData,
  updateQuote,
  updateValidation,
} from '../RiskDataCollectionPage/actions'
import VehicleModal from '../VehicleModal'
import { setVehicleListener, createUnkownVehicle } from '../Vehicle/actions'
import {
  SummarySection,
  SummaryBorderMobile,
  SummaryBorderLeftMobile,
  SummaryBorderBottomMobile,
  PolicyDurationSummary,
} from './SummarySectionStyles'
import VehicleReg from '../../components/VehicleReg'
import {
  PRIVATE_CAR,
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
} from '../Vehicle/constants'
import PolicyStartTimeModal from '../../components/PolicyStartTimeModal'

export const formatDate = (startDate, dateEdited, dateNotYetSet) => {
  if (dateEdited) {
    return Moment(startDate).format('ddd DD/MM/YYYY HH:mm')
  } else if (dateNotYetSet) {
    return 'My policy starts...'
  } else {
    return 'Policy starts immediately'
  }
}

export class QuoteSummary extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      vehicleModal: false,
      policyStartDateModal: false,
    }

    this.updateData = this.updateData.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.handleDateTimeSet = this.handleDateTimeSet.bind(this)
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.updateQuote &&
      (prevProps.vehicle.selected !== this.props.vehicle.selected ||
        prevProps.duration !== this.props.duration ||
        prevProps.riskData.PolicyStartDate !==
          this.props.riskData.PolicyStartDate)
    ) {
      if (
        prevProps.vehicle.selected.VehicleType ===
        this.props.vehicle.selected.VehicleType
      ) {
        this.props.actions.updateQuote({
          partialValidation: true,
          getPrice: true,
        })
      }
    }
  }

  updateData(payload) {
    this.hideModal(payload.modal)
    if (payload.durationDetails) {
      this.props.actions.updateRiskData({
        Duration: payload.durationDetails.duration,
        DurationType: payload.durationDetails.durationType,
      })
    }
    if (payload.vehicle) {
      if (this.props.vehicle.searched.VehicleFound) {
        this.props.actions.setVehicleListener(this.props.vehicle.searched)
      } else {
        this.props.actions.createUnkownVehicle()
      }
    }
  }

  handleDateTimeSet = (policyStartDate) => {
    this.props.actions.updateRiskData({
      PolicyStartDate: {
        value: policyStartDate.value,
        manuallyEdited: policyStartDate.manuallyEdited,
      },
    })
    this.hideModal('policyStartDateModal')
  }

  showModal(modal) {
    this.setState({ [modal]: true })
  }

  hideModal(modal) {
    this.setState({ [modal]: false })
  }

  render() {
    let vehicleSummary
    let registrationNumber =
      this.props.vehicle.selected.RegistrationNumber === null
        ? ''
        : this.props.vehicle.selected.RegistrationNumber

    switch (this.props.vehicle.selected.VehicleType) {
      case PRIVATE_CAR:
        if (this.props.vehicle.selected.Variant) {
          vehicleSummary = `${this.props.vehicle.selected.Variant}`
        } else {
          vehicleSummary = `${this.props.vehicle.selected.Make} ${this.props.vehicle.selected.Model}`
        }
        break
      case COMMERCIAL_VEHICLE:
        if (
          this.props.vehicle.selected.GrossWeight >
          COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT
        ) {
          vehicleSummary = `Weight: ${this.props.vehicle.selected.GrossWeight}kg Year: ${this.props.vehicle.selected.YearOfManufacture}`
        } else if (this.props.vehicle.selected.Variant) {
          vehicleSummary = `${this.props.vehicle.selected.Variant}`
        } else {
          vehicleSummary = `${this.props.vehicle.selected.Make} ${this.props.vehicle.selected.Model}`
        }
        break
      default:
        break
    }
    return (
      <Grid parent>
        {/* mobile view for quote summary */}
        {!this.props.hideDurationAndStartTime && (
          <Grid desktop-hide tablet-hide mobile='100'>
            <SummaryBorderMobile>
              <Grid parent mobile='60'>
                <SummarySection
                  id='VehicleSummaryMobile'
                  onClick={() => this.showModal('vehicleModal')}
                >
                  <p className='quote-summary-title'>Vehicle to be covered</p>
                  <p className='quote-summary-text'>{vehicleSummary}</p>
                  <VehicleReg reg={registrationNumber} />
                  <i className='fa fa-pencil-alt'></i>
                </SummarySection>
              </Grid>
              <Grid parent mobile='40'>
                <SummaryBorderLeftMobile>
                  <Grid parent mobile='100'>
                    <SummaryBorderBottomMobile>
                      <PolicyDurationSummary>
                        <p className='quote-summary-title'>Duration</p>
                        <p className='quote-summary-text'>
                          {this.props.riskData.Duration}{' '}
                          {parseInt(this.props.riskData.Duration) === 1
                            ? pluralize.singular(
                                this.props.riskData.DurationType,
                              )
                            : this.props.riskData.DurationType}
                        </p>
                      </PolicyDurationSummary>
                    </SummaryBorderBottomMobile>
                  </Grid>
                  <Grid parent mobile='100'>
                    <SummarySection
                      onClick={() =>
                        this.props.appData.CanFutureDatePolicies
                          ? this.showModal('policyStartDateModal')
                          : null
                      }
                    >
                      <p className='quote-summary-title'>Start time</p>
                      <p className='quote-summary-text'>
                        {formatDate(
                          this.props.riskData.PolicyStartDate.value,
                          this.props.riskData.PolicyStartDate.manuallyEdited,
                          this.props.appData.CanFutureDatePolicies,
                        )}
                      </p>
                      <i className='fa fa-pencil-alt'></i>
                    </SummarySection>
                  </Grid>
                </SummaryBorderLeftMobile>
              </Grid>
              <GridClear />
            </SummaryBorderMobile>
          </Grid>
        )}
        {/* desktop and tablet view for quote summary */}
        <Grid
          parent
          desktop='100'
          tablet='100'
          mobile-hide={!this.props.hideDurationAndStartTime}
        >
          <Grid desktop='100' tablet='100'>
            <SummarySection
              id='VehicleSummaryDesktop'
              onClick={() => this.showModal('vehicleModal')}
              showBorderOnMobile={this.props.hideDurationAndStartTime}
            >
              <p className='quote-summary-title vehicle'>
                Vehicle to be covered
              </p>

              <p className='quote-summary-text vehicle'>{vehicleSummary}</p>
              <VehicleReg reg={registrationNumber} />
              <i className='fa fa-pencil-alt'></i>
            </SummarySection>
          </Grid>
          {!this.props.hideDurationAndStartTime && (
            <>
              <Grid desktop='33' tablet='33'>
                <PolicyDurationSummary>
                  <p className='quote-summary-title'>Duration</p>
                  <p className='quote-summary-text'>
                    {this.props.riskData.Duration}{' '}
                    {parseInt(this.props.riskData.Duration) === 1
                      ? pluralize.singular(this.props.riskData.DurationType)
                      : this.props.riskData.DurationType}
                  </p>
                </PolicyDurationSummary>
              </Grid>
              <Grid desktop='66' tablet='66'>
                <SummarySection
                  onClick={() => this.showModal('policyStartDateModal')}
                >
                  <p className='quote-summary-title'>Start time</p>
                  <p className='quote-summary-text'>
                    {formatDate(
                      this.props.riskData.PolicyStartDate.value,
                      this.props.riskData.PolicyStartDate.manuallyEdited,
                      this.props.appData.CanFutureDatePolicies,
                    )}
                  </p>
                  <i className='fa fa-pencil-alt'></i>
                </SummarySection>
              </Grid>
            </>
          )}
        </Grid>
        <VehicleModal
          isVisible={this.state.vehicleModal}
          handleUpdate={() =>
            this.updateData({
              modal: 'vehicleModal',
              vehicle: true,
            })
          }
          hideModal={() => this.hideModal('vehicleModal')}
        />
        <PolicyStartTimeModal
          isVisible={this.state.policyStartDateModal}
          handleDateTimeSet={this.handleDateTimeSet}
          hideModal={() => this.hideModal('policyStartDateModal')}
          canFutureDate={this.props.appData.CanFutureDatePolicies}
        />
      </Grid>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    price: state.price,
    step: state.step,
    scheme: state.scheme.selected,
    appData: state.appData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateRiskData,
        updateValidation,
        updateQuote,
        setVehicleListener,
        createUnkownVehicle,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSummary)
