import http from '../api/http'

export default class cookieApi {
  static createSession() {
    const getDeviceType = () => {
      const ua = navigator.userAgent
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet'
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua,
        )
      ) {
        return 'mobile'
      }
      return 'desktop'
    }

    return http.post('/api/clientSessionCookies', {
      DeviceType: getDeviceType(),
      UserAgent: navigator.userAgent,
    })
  }

  static updateCookiePreferences(analyticsOptIn, trackingOptIn) {
    const sessionId = localStorage.getItem('clientSessionId')

    return http.put('/api/cookies/saveCookiePreferences', {
      SessionId: parseInt(sessionId),
      EssentialOptIn: true,
      AnalyticsOptIn: analyticsOptIn,
      TrackingOptIn: trackingOptIn,
    })
  }
}
