import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MailCheck from 'react-mailcheck'
import { ErrorMessage } from '../ErrorMessage'
import { FormControlWrapper } from '../../FormControlWrapper'
import { BaseInput } from '../Input/BaseInput'
import { P } from '../P'

const EmailIconWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.primaryColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  width: 43px;
  height: 32px;
  position: absolute;
  padding-top: 11px;
  background-color: ${(props) => props.theme.iconBackground};
  text-align: center;
`
const I = styled.i`
  font-size: 20px;
  color: ${(props) => props.theme.primaryColour};
`

export const SuggestionText = styled.p`
  display: inline;
  cursor: pointer;
  text-decoration: underline;
  color: turquoise;
`
export const EmailInput = styled(BaseInput)`
  padding-left: 55px;
`

export class Email extends React.Component {
  constructor(props) {
    super(props)
    this.handleSuggestion = this.handleSuggestion.bind(this)
  }

  handleSuggestion = (suggestionText) => {
    this.props.onSuggestClick(suggestionText)
  }
  render() {
    return (
      <>
        <MailCheck email={this.props.value || ''}>
          {(suggestion) => (
            <div>
              <FormControlWrapper>
                {this.props.error && (
                  <ErrorMessage id={`${this.props.name}Error`}>
                    {this.props.errorMessage}
                  </ErrorMessage>
                )}
                <EmailIconWrapper>
                  <I className='fas fa-envelope'></I>
                </EmailIconWrapper>
                <EmailInput
                  type='email'
                  value={this.props.value}
                  error={this.props.error}
                  placeholder={this.props.placeholder || 'My email is...'}
                  id={this.props.id}
                  name={this.props.name}
                  onBlur={this.props.onBlur}
                  onChange={this.props.onChange}
                />
              </FormControlWrapper>
              {suggestion && (
                <P>
                  Did you mean{' '}
                  <SuggestionText
                    onClick={() => this.handleSuggestion(suggestion.full)}
                  >
                    {suggestion.full}?
                  </SuggestionText>
                </P>
              )}
            </div>
          )}
        </MailCheck>
      </>
    )
  }
}

export default Email

Email.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
}
