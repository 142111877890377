import config from './config'

export const HTTP_CODES = {
  SUCCESS: {
    OK: 200,
  },
  ERROR: {
    BadRequest: 400,
    Forbidden: 403,
    TooManyRequests: 429,
    Unauthorized: 401,
    NotFound: 404,
    InternalServerError: 500,
    ServiceUnavailable: 503,
  },
}

export const ANNUAL_INSURANCE_PROVIDER_ID = {
  NotSet: 'NotSet',
  APlan: 'Aplan',
  ArnoldClark: 'ArnoldClark',
  CompareTheMarket: 'CompareTheMarket',
  Flow: 'Flow',
  Honcho: 'Honcho',
  Confused: 'Confused',
  ConfusedRTP: 'ConfusedRTP',
}

export const ANNUAL_CALLBACK_PROVIDER = {
  Confused: {
    Id: 'Confused',
    RedirectUrl: (introducer) =>
      `${config.CONFUSED_QUICK_QUOTE_REDIRECT_URL_STATIC}?utm_medium=referrer&utm_source=tempcover.com&utm_campaign=${introducer}_static`,
  },
  ConfusedRTP: {
    Id: 7,
    RedirectUrl: (introducer) =>
      `${config.CONFUSED_QUICK_QUOTE_REDIRECT_URL}&utm_medium=referrer&utm_source=tempcover.com&utm_campaign=${introducer}_rtp`,
  },
}
