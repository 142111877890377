import styled from 'styled-components'
import {
  GapButton,
  GapButtonContainer,
  GapContainer,
  GapText,
  LinkContainer,
} from '../GapModal/styles'

export const GapActivationContainer = styled(GapContainer)`
  text-align: left;
  margin-top: 16px;
  h2 {
    text-align: left;
    margin-top: 0;
  }
  margin-bottom: 8px;
`
export const GapActivationWrapper = styled.div`
  padding: 14px;
`
export const GapLinkText = styled.p`
  color: #000;
  margin-top: 0;
  font-weight: bold;
  a {
    color: #000;
    font-weight: bold;
    text-decoration: underline;
  }
`
export const GapMoreInfo = styled(LinkContainer)`
  color: #000;
  padding: 0;
`
export const GapActivationText = styled(GapText)`
  // margin: 0;
`
export const GapActivationButtonContainer = styled(GapButtonContainer)`
  width: 50%;
`
export const GapActivationButton = styled(GapButton)`
  background: rgb(38, 75, 148);
  color: rgb(255, 255, 255);
  border-radius: 6px;
  font-size: 16px;
  font-family: BrandonText-Bold;
  text-transform: uppercase;
  text-align: center;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: 45px;
  width: 70%;
`
export const GapActivationRemoveButton = styled(GapButton)`
  background: rgb(38, 75, 148);
  color: rgb(255, 255, 255);
  border-radius: 6px;
  font-size: 16px;
  font-family: BrandonText-Bold;
  text-transform: uppercase;
  text-align: center;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: 45px;
  width: 70%;
`

export const GapActivationGrid = styled.div`
  display: grid;
  grid-template-columns: 65% 35%;
`
export const GapSmallPrint = styled.p`
  font-size: 10px;
  color: #000;
`
