import initialState from '../../../initialState'
import { GET_ANALYSIS_DATA_SUCCESS } from '../../PolicyConfirmationPage/constants'

function affiliateReducer(state = initialState.affiliateRef, action) {
  switch (action.type) {
    case GET_ANALYSIS_DATA_SUCCESS:
      return action.response.AffiliateRef
    default:
      return state
  }
}

export default affiliateReducer
