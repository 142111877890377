import React from 'react'
import {
  Wrapper,
  ImagePlayer,
  ImageWrapper,
  ImageLaptop,
  ImageSkating,
  ImageCar,
  TopRowWrapper,
  TopRowInnerWrapper,
  BottomRowWrapper,
  Button,
  PaddingLeft,
} from './HonchoStyles'
import laptop from './laptop.png'
import skating from './skating.png'
import car from './car.png'
import honcho from './honcho.png'
import circle from './circle.png'
import header from './header.png'
import subHeader from './subHeader.png'
import button from './button.png'
import player from './player.png'

const HonchoProvider = () => {
  const handleClick = () => {
    window.location =
      'https://app.gethoncho.com?utm_source=affinity&utm_medium=tcg&utm_campaign=tcg-web-web'
  }

  return (
    <Wrapper>
      <TopRowWrapper>
        <PaddingLeft>
          <img src={header} alt={'Driveaway, done!'} />
          <img src={subHeader} alt={'Tap to sort your 12 month cover'} />
          <TopRowInnerWrapper>
            <Button onClick={() => handleClick()}>
              <img src={button} alt={"Let's go"} />
            </Button>
            <ImagePlayer src={player} alt={'Mobile - Image 1'} />
          </TopRowInnerWrapper>
        </PaddingLeft>
        <ImageWrapper>
          <ImageLaptop src={laptop} alt={'Desktop - Image 1'} />
          <ImageSkating src={skating} alt={'Desktop - Image 2'} />
          <ImageCar src={car} alt={'Desktop - Image 3'} />
        </ImageWrapper>
      </TopRowWrapper>
      <BottomRowWrapper>
        <img src={honcho} alt={'Honcho'} />
        <img src={circle} alt={'Circle'} />
      </BottomRowWrapper>
    </Wrapper>
  )
}

export default HonchoProvider
