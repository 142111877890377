import {
  CREATE_PAYPAL_PAYMENT,
  CREATE_PAYPAL_PAYMENT_SUCCESS,
  CREATE_PAYPAL_PAYMENT_FAILURE,
  CANCEL_PAYPAL_PAYMENT,
  CANCEL_PAYPAL_PAYMENT_SUCCESS,
  CANCEL_PAYPAL_PAYMENT_FAILURE,
  PAYPAL_PAYMENT_ERROR,
  PAYPAL_PAYMENT_ERROR_SUCCESS,
  PAYPAL_PAYMENT_ERROR_FAILURE,
} from './constants'

export function createPayPalPayment(paymentDetails) {
  return {
    type: CREATE_PAYPAL_PAYMENT,
    paymentDetails,
  }
}

export function createPayPalPaymentSuccess() {
  return {
    type: CREATE_PAYPAL_PAYMENT_SUCCESS,
  }
}

export function createPayPalPaymentFailure(message) {
  return {
    type: CREATE_PAYPAL_PAYMENT_FAILURE,
    message,
  }
}

export function cancelPayPayPayment() {
  return {
    type: CANCEL_PAYPAL_PAYMENT,
  }
}

export function cancelPayPayPaymentSuccess(errorMessage) {
  return {
    type: CANCEL_PAYPAL_PAYMENT_SUCCESS,
    errorMessage,
  }
}

export function cancelPayPayPaymentFailure(message) {
  return {
    type: CANCEL_PAYPAL_PAYMENT_FAILURE,
    message,
  }
}

export function payPalPaymentError(errorDetails) {
  return {
    type: PAYPAL_PAYMENT_ERROR,
    errorDetails,
  }
}

export function payPalPaymentErrorSuccess() {
  return {
    type: PAYPAL_PAYMENT_ERROR_SUCCESS,
  }
}

export function payPalPaymentErrorFailure(error) {
  return {
    type: PAYPAL_PAYMENT_ERROR_FAILURE,
    error,
  }
}
