import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCallback } from '../actions'
import ArnoldClarkProvider from './ArnoldClarkEmail'
import FlowProviderEmail from '../AnnualInsuranceProviders/Flow/FlowEmail'
import APlanProvider from './APlanEmail'
import { CALLBACK_SOURCE, REQUEST_CALLBACK_STATUS } from '../constants'
import { HTTP_CODES } from '../../../constants'
import {
  AnnualInsuranceText,
  RequestCallbackButton,
  EmailConfirmationWrapper,
  ShiftBottom,
} from '../styles'

const AnnualInsuranceConfirmationPage = ({
  actions,
  appData: { AnnualInsuranceProvider, IsLoading },
  callbackRequest,
}) => {
  useEffect(() => {
    if (!IsLoading) {
      actions.requestCallback(CALLBACK_SOURCE.Email)
    }
  }, [IsLoading])

  if (!IsLoading) {
    switch (callbackRequest.status) {
      case REQUEST_CALLBACK_STATUS.SUCCESS:
        switch (AnnualInsuranceProvider) {
          case 'ArnoldClark':
            return <ArnoldClarkProvider />
          case 'APlan':
            return <APlanProvider />
          case 'Flow':
            return (
              <ShiftBottom>
                <FlowProviderEmail />
              </ShiftBottom>
            )
          default:
            return <></>
        }
      case REQUEST_CALLBACK_STATUS.ERROR:
        switch (callbackRequest.httpCode) {
          case HTTP_CODES.ERROR.TooManyRequests:
            return (
              <EmailConfirmationWrapper>
                <AnnualInsuranceText>
                  We’re sorry, there is a limit to the number of call back
                  requests you’re able to make in one day.
                </AnnualInsuranceText>
                <AnnualInsuranceText>
                  If you do not receive your call back soon, please request
                  another call back tomorrow.
                </AnnualInsuranceText>
              </EmailConfirmationWrapper>
            )
          default:
            return (
              <EmailConfirmationWrapper>
                <AnnualInsuranceText>
                  We’re sorry, but something went wrong and we’re unable to
                  submit your request right now. Please try again later.
                </AnnualInsuranceText>
                <RequestCallbackButton
                  onClick={() => actions.requestCallback(CALLBACK_SOURCE.Email)}
                >
                  Request a call back
                </RequestCallbackButton>
              </EmailConfirmationWrapper>
            )
        }
      default:
        return <></>
    }
  }
  return <></>
}

const mapStateToProps = (state) => {
  return {
    callbackRequest: state.policy.callbackRequest,
    appData: state.appData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestCallback,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnualInsuranceConfirmationPage)
