import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { requestAddress, selectAddress } from './actions'
import { InputWrapper } from '../../components/common/Input/InputWrapper'
import { bindActionCreators } from 'redux'
import { Button } from '../../components/common/Button'
import { UppercaseInput } from '../../components/common/Input/UppercaseInput'
import AddressSelect from '../../components/AddressSelect'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { isButtonDisabled } from '../RiskDataCollectionPage/actions'
import { Label } from '../../components/common/Label'

export const AddressInputWrapper = styled(InputWrapper)`
  margin-bottom: 0;
`
const AddressButton = styled(Button)`
  background: rgb(38, 75, 148);
  color: rgb(255, 255, 255);
  border-radius: 6px;
  font-size: 16px;
  font-family: BrandonText-Bold;
  text-transform: uppercase;
  text-align: center;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: 45px;
  width: 100%;
`

const AddressWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;

  & div input {
    margin-bottom: 0px;
  }
`

const Address = ({
  actions,
  onBlur,
  onChange,
  setFieldTouched,
  setFieldValue,
  values,
  touched,
  errors,
  address,
  addressButtonSearchDisabled,
}) => {
  const [postcodeEdited, setPostcodeEdited] = useState(false)

  const onAddressSearch = (e, errors) => {
    e.preventDefault()
    setPostcodeEdited(false)
    setFieldTouched('Address.Postcode', true)
    setFieldTouched('Address.PostcodeSearched', true)
    setFieldValue('Address.PostcodeSearched', true)
    if (!errors.Postcode) {
      actions.requestAddress(values.Postcode)
    }
  }

  const handlePostcodeChange = (e) => {
    setPostcodeEdited(true)
    setFieldValue('Address.PostcodeSearched', false)
    setFieldValue('Address.AddressKey', '')
    onChange(e)
  }

  const handleAddressSelect = (e) => {
    const index = e.nativeEvent.target.selectedIndex
    actions.selectAddress(e.nativeEvent.target[index].text)
    onChange(e)
  }

  return (
    <>
      <Label htmlFor='Address.Postcode'>Enter postcode</Label>
      <AddressWrapper>
        <div className='grid-parent grid-45 mobile-grid-45 tablet-grid-45'>
          <UppercaseInput
            id='Address.Postcode'
            name='Address.Postcode'
            placeholder='my postcode is...'
            onChange={handlePostcodeChange}
            onBlur={onBlur}
            error={Boolean(
              touched.Postcode &&
                (errors.Postcode ||
                  (touched.PostcodeSearched && errors.PostcodeSearched)),
            )}
            errorMessage={
              touched.Postcode &&
              (errors.Postcode ||
                (touched.PostcodeSearched && errors.PostcodeSearched))
            }
            value={values.Postcode}
            disallowSpaces={true}
          />
        </div>
        <div className='grid-parent grid-50 prefix-5 mobile-grid-50 mobile-prefix-5 tablet-grid-50 tablet-prefix-5'>
          <AddressButton
            secondary
            id='PostcodeSearch'
            onClick={(e) => onAddressSearch(e, errors)}
            disabled={addressButtonSearchDisabled}
          >
            Look up address
            <ButtonSpinner disabled={addressButtonSearchDisabled} />
          </AddressButton>
        </div>
      </AddressWrapper>
      {(address.SearchResults || []).length > 0 &&
        !postcodeEdited &&
        !Boolean(errors.Postcode) &&
        !addressButtonSearchDisabled && (
          <div className='grid-parent grid-100 mobile-grid-100 tablet-grid-100'>
            <Label htmlFor='Address.AddressKey'>Address</Label>
            <AddressSelect
              onChange={handleAddressSelect}
              id='Address.AddressKey'
              options={address.SearchResults}
              name='Address.AddressKey'
              value={values.AddressKey}
              error={Boolean(touched.AddressKey && errors.AddressKey)}
              errorMessage={touched.AddressKey && errors.AddressKey}
            />
          </div>
        )}
    </>
  )
}

const mapStateToProps = ({ address, formButtons }) => {
  return {
    address: address,
    addressButtonSearchDisabled: formButtons.addressSearch.disabled,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestAddress,
        selectAddress,
        isButtonDisabled,
      },
      dispatch,
    ),
  }
}

Address.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  requestAddress: PropTypes.func,
  updateValidation: PropTypes.func,
  updatePostcode: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Address)
