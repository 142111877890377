import React from 'react'
import { connect } from 'react-redux'
import { Grid } from 'unsemantic'
import { H2 } from '../../components/common/H2'
import { DeclarationModal } from '../../components/DeclarationModal'
import { LegalDeclarationButton } from './Button'
import { acceptLegalDeclarations, updateStep } from '../App/actions'
import { bindActionCreators } from 'redux'
import { LegalDeclarationPageWrapper } from './LegalDeclarationPageWrapper'
import { A } from '../../components/common/A'
import { H3 } from '../../components/common/H3'
import config from '../../config'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { LegalDeclarationAgreeAndContinueText } from './styles'

export class LegalDeclarationPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fullDeclarationModal: false,
      dissallowedOccupationsModal: false,
    }

    this.disallowedOccupationsModalRef = React.createRef()
    this.agreeClick = this.agreeClick.bind(this)
  }

  componentDidMount() {
    this.props.actions.updateStep(2)
    if (document.getElementById('DisallowedOccupationListItem')) {
      const disallowedOccupationsList = document.getElementById(
        'DisallowedOccupationListItem',
      )
      const disallowedOccupationsText = document.createTextNode(
        'Not be in one of the following occupations: ',
      )
      const disallowedOccupationsLink = document.createElement('p')
      disallowedOccupationsLink.innerHTML = 'View'
      disallowedOccupationsLink.setAttribute('id', 'DisallowedOccupationsLink')
      disallowedOccupationsLink.onclick = () => {
        this.showModal('dissallowedOccupationsModal')
      }
      disallowedOccupationsList.appendChild(disallowedOccupationsText)
      disallowedOccupationsList.appendChild(disallowedOccupationsLink)
    }
  }

  showModal(modal) {
    this.setState({ [modal]: true })
  }
  hideModal(modal) {
    this.setState({ [modal]: false })
  }
  agreeClick() {
    this.props.actions.acceptLegalDeclarations()
  }

  getQuoteId() {
    const params = new URL(window.location.href).searchParams
    const quoteId = params.get('q')
    return quoteId
  }

  render() {
    return (
      <LegalDeclarationPageWrapper>
        <DeclarationModal
          id='LegalDeclarationsModal'
          text={this.props.legalDeclaration.FullText}
          title='Full Declaration'
          hideModal={() => this.hideModal('fullDeclarationModal')}
          isVisible={this.state.fullDeclarationModal}
        />
        <DeclarationModal
          id='UnacceptableOccupationsModal'
          text={this.props.legalDeclaration.DisallowedOccupations}
          title='Unacceptable Occupations'
          hideFullDeclaration={() =>
            this.hideModal('dissallowedOccupationsModal')
          }
          isVisible={this.state.dissallowedOccupationsModal}
        />
        <H2>Your policy is</H2>
        <H3>
          Fully comprehensive with £{this.props.price.excess.toFixed(2)} excess
        </H3>
        <H2>Just to check...</H2>
        <div
          id='ShortDeclaration'
          dangerouslySetInnerHTML={{
            __html: this.props.legalDeclaration.ShortText,
          }}
        />
        <Grid desktop='100'>
          <Grid desktop='100' mobile='100' tablet='100'>
            <A
              id='FullDeclaration'
              onClick={() => this.showModal('fullDeclarationModal')}
            >
              Read full declaration here
            </A>
          </Grid>
        </Grid>
        <Grid desktop='100'>
          <Grid desktop='50' mobile='100' tablet='50'>
            <A
              id='PolicyWording'
              href={`${
                config.BASE_API_URL
              }/api/motorQuotePolicies/${this.getQuoteId()}/documents/2`}
              target='_blank'
            >
              Download policy wording here
            </A>
          </Grid>
          <Grid desktop='50' mobile='100' tablet='50'>
            <A
              id='ProductInformation'
              href={`${
                config.BASE_API_URL
              }/api/motorQuotePolicies/${this.getQuoteId()}/documents/31`}
              target='_blank'
            >
              Download insurance product information here
            </A>
          </Grid>
        </Grid>
        <Grid desktop='100'>
          <LegalDeclarationAgreeAndContinueText>
            By clicking{' '}
            {this.props.appData.PartnerPays
              ? "'Get your Policy'"
              : "'Agree and Continue'"}{' '}
            you are confirming you have read, accepted and understood all terms
            and conditions as laid out above.
          </LegalDeclarationAgreeAndContinueText>
          <LegalDeclarationAgreeAndContinueText>
            This policy meets the demands &amp; needs of someone needing to
            insure their vehicle for a short period. You accept that once a
            policy has been processed it is not possible to amend any of the
            details.
          </LegalDeclarationAgreeAndContinueText>
        </Grid>
        <LegalDeclarationButton
          id='TeslaSubmitDeclarationNextButton'
          onClick={this.agreeClick}
          disabled={this.props.legalDeclarationButtonDisabled}
        >
          {this.props.legalDeclarationButtonDisabled
            ? 'Please wait'
            : this.props.appData.PartnerPays
            ? 'Get your policy'
            : 'Agree and Continue'}
          <ButtonSpinner disabled={this.props.legalDeclarationButtonDisabled} />
        </LegalDeclarationButton>
      </LegalDeclarationPageWrapper>
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  return {
    price: state.price,
    legalDeclaration: state.legalDeclaration,
    legalDeclarationButtonDisabled: state.formButtons.legalDeclaration.disabled,
    appData: state.appData,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateStep,
        acceptLegalDeclarations,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LegalDeclarationPage)
