import styled from 'styled-components'
import {
  CookieConsentBannerWrapper,
  CookieConsentButton,
  CookieConsentHeader,
  CookieConsentLink,
} from '../CookieConsentBanner/CookieConsentBanner.styles'

export const CookieManagementBannerWrapper = styled(CookieConsentBannerWrapper)`
  z-index: 99999999;
  @media (max-width: 768px) {
    padding: 8px 12px;
  }
`

export const CookieManagementBannerHeader = styled(CookieConsentHeader)``

export const CookieManagementButton = styled(CookieConsentButton)`
  width: 300px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const CookieManagementButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`

export const CookieManagementLink = styled(CookieConsentLink)``
