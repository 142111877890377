import styled from 'styled-components'

export const BrandLogo = styled.div`
  float: left;
  width: 361px;
  height: 70px;

  @media (max-width: 1170px) {
    width: 300px;
    height: 40px;
    margin: 15px 0 14px;
  }

  @media (max-width: 1024px) {
    width: 205px;
    height: 36px;
    margin: 15px 0 14px;
    max-width: calc(100vw - 130px - 73px);
  }
`

export const BrandLogoAlign = styled.div`
  display: flex;
  width: 395px;
  height: 70px;
  align-items: center;
  grid-gap: 20px;

  @media (max-width: 1170px) {
    height: 46px;
    width: 300px;
    grid-gap: 15px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    transform: none;
    height: 36px;
    grid-gap: 10px;
  }
`

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: opacity 0.2s;
  img,
  svg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    max-height: 68px;
    max-width: 350px;

    @media (max-width: 1024px) {
      max-height: 100%;
      max-width: 200px;
    }
    &.hide-strapline {
      .strapline {
        display: none;
      }
    }
  }
`

export const Image = styled.img`
  max-width: 395px;
  max-height: 100%;
  vertical-align: top;

  @media (max-width: 1170px) {
    max-width: 250px;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`

export const WhiteLabelImage = styled.img`
  display: block;
  height: auto;
  max-width: 50%;
  padding-top: 10px;
`

export const AssociationText = styled.p`
  position: relative;
  top: -10px;
  left: 130px;
  margin: 0;
  width: auto;
  white-space: nowrap;
  color: #fff;
  font-size: 18px;

  @media (max-width: 1170px) {
    left: 100px;
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    left: 80px;
  }

  @media (max-width: 500px) {
    font-size: 10px;
    left: unset;
    top: 0;
  }
`
