import styled from 'styled-components'

export const PolicyStartTimeWrapper = styled.div`
  display: inline-flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  -webkit-box-align: baseline;
  align-items: baseline;
`

export const TimeSeparator = styled.div`
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 22px;
  font-weight: bold;
  padding: 0px 6px;
`
export const IndentedLine = styled.div`
  border-left: 1px solid rgb(38, 75, 148);
  box-sizing: border-box;
  margin-bottom: 1em;
  padding: 1em 0px 1em 1em;
  text-align: left;
`

export const FieldLabel = styled.p`
  margin: 8px 1em 8px 0px;
`
export const TimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  color: rgb(38, 75, 148);
  & div input {
    margin-bottom: 0px;
  }
`
export const DualInputWrapper = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
`
export const DualInput = styled.div`
  flex: 0 0 calc(50% - 0.25em);
  text-align: left;
  position: relative;
  @media (max-width: 515px) {
    flex: 1 1 300px;
    margin: 0;
  }
`
