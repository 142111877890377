import moment from 'moment'
import {
  REQUEST_VEHICLE_SUCCESS,
  REQUEST_VEHICLE_FAILED,
  SET_VEHICLE,
  SET_VEHICLE_TYPE,
  UPDATE_SEARCHED_VEHICLE,
  UPDATE_GROSS_WEIGHT,
  UPDATE_YEAR_OF_MANUFACTURE,
  GET_MAKES_SUCCESS,
  GET_MODELS_SUCCESS,
  GET_VARIANTS_SUCCESS,
  CREATE_UNKNOWN_VEHICLE_SUCCESS,
  UNKNOWN_VEHICLE_REG_SUCCESS,
  SET_VEHICLE_SEARCHED_REG,
  SET_VEHICLE_ERROR,
  UPDATE_VEHICLE_VALUE,
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
} from './constants'
import { REQUEST_QUOTE_SUCCESS, CREATE_QUOTE_SUCCESS } from '../App/constants'
import initialState from '../../initialState'
import {
  REQUEST_POLICY_SUCCESS,
  GET_ANALYSIS_DATA_SUCCESS,
} from '../PolicyConfirmationPage/constants'
import { VEHICLE_VALUES } from '../../components/VehicleValue/constants'
import { stat } from 'fs'

function vehicleReducer(state = initialState.vehicle, action) {
  switch (action.type) {
    case UPDATE_SEARCHED_VEHICLE:
      let vehicle = action.vehicle
      return {
        ...state,
        searched: {
          ...state.searched,
          ...action.vehicle,
          VehicleBuilt: false,
          VehicleFound: false,
        },
        error: state.error,
      }
    case REQUEST_VEHICLE_SUCCESS:
      vehicle = action.vehicle
      return {
        ...state,
        searched: {
          ...state.searched,
          VehicleId: vehicle.VehicleId,
          RegistrationNumber: vehicle.RegistrationNumber,
          Make: vehicle.Make,
          Model: vehicle.Model,
          VehicleType:
            vehicle.VehicleType === 'Motorcycle'
              ? 'Motorbike'
              : vehicle.VehicleType,
          Abi: vehicle.Abi,
          MakeAndModel: vehicle.MakeAndModel,
          DateFirstRegistered: vehicle.DateFirstRegistered,
          vehicleValue: VEHICLE_VALUES.find(
            (vehicleValue) => vehicleValue.value === vehicle.VehicleValueBandId,
          ),
          VehicleFound: true,
          Variant: null,
        },
        error: null,
      }
    case REQUEST_QUOTE_SUCCESS:
      vehicle = action.quote.Vehicle
      return {
        ...state,
        selected: {
          ...state.selected,
          VehicleId: vehicle.VehicleId,
          RegistrationNumber: vehicle.Registration,
          Make: vehicle.Make,
          Model: vehicle.Model,
          Abi: vehicle.Abi,
          VehicleType:
            vehicle.VehicleType === 'Motorcycle'
              ? 'Motorbike'
              : vehicle.VehicleType,
          vehicleValue: VEHICLE_VALUES.find(
            (vehicleValue) =>
              vehicleValue.value === action.quote.VehicleValueBandId,
          ),
          YearOfManufacture: !vehicle.DateOfManufacture
            ? null
            : moment(vehicle.DateOfManufacture).year(),
          GrossWeight: vehicle.GrossWeight,
        },
        searched: {
          ...state.searched,
          VehicleId: vehicle.VehicleId,
          RegistrationNumber: vehicle.Registration,
          VehicleType:
            vehicle.VehicleType === 'Motorcycle'
              ? 'Motorbike'
              : vehicle.VehicleType,
        },
        error: null,
      }
    case CREATE_UNKNOWN_VEHICLE_SUCCESS:
      vehicle = action.vehicle
      return {
        ...state,
        searched: {
          ...state.searched,
          VehicleId: action.vehicle.VehicleId,
          VehicleBuilt: true,
          vehicleValue: {
            description: '£1,500 to £5,000',
            value: 5,
          },
        },
      }
    case SET_VEHICLE:
      vehicle = action.vehicle
      if (
        vehicle &&
        vehicle.VehicleType === COMMERCIAL_VEHICLE &&
        vehicle.GrossWeight > COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT
      ) {
        vehicle = {
          ...vehicle,
          Make: null,
          Model: null,
          Variant: null,
        }
      }

      return {
        ...state,
        searched: {
          ...state.searched,
        },
        selected: vehicle,
      }
    case SET_VEHICLE_TYPE:
      return {
        ...state,
        searched: {
          ...state.searched,
          VehicleType: action.vehicleType,
          Abi: null,
          Model: null,
          Make: '',
          VehicleBuilt: null,
          Variant: null,
          GrossWeight: null,
          YearOfManufacture: null,
        },
      }
    case REQUEST_VEHICLE_FAILED:
      return {
        ...state,
        searched: {
          ...state.searched,
          RegistrationNumber: action.registrationNumber,
          VehicleId: null,
          Make: null,
          Model: null,
          VehicleType: null,
          Abi: null,
          MakeAndModel: null,
          DateFirstRegistered: null,
          VehicleFound: false,
          Variant: null,
        },
        error: action.error,
      }
    case GET_MAKES_SUCCESS:
      return {
        ...state,
        makes: action.makes,
        models: [],
        variants: [],
      }
    case GET_MODELS_SUCCESS:
      return {
        ...state,
        models: action.models,
        variants: [],
      }
    case GET_VARIANTS_SUCCESS:
      return {
        ...state,
        variants: action.variants,
      }
    case UNKNOWN_VEHICLE_REG_SUCCESS:
      return {
        ...state,
        searched: {
          ...state.searched,
          Make: action.manualVehicle.Make,
          Model: action.manualVehicle.Model,
        },
        selected: {
          ...state.selected,
          VehicleId: action.builtVehicle.VehicleId,
          Make: action.manualVehicle.Make,
          Model: action.manualVehicle.Model,
          Abi: action.manualVehicle.Variant,
          VehicleType: action.manualVehicle.VehicleType,
          GrossWeight: action.manualVehicle.GrossWeight,
          YearOfManufacture: action.manualVehicle.YearOfManufacture,
        },
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          Make: action.policy.VehicleSummary.Make,
          Model: action.policy.VehicleSummary.Model,
          RegistrationNumber: action.policy.VehicleSummary.Registration,
        },
      }
    case SET_VEHICLE_SEARCHED_REG:
      return {
        ...state,
        searched: {
          ...state.searched,
          RegistrationNumber: action.vehicleReg,
          VehicleType: action.vehicleType || null,
          VehicleFound: action.vehicleType ? true : null,
        },
      }
    case SET_VEHICLE_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          vehicleValue: VEHICLE_VALUES.find(
            (vehicleValue) =>
              vehicleValue.value === action.quote.VehicleValueBandId,
          ),
        },
      }
    case UPDATE_VEHICLE_VALUE:
      return {
        ...state,
        selected: {
          ...state.selected,
          vehicleValue: action.value,
        },
      }
    case UPDATE_GROSS_WEIGHT:
      return {
        ...state,
        searched: {
          ...state.searched,
          GrossWeight: action.grossWeight,
        },
      }
    case UPDATE_YEAR_OF_MANUFACTURE:
      return {
        ...state,
        searched: {
          ...state.searched,
          YearOfManufacture: action.yearOfManufacture,
        },
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        scheme: action.response.Scheme,
      }
    default:
      return state
  }
}

export default vehicleReducer
