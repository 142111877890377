import React, { useEffect, useState } from 'react'
import CookiePolicyModal from './CookiePolicyModal'
import wordPressApi from '../../api/wordpressApi'
import { WORDPRESS_COOKIE_POLICY_PAGE_NUMBER } from './CookiePolicyModal.constants'

const CookiePolicyModalContainer = (props) => {
  const [cookieText, setCookieText] = useState('')

  const fetchCookiesText = async () => {
    try {
      const response = await wordPressApi.getWordpressContent(
        WORDPRESS_COOKIE_POLICY_PAGE_NUMBER,
      )
      setCookieText(response.data.content.rendered)
    } catch (err) {
      setCookieText('')
    }
  }
  useEffect(() => {
    fetchCookiesText()
  }, [])

  return <CookiePolicyModal cookieText={cookieText} {...props} />
}

export default CookiePolicyModalContainer
