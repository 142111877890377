import React from 'react'
import PropTypes from 'prop-types'
import { BaseSelect } from './BaseSelect'

export const Select = ({
  id,
  value,
  options,
  error,
  onChange,
  placeholder,
  name,
  style,
  myRef,
  ariaLabel,
}) => {
  const className =
    value === null || value === '' || value === 'Uninitialised'
      ? 'placeholder'
      : ''
  return (
    <BaseSelect
      ref={myRef}
      id={id}
      error={error}
      onChange={onChange}
      value={value}
      name={name}
      style={style}
      className={className}
      aria-label={ariaLabel}
    >
      {placeholder && <option value=''>{placeholder}</option>}
      {options.map((option) => (
        <option key={option.id} value={option.value} selected={option.selected}>
          {option.description}
        </option>
      ))}
    </BaseSelect>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      id: PropTypes.number,
      description: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string,
}
