import React from 'react'
import { Grid } from 'unsemantic'
import { NonEuDrivinglicence } from './constants'

import { H1, H2, P, Italic, LI } from './styles'

const DriveawayEligibilityPage = ({
  appData: { Eligibility, HomePagePricing, Affiliate },
}) => {
  const { MinAge, MaxAge, MinVehicleValue, MaxVehicleValue, MaxVehicleAge } =
    Eligibility
  return (
    <Grid desktop='100' mobile='100' tablet='100'>
      <H1>Eligibility</H1>
      <H2>Who we cover</H2>
      <P>
        We cover drivers who are buying a new vehicle and want to collect it and
        take it home before arranging an annual policy.
      </P>
      <P>
        Policies can vary in duration. All cover is fully comprehensive.
      </P>
      <H2>Eligibility criteria</H2>
      <ul>
        <LI>
          You and any additional driver must be aged between {MinAge} and{' '}
          {MaxAge} years old.
        </LI>
        <LI>
          {`You and any additional driver must hold a full current and valid UK ${
            NonEuDrivinglicence.includes(Affiliate.AffiliateRef) ? '' : 'or EU '
          }driving licence.`}
        </LI>
        <LI>
          The minimum vehicle value is {MinVehicleValue} and the maximum is{' '}
          {MaxVehicleValue}. The valuation is based on the price at which the
          vehicle has been sold to you.
        </LI>
        <LI>
          The vehicle must be {MaxVehicleAge} years old or less from the date of
          its first registration.
        </LI>
        <LI>
          If adding an additional driver, they must be your Spouse or Common Law
          Partner and you must both live at the same address.
        </LI>
      </ul>
      <Italic>
        Please ensure you pay close attention to the Proposer’s Declaration for
        the full eligibility criteria and any exclusions.
      </Italic>
    </Grid>
  )
}

export default DriveawayEligibilityPage
