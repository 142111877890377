import React from 'react'
import { connect } from 'react-redux'
import CarImage from '../../../../images/flow-car.png'
import FlowImage from '../../../../images/flow-logo.png'
import {
  FlowCarImage,
  HeaderRow,
  TextRow,
  RedirectingHeader,
  ContentText,
  ImageRow,
  FlowLogo,
  ImageWrapper,
  HeaderRowContainer,
  FlowCarImageWrapper,
  Spinner,
  HeaderWrapper,
  FooterWrapper,
  EmailConfirmationWrapper,
  RedirectingErrorText,
} from './styles'
import { REQUEST_CALLBACK_STATUS } from '../../constants'

const FlowProviderEmail = ({ callbackRequest }) => {
  return (
    <EmailConfirmationWrapper>
      <HeaderWrapper>
        {callbackRequest.status !== REQUEST_CALLBACK_STATUS.ERROR && (
          <>
            <RedirectingHeader>Redirecting to Flow</RedirectingHeader>
            <p>
              <Spinner className='fa fa-pulse fa-spinner'></Spinner>
            </p>
          </>
        )}
        {callbackRequest.status === REQUEST_CALLBACK_STATUS.ERROR && (
          <RedirectingErrorText>
            We’re sorry, but something went wrong and we’re unable to redirect
            you to Flow right now.
            <br />
            Please try again later.
          </RedirectingErrorText>
        )}
      </HeaderWrapper>
      <FooterWrapper>
        <HeaderRowContainer>
          <div></div>
          <HeaderRow>
            <div></div>
            <div></div>
            <FlowCarImageWrapper>
              <FlowCarImage src={CarImage} alt={'Flow'} />
            </FlowCarImageWrapper>
            <div></div>
          </HeaderRow>
          <div></div>
        </HeaderRowContainer>
        <TextRow>
          <div></div>
          <div>
            <ContentText>&nbsp;</ContentText>
            <ImageRow>
              <div></div>
              <ImageWrapper>
                <FlowLogo src={FlowImage} alt={'Flow Logo'} />
              </ImageWrapper>
              <div></div>
            </ImageRow>
          </div>
          <div></div>
        </TextRow>
      </FooterWrapper>
    </EmailConfirmationWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    callbackRequest: state.policy.callbackRequest,
  }
}

export default connect(mapStateToProps)(FlowProviderEmail)
