import axios from 'axios'
import appConfig from '../config'
import { store } from './store'
import antiForgeryApi from './antiForgeryApi'
import { HTTP_CODES } from '../constants'
import { ROUTES } from '../services/constants'
import { history } from '..'

const http = axios.create({
  baseURL: `${appConfig.BASE_API_URL}`,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
})

let cancelSource = axios.CancelToken.source()

http.interceptors.request.use(
  (config) => {
    const antiForgeryToken = store.antiForgeryToken
    if (antiForgeryToken)
      config.headers['X-RequestVerificationToken'] = antiForgeryToken

    config.cancelToken = cancelSource.token
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

http.interceptors.response.use(null, (error) => {
  if (error.config && error.response) {
    switch (error.response.status) {
      case HTTP_CODES.ERROR.Unauthorized:
        return antiForgeryApi.getAntiForgeryToken().then((response) => {
          const antiForgeryToken =
            response.headers['x-requestverificationtoken']
          store['antiForgeryToken'] = antiForgeryToken
          if (antiForgeryToken)
            error.config.headers['X-RequestVerificationToken'] =
              antiForgeryToken
          return axios.request(error.config)
        })
      case HTTP_CODES.ERROR.ServiceUnavailable:
        cancelSource.cancel()
        history.push(ROUTES.HOLDING_PAGE)
        return Promise.resolve()
      case HTTP_CODES.ERROR.InternalServerError:
        cancelSource.cancel()
        history.push(ROUTES.ERROR)
        return Promise.resolve()
      case HTTP_CODES.ERROR.BadRequest:
      case HTTP_CODES.ERROR.Forbidden:
      case HTTP_CODES.ERROR.TooManyRequests:
      case HTTP_CODES.ERROR.NotFound:
        return Promise.reject(error)
      default:
        return Promise.resolve()
    }
  } else {
    return Promise.reject(error)
  }
})

export default http
