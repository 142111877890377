import { setVehicleSearchedReg, setVehicleError } from '../Vehicle/actions'
import moment from 'moment'
import { put, takeLatest } from 'redux-saga/effects'
import { INIT_INVALID_REG_PAGE } from './constants'
import { IMMEDIATE_START_TEXT } from '../RiskDataCollectionPage/constants'
import { handleQueryString } from '../../services/handlers'
import { updateRiskData } from '../RiskDataCollectionPage/actions'
import { initInvalidRegPageSuccess, initInvalidRegPageFailure } from './actions'
import { isLoading } from '../App/actions'
import { history } from '../../'

function* initInvalidRegPage() {
  try {
    yield put(isLoading(true))
    const vehicleReg = handleQueryString().RegistrationNumber
    const vehicleType = handleQueryString().VehicleType

    if (history.location.state.error === 'VehicleNotFound') {
      yield put(setVehicleError('NotFound'))
    } else {
      yield put(setVehicleError(history.location.state.error))
    }

    yield* setStateFromParams(vehicleReg, vehicleType)
    yield put(initInvalidRegPageSuccess())
    yield put(isLoading(false))
  } catch (e) {
    yield put(initInvalidRegPageFailure(e))
    yield put(isLoading(false))
  }
}

export default function* invalidRegPageSaga() {
  yield takeLatest(INIT_INVALID_REG_PAGE, initInvalidRegPage)
}

function* setStateFromParams(vehicleReg, vehicleType) {
  const startTime = handleQueryString().StartTime
  const startDate = moment().format('YYYY-MM-DD')
  yield put(
    updateRiskData({
      PolicyStartDate:
        startTime !== IMMEDIATE_START_TEXT
          ? {
              value: moment(startDate + ' ' + startTime, 'YYYY-MM-DDTHH:mm'),
              manuallyEdited: true,
            }
          : { value: null, manuallyEdited: false },
    }),
  )

  yield put(setVehicleSearchedReg(vehicleReg, vehicleType))
}
