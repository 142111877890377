import React, { useCallback, useState } from 'react'

import CookieManagementModal from './CookieManagementModal'
import { setCookie } from '../../services/cookieService'
import {
  ANALYTICS_TRACKING_TOGGLE_NAME,
  RECORDING_TRACKING_TOGGLE_NAME,
} from './CookieManagementArea.constants'

const CookieManagementAreaContainer = ({ onCookieSaveClick, ...props }) => {
  const [optionalCookies, setOptionalCookies] = useState([
    {
      id: 1,
      name: ANALYTICS_TRACKING_TOGGLE_NAME,
      value: false,
      description: `We'd like to set non-essential analytics and marketing cookies that help us provide a better experience to our users. These help us improve our website and marketing by collecting and reporting information on the campaigns and web pages you interact with. It also helps us to target our marketing campaigns to people who are most likely to be interested in our services.`,
    },
    {
      id: 2,
      name: RECORDING_TRACKING_TOGGLE_NAME,
      value: false,
      description: `We'd also like to set a non-essential cookie which enables us to playback your journey on our website to assist with troubleshooting and to help us improve our website based on the behaviour of our customers.`,
    },
  ])

  const handleCookieSaveClick = useCallback(() => {
    // Handle array and save values as params
    const analyticsTrackingValue = optionalCookies.find(
      (cookie) => cookie.name === ANALYTICS_TRACKING_TOGGLE_NAME,
    ).value
    const recordingTrackingValue = optionalCookies.find(
      (cookie) => cookie.name === RECORDING_TRACKING_TOGGLE_NAME,
    ).value
    onCookieSaveClick(analyticsTrackingValue, recordingTrackingValue)
  }, [setCookie, optionalCookies])

  const toggleOptionalCookies = (cookieId) => {
    let newOptionalCookies = [...optionalCookies]
    const valueToUpdate = newOptionalCookies.find(
      (cookie) => cookie.id === cookieId,
    ).value
    newOptionalCookies.find((cookie) => cookie.id === cookieId).value =
      !valueToUpdate
    setOptionalCookies(newOptionalCookies)
  }

  return (
    <CookieManagementModal
      optionalCookies={optionalCookies}
      toggleOptionalCookies={toggleOptionalCookies}
      onClick={handleCookieSaveClick}
      {...props}
    />
  )
}

export default CookieManagementAreaContainer
