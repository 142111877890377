import styled from 'styled-components'

export const ModalContent = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  text-align: left;

  .list-declaration {
    margin: 0;
    li {
      margin-bottom: 16px;
      padding: 0;
      border-top: none;
    }
    ul {
      padding-left: 40px;

      li {
        list-style: unset;
      }
    }

    @media (max-width: 500px) {
      padding-left: 0;
      ul {
        padding-left: 20px;
      }
    }
  }

  .list-declaration-details li {
    margin-bottom: 4px;
  }
`
