import styled from 'styled-components'

export const PriceSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 395px;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  background: #264694;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 0 10px;
  box-sizing: border-box;
  margin: auto;

  .cover {
    display: inline;
    font-weight: 600;
    max-width: 130px;
  }

  .price {
    display: block;
    font-family: ${(props) => props.theme.headerFontFamily};
    font-size: 32px;
    margin-top: 5px;

    & span {
      font-family: ${(props) => props.theme.fontFamily};
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -6px;
    padding: 6px 10px;
    font-size: 10px;
    max-width: none;

    .price {
      display: inline;
      font-size: 22px;
      vertical-align: middle;

      & span {
        font-size: 10px;
        vertical-align: top;
      }
    }
  }
`
