import {
  GET_INTRODUCER_SUCCESS,
  GET_INTRODUCER_BRANCHES,
  GET_INTRODUCER_BRANCHES_SUCCESS,
  GET_INTRODUCER_BRANCHES_FAILURE,
  SET_INTRODUCER_BRANCH,
} from './constants'
import initialState from '../../initialState'
import { REQUEST_QUOTE_SUCCESS } from '../App/constants'
import { REQUEST_VEHICLE_SUCCESS } from '../Vehicle/constants'

function driveawayReducer(state = initialState.driveaway, action) {
  switch (action.type) {
    case GET_INTRODUCER_SUCCESS:
      return {
        ...state,
        AffiliateName: action.data.IntroducerName,
      }
    case GET_INTRODUCER_BRANCHES:
      return {
        ...state,
        IsLoading: true,
      }
    case GET_INTRODUCER_BRANCHES_SUCCESS:
      const branch =
        action.data.Branches.length === 1 ? action.data.Branches[0] : null
      return {
        ...state,
        Branches: action.data.Branches,
        SelectedBranch: branch,
        IsLoading: false,
      }
    case REQUEST_VEHICLE_SUCCESS:
      if (action.branchesDrivenByStocklist) {
        const preSelectedBranch =
          action.vehicle.IntroducerBranches.length === 1
            ? action.vehicle.IntroducerBranches[0]
            : null
        return {
          ...state,
          SelectedBranch: preSelectedBranch,
          Branches: action.vehicle.IntroducerBranches,
        }
      }
      return {
        ...state,
      }
    case GET_INTRODUCER_BRANCHES_FAILURE:
      return {
        ...state,
        IsLoading: false,
      }
    case SET_INTRODUCER_BRANCH:
      return {
        ...state,
        SelectedBranch: action.branch,
      }
    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        SelectedBranch: action.quote.IntroducerBranch,
        Branches: action.quote.IntroducerBranches,
      }
    default:
      return state
  }
}

export default driveawayReducer
