import React from 'react'
import Logo from '../Logo'
import HeaderWrapper from './HeaderWrapper'
import TrustPilot from '../TrustPilot'

import {
  HeaderContainer,
  TeslaBrand,
  HeaderLinkWrapper,
  HeaderLinkStyled,
} from './styles'
import { MobileNav } from '../MobileNavigation'
import { ROUTES } from '../../services/constants'
import { AFFILIATE_TYPES } from '../Logo/constants'

const Header = ({ AppData }) => {
  return (
    <HeaderWrapper>
      <MobileNav />
      <HeaderContainer>
        <TeslaBrand>
          <Logo AppData={AppData} />
        </TeslaBrand>
        <TrustPilot AppData={AppData} />
        <HeaderLinkWrapper>
          {AppData.Affiliate.AffiliateType === AFFILIATE_TYPES.WHITE_LABEL ? (
            <HeaderLinkStyled
              id='EligibiltyHeaderLink'
              href={ROUTES.ELIGIBILITY}
              target='_blank'
              rel='noopener noreferrer'
            >
              Eligibility
            </HeaderLinkStyled>
          ) : null}
          <HeaderLinkStyled
            id='FAQsHeaderLink'
            href={ROUTES.FAQ}
            target='_blank'
            rel='noopener noreferrer'
          >
            FAQs
          </HeaderLinkStyled>
          <HeaderLinkStyled
            id='ClaimsHeaderLink'
            href={ROUTES.CLAIMS}
            target='_blank'
            rel='noopener noreferrer'
          >
            Claims
          </HeaderLinkStyled>
          <HeaderLinkStyled
            id='ContactHeaderLink'
            href={ROUTES.CONTACT_US}
            target='_blank'
            rel='noopener noreferrer'
          >
            Contact us
          </HeaderLinkStyled>
          <HeaderLinkStyled
            id='PrivacyAndCookiesHeaderLink'
            href={ROUTES.PRIVACY_AND_COOKIES}
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy &amp; cookies
          </HeaderLinkStyled>
          <HeaderLinkStyled
            id='T&CsHeaderLink'
            href={ROUTES.TERMS_AND_CONDITIONS}
            target='_blank'
            rel='noopener noreferrer'
          >
            T &amp; Cs
          </HeaderLinkStyled>
        </HeaderLinkWrapper>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default Header
