import React from 'react'
import Stepper from 'react-stepper-horizontal'
import tick from '../../images/breadcrumb-tick-white.png'
import { Wrapper } from './Wrapper'
import { withTheme } from 'styled-components'

const Breadcrumb = ({ step, theme, stepCount }) => {
  const steps = Array(stepCount).fill({ icon: tick })
  return (
    <Wrapper id='Breadcrumb'>
      <Stepper
        steps={steps}
        activeStep={step}
        activeColor={theme.breadcrumb.activeColor}
        activeBorderStyle='solid'
        activeBorderColor={theme.breadcrumb.activeBorderColor}
        completeBorderStyle='solid'
        defaultBorderStyle='solid'
        defaultBorderColor={theme.breadcrumb.defaultBorderColor}
        defaultBarColor={theme.breadcrumb.defaultBarColor}
        defaultColor={theme.breadcrumb.defaultColor}
        completeBarColor={theme.breadcrumb.completeBarColor}
        completeBorderColor={theme.breadcrumb.completeBorderColor}
        completeColor={theme.breadcrumb.completeColor}
        circleFontSize={0}
      />
    </Wrapper>
  )
}

export default withTheme(Breadcrumb)
