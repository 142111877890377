import React from 'react'
import Logo from '../../../images/A-PlanLogo.jpg'
import {
  Wrapper,
  AnnualInsurance,
  AnnuaLInsuranceLogo,
  AnnualInsuranceHeader,
  AnnualInsuranceHeaderWrapper,
} from '../styles'
import Content from '../Content'

const APlanProvider = ({ requestCallback, callbackRequest }) => {
  const style = { backgroundColor: '#F33', highlightColor: '#F33' }
  return (
    <Wrapper styleOverride={style}>
      <AnnualInsuranceHeaderWrapper styleOverride={style}>
        <AnnualInsuranceHeader styleOverride={style}>
          NEED ANNUAL INSURANCE?
        </AnnualInsuranceHeader>
      </AnnualInsuranceHeaderWrapper>
      <p>
        With temporary insurance sorted, if you're now on the lookout for an
        annual policy, our friends at{' '}
        <AnnualInsurance styleOverride={style}>A-Plan</AnnualInsurance> are here
        to help!
      </p>
      <p style={{ textAlign: 'left' }}>
        <strong>
          Choose <AnnualInsurance styleOverride={style}>A-Plan</AnnualInsurance>{' '}
          for:
        </strong>
      </p>
      <ul style={{ textAlign: 'left' }}>
        <li>
          Price comparison from a select panel of high quality insurers -{' '}
          <strong>New customers save £128 on average!</strong>
        </li>
        <li>Tailored policies to suit your needs</li>
        <li>Access to 24/7 &amp; 365 A-Plan Rescue breakdown</li>
        <li>Friendly, helpful and qualified advisors</li>
      </ul>
      <p>
        <strong>
          Save time and money on your car insurance by speaking to{' '}
          <AnnualInsurance styleOverride={style}>A-Plan</AnnualInsurance> today!
          Click below to request a call back shortly.
        </strong>
      </p>
      <p>
        <em>
          By requesting a call back, you understand that we will pass your
          details to{' '}
          <AnnualInsurance styleOverride={style}>A-Plan</AnnualInsurance> for
          the purpose of providing you with a quotation for annual insurance.
        </em>
      </p>
      <Content
        callbackRequest={callbackRequest}
        requestCallback={requestCallback}
        annualInsuranceProvider='A-Plan'
      />
      <AnnuaLInsuranceLogo src={Logo} alt={'A-Plan Logo'} />
    </Wrapper>
  )
}

export default APlanProvider
