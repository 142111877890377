import React, { useState } from 'react'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { AccordionHeader, AccordionBody, AccordionChevron } from './style'

const Accordion = ({ Header, children }) => {
  const [isActive, setIsActive] = useState(null)

  return (
    <>
      <AccordionHeader
        onClick={() => {
          setIsActive(!isActive)
        }}
      >
        {Header}
        <AccordionChevron
          icon={faChevronRight}
          className={isActive ? 'Active' : ''}
        />
      </AccordionHeader>
      <AccordionBody className={isActive ? 'Active' : ''}>
        {children}
      </AccordionBody>
    </>
  )
}

export default Accordion
