import {
  TOGGLE_ADDITIONAL_DRIVER,
  UPDATE_ADDITIONAL_DRIVER,
  UPDATE_ADDITIONAL_DRIVER_VALIDATION,
} from './constants'

export function toggleAdditionalDriver(additionalDriverSelected) {
  return {
    type: TOGGLE_ADDITIONAL_DRIVER,
    additionalDriverSelected,
  }
}

export function updateAdditionalDriver(fields, additionalDriverNumber) {
  return {
    type: UPDATE_ADDITIONAL_DRIVER,
    fields,
    additionalDriverNumber,
  }
}

export function updateAdditionalDriverValidation(
  fields,
  additionalDriverNumber,
) {
  return {
    type: UPDATE_ADDITIONAL_DRIVER_VALIDATION,
    fields,
    additionalDriverNumber,
  }
}
