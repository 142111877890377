import { takeLatest, call, select } from 'redux-saga/effects'
import paymentApi from '../../api/paymentApi'
import { CREATE_STRIPE_PAYMENT, GET_PRICE_INFO } from './constants'
import { history } from '../..'
import { getRiskData, getPriceData } from '../LegalDeclarationModal/selector'
import { getQueryString } from '../RiskDataCollectionPage/selector'

function* createStripePayment({ payload }) {
  const riskData = yield select(getRiskData)
  const priceData = yield select(getPriceData)
  const queryString = yield select(getQueryString)
  try {
    const result = yield call(
      paymentApi.createStripePayment,
      '/api/policy/payment/stripe',
      payload.paymentDetails,
    )
    handleServerResponse(
      result.data,
      payload.stripeActions,
      riskData,
      queryString,
      priceData,
      payload.actions,
    )
  } catch (e) {
    handleStripeErrors(e.response.status, payload.actions, queryString)
  }
}

function handleServerResponse(
  response,
  stripeActions,
  riskData,
  queryString,
  priceData,
  actions,
) {
  const { QuoteId } = riskData
  const { EncryptedString, TotalPrice } = priceData
  if (response.PaymentStatus !== 200) {
    switch (response.PaymentStatus) {
      case 1:
        actions.createStripePaymentFailure(
          'We are sorry your payment has been declined. Please try again.',
        )
        break
      case 2:
        actions.createStripePaymentFailure(
          'We are sorry there has been an error with your payment. Please try again.',
        )
        break
      case 3:
        stripeActions
          .handleCardAction(response.PaymentIntent)
          .then((result) => {
            if (result.error) {
              // Show error in payment form
              actions.createStripePaymentFailure(result.error.message)
              actions.enableAllButtons()
            } else {
              const paymentDetails = {
                payment_intent_id: result.paymentIntent.id,
                amount: TotalPrice.toFixed(2).toString().replace('.', ''),
                QuoteId: {
                  Value: QuoteId,
                },
                ValidationData: {
                  Value: EncryptedString,
                },
              }

              try {
                paymentApi
                  .createStripePayment(
                    '/api/policy/payment/stripe',
                    paymentDetails,
                  )
                  .then((response) => {
                    handleServerResponse(
                      response.data,
                      stripeActions,
                      riskData,
                      queryString,
                      priceData,
                      actions,
                    )
                  })
              } catch (e) {
                handleStripeErrors(e.response, actions, queryString)
              }
            }
          })
        break
      case 8:
        actions.createStripePaymentFailure(
          'We are sorry your payment has been declined. Please use an alternative payment method and try again.',
        )
        break
      case 9:
        actions.createStripePaymentFailure(
          'We are sorry your payment has been declined. Please double check your card details and try again.',
        )
        break
      case 10:
        actions.createStripePaymentFailure(
          'We are already processing your payment. Please wait for further instruction.',
        )
        break
      case 11:
        actions.createStripePaymentFailure(
          'We are sorry your payment has been declined. Please contact your card issuer.',
        )
        break
      case 12:
        actions.createStripePaymentFailure(
          'We are sorry this payment method has expired. Please use an alternative and try again.',
        )
        break
      case 13:
        actions.createStripePaymentFailure(
          'There has been a problem with your payment. Please try again.',
        )
        break
      case 14:
        actions.createStripePaymentFailure(
          'There has been an issue with your payment. Please contact your card issuer.',
        )
        break
      default:
        actions.createStripePaymentFailure(
          'There has been an issue with your payment. Please try again.',
        )
        break
    }

    actions.enableAllButtons()
  } else {
    history.push(`/policy/confirmation${queryString}&t=${Date.now()}`)
  }
}

const handleStripeErrors = (statusCode, actions, queryString) => {
  switch (statusCode) {
    case 400:
      actions.createStripePaymentFailure(
        'We are sorry there has been an issue. Please try your payment again.',
      )
      actions.enableAllButtons()
      break
    case 403:
      actions.fraudFailure()
      history.push({
        pathname: `/quote/driving-licence`,
        search: `${queryString}`,
        state: {
          fraudFailure: true,
        },
      })
      break
    default:
      actions.createStripePaymentFailure(
        'We are sorry there has been an issue. Please try your payment again.',
      )
      actions.enableAllButtons()
      break
  }
}

const startStripePayment = (
  stripeActions,
  actions,
  { riskData, priceData, stripeElement },
) => {
  stripeActions.createPaymentMethod('card', stripeElement).then((result) => {
    actions.setPaymentInProgress()
    if (result.error) {
      // Show error in payment form
      actions.createStripePaymentFailure(result.error.message)
      actions.enableAllButtons()
    } else {
      const { TotalPrice, EncryptedString } = priceData
      const paymentDetails = {
        payment_method_id: result.paymentMethod.id,
        amount: TotalPrice.toFixed(2).toString().replace('.', ''),
        QuoteId: {
          value: riskData.QuoteId,
        },
        ValidationData: {
          Value: EncryptedString,
        },
        Free30DayGAPInsuranceIncluded:
          priceData.FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
      }
      actions.createStripePayment({
        paymentDetails: paymentDetails,
        stripeActions,
        actions,
      })
    }
  })
}

function* getPriceInfo({ payload }) {
  const { actions, stripeActions, stripeElement } = payload
  const priceData = yield select(getPriceData)
  const riskData = yield select(getRiskData)

  startStripePayment(stripeActions, actions, {
    riskData,
    priceData,
    stripeElement,
  })
}

export default function* stripeSaga() {
  yield takeLatest(CREATE_STRIPE_PAYMENT, createStripePayment)
  yield takeLatest(GET_PRICE_INFO, getPriceInfo)
}
