import React from 'react'
import Proptypes from 'prop-types'
import { Select } from '../../components/common/Select'
import { ENQUIRYTYPE_OPTIONS } from './constants'
import { ErrorMessage } from '../../components/common/ErrorMessage'

export const EnquiryType = ({ name, value, error, onChange }) => {
  return (
    <>
      <Select
        id='EnquiryType'
        placeholder='Please select'
        options={ENQUIRYTYPE_OPTIONS}
        name={name}
        value={value}
        onChange={onChange}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </>
  )
}

EnquiryType.propTypes = {
  name: Proptypes.string.isRequired,
  value: Proptypes.string,
}
