import moment from 'moment'

export const createSplitPolicyStartDateObject = ({ value, manuallyEdited }) => {
  if (value && manuallyEdited) {
    return {
      date: moment(value).format('YYYY-MM-DD'),
      hour: moment(value).format('HH'),
      minute: moment(value).format('mm'),
      manuallyEdited,
    }
  } else {
    return {
      date: '',
      hour: '',
      minute: '',
      manuallyEdited,
    }
  }
}
