import moment from 'moment'
import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Grid, GridContainer } from 'unsemantic'
import { ErrorContainer } from '../../components/ErrorContainer'
import GapActivationModal from '../../components/GapActivationModal'
import { ErrorIcon } from '../../components/Message/ErrorIcon'
import { MessageContent } from '../../components/Message/MessageContent'
import { MessageHeader } from '../../components/Message/MessageHeader'
import { PriceSummary } from '../../components/PriceSummary'
import { Button } from '../../components/common/Button'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { ClearFix } from '../../components/common/ClearFix'
import { H2 } from '../../components/common/H2'
import { ROUTES } from '../../services/constants'
import { updateStep } from '../App/actions'
import { sendEmailQuote } from '../EmailQuoteModal/actions'
import QuoteSummary from '../QuoteSummary'
import {
  isButtonDisabled,
  updateQuote,
  updateRiskData,
  updateValidation,
} from '../RiskDataCollectionPage/actions'
import { Label } from './Label'
import { RiskDataValue } from './RiskDataValue'
import { Wrapper } from './Wrapper'
import './driving-licence.css'

export const AntiFraudSmallPrint = styled.p`
  font-size: 12px;
`

export const ContinueButton = styled(Button)`
  width: 100%;
`

const DrivingLicence = (props) => {
  useEffect(() => {
    props.actions.updateStep(1)
  }, [])
  const freeGAPInsuranceAvailable = useSelector(
    (state) => state.price.FreeGAPInsuranceCover.FreeGAPInsuranceAvailable,
  )
  const gapActivationLinkIncluded = useSelector(
    (state) =>
      state.price.FreeGAPInsuranceCover.FreeGapActivationJourneyIncluded,
  )

  const handleSelect = (event) => {
    var index = event.nativeEvent.target.selectedIndex

    props.actions.updateRiskData({
      [event.target.name]: {
        value: event.nativeEvent.target[index].value,
        description: event.nativeEvent.target[index].text,
      },
    })
  }

  const handleContinueClick = () => {
    props.actions.isButtonDisabled({
      buttonName: 'driverLicence',
      disabled: true,
    })

    props.actions.updateQuote({
      partialValidation: true,
      confirmRiskData: true,
      getPrice: true,
      requestConfusedQuickQuote: true,
      gapActivationIncluded: gapActivationLinkIncluded,
    })
  }

  const handleValidation = (field, message) => {
    props.actions.updateValidation({
      [`${field}Error`]: message,
    })
  }

  const onEmailClick = () => {
    props.actions.sendEmailQuote()
  }

  let fraudFailure = props.location.state
    ? props.location.state.fraudFailure
    : null

  const AddressInfo = props.address.SelectedAddress
    ? props.address.SelectedAddress
    : `${props.address.AddressLineOne} ${props.address.AddressLineTwo} ${props.address.AddressLineThree} ${props.address.AddressLineFour} ${props.address.Postcode}`

  return (
    <>
      <QuoteSummary updateQuote={true} />
      {props.price.TotalPrice > 0 && !props.appData.PartnerPays && (
        <>
          <GridContainer>
            <PriceSummary
              price={props.price}
              affiliate={props.appData.Affiliate}
            />
          </GridContainer>
        </>
      )}
      {fraudFailure && (
        <ErrorContainer>
          <Grid desktop='20' tablet='20' mobile='20'>
            <ErrorIcon className='fa fa-exclamation-triangle'></ErrorIcon>
          </Grid>
          <Grid desktop='80' tablet='80' mobile='80'>
            <MessageHeader>
              There has been an issue with your quote.
            </MessageHeader>
            <MessageContent>
              Please ensure all data is valid and try again.
            </MessageContent>
          </Grid>
          <ClearFix />
        </ErrorContainer>
      )}
      {/* {freeGAPInsuranceAvailable && <GapModal />} */}
      {freeGAPInsuranceAvailable && <GapActivationModal />}

      <H2>Driver details</H2>

      <GridContainer>
        <Grid
          className='driving-licence-wrapper'
          desktop='70'
          tablet='70'
          desktop-prefix='15'
          tablet-prefix='15'
          mobile='100'
          parent
        >
          <Wrapper>
            <H2>Main driver</H2>
            <GridContainer>
              <Grid desktop='25' tablet='30' mobile='30'>
                <Label>Name:</Label>
              </Grid>
              <Grid desktop='75' tablet='70' mobile='70'>
                <RiskDataValue>
                  {props.riskData.Forename} {props.riskData.Surname}
                </RiskDataValue>
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid desktop='25' tablet='30' mobile='30'>
                <Label>D.O.B:</Label>
              </Grid>
              <Grid desktop='75' tablet='70' mobile='70'>
                <RiskDataValue>
                  {props.riskData.DateOfBirth
                    ? moment(props.riskData.DateOfBirth).format('DD/MM/YYYY')
                    : null}
                </RiskDataValue>
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid desktop='25' tablet='30' mobile='30'>
                <Label>Address:</Label>
              </Grid>
              <Grid desktop='75' tablet='70' mobile='70'>
                <RiskDataValue>{AddressInfo}</RiskDataValue>
              </Grid>
            </GridContainer>

            {props.riskData.AdditionalDriver.Required && (
              <>
                {/* Need to remember to use index if we ever add more than one additional driver */}
                <H2>Additional driver </H2>
                <GridContainer>
                  <Grid desktop='25' tablet='30' mobile='30'>
                    <Label>Name:</Label>
                  </Grid>
                  <Grid desktop='75' tablet='70' mobile='70'>
                    <RiskDataValue>
                      {props.riskData.AdditionalDriver.Forename}{' '}
                      {props.riskData.AdditionalDriver.Surname}
                    </RiskDataValue>
                  </Grid>
                </GridContainer>
                <GridContainer>
                  <Grid desktop='25' tablet='30' mobile='30'>
                    <Label>D.O.B:</Label>
                  </Grid>
                  <Grid desktop='75' tablet='70' mobile='70'>
                    <RiskDataValue>
                      {props.riskData.AdditionalDriver.DateOfBirth
                        ? moment(
                            props.riskData.AdditionalDriver.DateOfBirth,
                          ).format('DD/MM/YYYY')
                        : null}
                    </RiskDataValue>
                  </Grid>
                </GridContainer>
              </>
            )}
            <GridContainer>
              <Grid desktop='75' tablet='75' mobile='70'>
                <RiskDataValue>
                  <Link
                    id='AmendDetails'
                    to={`${ROUTES.QUOTE_DRIVER_DETAILS}?q=${props.quoteId}`}
                  >
                    Amend details
                  </Link>
                </RiskDataValue>
              </Grid>
            </GridContainer>
          </Wrapper>
        </Grid>
      </GridContainer>

      {!props.vehicle.selected.RegistrationNumber && (
        <ErrorContainer>
          <Grid desktop='20' tablet='20' mobile='20'>
            <ErrorIcon className='fa fa-exclamation-triangle'></ErrorIcon>
          </Grid>
          <Grid desktop='80' tablet='80' mobile='80'>
            <MessageContent>
              We are unable to proceed any further without a registration.
              Please enter a valid reg to continue.
            </MessageContent>
          </Grid>
          <ClearFix />
        </ErrorContainer>
      )}
      {!props.price.Underwriter &&
        props.vehicle.selected.RegistrationNumber && (
          <ErrorContainer>
            <Grid desktop='20' tablet='20' mobile='20'>
              <ErrorIcon className='fa fa-exclamation-triangle'></ErrorIcon>
            </Grid>
            <Grid desktop='80' tablet='80' mobile='80'>
              <MessageContent id='DeclineMessage'>
                We have been unable to offer you a quote at this time.{' '}
              </MessageContent>
              <MessageContent>
                We are regularly reviewing our products so we may be able to
                cover you in the future.
              </MessageContent>
            </Grid>
            <ClearFix />
          </ErrorContainer>
        )}
      {props.price.Underwriter && props.vehicle.selected.RegistrationNumber && (
        <Grid desktop='100'>
          <AntiFraudSmallPrint>
            By proceeding, you agree you have taken care not to make any
            misrepresentation of the information provided.{' '}
          </AntiFraudSmallPrint>
          <ContinueButton
            id='TeslaSubmitPricePageNextButton'
            onClick={handleContinueClick}
            disabled={props.driverLicenceButtonDisabled}
          >
            {props.driverLicenceButtonDisabled ? 'Please wait' : 'Continue'}
            <ButtonSpinner disabled={props.driverLicenceButtonDisabled} />
          </ContinueButton>
        </Grid>
      )}
    </>
  )
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    validation: state.validation.riskDataCollectionPage,
    address: state.address,
    price: state.price,
    vehicle: state.vehicle,
    location: state.router.location,
    quoteId: state.riskData.QuoteId,
    driverLicenceButtonDisabled: state.formButtons.driverLicence.disabled,
    appData: state.appData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateQuote,
        updateRiskData,
        updateValidation,
        updateStep,
        sendEmailQuote,
        isButtonDisabled,
      },
      dispatch,
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DrivingLicence),
)
