export const UPSELL_SET_VEHICLE_ERROR_CODE =
  'src/RiskCollection/UPSELL_SET_VEHICLE_ERROR_CODE'

export const UPSEll_SET_VEHICLE_REGISTRATION =
  'src/RiskCollection/UPSEll_SET_VEHICLE_REGISTRATION'

export const UPSEll_SET_UPSELL_ENABLED =
  'src/RiskCollection/UPSEll_SET_UPSELL_ENABLED'

export const UPSEll_SET_UPSELL_ENABLED_SUCCESS =
  'src/RiskCollection/UPSEll_SET_UPSELL_ENABLED_SUCCESS'
