export const CREATE_JUDO_PAYMENT = 'src/JudoPay/CREATE_JUDO_PAYMENT'
export const CREATE_JUDO_PAYMENT_SUCCESS =
  'src/JudoPay/CREATE_JUDO_PAYMENT_SUCCESS'
export const CREATE_JUDO_PAYMENT_FAILURE =
  'src/JudoPay/CREATE_JUDO_PAYMENT_FAILURE'

export const SEND_3D_SECURE_DATA = 'src/JudoPay/SEND_3D_SECURE_DATA'
export const SEND_3D_SECURE_DATA_SUCCESS =
  'src/JudoPay/SEND_3D_SECURE_DATA_SUCCESS'
export const SEND_3D_SECURE_DATA_LINK = 'src/JudoPay/SEND_3D_SECURE_DATA_LINK'

export const CREATE_JUDO_PAYMENT_LINK_REL = 'Judo payment request'

export const DISABLE_PAYMENT_BUTTON = 'src/JudoPay/DISABLE_PAYMENT_BUTTON'
