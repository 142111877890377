import styled from 'styled-components'
import { H2 } from '../../components/common/H2'

export const PaymentHeader = styled(H2)`
  margin: 0;
`

export const StripePaymentWrapper = styled.div`
  margin-top: 3em;
  margin-bottom: 7em;
`
