import styled from 'styled-components'

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.header.background};
  padding: 21px 0;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    height: 36px;
    padding: 14px 0 15px;
  }
`

export default HeaderWrapper
