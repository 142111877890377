import React from 'react'
import validator from 'validator'
import { ErrorMessage } from '../common/ErrorMessage'
import { RegistrationInput } from './styles'
import { ChangeVehicleGBIcon } from './styles'

export class VehicleRegistration extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.onRegInput = this.onRegInput.bind(this)
  }

  handleChange = (event) => {
    let message = this.handleValidation(event)
    this.props.onChange(event, message)
  }

  onRegInput = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase()
    e.target.value = e.target.value.replace(/\s/g, '')
  }

  handleValidation = (event) => {
    let message = validator.matches(
      event.target.value.trim().replace(/\s/g, ''),
      /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/,
    )
      ? ''
      : 'Please enter a valid Registration'

    return message
  }

  render() {
    return (
      <>
        <ChangeVehicleGBIcon>GB</ChangeVehicleGBIcon>
        <RegistrationInput
          id={this.props.id || 'RegistrationInput'}
          placeholder={this.props.placeholder || 'My reg number is...'}
          onChange={this.handleChange}
          onBlur={this.handleChange}
          onInput={this.onRegInput}
          name={this.props.name || 'RegistrationInput'}
          value={this.props.value}
          error={this.props.error}
          ariaLabel='RegistrationInput'
        />
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </>
    )
  }
}

export default VehicleRegistration
