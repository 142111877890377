import { REQUEST_PRICE } from '../constants'

import { call, put, takeLatest, select } from 'redux-saga/effects'

import quoteApi from '../../../api/quoteApi'

import { getPriceLink, getRiskData } from '../selector'

import { requestPriceSuccess, requestPriceFailure } from '../actions'

export function* requestPrice() {
  try {
    const priceLink = yield select(getPriceLink)
    const riskData = yield select(getRiskData)
    const freeGAPInsuranceIncluded = yield select(
      (state) =>
        state.price.FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
    )

    const price = yield call(quoteApi.getPrice, priceLink.Href, {
      value: riskData.QuoteId,
      Free30DayGAPInsuranceIncluded: freeGAPInsuranceIncluded,
    })
    yield put(requestPriceSuccess(price.data))
  } catch (e) {
    yield put(requestPriceFailure(e))
  }
}

export default function* getPriceSaga() {
  yield takeLatest(REQUEST_PRICE, requestPrice)
}
