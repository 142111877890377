import { call, put, select } from 'redux-saga/effects'
import quoteApi from '../../../api/quoteApi'

import { updateUnderwriterSuccess } from '../actions'

import { getRiskData, updateUnderwriterLinkSelector } from '../selector'

export function* underwriterSaga() {
  try {
    const updateUnderwriterLink = yield select(updateUnderwriterLinkSelector)
    const riskData = yield select(getRiskData)
    const FreeGAPInsuranceCover = yield select(
      (state) => state.price.FreeGAPInsuranceCover,
    )
    const data = {
      QuoteId: {
        value: riskData.QuoteId,
      },
      Free30DayGAPInsuranceIncluded:
        FreeGAPInsuranceCover.Free30DayGAPInsuranceIncluded,
    }

    const response = yield call(
      quoteApi.updateUnderwriter,
      updateUnderwriterLink.Href,
      data,
    )
    yield put(updateUnderwriterSuccess(response.data))
  } catch (e) {}
}
