import styled from 'styled-components'

export const H1 = styled.h1`
  font-weight: 700;
  color: #333;
  text-align: center;
  font-size: 35px;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.primaryTextColour};
`
