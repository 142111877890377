import { SET_QUERY_STRING } from '../constants'

import initialState from '../../../initialState'

function queryStringReducer(state = initialState.queryString, action) {
  switch (action.type) {
    case SET_QUERY_STRING:
      return action.queryString
    default:
      return state
  }
}

export default queryStringReducer
