import React from 'react'
import Modal from '../common/NewModal'
import { isMobile } from 'react-device-detect'

const CookiePolicyModal = ({ cookieText, isVisible, onClose }) => {
  const handleClose = () => {
    onClose()
  }

  return (
    <Modal
      hasHeaderBorder
      id='CookiePolicyModal'
      fullscreen={isMobile}
      header={'Cookies policy'}
      isVisible={isVisible}
      onClose={handleClose}
      shouldModalHandleOverflow={false}
    >
      <div dangerouslySetInnerHTML={{ __html: cookieText }} />
    </Modal>
  )
}

export default CookiePolicyModal
