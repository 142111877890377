import styled from 'styled-components'

export const H4 = styled.h4`
  font-size: ${(props) => props.theme.generalFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  color: #000;
  display: inline-block;
  margin-bottom: 0px;
`
