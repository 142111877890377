import React from 'react'
import { Grid, GridContainer, GridClear } from 'unsemantic'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { H4 } from '../common/H4'
import { H2 } from '../common/H2'
import { ProgressionOption } from '../common/ProgressionOption'
import { history } from '../..'
import { ProgressionHeader } from '../common/ProgressionOption/ProgressionHeader'
import { ProgressionHeaderImage } from '../common/ProgressionOption/ProgressionHeaderImage'
import { ProgressionContent } from '../common/ProgressionOption/ProgressionContent'
import { ProgressionFlexGrid } from '../common/ProgressionOption/ProgressionFlexGrid'
import { H4PrimaryTextColour } from './H4'
import { H5 } from '../common/H5'
import { ProgressionSubContent } from '../common/ProgressionOption/ProgressionSubContent'
import ScanImage from '../../images/scan-driving-licence.png'
import ScanImage2 from '../../images/scan-driving-licence@2x.png'
import { I, TextWrapStyle } from './Styles'

export class DrivingLicenceScan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCamera: false,
      cameraEnabled: true,
    }
    this.startScanClick = this.startScanClick.bind(this)
  }

  startScanClick = () => {
    history.push(`/quote/scan?q=${this.props.quoteId}`)
  }

  render() {
    return (
      <>
        {this.state.cameraEnabled && (
          <>
            <ProgressionOption
              id='TeslaStartScanLicenceNextButton'
              onClick={this.startScanClick}
            >
              <GridContainer>
                <Grid desktop='100' mobile='100' tablet='100' parent>
                  <ProgressionHeader>
                    <H2>Scan your driving licence</H2>
                  </ProgressionHeader>
                </Grid>
                <GridClear />
                <ProgressionFlexGrid>
                  <div>
                    <ProgressionHeaderImage>
                      <picture>
                        <source srcset={ScanImage} media='(max-width: 768px)' />
                        <img src={ScanImage2} alt='example' />
                      </picture>
                    </ProgressionHeaderImage>
                  </div>
                  <div>
                    <ProgressionContent id='TeslaSubmitManualDetailsNextButton'>
                      <H4>(UK licence holders only)</H4>
                      <TextWrapStyle>
                        <H4PrimaryTextColour>
                          A quick and easy way to add your info
                        </H4PrimaryTextColour>
                      </TextWrapStyle>
                      <ProgressionSubContent>
                        <H5>
                          <I className='fas fa-lock fa-xs' />
                          It's secure - we won't share this photo
                        </H5>
                      </ProgressionSubContent>
                    </ProgressionContent>
                  </div>
                </ProgressionFlexGrid>
              </GridContainer>
            </ProgressionOption>
          </>
        )}
        {!this.state.cameraEnabled && (
          <>
            <p>
              Please refresh the page and ensure you allow the camera to enable
              scanning.
            </p>
          </>
        )}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    quoteId: state.riskData.QuoteId,
  }
}

export default withRouter(connect(mapStateToProps)(DrivingLicenceScan))
