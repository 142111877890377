import queryString from 'query-string'
import {
  REQUEST_VEHICLE,
  GET_MAKES,
  GET_MODELS,
  GET_VARIANTS,
  CREATE_UNKNOWN_VEHICLE,
  PRIVATE_CAR,
  COMMERCIAL_VEHICLE,
  SET_VEHICLE_LISTENER,
} from './constants'
import {
  vehicleLinkSelector,
  getMakesLinkSelector,
  getModelsLinkSelector,
  getVariantsLinkSelector,
  transformVehicleType,
  transformVariants,
  createUnkownVehicleLinkSelector,
  createUnkownCommercialVehicleLinkSelector,
  getVariant,
  getSelectedVehicle,
} from './selector'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import vehicleApi from '../../api/vehicleApi'

import {
  requestVehicleFailed,
  requestVehicleSuccess,
  getMakesSuccess,
  getModelsSuccess,
  getVariantsSuccess,
  createUnkownVehicleSuccess,
  getMakesFailure,
  getModelsFailure,
  getVariantsFailure,
  createUnkownVehicleFailure,
  unknownRegSuccess,
  unknownRegFailure,
  setVehicle,
  vehicleTypeChange,
} from './actions'
import { getVehicleInfo } from '../App/sagas/select'
import { HTTP_CODES } from '../../constants'
import { getSubDomain } from '../../services/handlers'
import { createQuoteSaga } from '../App/sagas/quoteSaga'
import { setQueryString } from '../App/actions'
import { getQueryString, getQuoteId } from '../RiskDataCollectionPage/selector'
import { history } from '../..'
import { getBranchesDrivenByStocklist } from '../BranchSelect/selector'

export default function* fetchVehicleSaga({ registrationNumber }) {
  try {
    let branchesDrivenByStocklist = yield select(getBranchesDrivenByStocklist)
    const vehicleLinkSelect = yield select(vehicleLinkSelector)
    const subDomain = getSubDomain()
    let vehicleLink = vehicleLinkSelect.Href.replace(
      '{introducer}',
      subDomain,
    ).replace('{registrationNumber}', registrationNumber)

    const vehicle = yield call(vehicleApi.getVehicle, `${vehicleLink}`)
    yield put(requestVehicleSuccess(vehicle.data, branchesDrivenByStocklist))
    return vehicle.status
  } catch (e) {
    let error
    if (e.response.status === HTTP_CODES.ERROR.BadRequest) {
      error = 'InvalidReg'
    } else if (e.response.status === HTTP_CODES.ERROR.Forbidden) {
      error = 'Forbidden'
    } else {
      error = 'NotFound'
    }

    yield put(requestVehicleFailed(registrationNumber, error))
    return e.response.status
  }
}

export function* getMakesSaga() {
  try {
    const vehicle = yield select(getVehicleInfo)
    const getMakesLinkSelect = yield select(getMakesLinkSelector)

    let getMakesLink = getMakesLinkSelect.Href.replace(
      /{[vehicleType}]*}/,
      vehicle.searched.VehicleType,
    )
    let response = yield call(vehicleApi.getMakes, getMakesLink)
    const makes = transformVehicleType(response.data.Makes)
    yield put(getMakesSuccess(makes, response.data.Links))
  } catch (e) {
    yield put(getMakesFailure(e))
  }
}

export function* getModels() {
  try {
    const vehicle = yield select(getVehicleInfo)
    const getModelsLinkSelect = yield select(getModelsLinkSelector)
    const getModelsLink = getModelsLinkSelect.Href.replace(
      /{[make}]*}/,
      btoa(vehicle.searched.Make),
    )
    let response = yield call(vehicleApi.getModels, getModelsLink)
    const models = transformVehicleType(response.data.Models)
    yield put(getModelsSuccess(models, response.data.Links))
  } catch (e) {
    yield put(getModelsFailure(e))
  }
}

export function* getVariants() {
  try {
    const vehicle = yield select(getVehicleInfo)
    const getVariantsLinkSelect = yield select(getVariantsLinkSelector)
    const getVariantsLink = getVariantsLinkSelect.Href.replace(
      /{[vehicleType}]*}/,
      vehicle.searched.VehicleType,
    )
      .replace(/{[make]*}/, btoa(vehicle.searched.Make))
      .replace(/{[model}]*}/, btoa(vehicle.searched.Model))
    let response = yield call(vehicleApi.getVariants, getVariantsLink)
    const variants = transformVariants(response.data.Variants)
    yield put(getVariantsSuccess(variants, response.data.Links))
  } catch (e) {
    yield put(getVariantsFailure(e))
  }
}

export function* createUnknownVehicle({ redirect }) {
  try {
    let vehicle = yield select(getVehicleInfo)
    let { searched } = vehicle

    const { data, createVehicleLink } = yield buildUnknownVehicleLink(searched)

    let manuallyBuiltVehicle = yield call(
      vehicleApi.createUnknownVehicle,
      createVehicleLink,
      data,
    )
    yield put(createUnkownVehicleSuccess(manuallyBuiltVehicle.data))
    yield* setVehicleSaga()
    if (redirect) {
      history.push(redirect)
    }
  } catch (e) {
    yield put(createUnkownVehicleFailure(e))
  }
}

export function* createUnknownVehicleFromUnknownReg(unknownVehicle) {
  try {
    const data = {
      AbiCode: unknownVehicle.Variant,
      IsRegistrationKnown: false,
    }
    const createUnknownVehicleLinkSelect = yield select(
      createUnkownVehicleLinkSelector,
    )
    const createUnknownVehicleLink = createUnknownVehicleLinkSelect.Href
    let manuallyBuiltVehicle = yield call(
      vehicleApi.createUnknownVehicle,
      createUnknownVehicleLink,
      data,
    )

    yield put(unknownRegSuccess(manuallyBuiltVehicle.data, unknownVehicle))
    yield call(getVariants)
    const variant = yield select(getVariant)
    // alert('hello')
    let vehicle = yield select(getSelectedVehicle)

    vehicle.Variant = variant.description
    yield put(setVehicle(vehicle))
  } catch (e) {
    yield put(unknownRegFailure(e))
  }
}

export function* setVehicleSaga() {
  const { searched, selected } = yield select(getVehicleInfo)

  yield put(setVehicle(searched))
  if (searched.VehicleType !== selected.VehicleType && selected.VehicleType) {
    yield* vehicleTypeChangeSaga()
  }
}

export function* vehicleTypeChangeSaga() {
  const parsedQueryString = queryString.parse(window.location.search)
  yield put(vehicleTypeChange())
  yield* createQuoteSaga()
  const quoteId = yield select(getQuoteId)
  yield put(
    setQueryString(
      '?q=' +
      quoteId +
      (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : ''),
    ),
  )
  const stateQueryString = yield select(getQueryString)
  history.push(`/quote/driver-details${stateQueryString}`)
}

export function* buildUnknownVehicleLink(vehicle) {
  let data = {}
  let createVehicleLink = ''
  const subDomain = getSubDomain()

  switch (vehicle.VehicleType) {
    case PRIVATE_CAR:
      data = {
        AbiCode: vehicle.Abi,
        IsRegistrationKnown: !!vehicle.RegistrationNumber,
        Registration: vehicle.RegistrationNumber,
      }
      const createUnknownVehicleLinkSelect = yield select(
        createUnkownVehicleLinkSelector,
      )
      createVehicleLink = createUnknownVehicleLinkSelect.Href
      break
    case COMMERCIAL_VEHICLE:
      data = {
        AbiCode: vehicle.Abi,
        IsRegistrationKnown: !!vehicle.RegistrationNumber,
        Weight: vehicle.GrossWeight,
        YearOfManufacture: vehicle.YearOfManufacture,
        Registration: vehicle.RegistrationNumber,
      }
      const createUnknownCommercialVehicleLinkSelect = yield select(
        createUnkownCommercialVehicleLinkSelector,
      )
      createVehicleLink = createUnknownCommercialVehicleLinkSelect.Href
      break
    default:
      break
  }

  data.IntroducerSubDomain = subDomain;

  return { data, createVehicleLink }
}

export function* fetchVehicleListenerSaga() {
  yield takeLatest(REQUEST_VEHICLE, fetchVehicleSaga)
  yield takeLatest(GET_MAKES, getMakesSaga)
  yield takeLatest(GET_MODELS, getModels)
  yield takeLatest(GET_VARIANTS, getVariants)
  yield takeLatest(CREATE_UNKNOWN_VEHICLE, createUnknownVehicle)
  yield takeLatest(SET_VEHICLE_LISTENER, setVehicleSaga)
}
