import http from '../api/http'

export default class driveawayApi {
  static getIntroducer(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept: 'application/tempcover.IntroducerRepresentation+json',
      },
    })
  }

  static getIntroducerBranch(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept: 'application/tempcover.IntroducerBranchRepresentation+json',
      },
    })
  }

  static getIntroducerBranches(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept: 'application/tempcover.IntroducerBranchesRepresentation+json',
      },
    })
  }

  static getUpsellPricing(subdomain, branch, reg) {
    return http.get(
      `api/introducers/${subdomain}/getupselldetails/${branch}/${reg}`,
      {
        headers: {
          Accept: 'application/tempcover.IntroducerBranchesRepresentation+json',
        },
      },
    )
  }

  static getUpsellEnabled(subdomain) {
    return http.get(`api/introducers/${subdomain}/upsellenabled`, {
      headers: {
        Accept: 'application/tempcover.IntroducerBranchesRepresentation+json',
      },
    })
  }
}
