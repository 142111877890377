import styled from 'styled-components'

export const FormControlValidIcon = styled.i`
  position: absolute;
  top: 11px;
  right: 9px;
  display: ${(props) => (props.error === '' ? 'block' : 'none')}!important;
  color: #fff;
  font-size: 16px;
  border: 4px solid ${(props) => props.theme.primaryColour};
  background-color: ${(props) => props.theme.primaryColour};
  border-radius: 50%;
`
