import React, { useEffect } from 'react'
import { ErrorMessage } from '../ErrorMessage'
import { DateInput } from '../Input/DateInput'
import {
  DateWrapper,
  DateInputFieldWrapper,
  YearInputWrapper,
  DateContainer,
} from './styles'

export const BasicDate = ({
  value,
  touched,
  onBlur,
  onChange,
  error,
  name: nameAttribute,
  id,
}) => {
  useEffect(() => {
    const { day, month, year } = value
    onChange(`${nameAttribute}.date`, `${year}-${month}-${day}T00:00:00`)
  }, [value])

  const handleInputChange = (event) => {
    let { value, name } = event.target
    onChange(name, value)
  }

  const handleInputBlur = (event) => {
    let { value, name } = event.target
    if (value) {
      if (name === `${nameAttribute}.year`) {
        if (value.length === 2) {
          value = `19${value}`
        }
      } else {
        if (value.length === 1) {
          value = `0${value}`
        }
      }
    }
    onChange(name, value)
    onBlur(event)
  }
  const errorKeys = (error && Object.keys(error)) || []
  const touchedKeys = (touched && Object.keys(touched)) || []
  const showError =
    error &&
    ((error.date && touchedKeys.length === 3) ||
      errorKeys.some((key) => (touched || [])[key]))

  return (
    <>
      {showError && errorKeys.length === 1 && error.date && (
        <ErrorMessage id={`${nameAttribute}Error`}>{error.date}</ErrorMessage>
      )}
      {showError && errorKeys.length > 1 && (
        <ErrorMessage id={`${nameAttribute}Error`}>
          Please enter a valid date of birth
        </ErrorMessage>
      )}
      <DateContainer>
        <DateWrapper>
          <DateInputFieldWrapper
            small
            error={Boolean(
              touched &&
                error &&
                ((touched.day && error.day) ||
                  (error.date && touchedKeys.length === 3)),
            )}
          >
            <DateInput
              type='number'
              value={value.day}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              name={`${nameAttribute}.day`}
              id={`${id}.day`}
              placeholder='DD'
              pattern='\d*'
              maxLength='2'
              aria-label='Day'
            />
          </DateInputFieldWrapper>
          <DateInputFieldWrapper
            small
            error={Boolean(
              touched &&
                error &&
                ((touched.month && error.month) ||
                  (error.date && touchedKeys.length === 3)),
            )}
          >
            <DateInput
              type='number'
              value={value.month}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              name={`${nameAttribute}.month`}
              id={`${id}.month`}
              placeholder='MM'
              pattern='\d*'
              maxLength='2'
              aria-label='Month'
            />
          </DateInputFieldWrapper>
          <YearInputWrapper
            error={Boolean(
              touched &&
                error &&
                ((touched.year && error.year) ||
                  (error.date && touchedKeys.length === 3)),
            )}
          >
            <DateInput
              type='number'
              value={value.year}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              name={`${nameAttribute}.year`}
              id={`${id}.year`}
              placeholder='YYYY'
              pattern='\d*'
              maxLength='4'
              aria-label='Year'
            />
          </YearInputWrapper>
        </DateWrapper>
      </DateContainer>
    </>
  )
}
