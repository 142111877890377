import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const AccordionHeader = styled.h3`
  position: relative;
  background: ${(props) => props.theme.primaryColour};
  color: #fff;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'BrandonText', sans-serif;
  padding: 5px 10px;
  font-weight: normal;
`

export const AccordionBody = styled.div`
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  transition: max-height 0.8s, opacity 0.8s, visibility 0.8s;

  &.Active {
    opacity: 1;
    max-height: 500px;
    visibility: visible;
  }
`

export const AccordionChevron = styled(FontAwesomeIcon)`
  float: right;
  margin-top: 3px;
  transform: rotateZ(0deg);
  transition: transform 0.8s;

  &.Active {
    transform: rotateZ(90deg);
  }
`
