export const getCookie = (name) => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    // eslint-disable-next-line
    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }
  return null
}

export const setCookie = (name, value, seconds) => {
  let expires = ''
  if (seconds) {
    let date = new Date()
    date.setTime(date.getTime() + seconds * 1000)
    expires = '; expires=' + date.toGMTString()
  }
  const domain = window.location.hostname.split('.').slice(-2).join('.')
  document.cookie =
    name + '=' + value + expires + '; path=/;domain=' + domain + ';'
}
