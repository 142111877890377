import React from 'react'
import { ErrorMessage } from '../common/ErrorMessage'

import { Select } from '../common/Select'

const AddressSelect = ({
  onChange,
  id,
  options,
  name,
  value,
  error,
  errorMessage,
}) => {
  const addresses = options.map((option) => {
    return {
      id: option.AddressKey,
      value: option.AddressKey,
      description: option.Address,
    }
  })

  return (
    <>
      {error && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
      <Select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        options={addresses}
        placeholder='and I live at...'
        error={error}
      />
    </>
  )
}
export default AddressSelect
