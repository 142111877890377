/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from 'redux'
import { isLoadingReducer as isLoading } from './containers/App/reducers'
import correlation from './containers/App/reducers/correlationIdReducer'
import links from './containers/App/reducers/linksReducer'
import features from './containers/App/reducers/featureTogglesReducer'
import step from './containers/App/reducers/stepReducer'
import queryString from './containers/App/reducers/queryStringReducer'
import vehicle from './containers/Vehicle/reducer'
import price from './containers/App/reducers/priceReducer'
import policy from './containers/PolicyConfirmationPage/reducer'
import riskData from './containers/RiskDataCollectionPage/reducer'
import address from './containers/Address/reducer'
import legalDeclaration from './containers/LegalDeclarationModal/reducer'
import scheme from './containers/App/reducers/schemeReducer'
import paymentPage from './containers/PaymentPage/reducer'
import contactInformation from './containers/EmailQuoteModal/reducer'
import affiliateRef from './containers/App/reducers/affiliateReducer'
import marketingPreferences from './containers/MarketingPreferencesContainer/reducer'
import contactUs from './containers/ContactUsPage/reducer'
import { validationReducer as riskDataCollectionPage } from './containers/RiskDataCollectionPage/reducer'
import { validationReducer as policyConfirmationPage } from './containers/PolicyConfirmationPage/reducer'
import { validationReducer as contactUsPage } from './containers/ContactUsPage/reducer'
import { connectRouter } from 'connected-react-router'
import formButtons from './containers/RiskDataCollectionPage/formButtonsReducer'
import camera from './containers/DrivingLicenceCamera/reducer'
import driveaway from './containers/BranchSelect/reducer'
import appData from './containers/App/reducers/appDataReducer'
import confusedQuickQuote from './containers/App/reducers/confusedQuickQuoteReducer'
import upsellEnabled from './containers/UpsellDurationPage/reducer'

const validation = combineReducers({
  riskDataCollectionPage,
  policyConfirmationPage,
  contactUsPage,
})

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    formButtons,
    isLoading,
    correlation,
    links,
    vehicle,
    price,
    policy,
    riskData,
    validation,
    address,
    legalDeclaration,
    step,
    queryString,
    paymentPage,
    contactInformation,
    camera,
    affiliateRef,
    scheme,
    driveaway,
    marketingPreferences,
    contactUs,
    appData,
    features,
    confusedQuickQuote,
    upsellEnabled,
  })
