import React from 'react'
import { Grid } from 'unsemantic'

import { H1, P, A } from './styles'
import { ROUTES } from '../../services/constants'

const RefundPolicyPage = () => {
  return (
    <Grid desktop='100' mobile='100' tablet='100'>
      <H1>Our refund policy</H1>
      <P>
        Once a policy has been purchased, we are unable to make any changes or
        additions to the cover, though you can cancel your policy at any time by{' '}
        <A href={ROUTES.CONTACT_US}> contacting us.</A>
      </P>

      <P>
        Policies of less than 1 month are not subject to the 14-day cooling-off
        period and due to the short term nature of the policy, refunds are not
        available once a policy has been purchased. This is in line with{' '}
        <A
          href='https://www.handbook.fca.org.uk/handbook/ICOBS/7/1.html'
          target='_blank'
          rel='noreferrer noopener'
        >
          rules set by the Financial Conduct Authority.
        </A>
      </P>
    </Grid>
  )
}

export default RefundPolicyPage
