import defaultTheme from './default'

let honchoTheme = { ...defaultTheme }
const primaryColour = '#6D44FF'
const secondaryColour = '#fff'
const honchoDarkerPurple = '#5636CB'

honchoTheme.primaryColour = primaryColour
honchoTheme.primaryTextColour = '#39393a'
honchoTheme.headerFontColour = primaryColour
honchoTheme.generalFontSize = '18px'
honchoTheme.labelFontFamily = 'Montserrat, sans-serif'
honchoTheme.fontFamily = 'Montserrat, sans-serif'
honchoTheme.breadcrumb = {
  ...defaultTheme.breadcrumb,
  activeColor: '#ffffff',
  activeBorderColor: primaryColour,
  defaultColor: '#ffffff',
  defaultBorderColor: '#CCCCCC',
  defaultBarColor: '#CCCCCC',
  completeColor: primaryColour,
  completeBorderColor: primaryColour,
  completeBarColor: primaryColour,
}
honchoTheme.spinner = {
  ...defaultTheme.spinner,
  primaryColour: primaryColour,
  textColour: primaryColour,
}
honchoTheme.durationOptions = {
  ...defaultTheme.durationOptions,
  primaryColour: primaryColour,
  secondaryColour: secondaryColour,
}
honchoTheme.staticHeaderColour = '#6D44FF'
honchoTheme.callUsNumberColour = '#63CDA2'
honchoTheme.button = {
  ...defaultTheme.button,
  backgroundColour: 'linear-gradient(#12b447, #10a140);',
  hoverColour: honchoDarkerPurple,
}
honchoTheme.infoBorderProperties = '2px solid #6D44FF'
honchoTheme.errorBorderProperties = '2px solid #e47008'
honchoTheme.headerLinkStyled.padding = '6px 7px;'

honchoTheme.headerLinkStyled = {
  ...defaultTheme.headerLinkStyled,
  hover: {
    ...defaultTheme.headerLinkStyled.hover,
    textDecoration: 'none',
    color: secondaryColour,
    backgroundColour: '#242D2C',
  },
}
honchoTheme.secondaryButton = {
  ...defaultTheme.secondaryButton,
  hoverColour: honchoDarkerPurple,
}
honchoTheme.header = {
  ...defaultTheme.header,
  background: '#0B004B',
  borderBottom: `6px solid ${secondaryColour}`,
}
honchoTheme.gradient = {
  ...defaultTheme.gradient,
  background: '#F7F6FF',
}
honchoTheme.icon = {
  ...defaultTheme.icon,
  color: primaryColour,
  backgroundColor: secondaryColour,
}
honchoTheme.GbIcon = secondaryColour
honchoTheme.formControlValidIcon = {
  backgroundColour: primaryColour,
  colour: secondaryColour,
}
honchoTheme.iconBackground = '#EEF2FF'
honchoTheme.backgroundColour = '#EEF2FF'
honchoTheme.inputBorderColour = primaryColour
honchoTheme.successColour = primaryColour
honchoTheme.footerInfoBackground = '#0B004B'
honchoTheme.footerWrapperBackground = '#977BFF'
honchoTheme.footerTextColour = '#fff'
honchoTheme.mobileHamburgerMenu = ''
honchoTheme.headerWrapperColour = honchoDarkerPurple
honchoTheme.documentWrapperBackground = '#eef2ff'
honchoTheme.headlineColour = '#0B004B'
honchoTheme.cookieTextColour = '#0B004B'
honchoTheme.toggleActiveColour = '#63CDA2'
honchoTheme.smallerFontSize = '15px'

export default honchoTheme
