import React from 'react'
import PaymentBreakdownIcon from '../PaymentBreakdownIcon'
import { PriceSummaryWrapper } from './PriceSummaryStyles'

export const PriceSummary = ({ price, affiliate }) => {
  return (
    <PriceSummaryWrapper>
      <p>
        <span className='price'>
          £{price.TotalPrice.toFixed(2)} <span>total</span>
        </span>
      </p>
      <p className='cover'>Fully comprehensive</p>
      <PaymentBreakdownIcon price={price} affiliate={affiliate} />
    </PriceSummaryWrapper>
  )
}
