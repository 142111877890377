import styled from 'styled-components'

export const I = styled.i`
  font-size: 30px;
  vertical-align: 50%;
  text-align: center;
  border-radius: 100%;
  padding: 5px;
  padding-bottom: 10px;
`

export const ErrorIcon = styled(I)`
  color: ${(props) => props.theme.errorIconColour};
  border: ${(props) => props.theme.errorBorderProperties};
`

export const InfoIcon = styled(I)`
  color: ${(props) => props.type === 'info' && props.theme.infoIconColour};
  font-size: 50px;
  margin-top: 10px;
`

export const Container = styled.div`
  background: ${(props) =>
    props.type === 'error' && props.theme.errorBackgroundColour};
  border: ${(props) =>
    props.type === 'error' && props.theme.errorBorderProperties};
  background: ${(props) =>
    props.type === 'info' && props.theme.backgroundColour};
  border: ${(props) =>
    props.type === 'info' && props.theme.infoBorderProperties};
  border-radius: ${(props) => props.theme.messageBorderRadius};
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
`
