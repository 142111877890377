import React from 'react'
import {
  FooterWrapper,
  FooterNavContainer,
  FooterInfoWrapper,
  FooterInfoContainer,
  FooterImageWrapper,
  FooterImage,
  AddressSection,
  AddressWrapper,
  Address,
  LegalParagraph,
  SocialSection,
  IconWrapper,
  DarkBlueBackground,
  WhiteLabelFooterNavContainer,
  WhiteLabelText,
  WhiteLabelTextSmaller,
  WhiteLabelFooterInfoWrapper,
  WhiteLabelFooterCenterWrapper,
  WhiteLabelFooterLink,
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'
import footerIcon from '../../images/footer-icon.png'
import { ROUTES } from '../../services/constants'
import InternalLink from '../common/InternalLink'
import { AFFILIATE_TYPES } from '../Logo/constants'
import { getSubDomain } from '../../services/handlers'

function CopyrightDate() {
  var date = new Date()
  return date.getFullYear()
}

const subDomain = getSubDomain()

const Footer = ({ AppData: { Affiliate } }) => {
  switch (Affiliate.AffiliateType) {
    case AFFILIATE_TYPES.WHITE_LABEL:
      return (
        <WhiteLabelFooterInfoWrapper className="footer">
          <WhiteLabelFooterCenterWrapper>
            <WhiteLabelFooterNavContainer>
              {subDomain === 'honcho' ? (
                <WhiteLabelText>
                  Honcho Markets Ltd, trading as Honcho, is an insurance
                  intermediary who introduces retail customers to Tempcover
                  Limited.
                  <br /> Honcho Markets Ltd is an insurance intermediary
                  authorised and regulated by the Financial Conduct Authority
                  (Firm Reference Number 791467). You can check this by visiting{' '}
                  <WhiteLabelFooterLink href='https://www.fca.org.uk/register'>
                    www.fca.org.uk/register
                  </WhiteLabelFooterLink>{' '}
                  or by contacting the FCA on 0845 606 1234.
                </WhiteLabelText>
              ) : (
                <WhiteLabelText>
                  {Affiliate.AffiliateName} is an independent intermediary who
                  introduces retail customers to Tempcover Limited.
                  <br /> {Affiliate.AffiliateName} is an independent
                  intermediary authorised and regulated by the Financial Conduct
                  Authority. Our FCA registration number is{' '}
                  {Affiliate.FcaNumber}. You can check this by visiting{' '}
                  <WhiteLabelFooterLink href='https://www.fca.org.uk/register'>
                    www.fca.org.uk/register
                  </WhiteLabelFooterLink>{' '}
                  or by contacting the FCA on 0845 606 1234.
                </WhiteLabelText>
              )}
            </WhiteLabelFooterNavContainer>
            <WhiteLabelFooterNavContainer>
              <WhiteLabelTextSmaller>
                Copyright © {CopyrightDate()} tempcover.com. All rights reserved
                | Authorised & regulated by the Financial Conduct Authority No:
                746985 | Registered in England, Company No: 9923259
              </WhiteLabelTextSmaller>
            </WhiteLabelFooterNavContainer>
            <WhiteLabelFooterNavContainer>
              <WhiteLabelTextSmaller>
                Tempcover Ltd, 2nd Floor Admiral House, Harlington Way, Fleet,
                Hampshire, GU51 4BB
              </WhiteLabelTextSmaller>
            </WhiteLabelFooterNavContainer>
          </WhiteLabelFooterCenterWrapper>
        </WhiteLabelFooterInfoWrapper>
      )

    default:
      return (
        <FooterWrapper className="footer">
          <FooterNavContainer>
            <h2>Tempcover</h2>
            <nav>
              <ul>
                <li>
                  <InternalLink
                    id='EligibilityFooterLink'
                    href={ROUTES.ELIGIBILITY}
                  >
                    Eligibility
                  </InternalLink>
                </li>
                <li>
                  <InternalLink id='FAQsFooterLink' href={ROUTES.FAQ}>
                    FAQs
                  </InternalLink>
                </li>
                <li>
                  <InternalLink id='ClaimsFooterLink' href={ROUTES.CLAIMS}>
                    Claims
                  </InternalLink>
                </li>
                <li>
                  <InternalLink id='ContactFooterLink' href={ROUTES.CONTACT_US}>
                    Contact us
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    id='PrivacyAndCookiesFooterLink'
                    href={ROUTES.PRIVACY_AND_COOKIES}
                  >
                    Privacy &amp; cookies
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    id='T&CsFooterLink'
                    href={ROUTES.TERMS_AND_CONDITIONS}
                  >
                    Terms &amp; conditions
                  </InternalLink>
                </li>
              </ul>
            </nav>
          </FooterNavContainer>
          <FooterInfoWrapper>
            <FooterInfoContainer>
              <FooterImageWrapper>
                <FooterImage src={footerIcon} alt='Tempcover' />
              </FooterImageWrapper>
              <AddressSection>
                <AddressWrapper>
                  <h2>Address</h2>{' '}
                  <Address>
                    <p>Tempcover Ltd</p>
                    <p>
                      2<sup>nd</sup> floor,
                    </p>
                    <p>Admiral House</p>
                    <p>Harlington Way, Fleet,</p>
                    <p>Hampshire, GU51&nbsp;4BB</p>{' '}
                  </Address>
                </AddressWrapper>
              </AddressSection>
              <SocialSection>
                <h2>Get the updates</h2>
                <ul className='social-icons'>
                  <li className='icon twitter'>
                    <a
                      id='TempcoverTwitterLink'
                      title='Twitter'
                      href='https://twitter.com/tempcover'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <IconWrapper>
                        <FontAwesomeIcon icon={faTwitter} />
                      </IconWrapper>
                    </a>
                  </li>
                  <li className='icon facebook'>
                    <a
                      id='TempcoverFacebookLink'
                      title='Facebook'
                      href='https://www.facebook.com/tempcover'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <IconWrapper>
                        <FontAwesomeIcon icon={faFacebook} />
                      </IconWrapper>
                    </a>
                  </li>
                  <li className='icon youtube'>
                    <a
                      id='TempcoverYoutubeLink'
                      title='YouTube'
                      href='https://www.youtube.com/user/tempcover'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <IconWrapper>
                        <FontAwesomeIcon icon={faYoutube} />
                      </IconWrapper>
                    </a>
                  </li>
                  <li className='icon linkedin'>
                    <a
                      id='TempcoverLinkedInLink'
                      title='LinkedIn'
                      href='https://www.linkedin.com/company/temporary-cover-ltd/?trk=tyah'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <IconWrapper>
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </IconWrapper>
                    </a>
                  </li>
                </ul>
              </SocialSection>
              <LegalParagraph>
                Copyright © {CopyrightDate()} tempcover.com.
                <br />
                All rights reserved | Authorised &amp; regulated by the
                Financial Conduct Authority No: 746985 | Registered in England,
                Company No: 9923259
              </LegalParagraph>
            </FooterInfoContainer>
          </FooterInfoWrapper>
          <DarkBlueBackground />
        </FooterWrapper>
      )
  }
}

export default Footer
