import React from 'react'
import PropTypes from 'prop-types'
import { P, Span } from './styles.js'
import { Grid, GridContainer } from 'unsemantic'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #264694;
  color: #fff;
  padding: 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

const PriceSpan = styled(Span)`
  color: #fff;
  @media (max-width: 768px) {
    font-size: 22px;
    font-weight: bold;
  }
`

const SummaryFooter = ({ price }) => {
  return (
    <Wrapper>
      <GridContainer>
        <Grid desktop='20' tablet='20' mobile='30'>
          <P>Policy cost and cover</P>
        </Grid>
        <Grid desktop='40' tablet='40' mobile='35'>
          <P>
            <PriceSpan>£{price}</PriceSpan>
          </P>
        </Grid>
        <Grid desktop='40' tablet='40' mobile='35'>
          <P>Fully comprehensive</P>
        </Grid>
      </GridContainer>
    </Wrapper>
  )
}

SummaryFooter.propTypes = {
  price: PropTypes.string,
}

export default SummaryFooter
