import React from 'react'
import { ProgressionOption } from '../common/ProgressionOption'
import { GridContainer, Grid, GridClear } from 'unsemantic'
import DrivingLicenceScan from '../DrivingLicenceScan'
import { H2 } from '../common/H2'
import QuoteSummary from '../../containers/QuoteSummary'
import { ProgressionHeader } from '../common/ProgressionOption/ProgressionHeader'
import { ProgressionHeaderImage } from '../common/ProgressionOption/ProgressionHeaderImage'
import { ProgressionContent } from '../common/ProgressionOption/ProgressionContent'
import { ProgressionFlexGrid } from '../common/ProgressionOption/ProgressionFlexGrid'
import { H4 } from '../common/H4'
import { history } from '../..'
import { PathSelectionP, ProgressionStepWidth } from './styles'
import DetailImage from '../../images/details.png'
import DetailImage2 from '../../images/details@2x.png'

const startManualForm = (quoteId) => {
  history.push(`/quote/driver-details?q=${quoteId}`)
}

export const RiskCollectionPathSelection = ({ quoteId }) => {
  return (
    <>
      <QuoteSummary />
      <PathSelectionP>
        To get a quote add your details using one of the options below.
      </PathSelectionP>
      <ProgressionStepWidth>
        <DrivingLicenceScan />
        <PathSelectionP>OR</PathSelectionP>
        <ProgressionOption
          id='TeslaTypeDetailsNextButton'
          onClick={() => startManualForm(quoteId)}
        >
          <GridContainer>
            <Grid desktop='100' mobile='100' tablet='100' parent>
              <ProgressionHeader>
                <H2>Type in your details</H2>
              </ProgressionHeader>
            </Grid>
            <GridClear />
            <ProgressionFlexGrid>
              <div>
                <ProgressionHeaderImage>
                  <picture>
                    <source srcset={DetailImage} media='(max-width: 768px)' />
                    <img src={DetailImage2} alt='example' />
                  </picture>
                </ProgressionHeaderImage>
              </div>
              <div>
                <ProgressionContent>
                  <H4>
                    We just need a little bit of information to get you a quote
                  </H4>
                </ProgressionContent>
              </div>
            </ProgressionFlexGrid>
          </GridContainer>
        </ProgressionOption>
      </ProgressionStepWidth>
    </>
  )
}
