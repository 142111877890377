import { setVehicle } from '../../Vehicle/actions'
import moment from 'moment'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  isMobile,
  isAndroid,
  browserVersion,
  isOpera,
  isMobileSafari,
  isChrome,
  isFirefox,
  browserName,
} from 'react-device-detect'
import {
  INIT_RISK_DATA_COLLECTION_PAGE,
  CONFIRM_RISK_DATA_COLLECTION_PAGE,
  DRIVING_LICENCE_CHECK,
  PASSPORT_CHECK,
  IMMEDIATE_START_TEXT,
  UPDATE_UNDERWRITER_SUCCESS,
} from '../constants'
import { handleQueryString } from '../../../services/handlers'
import { updateRiskData } from '../actions'
import fetchVehicleSaga, {
  createUnknownVehicleFromUnknownReg,
} from '../../Vehicle/saga'
import { underwriterSaga } from './underwriter'
import { drivingLicenceCheckSaga, passportCheckSaga } from './identity'
import legalDeclarationSaga from '../../LegalDeclarationModal/saga'
import { getVehicle, getQuoteId } from '../selector'
import { history } from '../../../'
import { createQuoteSaga, fetchQuoteSummary } from '../../App/sagas/quoteSaga'
import { isLoading, setQueryString } from '../../App/actions'
import queryString from 'query-string'
import { updateStep } from '../../App/actions'
import { HTTP_CODES } from '../../../constants'

function* initRiskDataCollection() {
  const allowedApple = isMobileSafari && browserVersion >= 11
  const allowedAndroid =
    isAndroid &&
    ((isOpera && browserVersion >= 46) ||
      (isChrome && browserVersion >= 52) ||
      (isFirefox && browserVersion >= 36) ||
      (browserName === 'Samsung Browser' && browserVersion >= 6.2) ||
      (browserName === 'Android Browser' && browserVersion >= 53))

  // eslint-disable-next-line no-unused-vars
  const approvedList = isMobile && (allowedApple || allowedAndroid)
  try {
    yield put(isLoading(true))

    const parsedQueryString = queryString.parse(window.location.search)
    let quoteId
    if (parsedQueryString.q) {
      quoteId = parsedQueryString.q
      yield put(
        setQueryString(
          '?q=' +
            quoteId +
            (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : ''),
        ),
      )
      yield put(updateStep(1))
      yield* fetchQuoteSummary(quoteId)
      return
    }

    if (
      handleQueryString().UnknownVehicle.Make ||
      handleQueryString().UnknownVehicle.GrossWeight
    ) {
      yield* createNewQuoteFromUnknownVehicle()
    } else {
      const vehicleReg = handleQueryString().RegistrationNumber
      yield* setStateFromParams()
      const requestStatus = yield* fetchVehicleSaga({
        registrationNumber: vehicleReg,
      })
      const vehicle = yield select(getVehicle)
      if (requestStatus === 200) {
        if (vehicle.searched.VehicleType === 'Motorbike') {
          history.replace({
            pathname: `/vehicle/invalid-reg`,
            search:
              window.location.search +
              '&VehicleType=' +
              vehicle.searched.VehicleType,
            state: { error: 'VehicleNotAllowed' },
          })
          return
        }
        yield* createNewQuoteFromReg()
      } else {
        let error
        if (requestStatus === HTTP_CODES.ERROR.BadRequest) {
          error = 'InvalidReg'
        } else if (requestStatus === HTTP_CODES.ERROR.Forbidden) {
          error = 'Forbidden'
        } else {
          error = 'VehicleNotFound'
        }
        history.replace({
          pathname: `/vehicle/invalid-reg`,
          search: window.location.search,
          state: { error: error },
        })
        return
      }
    }
    quoteId = yield select(getQuoteId)
    yield put(
      setQueryString(
        '?q=' +
          quoteId +
          (parsedQueryString.ref ? '&ref=' + parsedQueryString.ref : ''),
      ),
    )
    // if(!approvedList) {
    history.replace(`/quote/driver-details?q=${quoteId}`)
    // } else {
    //   yield put(updateStep(1));
    //   history.push(`/quote/select-path?q=${quoteId}`);
    // }
    yield put(isLoading(false))
  } catch (e) {
    yield put(isLoading(false))
  }
}
function* confirmRiskDataCollectionPage({ callback }) {
  try {
    yield call(underwriterSaga)
  } catch (e) {}
}

export default function* riskDataCollectionSaga() {
  yield takeLatest(INIT_RISK_DATA_COLLECTION_PAGE, initRiskDataCollection)
  yield takeLatest(
    CONFIRM_RISK_DATA_COLLECTION_PAGE,
    confirmRiskDataCollectionPage,
  )
  yield takeLatest(UPDATE_UNDERWRITER_SUCCESS, legalDeclarationSaga)
  yield takeLatest(DRIVING_LICENCE_CHECK, drivingLicenceCheckSaga)
  yield takeLatest(PASSPORT_CHECK, passportCheckSaga)
}

function* setStateFromParams() {
  const startTime = handleQueryString().StartTime
  const startDate = moment().format('YYYY-MM-DD')
  const duration = handleQueryString().Duration
  yield put(
    updateRiskData({
      Duration: duration,
      DurationType: 'days',
      PolicyStartDate:
        startTime !== IMMEDIATE_START_TEXT
          ? {
              value: moment(startDate + ' ' + startTime, 'YYYY-MM-DDTHH:mm'),
              manuallyEdited: true,
            }
          : { value: null, manuallyEdited: false },
    }),
  )
}

function* createNewQuoteFromReg() {
  yield* setStateFromParams()
  const vehicle = yield select(getVehicle)
  yield put(setVehicle(vehicle.searched))
  yield* createQuoteSaga()
}

function* createNewQuoteFromUnknownVehicle() {
  const { UnknownVehicle, RegistrationNumber } = handleQueryString()

  if (RegistrationNumber) {
    yield* createNewQuoteFromReg()
  } else {
    yield* setStateFromParams()
    yield* createUnknownVehicleFromUnknownReg(UnknownVehicle)
    yield* createQuoteSaga()
  }
}
