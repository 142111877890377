import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 225px;
  height: 60px;
  margin: 5px;
  float: right;
  margin-left: 20px;
  position: relative;
  display: flex;
  align-items: flex-end ;

  @media (max-width: 1170px) {
    width: 220px;
  }


  @media (max-width: 767px) {
    width: 80px;
  }
`

export const Link = styled.a`
  display: block;
`

export const Image = styled.img`
  vertical-align: top;
  max-width: 100%;
  height: auto;
`

export const ReviewsText = styled.span`
  font-size: 15px;
  color: #fff;
  bottom: -4px;
  right: 0;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  transition: opacity 0.2s;

  @media (max-width: 1024px) {
    font-size: 10px;
    bottom: 0;
  }
`
