import { call, put } from 'redux-saga/effects'
import linksApi from '../../../api/linksApi'
import {
  requestLinksSuccess,
  requestLinksFailure,
  requestLinks,
} from '../actions'

export default function* fetchLinksSaga() {
  try {
    yield put(requestLinks())
    const links = yield call(linksApi.getLinks)
    yield put(requestLinksSuccess(links.data))
  } catch (e) {
    yield put(requestLinksFailure(e.message))
  }
}
