import styled from 'styled-components'

export const ErrorContainer = styled.div`
  background: ${(props) => props.theme.errorBackgroundColour};
  border: ${(props) => props.theme.errorBorderProperties};
  border-radius: ${(props) => props.theme.messageBorderRadius};
  display: flex;
  align-items: center;
  margin: 10px 0;
`
