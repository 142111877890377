import styled from 'styled-components'

export const PriceDurationBox = styled.div`
  width: 100%;
  max-width: 395px;
  margin: 20px auto;
  background: ${(props) => props.theme.iconBackground}
  border: ${(props) => props.theme.infoBorderProperties};
  border-radius: 6px;
  box-sizing: border-box;

  .big {
    font-size: 2em;
    color: ${(props) => props.theme.primaryColour};
    text-transform: uppercase;
    vertical-align: middle;
    font-family: 'BrandonText', sans-serif;
  }

  p {
    font-family: 'freight-sans-pro', sans-serif !important;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    .big {
      font-size: 18px;
    }
  }
`

export const InsuranceDurationSpan = styled.span`
  color: ${(props) => props.theme.primaryColour};
`
