import React from 'react'
import Logo from '../../../images/A-PlanLogo.jpg'
import {
  ConfirmationWrapper,
  AnnuaLInsuranceLogo,
  AnnualInsuranceConfirmationHeaderWrapper,
  AnnualInsuranceConfirmationHeader,
  AnnualInsuranceText,
} from '../styles'

const APlanProvider = () => {
  const style = { backgroundColor: '#F33', highlightColor: '#F33' }
  return (
    <ConfirmationWrapper styleOverride={style}>
      <AnnualInsuranceConfirmationHeaderWrapper styleOverride={style}>
        <AnnualInsuranceConfirmationHeader styleOverride={style}>
          Thank you for Choosing 'A-Plan
        </AnnualInsuranceConfirmationHeader>
      </AnnualInsuranceConfirmationHeaderWrapper>
      <AnnualInsuranceText>
        Thank you for requesting a call back to discuss your annual insurance
        needs.
      </AnnualInsuranceText>
      <AnnualInsuranceText>
        Your request has been submitted to the team and you will receive a call
        shortly.
      </AnnualInsuranceText>
      <AnnuaLInsuranceLogo src={Logo} alt={'A-Plan Logo'} />
    </ConfirmationWrapper>
  )
}

export default APlanProvider
