import styled from 'styled-components'
import { FormControlWrapper } from '../FormControlWrapper'
import { GBIcon } from '../VehicleReg/styles'
import { UppercaseInput } from '../common/Input/UppercaseInput'
export const RegistrationInput = styled(UppercaseInput)`
  font-family: ${(props) => props.theme.regFont};
  color: #000;
  letter-spacing: 1px;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.primaryColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  box-sizing: border-box;
  margin: 0 0 0.5em 0;
  ::placeholder {
    text-transform: none;
    letter-spacing: 0;
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 26px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
    height: 32px;
  }
`

export const RegistrationWrapper = styled(FormControlWrapper)`
  text-align: center;
  input {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Charles-Wright', Arial, san-serif;
    font-size: 24px;
    color: #000;
    ::placeholder {
      text-transform: none;
      font-family: ${(props) => props.theme.fontFamily};
    }
  }
  i {
    @media (max-width: 767px) {
      top: 2px;
    }
  }
`

export const ChangeVehicleGBIcon = styled(GBIcon)`
  height: 45px;
  line-height: 45px;
`
