import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCallback } from '../../actions'
import { requestConfusedQuickQuoteResponse } from '../../../App/actions'
import ConfusedLoadingBanner from './ConfusedLoadingBanner'
import ConfusedStaticBanner from './ConfusedStaticBanner'
import ConfusedRTPBanner from './ConfusedRTPBanner'
import { Container } from './styles'
import config from '../../../../config'
import { SYSTEM_FEATURES } from '../../../../services/constants'
const ConfusedRtpProvider = ({
  callbackRequest,
  features,
  actions: { requestCallback, requestConfusedQuickQuoteResponse },
  confusedQuickQuote,
  confusedQuickQuote: {
    OptIn,
    RequestAccepted,
    RequestedDateTime,
    PricingLoaded,
    PricingLoadAttempts,
    Pricing,
  },
  vehicle,
}) => {
  const RenderBanner = () =>
    useMemo(() => {
      if (RequestAccepted) {
        if (PricingLoaded) {
          return (
            <Container>
              <ConfusedRTPBanner
                callbackRequest={callbackRequest}
                requestCallback={requestCallback}
                pricing={Pricing}
                registrationNumber={vehicle.RegistrationNumber}
              />
            </Container>
          )
        } else if (
          PricingLoadAttempts < config.CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS
        ) {
          return (
            <Container>
              <ConfusedLoadingBanner
                requestConfusedQuickQuoteResponse={
                  requestConfusedQuickQuoteResponse
                }
                confusedQuickQuoteDateTimeRequested={RequestedDateTime}
                confusedQuickQuoteLoadCount={PricingLoadAttempts}
                registrationNumber={vehicle.RegistrationNumber}
              />
            </Container>
          )
        }
      }

      return (
        <Container>
          <ConfusedStaticBanner
            callbackRequest={callbackRequest}
            requestCallback={requestCallback}
          />
        </Container>
      )
    }, [features, confusedQuickQuote])

  if (features[SYSTEM_FEATURES.UPSELL_CONFUSED_ENABLED] && OptIn !== null) {
    return <>{RenderBanner()}</>
  }
  return <></>
}

const mapStateToProps = (state) => {
  return {
    callbackRequest: state.policy.callbackRequest,
    affiliate: state.affiliate,
    features: state.features || {},
    confusedQuickQuote: state.confusedQuickQuote,
    vehicle: state.vehicle.selected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestCallback,
        requestConfusedQuickQuoteResponse,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfusedRtpProvider)
