import {
  CREATE_JUDO_PAYMENT,
  CREATE_JUDO_PAYMENT_SUCCESS,
  CREATE_JUDO_PAYMENT_FAILURE,
  SEND_3D_SECURE_DATA_SUCCESS,
} from './constants'
import { SEND_3D_SECURE_DATA } from './constants'

export function createJudoPay(payload) {
  return {
    type: CREATE_JUDO_PAYMENT,
    payload,
  }
}

export function createJudoPaySuccess(response) {
  return {
    type: CREATE_JUDO_PAYMENT_SUCCESS,
    response,
  }
}

export function createJudoPayFailure(errorMessage) {
  return {
    type: CREATE_JUDO_PAYMENT_FAILURE,
    errorMessage,
  }
}

export function send3DSecureData(data, callback) {
  return {
    type: SEND_3D_SECURE_DATA,
    data,
    callback,
  }
}

export function send3DSecureDataSuccess(response) {
  return {
    type: SEND_3D_SECURE_DATA_SUCCESS,
    response,
  }
}
