import React from 'react'
import { connect } from 'react-redux'
import Modal from '../../components/common/NewModal'
import { H2 } from '../../components/common/H2'
import { bindActionCreators } from 'redux'
import {
  requestVehicle,
  updateSearchedVehicle,
  getModels,
  getVariants,
  createUnkownVehicle,
  setVehicle,
} from '../Vehicle/actions'
import './style.css'
import Vehicle from '../Vehicle'

export class VehicleModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      pendingSearch: null,
    }
    this.onChange = this.onChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  onChange = (event, message) => {
    this.props.actions.updateSearchedVehicle({
      RegistrationNumber: event.target.value,
    })
    this.setState({
      error: message,
      pendingSearch: true,
    })
  }

  handleSearch() {
    this.props.actions.requestVehicle(
      this.props.vehicle.searched.RegistrationNumber,
    )
    this.setState({
      pendingSearch: false,
    })
  }

  handleSelect = (event) => {
    const index = event.nativeEvent.target.selectedIndex
    switch (event.target.name) {
      case 'makes':
        this.props.actions.updateSearchedVehicle({
          Make: event.target.value,
        })
        this.props.actions.getModels()
        break
      case 'models':
        this.props.actions.updateSearchedVehicle({
          Model: event.target.value,
        })
        this.props.actions.getVariants()
        break
      case 'variants':
        this.props.actions.updateSearchedVehicle({
          Abi: event.target.value,
          Variant: event.nativeEvent.target[index].text,
        })
        this.props.actions.createUnkownVehicle()
        break
      default:
        break
    }
  }

  render() {
    return (
      <Modal
        id='VehicleModal'
        isVisible={this.props.isVisible}
        display='center'
        theme='auto'
        buttons={[]}
        scrollLock={true}
        layout='liquid'
        onClose={this.props.hideModal}
        className='vehicle-modal'
      >
        <H2>Change vehicle</H2>
        <>
          <Vehicle handleConfirm={this.props.handleUpdate} />
        </>
      </Modal>
    )
  }
}
export const mapStateToProps = (state) => {
  return {
    vehicle: state.vehicle,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateSearchedVehicle,
        requestVehicle,
        getModels,
        getVariants,
        createUnkownVehicle,
        setVehicle,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleModal)
