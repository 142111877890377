import {
  RESET_PAYMENT_OPTIONS,
  HANDLE_MOBILE_VALIDATION,
  FRAUD_FAILURE,
  SET_PAYMENT_IN_PROGRESS,
} from './constants'

export function resetPaymentOptions() {
  return {
    type: RESET_PAYMENT_OPTIONS,
  }
}

export function handleMobileValidation(message) {
  return {
    type: HANDLE_MOBILE_VALIDATION,
    message,
  }
}

export function fraudFailure() {
  return {
    type: FRAUD_FAILURE,
  }
}

export function setPaymentInProgress() {
  return {
    type: SET_PAYMENT_IN_PROGRESS,
  }
}
