import styled from 'styled-components'
import { H2 } from '../common/H2'
export const GapButtonContainer = styled.section``

export const GapContainer = styled.div`
  @media (max-width: 767px) {
    padding-top: 12px;
  }
`

export const GapGrid = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 1.5fr;
  @media (max-width: 767px) {
    grid-template-columns: 0.5fr 1.75fr 1.25fr;
  }
  div:first-child {
    padding-left: 24px;
    @media (max-width: 767px) {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
  div:last-child {
    padding-right: 24px;
    @media (max-width: 767px) {
      padding-right: 12px;
    }
  }
`
export const GridItem = styled.div``
export const GridItemLeft = styled(GridItem)`
  text-align: left;
  h2 {
    text-align: left;
    margin-top: 12px;
    font-size: 18px;
    @media (max-width: 425px) {
      font-size: 16px;
    }
  }
  a {
    font-family: Lato;
    font-size: 14px;
    margin-top: 12px;
    @media (max-width: 425px) {
      font-size: 10px;
    }
  }
  p {
    margin: 0;
    @media (max-width: 425px) {
      font-size: 10px;
    }
  }
`
export const FreeGapText = styled(H2)`
  font-size: 24px !important;
`

export const GridItemRight = styled(GridItem)`
  text-align: right;
  h2 {
    text-align: right;
    font-size: 32px;
    margin-top: inherit;
  }
  p {
    font-weight: bold;
    font-size: 12px;
    @media (max-width: 425px) {
      font-size: 10px;
    }
  }
`
export const GapText = styled.p`
  font-size: 14px;
  color: #000;
  font-family: Lato;
`
export const GapDetailsText = styled(GapText)`
  margin-bottom: 0px;
  margin-top: 0px;
`

export const GapIcon = styled.div`
  display: flex;
  padding-left: 20px;
  align-items: center;
  width: 42px;
  flex: 0 0 66px;

  svg {
    width: 42px;
    height: 34px;

    #Path_538 {
      display: none;
    }
  }

  @media (max-width: 425px) {
    width: 40px;
    flex: 0 0 40px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`
export const GapButton = styled.button`
  width: 70%;
  background: ${(props) => props.theme.button.backgroundColour};
  color: ${(props) => props.theme.button.textColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-size: ${(props) => props.theme.button.fontSize};
  text-transform: ${(props) => props.theme.button.textTransform};
  text-align: ${(props) => props.theme.button.textAlign};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: ${(props) => props.theme.formControlHeight};

  :hover {
    filter: brightness(80%);
  }

  @media (max-width: 320px) {
    width: 90px;
  }

  ${({ active }) =>
    active &&
    `
    margin-bottom:0px;
    :hover {
      filter: none;
    }
    cursor: default;
    background: #808080;
  `}
`

export const RemoveButton = styled.button`
  background: none !important;
  border: none;
  font-family: arial, sans-serif;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding-right: 10px;
  width: 80%;
  margin-bottom: 13px;
  @media (max-width: 576px) {
    margin-bottom: 9px;
  }

  @media (min-width: 380px) and (max-width: 520px) {
    right: 90px;
  }

  @media (max-width: 380px) {
    right: 78px;
  }

  :hover {
    filter: brightness(80%);
  }
`

export const GapMoreInfoTitle = styled.h2`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: 22px;
  margin-left: 0 !important;
  margin: 5px;
`

export const GapMoreInfoBody = styled.div`
  font-family: ${(props) => props.theme.labelFontFamily};
  max-height: 60vh;
  overflow-y: auto;
`

export const GapMoreInfoText = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: 14px;
`
export const LinkContainer = styled.a`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.primaryTextColour};

  :visited {
    color: ${(props) => props.theme.primaryTextColour};
  }

  @media (max-width: 576px) {
    font-size: 12px;
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  user-select: none;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`
export const GapMoreInfoWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  cursor: default;
  width: 70%;
  max-height: 90vh;
  background: #fff;
  color: ${(props) => props.theme.primaryTextColour};
  box-shadow: 0px 0px 15px 0px #707070;
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  padding: 10px;
  z-index: 2;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;

    & li {
      font-family: ${(props) => props.theme.fontFamily};
      font-size: 14px;
      color: #000;
      text-align: left;

      &:last-child {
        border: none;
      }

      & span {
        font-family: 'BrandonText-Bold', sans-serif;
        display: block;
        color: ${(props) => props.theme.primaryTextColour};
      }
    }
  }

  a,
  p {
    margin: 1em 0 !important;
  }

  @media (max-width: 767px) {
    font-size: 12px;

    & ul li {
      font-size: 12px;
    }
  }
`

export const PaymentPageLinkContainer = styled.a`
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.primaryTextColour};
  font-family: ${(props) => props.theme.fontFamily};
  text-decoration: underline;

  :visited {
    color: ${(props) => props.theme.primaryTextColour};
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
