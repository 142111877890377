import React from 'react'
import Modal from '../../components/common/NewModal'
import { ModalContent } from './styles'

export const DeclarationModal = ({ id, text, title, isVisible, hideModal }) => {
  return (
    <Modal
      id={id}
      isLarge
      isVisible={isVisible}
      onClose={hideModal}
      header={title}
      headerAlignment='center'
      fullscreen={window.innerWidth <= 500}
    >
      <ModalContent
        id={id}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </Modal>
  )
}
