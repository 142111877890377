import {
  REQUEST_ADDRESS,
  REQUEST_ADDRESS_SUCCESS,
  REQUEST_ADDRESS_FAILED,
  UPDATE_POSTCODE,
  SELECT_ADDRESS,
} from './constants'
import { REQUEST_QUOTE_SUCCESS } from '../App/constants'
import initialState from '../../initialState'
import {
  REQUEST_OCR_FRONT_SUCCESS,
  REQUEST_OCR_ADDRESS_SUCCESS,
  REQUEST_OCR,
  REQUEST_OCR_ADDRESS_FAILURE,
} from '../DrivingLicenceCamera/constants'
import { toTitleCase } from '../../services/handlers'
import { GET_ANALYSIS_DATA_SUCCESS } from '../PolicyConfirmationPage/constants'

function addressReducer(state = initialState.address, action) {
  switch (action.type) {
    case UPDATE_POSTCODE:
      return {
        ...state,
        Postcode: action.postcode,
        FailedSearch: false,
      }
    case REQUEST_ADDRESS:
      return {
        ...state,
        Postcode: action.postcode,
      }
    case REQUEST_OCR_ADDRESS_SUCCESS:
    case REQUEST_ADDRESS_SUCCESS:
      return {
        ...state,
        SearchResults: action.addresses,
        FailedSearch: false,
      }
    case REQUEST_OCR_ADDRESS_FAILURE:
      return {
        ...state,
        SearchResults: [],
        FailedSearch: true,
      }
    case REQUEST_ADDRESS_FAILED:
      return {
        ...state,
        SearchResults: [],
        FailedSearch: true,
      }
    case REQUEST_OCR:
      return {
        ...state,
        Postcode: '',
        AddressLineOne: '',
        AddressLineTwo: '',
        AddressLineThree: '',
        AdrdressLineFour: '',
        SelectedAddress: '',
        SearchResults: [],
      }
    case REQUEST_OCR_FRONT_SUCCESS:
      return {
        ...state,
        Postcode: action.response.LicenceAddress.PostCode,
        AddressLineOne: toTitleCase(action.response.LicenceAddress.Line1),
        AddressLineTwo: toTitleCase(action.response.LicenceAddress.Line2),
        AddressLineThree: toTitleCase(action.response.LicenceAddress.Line3),
        AdrdressLineFour: toTitleCase(action.response.LicenceAddress.Line4),
      }
    case REQUEST_QUOTE_SUCCESS:
      const address = action.quote.QuoteAddresses.filter(
        (a) => a.QuoteAddressType === 'MainAddress',
      )[0]
      return {
        ...state,
        Postcode: address.Postcode,
        AddressLineOne: address.Line1,
        AddressLineTwo: address.Line2,
        AddressLineThree: address.Line3,
        AddressLineFour: address.PostTown,
      }
    case SELECT_ADDRESS:
      return {
        ...state,
        SelectedAddress: action.address,
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        Postcode: action.response.Postcode,
      }
    default:
      return state
  }
}

export default addressReducer
