import http from '../api/http'

export default class underwriterApi {
  static getLegalDeclaration(endpoint) {
    return http.get(endpoint, {
      headers: {
        'Content-Type':
          'application/tempcover.QuoteDeclarationRepresentation+json',
      },
    })
  }
}
