import React from 'react'
import { LICENCE_TYPES } from './constants'
import { ErrorMessage } from '../common/ErrorMessage'
import { Select } from '../common/Select'

const LicenceType = ({ id, error, errorMessage, onChange, name, value }) => {
  return (
    <>
      <Select
        id={id}
        name={name}
        onChange={onChange}
        options={LICENCE_TYPES}
        value={value}
      />
      {error && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
    </>
  )
}

export default LicenceType
