import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  BranchSelectWrapper,
  BranchFilter,
  BranchList,
  Branch,
  BranchDropdownChevron,
  BranchDropdownSpinner,
  BranchSelectHeader,
} from './styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { faChevronDown, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { getIntroducerBranches, setSelectedBranch } from './actions'
import { ErrorMessage } from '../../components/common/ErrorMessage'
import { Label } from '../../components/common/Label'

const BranchSelect = ({
  driveaway: { Branches: branches, IsLoading: branchesLoading },
  appData: { IsLoading, BranchesDrivenByStocklist },
  actions,
  filterId,
  dropdownId,
  tabIndex,
  setFieldValue,
  error,
  errorMessage,
  name,
  driverDetailsPage,
  placeholder,
  value,
}) => {
  const [selectedBranch, setSelected] = useState(value)
  const [filter, setFilter] = useState((value && value.BranchName) || '')
  const [isActive, setIsActive] = useState(false)
  const [isValid, setIsValid] = useState(null)
  const [filteredBranches, setFilteredBranches] = useState(
    branches.filter((b) => !b.IsHidden && b.BranchStatus === 2),
  )
  const [highlightedBranch, setHighlightedBranch] = useState(-1) // none selected

  const branchFilterRef = useRef()
  const branchListRef = useRef()

  useEffect(() => {
    if (!IsLoading && !BranchesDrivenByStocklist) {
      actions.getIntroducerBranches()
    }
  }, [IsLoading, BranchesDrivenByStocklist])
  useEffect(() => {
    setFieldValue('Branch', selectedBranch)
  }, [selectedBranch])

  useEffect(() => {
    let filtered = branches.filter((b) => !b.IsHidden && b.BranchStatus === 2)
    if (filter !== '') {
      const keywords = filter
        .toLowerCase()
        .split(/(\s+)/)
        .filter((k) => k.trim().length > 0)
      if (keywords.length > 0) {
        filtered = filtered.filter((branch) =>
          keywords.every(
            (key) =>
              branch.BranchName &&
              branch.BranchName.toLowerCase().includes(key),
          ),
        )
      }
    }

    filtered.sort((a, b) => (a.BranchName > b.BranchName ? 1 : -1))

    setFilteredBranches(filtered)

    if (highlightedBranch >= filtered.length) {
      setHighlightedBranch(filtered.length - 1)
    }
  }, [filter, branches, setFilteredBranches, setHighlightedBranch])

  useEffect(() => {
    if (selectedBranch) {
      setFilter(selectedBranch.BranchName)
      setIsActive(false)
      setHighlightedBranch(-1)
    }
    actions.setSelectedBranch(selectedBranch)
    if (setFieldValue) {
      setFieldValue('Branch', selectedBranch)
    }
  }, [selectedBranch, setFilter, setIsActive, setHighlightedBranch])

  useEffect(() => {
    if (isActive) {
      setSelected(null)
    } else {
      handleValidation()
    }
  }, [isActive, setSelected])

  const handleKeyPress = (e) => {
    switch (e.which) {
      case 9:
        handleTabAndEnter(e, false)
        break
      case 13:
        handleTabAndEnter(e, true)
        break
      case 38:
        handleArrow(e, -1)
        break
      case 40:
        handleArrow(e, 1)
        break
      default:
        break
    }
  }

  const handleTabAndEnter = (e, preventDefault) => {
    if (preventDefault) {
      e.preventDefault()
      branchFilterRef.current.blur()
    }

    if (filteredBranches && filteredBranches.length === 1) {
      setSelected(filteredBranches[0])
    } else if (
      filteredBranches &&
      highlightedBranch > -1 &&
      highlightedBranch <= filteredBranches.length
    ) {
      setSelected(filteredBranches[highlightedBranch])
    }
  }

  const handleArrow = (e, direction) => {
    e.preventDefault()

    if (filteredBranches && filteredBranches.length > 0) {
      if (highlightedBranch + direction >= filteredBranches.length) {
        setHighlightedBranch(filteredBranches.length - 1)
      } else if (highlightedBranch + direction < 0) {
        setHighlightedBranch(0)
      } else {
        setHighlightedBranch(highlightedBranch + direction)
      }

      branchListRef.current.scrollTo(
        0,
        33 * (highlightedBranch + direction) - 33,
      )
    }
  }

  const handleValidation = () => {
    setIsValid(
      (selectedBranch === null && isValid === null) || !!selectedBranch,
    )
  }

  const handleOnClick = useCallback(
    (e, branch) => {
      setSelected(branch)
    },
    [setSelected],
  )

  const handleOnBlur = (e) => {
    if (filteredBranches && filteredBranches.length === 1) {
      setSelected(filteredBranches[0])
    } else {
      e.preventDefault()
      setIsActive(false)
    }
  }

  return (
    <>
      {branches && branches.length > 1 && (
        <>
          {driverDetailsPage ? (
            <Label>Which branch are you purchasing from</Label>
          ) : (
            <BranchSelectHeader>
              Which branch are you purchasing from
            </BranchSelectHeader>
          )}
          {error && (
            <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>
          )}
          <BranchSelectWrapper onBlur={(e) => handleOnBlur(e)}>
            <BranchFilter
              value={filter}
              driverDetailsPage={driverDetailsPage}
              onChange={(e) => setFilter(e.target.value)}
              onFocus={() => setIsActive(true)}
              isActive={isActive}
              id={filterId}
              autoCorrect='off'
              autoComplete='off'
              placeholder={placeholder}
              onKeyDown={(e) => handleKeyPress(e)}
              tabIndex={tabIndex}
              ref={branchFilterRef}
            />
            {!branchesLoading && !IsLoading && (
              <BranchDropdownChevron
                icon={faChevronDown}
                driverDetailsPage={driverDetailsPage}
              />
            )}
            {(branchesLoading || IsLoading) && (
              <BranchDropdownSpinner icon={faCircleNotch} className='fa-spin' />
            )}
            {isActive && filteredBranches && filteredBranches.length > 0 && (
              <BranchList
                id={dropdownId}
                ref={branchListRef}
                driverDetailsPage={driverDetailsPage}
              >
                {filteredBranches.map((branch, index) => {
                  return (
                    <Branch
                      key={branch.BranchId}
                      onMouseDown={(e) => handleOnClick(e, branch)}
                      highlighted={index === highlightedBranch}
                    >
                      {branch.BranchName}
                    </Branch>
                  )
                })}
              </BranchList>
            )}
          </BranchSelectWrapper>
        </>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    driveaway: state.driveaway,
    appData: state.appData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getIntroducerBranches,
        setSelectedBranch,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchSelect)
