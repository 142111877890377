import http from './http'

export default class paymentApi {
  static getApplePayMerchantSession(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.ApplePayMerchantSessionRepresentation+json',
      },
    })
  }

  static createApplePayment(endpoint, data) {
    return http.post(endpoint, data, {
      header: {
        'Content-Type': 'application/tempcover.ApplePayRepresentation+json',
      },
    })
  }

  static createJudoPayment(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.judopurchasepolicyrepresentation+json',
        Accept: 'application/tempcover.JudoResultRepresentation+json',
      },
    })
  }

  static createPayPalPayment(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.paypalpurchaserepresentation+json',
      },
    })
  }

  static prePurchase(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.CreatePaymentRequestLogRepresentation+json',
      },
    })
  }

  static failedPayment(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.ProcessUnsuccessfulPaymentRepresentation+json',
      },
    })
  }

  static createStripePayment(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.StripePurchasePolicyRepresentation+json',
      },
    })
  }

  static createNoPayment(endpoint, data) {
    return http.post(endpoint, data, {
      headers: {
        'Content-Type':
          'application/tempcover.NoPaymentPurchasePolicyRepresentation+json',
      },
    })
  }
}
