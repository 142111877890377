import React from 'react'
import { history } from '../../..'

const InternalLink = (props) => {
  const handleClick = (e) => {
    e.preventDefault()
    const url = e.currentTarget.pathname
    history.push(url)
  }

  return (
    <a {...props} onClick={handleClick}>
      {props.children}
    </a>
  )
}

export default InternalLink
