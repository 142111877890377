import React from 'react'
import {
  GapActivationButton,
  GapActivationConfirmationContainer,
  GapHeader,
  GapText,
  GapTitle,
  MotorEasyLogo,
} from './styles'
const GapActivationConfirmation = () => {
  const handleClick = () => {
    window.open(
      'https://tempcover.motoreasy.com/activate/gap?utm_medium=affiliate&utm_campaign=driveawaygap&utm_source=tempcover',
      '_blank',
    )
  }

  return (
    <>
      <GapActivationConfirmationContainer>
        <GapHeader>
          <GapTitle>Don't forget to activate your GAP policy</GapTitle>
          <MotorEasyLogo />
        </GapHeader>
        <GapText id='GapActivationWording'>
          Your 30-day free GAP policy won't come into effect until you visit our
          partner MotorEasy. Click below to activate your free GAP policy in a
          few simple steps.
        </GapText>
        <GapActivationButton
          id='ActivateGapInsurance'
          onClick={handleClick}
          target='_blank'
          rel='noopener noreferrer'
        >
          Activate Gap Insurance
        </GapActivationButton>
      </GapActivationConfirmationContainer>
    </>
  )
}

export default GapActivationConfirmation
