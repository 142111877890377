import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  requestVehicle,
  updateSearchedVehicle,
  updateGrossWeight,
  updateYearOfManufacture,
  getModels,
  getVariants,
  createUnkownVehicle,
  setVehicle,
  setVehicleType,
  getMakes,
} from '../Vehicle/actions'
import { A } from '../../components/common/A'
import { Button } from '../../components/common/Button'
import { Select } from '../../components/common/Select'
import { VehicleRegistration } from '../../components/VehicleRegistration'
import { FormControlValidIcon } from '../../components/FormControlValidIcon'
import { RegistrationWrapper } from '../../components/VehicleRegistration/styles'
import { VehicleButtonWrapper, VehicleParagraph } from './styles'
import { Message } from '../../components/Message'
import {
  PRIVATE_CAR,
  COMMERCIAL_VEHICLE,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
} from './constants'
import { VehicleBuilder } from '../../components/VehicleBuilder'
import { GrossWeight } from '../../components/GrossWeight'
import { YearOfManufacture } from '../../components/YearOfManufacture'
import { MessageContent } from '../../components/Message/MessageContent'

/* eslint-disable react/prefer-stateless-function */
export class Vehicle extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
    }
    this.onChange = this.onChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.renderStocklistError = this.renderStocklistError.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.vehicle.searched.VehicleType &&
      prevProps.vehicle.searched.VehicleType !==
        this.props.vehicle.searched.VehicleType &&
      this.props.vehicle.error === 'NotFound'
    ) {
      this.props.actions.getMakes()
    }
  }
  onChange = (event, message) => {
    switch (event.target.name) {
      case 'RegistrationInput':
        this.props.actions.updateSearchedVehicle({
          RegistrationNumber: event.target.value.trim().replace(/\s/g, ''),
          VehicleId: null,
          Make: null,
          Model: null,
          VehicleType: null,
          Abi: null,
          MakeAndModel: null,
          DateFirstRegistered: null,
          Variant: null,
          GrossWeight: null,
          YearOfManufacture: null,
        })
        this.setState({
          error: message,
        })
        break
      case 'GrossWeight':
        this.props.actions.updateGrossWeight(event.target.value)
        break
      default:
        break
    }
  }

  handleSearch() {
    this.props.actions.requestVehicle(
      this.props.vehicle.searched.RegistrationNumber,
    )
  }

  handleSelect = (event) => {
    const index = event.nativeEvent.target.selectedIndex
    switch (event.target.name) {
      case 'vehicleType':
        this.props.actions.setVehicleType(event.target.value)
        break
      case 'makes':
        this.props.actions.updateSearchedVehicle({
          Make: event.target.value,
        })
        this.props.actions.getModels()
        break
      case 'models':
        this.props.actions.updateSearchedVehicle({
          Model: event.target.value,
        })
        this.props.actions.getVariants()
        break
      case 'variants':
        this.props.actions.updateSearchedVehicle({
          Abi: event.target.value,
          Variant: event.nativeEvent.target[index].text,
        })
        break
      default:
        break
    }
  }

  handleDateSet = (e) => {
    this.props.actions.updateYearOfManufacture(e.target.value)
  }

  validVehicle() {
    const { searched } = this.props.vehicle
    const isVehicleFoundAndAllowed =
      searched.VehicleFound &&
      this.props.schemes.some((s) =>
        s.Scheme.includes(this.props.vehicle.searched.VehicleType),
      )
    let isVehicleBuilt = false

    switch (searched.VehicleType) {
      case PRIVATE_CAR:
        isVehicleBuilt =
          searched.VehicleFound === false &&
          searched.Make &&
          searched.Model &&
          searched.Abi
        break
      case COMMERCIAL_VEHICLE:
        if (searched.GrossWeight <= COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) {
          isVehicleBuilt =
            searched.VehicleFound === false &&
            searched.GrossWeight &&
            searched.Make &&
            searched.Model &&
            searched.Abi &&
            searched.YearOfManufacture
        }
        break
      default:
        break
    }
    return isVehicleFoundAndAllowed || isVehicleBuilt
  }

  renderStocklistError() {
    switch (this.props.appData.Affiliate.AffiliateRef.toUpperCase()) {
      case 'CAZOOREF':
        return (
          <Message heading='Sorry!' type='error' id='CazooErrorMessage'>
            <MessageContent>
              Please double check you've entered your car's details correctly.
              <br />
              If you need help, you can call Cazoo on{' '}
              <A style={{ fontSize: '14px' }} href='tel:020-3901-3488'>
                020 3901 3488
              </A>{' '}
              to verify your car is valid for{' '}
              {this.props.appData.HomePagePricing.PolicyDuration} days of free
              insurance.
            </MessageContent>
          </Message>
        )
      case 'AACARS':
        return (
          <Message heading='Sorry!' type='error'>
            <MessageContent>
              It looks like this vehicle is not eligible for Driveaway
              Insurance. If you believe this is incorrect, please{' '}
              <A style={{ fontSize: '14px' }} href='/contact-us'>
                contact us
              </A>
              .
            </MessageContent>
          </Message>
        )
      default:
        return (
          <Message
            heading='Sorry!'
            content={`It looks like this vehicle is not yet on ${this.props.appData.Affiliate.IntroducerName}'s stock list.  Please check the registration number entered and if you believe it is correct then contact ${this.props.appData.Affiliate.IntroducerName}.`}
            type='error'
          />
        )
    }
  }

  render() {
    return (
      <>
        <p>To get started, tell us the reg of the vehicle you want cover for</p>
        <RegistrationWrapper>
          <FormControlValidIcon
            error={this.state.error}
            className='fas fa-check'
          ></FormControlValidIcon>
          <VehicleRegistration
            value={this.props.vehicle.searched.RegistrationNumber}
            onChange={this.onChange}
            error={this.state.error}
          />
          <Button
            id='SearchVehicle'
            onClick={this.handleSearch}
            disabled={this.state.error || this.props.appData.IsLoading}
          >
            Search
          </Button>
        </RegistrationWrapper>
        {!this.props.appData.IsLoading && (
          <>
            {this.props.vehicle.error === 'InvalidReg' && (
              <Message
                heading='Sorry!'
                content='The above registration number is invalid, please try again.'
                type='error'
              />
            )}
            {this.props.vehicle.error === 'Forbidden' &&
              this.renderStocklistError()}
            {parseInt(this.props.vehicle.searched.GrossWeight) >
              COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT &&
              this.props.vehicle.searched.VehicleType ===
                COMMERCIAL_VEHICLE && (
                <Message
                  heading='Sorry!'
                  content={`It looks like the vehicle you're trying to insure is above the maximum weight we allow. We can only insure vehicles with a gross weight of ${COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT}KGs or under.`}
                  type='error'
                />
              )}
          </>
        )}

        {this.props.vehicle.searched.VehicleFound &&
          this.props.schemes.some((s) =>
            s.Scheme.includes(this.props.vehicle.searched.VehicleType),
          ) && (
            <>
              <VehicleParagraph>
                Vehicle:&nbsp;
                {this.props.vehicle.searched.Make}{' '}
                {this.props.vehicle.searched.Model}
              </VehicleParagraph>
            </>
          )}
        {this.props.vehicle.error === 'NotFound' && (
          <>
            <p>
              Sorry we've not found that vehicle, please select from the options
              below
            </p>
            <Select
              onChange={this.handleSelect}
              name='vehicleType'
              placeholder='My vehicle is a...'
              options={[
                { id: 1, description: 'Car', value: PRIVATE_CAR },
                {
                  id: 2,
                  description: 'Commercial vehicle',
                  value: COMMERCIAL_VEHICLE,
                },
              ]}
              value={this.props.vehicle.searched.VehicleType}
            />

            {this.props.vehicle.searched.VehicleType && (
              <>
                {this.props.vehicle.searched.VehicleType ===
                  COMMERCIAL_VEHICLE && (
                  <GrossWeight
                    onChange={this.onChange}
                    value={this.props.vehicle.searched.GrossWeight}
                  />
                )}
                {(this.props.vehicle.searched.VehicleType === PRIVATE_CAR ||
                  parseInt(this.props.vehicle.searched.GrossWeight) <=
                    COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT) && (
                  <VehicleBuilder
                    searchedVehicle={this.props.vehicle.searched}
                    vehicleOptions={{
                      makes: this.props.vehicle.makes,
                      models: this.props.vehicle.models,
                      variants: this.props.vehicle.variants,
                    }}
                    handleSelect={this.handleSelect}
                  />
                )}
                {this.props.vehicle.searched.VehicleType ===
                  COMMERCIAL_VEHICLE && (
                  <YearOfManufacture
                    handleDateSet={this.handleDateSet}
                    value={this.props.vehicle.searched.YearOfManufacture}
                  />
                )}
              </>
            )}
          </>
        )}
        {this.props.vehicle.searched.VehicleFound &&
          !this.props.schemes.some((s) =>
            s.Scheme.includes(this.props.vehicle.searched.VehicleType),
          ) && (
            <>
              <p>
                Sorry, we're currently not able to provide driveaway insurance
                for this vehicle. Please double check the registration you have
                entered.
              </p>
            </>
          )}
        {this.validVehicle() && (
          <VehicleButtonWrapper>
            <Button id='SetVehicle' onClick={this.props.handleConfirm}>
              Set vehicle
            </Button>
          </VehicleButtonWrapper>
        )}
      </>
    )
  }
}
export const mapStateToProps = (state) => {
  return {
    vehicle: state.vehicle,
    appData: state.appData,
    schemes: state.scheme.open,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateSearchedVehicle,
        updateGrossWeight,
        updateYearOfManufacture,
        requestVehicle,
        getMakes,
        getModels,
        getVariants,
        createUnkownVehicle,
        setVehicle,
        setVehicleType,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle)
