import styled from 'styled-components'
import { Button } from '../../components/common/Button'

export const StripePaymentButton = styled(Button)`
  width: 100%;
  margin-top: 1em;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
`
