import React from 'react'
import { Input } from '../common/Input'
import PropTypes from 'prop-types'
import validator from 'validator'
import { ErrorMessage } from '../common/ErrorMessage'
import { FormControlWrapper } from '../FormControlWrapper'
import { FormControlValidIcon } from '../FormControlValidIcon'

export class FullName extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (event) => {
    this.handleValidation(event)
    this.props.onChange(event)
  }

  handleValidation = (event) => {
    let message =
      validator.matches(
        event.target.value.trim(),
        /^[a-zA-Z]+(([’'\-\s][a-zA-Z])?[a-zA-Z]+)$/,
      ) && validator.isLength(event.target.value.trim(), { min: 2, max: 50 })
        ? ''
        : 'Please enter a valid full name'

    this.props.handleValidation(event.target.name, message)
  }
  handleBlur = (event) => {
    this.handleValidation(event)
    this.props.onBlur(event)
  }
  render() {
    return (
      <FormControlWrapper>
        <FormControlValidIcon
          error={this.props.error}
          className='fas fa-check'
        ></FormControlValidIcon>
        <Input
          placeholder={this.props.placeholder}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          error={this.props.error}
          name={this.props.name}
          value={this.props.value}
          id={this.props.id}
        />
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </FormControlWrapper>
    )
  }
}

export default FullName

FullName.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  valid: PropTypes.bool,
}
