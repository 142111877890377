export const TITLE_OPTIONS = [
  {
    id: 1,
    description: 'Mr',
    value: 'Mr',
  },
  {
    id: 2,
    description: 'Mrs',
    value: 'Mrs',
  },
  {
    id: 3,
    description: 'Miss',
    value: 'Miss',
  },
  {
    id: 4,
    description: 'Ms',
    value: 'Ms',
  },
]
