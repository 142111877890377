import axios from 'axios'
import config from '../config'

export default class wordPressApi {
  static getWordpressContent(pageNumber) {
    return axios.get(
      `${config.DIRECT_WORDPRESS_URL}/wp-json/wp/v2/pages/${pageNumber}`
    )
  }
}
