import {
  REQUEST_CONFUSED_QUICK_QUOTE_FAILURE,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS,
  REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS,
  REQUEST_QUOTE_SUCCESS,
  UPDATE_CONFUSED_QUICK_QUOTE_OPT_IN,
} from '../constants'

import initialState from '../../../initialState'
import { REQUEST_POLICY_SUCCESS } from '../../PolicyConfirmationPage/constants'

function confusedQuickQuoteReducer(
  state = initialState.confusedQuickQuote,
  action,
) {
  switch (action.type) {
    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        OptIn: action.quote.ConfusedQuickQuoteOptIn,
      }
    case UPDATE_CONFUSED_QUICK_QUOTE_OPT_IN:
      return {
        ...state,
        OptIn: action.optIn,
      }
    case REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS:
      return {
        ...state,
        RequestAccepted: action.status,
        RequestedDateTime: new Date().getTime(),
      }
    case REQUEST_CONFUSED_QUICK_QUOTE_FAILURE:
      return {
        ...state,
        RequestAccepted: false,
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        OptIn: action.policy.ConfusedQuickQuoteOptIn,
        RequestAccepted: action.policy.ConfusedQuickQuoteRequestAccepted,
      }
    case REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS:
      return {
        ...state,
        Pricing: action.quickQuote.Pricing,
        PricingLoaded: true,
      }
    case REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE:
      return {
        ...state,
        PricingLoaded: false,
        PricingLoadAttempts: state.PricingLoadAttempts + 1,
      }
    default:
      return state
  }
}

export default confusedQuickQuoteReducer
