import { put, select, takeLatest } from 'redux-saga/effects'
import { history } from '../..'
import { ANNUAL_INSURANCE_PROVIDER_ID } from '../../constants'
import { SYSTEM_FEATURES } from '../../services/constants'
import { updateConfusedQuickQuoteOptIn } from '../App/actions'
import { getAppData } from '../App/sagas/select'
import { getAnnualInsuranceProvider, getSystemFeatures } from '../App/selector'
import { setSelectedBranch } from '../BranchSelect/actions'
import { saveMarketingPreferences } from '../MarketingPreferencesContainer/saga'
import { enableAllButtons } from '../RiskDataCollectionPage/actions'
import { updateRiskDataSaga } from '../RiskDataCollectionPage/sagas/riskData'
import { getQueryString, getRiskData } from '../RiskDataCollectionPage/selector'
import { SUBMIT_DRIVER_DETAILS_FORM } from './constants'

function* submitDriverDetailsForm(action) {
  const { formValues } = action
  const stateRiskData = yield select(getRiskData)
  const features = yield select(getSystemFeatures)
  const appData = yield select(getAppData)
  const annualInsuranceProvider = yield select(getAnnualInsuranceProvider)

  // TODO: Finalise Marketing Preferences Payload
  const marketingPreferences = {
    email: formValues.EmailAddress,
    emailOptIn: formValues.EmailAddressOptIn,
    telephoneNumber: formValues.MobileNumber,
    telephoneNumberOptIn: formValues.TelephoneNumberOptIn,
    affiliateId: appData.Affiliate.AffiliateRef,
  }

  if (appData.EnableMarketingPreferences)
    yield* saveMarketingPreferences(marketingPreferences)

  if (
    annualInsuranceProvider === ANNUAL_INSURANCE_PROVIDER_ID.ConfusedRTP &&
    features[SYSTEM_FEATURES.UPSELL_CONFUSED_ENABLED] &&
    formValues.ConfusedQuickQuoteOptIn !== ''
  ) {
    yield put(updateConfusedQuickQuoteOptIn(formValues.ConfusedQuickQuoteOptIn))
  }

  const riskData = {
    ...stateRiskData,
    PolicyStartDate: formValues.PolicyStartDate,
    Title: formValues.Title,
    Forename: formValues.Forename,
    Surname: formValues.Surname,
    DateOfBirth: formValues.DateOfBirth.date,
    AddressKey: formValues.Address.AddressKey,
    LicenceType: formValues.LicenceType,
    AdditionalDriver: {
      ...formValues.AdditionalDriver,
      DateOfBirth: formValues.AdditionalDriver.DateOfBirth.date,
    },
    EmailAddress: formValues.EmailAddress,
    Mobile: formValues.MobileNumber,
    FreeGapActivationJourneyIncluded:
      formValues.FreeGapActivationJourneyIncluded,
  }

  const queryString = yield select(getQueryString)
  yield put(setSelectedBranch(formValues.Branch))
  yield* updateRiskDataSaga({
    payload: {
      riskData,
      getPrice: true,
      callback: () => {
        enableAllButtons()
        history.push(`/quote/driving-licence${queryString}`)
      },
    },
  })
}

export default function* driverDetailsSaga() {
  yield takeLatest(SUBMIT_DRIVER_DETAILS_FORM, submitDriverDetailsForm)
}
