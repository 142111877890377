import React from 'react'
import { PriceDurationBox } from './styles'
import Spinner from '../Spinner'

const HomePagePrice = ({
  AppData: { HomePagePricing, PartnerPays, IsLoading },
}) => {
  var formattedPrice = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  })
    .format(HomePagePricing.Price)
    .replace(/\.00/g, '')
  return (
    <section id='DurationPrice'>
      <PriceDurationBox className='price-duration-box'>
        <p>
          {IsLoading && <Spinner size='smaller' />}
          {!IsLoading && PartnerPays && (
            <>
              <span className='big'>{HomePagePricing.PolicyDuration} day</span>{' '}
              driveaway insurance
            </>
          )}
          {!IsLoading && !PartnerPays && (
            <>
              {HomePagePricing.MultiplePricing ? 'From only ' : 'Only '}
              <span className='big'>{formattedPrice}</span> for{' '}
              <span className='big'>{HomePagePricing.PolicyDuration} Days</span>
            </>
          )}
        </p>
      </PriceDurationBox>
    </section>
  )
}

export default HomePagePrice
