import initialState from '../../initialState'
import {
  SEND_EMAIL_QUOTE_SUCCESS,
  SEND_EMAIL_QUOTE_FAILURE,
  HANDLE_EMAIL_VALIDATION,
} from './constants'
import { UPDATE_RISK_DATA } from '../RiskDataCollectionPage/constants'
import { HANDLE_MOBILE_VALIDATION } from '../PaymentPage/constants'
import { REQUEST_QUOTE_SUCCESS } from '../App/constants'

export default function contactInformationReducer(
  state = initialState.contactInformation,
  action,
) {
  switch (action.type) {
    case SEND_EMAIL_QUOTE_SUCCESS:
      return {
        ...state,
        emailSent: true,
      }
    case SEND_EMAIL_QUOTE_FAILURE:
      return {
        ...state,
        emailError: action.error,
      }
    case UPDATE_RISK_DATA:
      return {
        ...state,
        emailSent: false,
      }
    case HANDLE_EMAIL_VALIDATION:
      return {
        ...state,
        emailError: action.message,
      }
    case HANDLE_MOBILE_VALIDATION:
      return {
        ...state,
        mobileNumberError: action.message,
      }
    case REQUEST_QUOTE_SUCCESS:
      return {
        ...state,
        emailError: action.quote.EmailAddress === '' || null ? null : '',
        mobileNumberError:
          action.quote.TelephoneNumber === '' || null ? null : '',
      }
    default:
      return state
  }
}
