import React, { Component } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Grid, GridContainer } from 'unsemantic'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'
import RiskDataCollectionPage from '../RiskDataCollectionPage'
import { PageNotFound } from '../../components/PageNotFound'
import { ThemeProvider } from 'styled-components'
import themeService from '../../services/themeService'
import PolicyConfirmationPage from '../PolicyConfirmationPage'
import InvalidRegPage from '../InvalidRegPage'
import ErrorPage from '../../components/ErrorPage'
import AppWrapper from './AppWrapper'
import BodyWrapper from './BodyWrapper'
import HoldingPage from '../../components/HoldingPage'
import HomePage from '../HomePage'
import ContactUsPage from '../ContactUsPage'
import { ROUTES } from '../../services/constants'
import { bindActionCreators } from 'redux'
import { getAppStartup } from './actions'
import ClaimsPage from '../../components/StaticPages/ClaimsPage'
import DriveawayFaqsPage from '../../components/StaticPages/DriveawayFaqsPage'
import PrivacyPage from '../../components/StaticPages/PrivacyPage'
import RefundPolicyPage from '../../components/StaticPages/RefundPolicyPage'
import TermsAndConditionsPage from '../../components/StaticPages/TermsAndConditionsPage'
import ThankYouPage from '../ContactUsPage/ThankYouPage'
import { addMissingUtmTags } from '../..'
import HomeDeliveryFaqsPage from '../../components/StaticPages/HomeDeliveryFaqsPage'
import HomeDeliveryEligibilityPage from '../../components/StaticPages/HomeDeliveryEligibilityPage'
import DriveawayEligibilityPage from '../../components/StaticPages/DriveawayEligibilityPage'
import AnnualInsuranceConfirmationPage from '../AnnualUpsell/EmailConfirmationPage'
import CookieConsentBanner from '../../components/CookieConsentBanner'
import UpsellDurationPage from '../UpsellDurationPage'
import config from '../../config'

const BackgroundGradient = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #fff;
  background-image: linear-gradient(
    170deg,
    ${(props) => (props.isLoading ? '#FFF' : props.theme.gradient.background)}
      510px,
    #fff 510px
  );
  @media (max-width: 767px) {
    background-color: #fff;
    background-image: linear-gradient(
      170deg,
      ${(props) => (props.isLoading ? '#FFF' : props.theme.gradient.background)}
        285px,
      #fff 0
    );
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    background-color: #fff;
    background-image: linear-gradient(
      170deg,
      ${(props) => (props.isLoading ? '#FFF' : props.theme.gradient.background)}
        385px,
      #fff 0
    );
  }
`

class App extends Component {
  constructor(props) {
    super(props)
    let parsed = queryString.parse(this.props.location.search)
    const temp = parsed
    parsed = addMissingUtmTags(parsed)

    if (temp !== parsed) {
      this.props.history.replace({
        ...this.props.location,
        search: queryString.stringify(parsed),
      })
    }
  }

  componentDidMount() {
    this.props.actions.getAppStartup()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.pathname) {
      window.scrollTo(0, 0)
    }
  }
  render() {
    return (
      <ThemeProvider
        theme={themeService.getTheme(this.props.appData.Affiliate.AffiliateRef)}
      >
        <AppWrapper>
          {/* <BackgroundGradient isLoading={this.props.isLoading}> */}
          <BackgroundGradient isLoading={false}>
            <Header AppData={this.props.appData} />
            <BodyWrapper>
              <GridContainer>
                <Grid
                  desktop-prefix='20'
                  desktop='60'
                  desktop-suffix='20'
                  tablet='100'
                  parent
                >
                  <Switch>
                    <Route
                      path={ROUTES.CONTACT_US_THANK_YOU}
                      component={ThankYouPage}
                    />
                    <Route path={ROUTES.CONTACT_US} component={ContactUsPage} />
                    <Route
                      path={ROUTES.ELIGIBILITY}
                      render={() => {
                        if (
                          this.props.schemes &&
                          this.props.schemes.some((s) =>
                            s.Scheme.includes('HomeDelivery'),
                          )
                        ) {
                          return (
                            <HomeDeliveryEligibilityPage
                              appData={this.props.appData}
                            />
                          )
                        } else {
                          return (
                            <DriveawayEligibilityPage
                              appData={this.props.appData}
                            />
                          )
                        }
                      }}
                    />
                    <Route
                      path={ROUTES.FAQ}
                      render={() => {
                        if (
                          this.props.schemes &&
                          this.props.schemes.some((s) =>
                            s.Scheme.includes('HomeDelivery'),
                          )
                        ) {
                          return (
                            <HomeDeliveryFaqsPage
                              appData={this.props.appData}
                              schemes={this.props.schemes}
                            />
                          )
                        } else {
                          return (
                            <DriveawayFaqsPage
                              appData={this.props.appData}
                              schemes={this.props.schemes}
                            />
                          )
                        }
                      }}
                    />
                    <Route path={ROUTES.CLAIMS} component={ClaimsPage} />
                    <Route
                      path={ROUTES.PRIVACY_AND_COOKIES}
                      component={PrivacyPage}
                    />
                    <Route
                      path={ROUTES.REFUND_POLICY}
                      component={RefundPolicyPage}
                    />
                    <Route
                      path={ROUTES.TERMS_AND_CONDITIONS}
                      component={TermsAndConditionsPage}
                    />
                    <Route path={ROUTES.HOLDING_PAGE} component={HoldingPage} />
                    <Route path={ROUTES.ERROR} component={ErrorPage} />
                    <Route
                      path={ROUTES.POLICY_CONFIRMATION}
                      component={PolicyConfirmationPage}
                    />
                    <Route
                      path={ROUTES.INVALID_REG}
                      component={InvalidRegPage}
                    />
                    <Route
                      path={ROUTES.QUOTE_BASE}
                      component={RiskDataCollectionPage}
                    />
                    <Route
                      path={ROUTES.ANNUAL_INSURANCE_EMAIL_CONFIRMATION}
                      component={AnnualInsuranceConfirmationPage}
                    />
                    <Route
                      path={ROUTES.DURATION_PAGE}
                      component={UpsellDurationPage}
                    />
                    <Route exact path='/' component={HomePage} />
                    <Route component={PageNotFound} />
                    {/* Future roots into app, Admin, MyAccount...... */}
                  </Switch>
                </Grid>
              </GridContainer>
            </BodyWrapper>
            {!(
              this.props.location.pathname.includes(ROUTES.POLICY_BASE) ||
              this.props.location.pathname.includes(ROUTES.QUOTE_BASE) ||
              this.props.location.pathname
                .toLowerCase()
                .includes(
                  ROUTES.ANNUAL_INSURANCE_EMAIL_CONFIRMATION.toLowerCase(),
                )
            ) && <Footer AppData={this.props.appData} />}
            <CookieConsentBanner />
            {/* <CookieConsent /> */}
          </BackgroundGradient>
        </AppWrapper>
      </ThemeProvider>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    theme: state.theme,
    location: ownProps.location,
    step: state.step,
    router: state.router,
    showLiveChat: state.showLiveChat,
    isLoading: state.isLoading,
    appData: state.appData,
    schemes: state.scheme.open,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getAppStartup,
      },
      dispatch,
    ),
  }
}

App.propTypes = {
  theme: PropTypes.shape({
    logo: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
