import styled from 'styled-components'

export const LegalDeclarationPageWrapper = styled.div`
  ul {
    padding: 0;
  }
  li {
    padding: 3px 0;
    list-style: none;
    border-top: 1px solid ${(props) => props.theme.primaryColour};
    font-family: ${(props) => props.theme.fontFamily};
    font-style: ${(props) => props.theme.fontStyle};
    font-weight: ${(props) => props.theme.fontWeight};
    font-size: ${(props) => props.theme.generalFontSize};
  }
  #DisallowedOccupationsLink {
    color: ${(props) => props.theme.primaryColour};
    display: inline;
    cursor: pointer;
    text-decoration: underline;
  }
`
