import React from 'react'
import UpsellDuration from '../../components/UpsellDuration'
import { getSubDomain } from '../../services/handlers'
import queryString from 'query-string'

const UpsellDurationPage = () => {
  const subDomain = getSubDomain()
  const vehicleReg = queryString.parse(window.location.search).registration

  if (!vehicleReg) {
    window.location.href = '/'
  }

  return <UpsellDuration vehicleReg={vehicleReg} subdomain={subDomain} />
}

export default UpsellDurationPage
