import React from 'react'
import Switch from 'react-switch'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'

const Toggle = ({
  id,
  checked,
  onChange,
  checkedIcon,
  uncheckedIcon,
  theme,
  extraText,
}) => {
  const toggleTheme = {
    activeColour: theme.toggleActiveColour,
    disabledColour: '#616161',
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Switch
        id={id}
        checked={checked}
        onColor={toggleTheme.activeColour}
        offColor={toggleTheme.disabledColour}
        onChange={onChange}
        checkedIcon={checkedIcon}
        uncheckedIcon={uncheckedIcon}
        aria-label='AdditionalDriverRequiredToggle'
      />
      {extraText && <span style={{ marginLeft: 8 }}>{extraText}</span>}
    </div>
  )
}
export default withTheme(Toggle)

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
