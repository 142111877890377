import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid, GridContainer } from 'unsemantic'
import Breadcrumb from '../../components/Breadcrumb'
import {
  initPolicyConfirmationPage,
  requestPolicy,
  updateAnalysisData,
  updatePolicy,
  updatePolicyContactDetails,
  updateReasonForTempcover,
  updateValidation,
} from './actions'

import { updateStep } from '../App/actions'

import GapActivation from '../../components/GapActivation/index.js'
import LoadingScreen from '../../components/LoadingScreen'
import { H3 } from '../../components/common/H3'
import config from '../../config'
import AnnualUpsell from '../AnnualUpsell'
import { SummaryBorderMobile } from '../QuoteSummary/SummarySectionStyles'
import DocumentHeader from './Sections/DocumentHeader'
import PolicyEndSection from './Sections/PolicyEndSection'
import PolicyRefSection from './Sections/PolicyRefSection'
import PolicyStartSection from './Sections/PolicyStartSection'
import SummaryFooter from './Sections/SummaryFooter'
import SummaryHeader from './Sections/SummaryHeader'
import VehicleDescriptionSection from './Sections/VehicleDescriptionSection'
import VehicleRegSection from './Sections/VehicleRegSection'
import {
  A,
  DocumentPostText,
  DocumentsWrapper,
  I,
  IconWrapper,
  MainHeading,
} from './styles.js'

/* eslint-disable react/prefer-stateless-function */
export class PolicyConfirmationPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelect = this.handleSelect.bind(this)
    this.updateReasonForTempcover = this.updateReasonForTempcover.bind(this)

    this.state = {
      selectedOption: null,
    }
  }

  componentDidMount = () => {
    if (!this.props.appData.IsLoading) {
      this.props.actions.initPolicyConfirmationPage()
      this.props.actions.updateStep(3)
    }
  }

  handleSelect = (event) => {
    var index = event.nativeEvent.target.selectedIndex
    this.setState({
      selectedOption: {
        value: event.target.value,
        description: event.nativeEvent.target[index].text,
      },
    })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.appData.IsLoading && !this.props.appData.IsLoading) {
      this.props.actions.initPolicyConfirmationPage()
      this.props.actions.updateStep(3)
    }

    if (this.props.policy.reasonForCover !== prevProps.policy.reasonForCover) {
      window.dataLayer.push({
        event: 'reasonForCover',
        policyConfirmationDataLayerPushEvent: [
          {
            reasonForCover: this.props.policy.reasonForCover.description,
          },
        ],
      })
    }
  }

  updateReasonForTempcover() {
    this.props.actions.updateReasonForTempcover(this.state.selectedOption)
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <LoadingScreen message='Generating your documents' />
        ) : (
          <>
            <Breadcrumb
              step={4}
              stepCount={this.props.appData.PartnerPays ? 3 : 4}
            />
            <MainHeading>You're covered!</MainHeading>
            <IconWrapper>
              <I className='fas fa-check-circle'></I>
            </IconWrapper>
            <H3>
              Your policy documents have been sent to the email address
              provided.
            </H3>
            <GridContainer>
              <SummaryBorderMobile>
                <SummaryHeader />
                <VehicleRegSection
                  reg={this.props.vehicle.selected.RegistrationNumber}
                />
                <VehicleDescriptionSection
                  vehicle={this.props.vehicle.selected}
                />
                <PolicyStartSection
                  policyStartDate={this.props.riskData.PolicyStartDate.value}
                />
                <PolicyEndSection
                  policyEndDate={this.props.riskData.PolicyEndDate}
                />
                <PolicyRefSection policyId={this.props.policy.PolicyId} />
                {!this.props.appData.PartnerPays && (
                  <SummaryFooter
                    price={this.props.price.TotalPrice.toFixed(2)}
                  />
                )}
              </SummaryBorderMobile>
            </GridContainer>
            <br />
            <GridContainer>
              <DocumentsWrapper>
                <DocumentHeader />
                <Grid>
                  <ul className='fa-ul'>
                    {this.props.policy.PolicyDocuments.map(
                      (document, index) => (
                        <li key={`Document_${index}`}>
                          <A
                            href={
                              config.BASE_API_URL +
                              '/api/motorQuotePolicies/{encryptedPolicyId}/documents/{documentTypeId}'
                                .replace(
                                  /{[encryptedPolicyId}]*}/,
                                  this.props.riskData.QuoteId,
                                )
                                .replace(
                                  '{documentTypeId}',
                                  document.DocumentUnitTypeId,
                                )
                            }
                            target='_blank'
                          >
                            <span className='fa-li'>
                              <i className='far fa-file-pdf' />
                            </span>
                            {document.DocumentName}
                          </A>
                        </li>
                      ),
                    )}
                  </ul>
                  <DocumentPostText>
                    Need your documents posting? No problem, just email us at{' '}
                    <a
                      id='ContactUsMailTo'
                      href='mailto:contactus@tempcover.com'
                    >
                      contactus@tempcover.com
                    </a>{' '}
                    and we’ll get these out to you.{' '}
                  </DocumentPostText>
                </Grid>
              </DocumentsWrapper>
            </GridContainer>
            {this.props.policy.GapActivationIncluded && (
              <GapActivation></GapActivation>
            )}
            <br />
            {this.props.appData.AnnualInsuranceProvider != null && (
              <AnnualUpsell />
            )}
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    policy: state.policy,
    riskData: state.riskData,
    address: state.address,
    price: state.price,
    vehicle: state.vehicle,
    affiliateRef: state.affiliateRef,
    loading: state.isLoading,
    appData: state.appData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updatePolicy,
        updatePolicyContactDetails,
        updateValidation,
        requestPolicy,
        updateReasonForTempcover,
        initPolicyConfirmationPage,
        updateStep,
        updateAnalysisData,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyConfirmationPage)
