import React from 'react'
import { ErrorMessage } from '../common/ErrorMessage'
import { FormControlWrapper } from '../FormControlWrapper'
import { FormControlValidIcon } from '../FormControlValidIcon'
import { NumberInput } from '../common/Input/NumberInput'
import styled from 'styled-components'

export const GrossWeightIcon = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 45px;
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  height: 45px;
  background-color: ${(props) => props.theme.primaryColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  color: ${(props) => props.theme.buttonTextColour};
  text-align: center;
`

export class GrossWeight extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (event) => {
    this.props.onChange(event)
  }

  render() {
    return (
      <FormControlWrapper>
        <FormControlValidIcon
          error={this.props.error}
          className='fas fa-check'
        ></FormControlValidIcon>
        <NumberInput
          id='GrossWeightInput'
          placeholder='Weight in kgs'
          type='number'
          onChange={this.handleChange}
          error={this.props.error}
          name='GrossWeight'
          value={this.props.value}
        />
        <GrossWeightIcon className='kg-icon'>KGs</GrossWeightIcon>
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </FormControlWrapper>
    )
  }
}
