import {
  GET_INTRODUCER_REL,
  GET_INTRODUCER_BRANCH_REL,
  GET_INTRODUCER_BRANCHES_REL,
} from './constants'

export const getIntroducerLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === GET_INTRODUCER_REL)[0]

export const getIntroducerBranchLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === GET_INTRODUCER_BRANCH_REL)[0]

export const getIntroducerBranchesLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === GET_INTRODUCER_BRANCHES_REL)[0]

export const getDriveawayInfo = (state) => state.driveaway

export const getBranchesDrivenByStocklist = (state) =>
  state.appData.BranchesDrivenByStocklist
