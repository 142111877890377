import styled from 'styled-components'

export const PathSelectionP = styled.p`
  margin: 0.3em 0;
`

export const ProgressionStepWidth = styled.div`
  max-width: 374px;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
`
