export const GET_INTRODUCER = 'src/BranchSelect/GET_INTRODUCER'
export const GET_INTRODUCER_SUCCESS = 'src/BranchSelect/GET_INTRODUCER_SUCCESS'
export const GET_INTRODUCER_REL = 'GetIntroducer'

export const GET_INTRODUCER_BRANCHES =
  'src/BranchSelect/GET_INTRODUCER_BRANCHES'
export const GET_INTRODUCER_BRANCHES_SUCCESS =
  'src/BranchSelect/GET_INTRODUCER_BRANCHES_SUCCESS'
export const GET_INTRODUCER_BRANCHES_FAILURE =
  'src/BranchSelect/GET_INTRODUCER_BRANCHES_FAILURE'
export const GET_INTRODUCER_BRANCH_REL = 'GetIntroducerBranch'
export const GET_INTRODUCER_BRANCHES_REL = 'GetIntroducerBranches'

export const SET_INTRODUCER_BRANCH = 'src/BranchSelect/SET_INTRODUCER_BRANCH'
