import {
  UPSELL_SET_VEHICLE_ERROR_CODE,
  UPSEll_SET_VEHICLE_REGISTRATION,
  UPSEll_SET_UPSELL_ENABLED,
  UPSEll_SET_UPSELL_ENABLED_SUCCESS,
} from './constants'

export function initSetVehicleErrorCode(errorCode) {
  return {
    type: UPSELL_SET_VEHICLE_ERROR_CODE,
    errorCode,
  }
}

export function setVehicleRegistration(registration) {
  return {
    type: UPSEll_SET_VEHICLE_REGISTRATION,
    registration,
  }
}

export function setUpsellEnabled(subdomain) {
  return {
    type: UPSEll_SET_UPSELL_ENABLED,
    subdomain,
  }
}

export function setUpsellEnabledSuccess(enabled) {
  return {
    type: UPSEll_SET_UPSELL_ENABLED_SUCCESS,
    enabled,
  }
}
