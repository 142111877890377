import styled from 'styled-components'

export const I = styled.i`
  color: ${(props) => props.color};
  font-size: 30px;
`

export const IconWrapper = styled.div`
  text-align: center;
`
