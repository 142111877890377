import { fetchVehicleListenerSaga } from './containers/Vehicle/saga'
import priceSaga from './containers/App/sagas/priceSaga'
import addressSaga from './containers/Address/saga'
import { formValidationSaga as riskDataCollectionValidationSaga } from './containers/RiskDataCollectionPage/sagas/validation'
import { policyConfirmationSaga } from './containers/PolicyConfirmationPage/saga'

import { all } from 'redux-saga/effects'
import applePaySaga from './containers/ApplePay/saga'
import judoPaySaga from './containers/JudoPay/saga'
import stripeSaga from './containers/Stripe/saga'
import ocrSaga from './containers/DrivingLicenceCamera/saga'

import fetchRiskDataSaga from './containers/RiskDataCollectionPage/sagas/riskData'
import emailQuoteSaga from './containers/EmailQuoteModal/saga'
import riskDataCollectionSaga from './containers/RiskDataCollectionPage/sagas/saga'
import payPalSaga from './containers/PayPal/saga'
import fetchQuoteSaga from './containers/App/sagas/quoteSaga'
import invalidRegPageSaga from './containers/InvalidRegPage/saga'
import annualUpsellSaga from './containers/AnnualUpsell/saga'
import introducerSaga from './containers/BranchSelect/saga'
import contactUsPageSaga from './containers/ContactUsPage/saga'
import startupSaga from './containers/App/sagas/startupSaga'
import legalDeclarationSaga from './containers/LegalDeclarationPage/saga'
import driverDetailsSaga from './containers/DriverDetails/saga'
import marketingPreferencesSaga from './containers/MarketingPreferencesContainer/saga'
import confusedQuickQuoteSaga from './containers/App/sagas/confusedQuickQuoteSaga'
import upsellDurationSaga from './containers/UpsellDurationPage/saga'

//fix saga imports
export default function* rootSaga() {
  yield all([
    riskDataCollectionSaga(),
    fetchVehicleListenerSaga(),
    priceSaga(),
    riskDataCollectionValidationSaga(),
    policyConfirmationSaga(),
    addressSaga(),
    applePaySaga(),
    judoPaySaga(),
    stripeSaga(),
    ocrSaga(),
    fetchRiskDataSaga(),
    emailQuoteSaga(),
    payPalSaga(),
    fetchQuoteSaga(),
    invalidRegPageSaga(),
    annualUpsellSaga(),
    introducerSaga(),
    contactUsPageSaga(),
    startupSaga(),
    driverDetailsSaga(),
    legalDeclarationSaga(),
    marketingPreferencesSaga(),
    confusedQuickQuoteSaga(),
    upsellDurationSaga(),
  ])
}
