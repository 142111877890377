import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { Grid, GridContainer } from 'unsemantic'
import * as Yup from 'yup'
import { Message } from '../../components/Message'
import MobileTelephone from '../../components/MobileTelephone'
import { Button } from '../../components/common/Button'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import Email from '../../components/common/Email'
import { H2 } from '../../components/common/H2'
import { Input } from '../../components/common/Input'
import { Label } from '../../components/common/Label'
import SplitPolicyStartTimePicker from '../../components/common/SplitPolicyStartTimePicker'
import Toggle from '../../components/common/Toggle'
import AdditionalDriver from '../AdditionalDriver'
import { toggleAdditionalDriver } from '../AdditionalDriver/actions'
import Address from '../Address'
import { requestAddress } from '../Address/actions'
import { updateStep } from '../App/actions'
import {
  checkMarketingOptInState,
  checkMarketingOptInStateAndForward,
  setMarketingOptInState,
} from '../MarketingPreferencesContainer/actions'
import QuoteSummary from '../QuoteSummary'
import {
  clearIdentityCheck,
  formManuallyEdited,
  updateQuote,
  updateRiskData,
  updateValidation,
} from '../RiskDataCollectionPage/actions'
import { updateVehicleValue } from '../Vehicle/actions'
import { submitDriverDetailsForm } from './actions'
import {
  MarketingPurposesText,
  StyledFieldset,
  StyledLegend,
  ToggleCheckedIcon,
  ToggleLabel,
  ToggleUncheckedIcon,
  ToggleWrapper,
} from './style'

import ConfusedQuickQuoteOptIn from '../../components/ConfusedQuickQuoteOptIn/ConfusedQuickQuoteOptIn'
import HomePagePrice from '../../components/HomePagePrice'
import LicenceType from '../../components/LicenceType'
import { MarketingPreferences } from '../../components/MarketingPreferences'
import { Title } from '../../components/Title'
import { BasicDate } from '../../components/common/BasicDate'
import { H3 } from '../../components/common/H3'
import { H5 } from '../../components/common/H5'
import { ANNUAL_INSURANCE_PROVIDER_ID } from '../../constants'
import { SYSTEM_FEATURES } from '../../services/constants'
import { createSplitPolicyStartDateObject } from '../../services/split-policy-start-date'
import {
  ADDITIONAL_DRIVER_VALIDATION,
  ADDRESS_VALIDATION,
  CAN_FUTURE_DATE_SPLIT_POLICY_START_DATE_VALIDATION,
  CONFUSED_QUICK_QUOTE_VALIDATION,
  DATE_OF_BIRTH_VALIDATION,
  EMAIL_VALIDATION,
  FORENAME_VALIDATION,
  LICENCE_TYPE_VALIDATION,
  MOBILE_NUMBER_VALIDATION,
  SPLIT_POLICY_START_DATE_VALIDATION,
  SURNAME_VALIDATION,
  TITLE_VALIDATION,
} from '../../services/validation'
import BranchSelect from '../BranchSelect'
import { PRIVATE_CAR } from '../Vehicle/constants'

export const ContinueButton = styled(Button)`
  width: 100%;
`

const DriverDetails = ({
  actions,
  address,
  riskData,
  appData,
  driveaway,
  confusedQuickQuote,
  annualInsuranceProvider,
  vehicle,
  marketingPreferences: { loaded, EmailAddressOptIn, TelephoneNumberOptIn },
  validation: { AllFieldsValid, IdentityCheck },
  features,
}) => {
  const {
    Eligibility: { MinAge, MaxAge },
    CanFutureDatePolicies,
    EnableMarketingPreferences,
  } = appData
  const [updatingMarketingPreferences, setUpdatingMarketingPreferences] =
    useState(false)

  useEffect(() => {
    setUpdatingMarketingPreferences(true)
  }, [EmailAddressOptIn, TelephoneNumberOptIn])

  useEffect(() => {
    if (EnableMarketingPreferences) {
      actions.checkMarketingOptInState()
    }
  }, [EnableMarketingPreferences])

  useEffect(() => {
    actions.updateStep(1)
  }, [])

  const splitDate = (riskData.DateOfBirth || '')
    .replace(/[ :T]/g, '-')
    .split('-')
  const additionalSplitDate = (riskData.AdditionalDriver.DateOfBirth || '')
    .replace(/[ :T]/g, '-')
    .split('-')

  if (riskData.LicenceType == null || riskData.LicenceType === 'NotSet') {
    riskData.LicenceType = 'FullUkLicence'
  }

  return (
    <>
      <QuoteSummary hideDurationAndStartTime />
      <HomePagePrice AppData={{ ...appData, PartnerPays: true }} />
      <GridContainer>
        {(IdentityCheck === 'passport' ||
          IdentityCheck === 'drivingLicence' ||
          !AllFieldsValid) && (
          <Message
            heading='Sorry, we have been unable to confirm your information.'
            content='Please double check your information and your policy start time is correct then try again.'
            type='error'
          />
        )}
        <H2>Tell us about the driver</H2>
        <Formik
          initialValues={{
            PolicyStartDate: createSplitPolicyStartDateObject(
              riskData.PolicyStartDate,
              CanFutureDatePolicies,
            ),
            Title: riskData.Title,
            Forename: riskData.Forename,
            Surname: riskData.Surname,
            DateOfBirth: {
              day: splitDate[2],
              month: splitDate[1],
              year: splitDate[0],
              date: riskData.DateOfBirth,
            },
            Address: {
              Postcode: (address.Postcode || '').replace(' ', ''),
              PostcodeSearched: Boolean(riskData.AddressKey),
              AddressKey: riskData.AddressKey,
            },
            LicenceType: riskData.LicenceType,
            EmailAddress: riskData.EmailAddress,
            MobileNumber: riskData.Mobile,
            Branch: driveaway.SelectedBranch,
            AdditionalDriver: {
              ...riskData.AdditionalDriver,
              DateOfBirth: {
                day: additionalSplitDate[2],
                month: additionalSplitDate[1],
                year: additionalSplitDate[0],
                date: riskData.AdditionalDriver.DateOfBirth,
              },
            },
            EmailAddressOptIn: EmailAddressOptIn,
            TelephoneNumberOptIn: TelephoneNumberOptIn,
            ConfusedQuickQuoteOptIn: (
              confusedQuickQuote.OptIn || ''
            ).toString(),
          }}
          validationSchema={Yup.object().shape({
            PolicyStartDate: CanFutureDatePolicies
              ? CAN_FUTURE_DATE_SPLIT_POLICY_START_DATE_VALIDATION
              : SPLIT_POLICY_START_DATE_VALIDATION,
            Title: TITLE_VALIDATION,
            Forename: FORENAME_VALIDATION,
            Surname: SURNAME_VALIDATION,
            DateOfBirth: DATE_OF_BIRTH_VALIDATION(MinAge, MaxAge),
            Address: ADDRESS_VALIDATION,
            LicenceType: LICENCE_TYPE_VALIDATION,
            EmailAddress: EMAIL_VALIDATION,
            MobileNumber: MOBILE_NUMBER_VALIDATION,
            ConfusedQuickQuoteOptIn: CONFUSED_QUICK_QUOTE_VALIDATION(
              annualInsuranceProvider,
              vehicle,
              features[SYSTEM_FEATURES.UPSELL_CONFUSED_ENABLED],
            ),
            AdditionalDriver: ADDITIONAL_DRIVER_VALIDATION(MinAge, MaxAge),
          })}
          onSubmit={(values, formActions) => {
            if (!loaded && EnableMarketingPreferences) {
              actions.checkMarketingOptInStateAndForward({
                email: values.EmailAddress,
                telephoneNumber: values.MobileNumber,
              })
              formActions.setSubmitting(false)
            } else {
              actions.submitDriverDetailsForm(values)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
            touched,
            values,
          }) => {
            if (updatingMarketingPreferences) {
              if (EmailAddressOptIn !== values.EmailAddressOptIn) {
                setFieldValue('EmailAddressOptIn', EmailAddressOptIn)
              }

              if (TelephoneNumberOptIn !== values.TelephoneNumberOptIn) {
                setFieldValue('TelephoneNumberOptIn', TelephoneNumberOptIn)
              }

              setUpdatingMarketingPreferences(false)
            }
            let errorCount = Object.keys(errors || {}).length
            if (
              errorCount >= 2 &&
              errors.DateOfBirth &&
              errors.LicenceHeldDuration
            ) {
              errorCount -= 1
            }
            return (
              <Form>
                <Grid desktop='100' parent>
                  <StyledLegend htmlFor='PolicyStartDateFieldSet'>
                    When do you need the cover to start?
                  </StyledLegend>
                  <StyledFieldset id='PolicyStartDateFieldSet'>
                    <SplitPolicyStartTimePicker
                      id='PolicyStartDate'
                      name='PolicyStartDate'
                      value={values.PolicyStartDate}
                      errors={errors.PolicyStartDate || {}}
                      touched={touched.PolicyStartDate || {}}
                      canFutureDate={CanFutureDatePolicies}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </StyledFieldset>
                </Grid>
                {appData.BranchesDrivenByStocklist && (
                  <Grid desktop='100' parent>
                    <section id='BranchSection'>
                      <BranchSelect
                        driverDetailsPage={true}
                        filterId='DriverDetails_BranchFilter'
                        name='BranchSelection'
                        dropdownId='DriverDetails_BranchDropdown'
                        value={values.Branch}
                        tabIndex={2}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        placeholder={'Please select a branch'}
                        touched={touched}
                        error={Boolean(touched.Branch && errors.Branch)}
                        errorMessage={errors.Branch}
                      ></BranchSelect>
                    </section>
                  </Grid>
                )}
                <Grid desktop='100' parent>
                  <Label htmlFor='LicenceType'>Licence type</Label>
                  <LicenceType
                    id='LicenceType'
                    name='LicenceType'
                    onChange={handleChange}
                    value={values.LicenceType}
                    error={Boolean(touched.LicenceType && errors.LicenceType)}
                    errorMessage={touched.LicenceType && errors.LicenceType}
                  />
                </Grid>
                <Grid desktop='100' parent>
                  <Label htmlFor='Title'>Title</Label>
                  <Title
                    id='Title'
                    name='Title'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.Title && errors.Title)}
                    errorMessage={touched.Title && errors.Title}
                    value={values.Title}
                  />
                </Grid>
                <Grid desktop='100' parent>
                  <Label htmlFor='Forename'>First name</Label>
                  <Input
                    id='Forename'
                    name='Forename'
                    placeholder='Hi, my name is...'
                    titleCase={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    error={Boolean(touched.Forename && errors.Forename)}
                    errorMessage={touched.Forename && errors.Forename}
                    value={values.Forename}
                  />
                </Grid>
                <Grid desktop='100' parent>
                  <Label htmlFor='Surname'>Surname</Label>
                  <Input
                    id='Surname'
                    name='Surname'
                    placeholder='and my surname is...'
                    titleCase={true}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    error={Boolean(touched.Surname && errors.Surname)}
                    errorMessage={touched.Surname && errors.Surname}
                    value={values.Surname}
                  />
                </Grid>
                <Grid desktop='100' parent>
                  <Label htmlFor='DateOfBirth'>Date of birth</Label>
                  <BasicDate
                    id='DateOfBirth'
                    name='DateOfBirth'
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                    value={values.DateOfBirth}
                    touched={touched.DateOfBirth}
                    error={errors.DateOfBirth}
                  />
                </Grid>
                <Address
                  onBlur={handleBlur}
                  onChange={handleChange}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  values={values.Address}
                  touched={touched.Address || {}}
                  errors={errors.Address || {}}
                  disallowSpaces={true}
                />
                <Grid desktop='100' parent>
                  <Grid desktop='80' tablet='80' mobile='100' parent>
                    <ToggleLabel htmlFor='AdditionalDriver.Required'>
                      Do you wish to add an additional driver? (This must be
                      your Spouse or Common Law Partner and you must both live
                      at the same address)
                    </ToggleLabel>
                  </Grid>
                  <ToggleWrapper>
                    <Grid desktop='20' tablet='20' mobile='100' parent>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Toggle
                          id='AdditionalDriverRequired'
                          name='AdditionalDriver.Required'
                          checked={values.AdditionalDriver.Required}
                          onChange={(e) => {
                            setFieldValue('AdditionalDriver.Required', e)
                          }}
                          checkedIcon={
                            <ToggleCheckedIcon aria-label='AdditionalDriverRequired'>
                              Yes
                            </ToggleCheckedIcon>
                          }
                          uncheckedIcon={
                            <ToggleUncheckedIcon aria-label='AdditionalDriverNotRequired'>
                              No
                            </ToggleUncheckedIcon>
                          }
                        />
                      </div>
                    </Grid>
                  </ToggleWrapper>
                </Grid>
                {values.AdditionalDriver.Required && (
                  <>
                    <H2>Tell us about the additional driver</H2>
                    <AdditionalDriver
                      values={values.AdditionalDriver}
                      errors={errors.AdditionalDriver || {}}
                      touched={touched.AdditionalDriver || {}}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}

                {annualInsuranceProvider ===
                  ANNUAL_INSURANCE_PROVIDER_ID.ConfusedRTP &&
                  features[SYSTEM_FEATURES.UPSELL_CONFUSED_ENABLED] &&
                  vehicle.selected.VehicleType === PRIVATE_CAR && (
                    <ConfusedQuickQuoteOptIn
                      id='ConfusedQuickQuoteOptIn'
                      name='ConfusedQuickQuoteOptIn'
                      value={values.ConfusedQuickQuoteOptIn}
                      errors={Boolean(
                        touched.ConfusedQuickQuoteOptIn &&
                          errors.ConfusedQuickQuoteOptIn,
                      )}
                      errorMessage={
                        touched.ConfusedQuickQuoteOptIn &&
                        errors.ConfusedQuickQuoteOptIn
                      }
                      onChange={handleChange}
                    />
                  )}
                <>
                  <H2>Tell us where to send your documents</H2>
                  <Grid desktop='100' parent>
                    <Label htmlFor='EmailPolicyDocuments'>Email</Label>
                    <Email
                      id='EmailPolicyDocuments'
                      name='EmailAddress'
                      placeholder='Send my policy documents to...'
                      onSuggestClick={(s) => setFieldValue('EmailAddress', s)}
                      value={values.EmailAddress}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.EmailAddress && errors.EmailAddress,
                      )}
                      errorMessage={touched.EmailAddress && errors.EmailAddress}
                    />
                  </Grid>

                  <Grid desktop='100' parent>
                    <Label htmlFor='MobileNumber'>Mobile number</Label>
                    <MobileTelephone
                      id='MobileNumber'
                      name='MobileNumber'
                      placeholder='07...'
                      value={values.MobileNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.MobileNumber && errors.MobileNumber,
                      )}
                      errorMessage={touched.MobileNumber && errors.MobileNumber}
                    />
                  </Grid>
                </>
                {loaded && (
                  <>
                    {EnableMarketingPreferences && (
                      <>
                        <H2 id='keepingInTouch_hdr'>Keeping in touch</H2>
                        <H5 id='keepingInTouch_desc'>
                          You will shortly receive a summary of your quote by
                          email.
                        </H5>
                        <>
                          <H3 style={{ color: '#264694' }}>
                            We'd also like to keep you up to date with
                            Tempcover's latest customer discounts, products and
                            promotions. Here are your current marketing
                            preferences with Tempcover. If you'd like to change
                            them, simply update below
                          </H3>
                          <MarketingPreferences
                            marketingType='EmailAddress'
                            label='Send via email'
                            optIn={values.EmailAddressOptIn}
                            selected={(e) => {
                              setFieldValue('EmailAddressOptIn', e)
                            }}
                          />
                          <MarketingPreferences
                            marketingType='TelephoneNumber'
                            label='Send via text message'
                            optIn={values.TelephoneNumberOptIn}
                            selected={(e) => {
                              setFieldValue('TelephoneNumberOptIn', e)
                            }}
                          />
                          <MarketingPurposesText>
                            Please note, we will never pass your details to
                            third parties for marketing purposes without your
                            permission
                          </MarketingPurposesText>
                        </>
                      </>
                    )}
                  </>
                )}

                <ContinueButton
                  type='submit'
                  disabled={isSubmitting}
                  id='TeslaSubmitManualDetailsNextButton'
                  onMouseDown={(e) => {
                    e.preventDefault()
                  }}
                >
                  {isSubmitting ? 'Please wait' : 'Continue'}
                  <ButtonSpinner disabled={isSubmitting} />
                </ContinueButton>
              </Form>
            )
          }}
        </Formik>
      </GridContainer>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    validation: state.validation.riskDataCollectionPage,
    riskData: state.riskData,
    address: state.address,
    vehicle: state.vehicle,
    location: state.router.location,
    driverDetailsButtonDisabled: state.formButtons.driverDetails.disabled,
    appData: state.appData,
    driveaway: state.driveaway,
    confusedQuickQuote: state.confusedQuickQuote,
    marketingPreferences: state.marketingPreferences,
    features: state.features,
    annualInsuranceProvider: state.appData.AnnualInsuranceProvider,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateRiskData,
        updateValidation,
        updateQuote,
        requestAddress,
        formManuallyEdited,
        clearIdentityCheck,
        updateStep,
        updateVehicleValue,
        toggleAdditionalDriver,
        setMarketingOptInState,
        submitDriverDetailsForm,
        checkMarketingOptInState,
        checkMarketingOptInStateAndForward,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverDetails)
