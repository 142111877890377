import React from 'react'
import { REQUEST_CALLBACK_STATUS } from './constants'
import { RequestCallbackButton } from './styles'
import { HTTP_CODES } from '../../constants'

const Content = ({
  callbackRequest,
  annualInsuranceProvider,
  requestCallback,
}) => {
  switch (callbackRequest.status) {
    case REQUEST_CALLBACK_STATUS.WAITING:
      return (
        <RequestCallbackButton onClick={() => requestCallback('Website')}>
          Request a call back
        </RequestCallbackButton>
      )
    case REQUEST_CALLBACK_STATUS.SUCCESS:
      return (
        <>
          <hr />
          <p>
            Thank you for requesting a call back to discuss your annual
            insurance needs. Your request has been submitted to the team at{' '}
            {annualInsuranceProvider} and you will receive a call shortly.
          </p>
          <hr />
        </>
      )
    case REQUEST_CALLBACK_STATUS.ERROR:
      switch (callbackRequest.httpCode) {
        case HTTP_CODES.ERROR.TooManyRequests:
          return (
            <>
              <hr />
              <p>
                We’re sorry, there is a limit to the number of call back
                requests you’re able to make in one day. If you do not receive
                your call back soon, please request another call back tomorrow.
              </p>
              <hr />
            </>
          )
        default:
          return (
            <>
              <hr />
              <p>
                We’re sorry, but something went wrong and we’re unable to submit
                your request right now. Please try again later.
              </p>
              <RequestCallbackButton onClick={() => requestCallback('Website')}>
                Request a call back
              </RequestCallbackButton>
              <hr />
            </>
          )
      }

    default:
      return (
        <RequestCallbackButton onClick={() => requestCallback('Website')}>
          Request a call back
        </RequestCallbackButton>
      )
  }
}

export default Content
