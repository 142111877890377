import { call, put } from 'redux-saga/effects'
import antiForgeryApi from '../../../api/antiForgeryApi'
import { store } from '../../../api/store'
import {
  requestAntiForgeryFailure,
  requestAntiForgery,
  requestAntiForgerySuccess,
} from '../actions'

export default function* fetchAntiForgeryTokenSaga() {
  try {
    yield put(requestAntiForgery())
    const response = yield call(antiForgeryApi.getAntiForgeryToken)
    const antiForgeryToken = response.headers['x-requestverificationtoken']
    store['antiForgeryToken'] = antiForgeryToken
    yield put(requestAntiForgerySuccess())
  } catch (e) {
    yield put(requestAntiForgeryFailure(e))
  }
}
