import React from 'react'
import { Grid } from 'unsemantic'
import { Title } from '../../components/Title'
import { Label } from '../../components/common/Label'
import LicenceType from '../../components/LicenceType'
import { BasicDate } from '../../components/common/BasicDate'
import { Input } from '../../components/common/Input'

const AdditionalDriver = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
}) => {
  return (
    <>
      <Grid desktop='100' parent>
        <Label>Licence type</Label>
        <LicenceType
          id='AdditionalDriverLicenceType'
          name='AdditionalDriver.LicenceType'
          onChange={handleChange}
          value={values.LicenceType}
          error={Boolean(touched.LicenceType && errors.LicenceType)}
          errorMessage={touched.LicenceType && errors.LicenceType}
        />
      </Grid>
      <Grid desktop='100' parent>
        <Label>Title</Label>
        <Title
          id='AdditionalDriverTitle'
          name='AdditionalDriver.Title'
          onBlur={handleBlur}
          onChange={handleChange}
          error={Boolean(touched.Title && errors.Title)}
          errorMessage={touched.Title && errors.Title}
          value={values.Title}
        />
      </Grid>
      <Grid desktop='100' parent>
        <Label>First name</Label>
        <Input
          id='AdditionalDriverForename'
          name='AdditionalDriver.Forename'
          placeholder='Their first name is...'
          titleCase={true}
          onBlur={handleBlur}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          error={Boolean(touched.Forename && errors.Forename)}
          errorMessage={touched.Forename && errors.Forename}
          value={values.Forename}
        />
      </Grid>
      <Grid desktop='100' parent>
        <Label>Surname</Label>
        <Input
          id='AdditionalDriverSurname'
          name='AdditionalDriver.Surname'
          placeholder='their surname is...'
          titleCase={true}
          onBlur={handleBlur}
          onChange={handleChange}
          setFieldValue={setFieldValue}
          error={Boolean(touched.Surname && errors.Surname)}
          errorMessage={touched.Surname && errors.Surname}
          value={values.Surname}
        />
      </Grid>
      <Grid desktop='100' parent>
        <Label>Date of birth</Label>
        <BasicDate
          id='AdditionalDriverDateOfBirth'
          name='AdditionalDriver.DateOfBirth'
          onBlur={handleBlur}
          onChange={setFieldValue}
          value={values.DateOfBirth}
          touched={touched.DateOfBirth}
          error={errors.DateOfBirth}
        />
      </Grid>
    </>
  )
}

export default AdditionalDriver
