import http from '../api/http'

export default class trustPilotApi {
  static getTrustPilotScore() {
    return http.get(`/api/trustpilot/score`, {
      headers: {
        Accept: 'application/tempcover.TrustPilotScoreRepresentation+json',
      },
    })
  }
}
