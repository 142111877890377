import React from 'react'
import PropTypes from 'prop-types'
import { RegInput, SmallGBIcon } from './styles.js'

const VehicleReg = ({ reg }) => {
  return (
    <div>
      <RegInput>
        <SmallGBIcon>GB</SmallGBIcon>
        {reg}
      </RegInput>
    </div>
  )
}

VehicleReg.propTypes = {
  reg: PropTypes.string,
}

export default VehicleReg
