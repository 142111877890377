/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'

import { H1, H2, H3, P, UL_C, LI, A } from './styles'

const PrivacyPage = () => {
  return (
    <Grid desktop='100' mobile='100' tablet='100'>
      <H1>Privacy &amp; cookies</H1>
      <H2>Privacy Policy</H2>
      <P>
        Tempcover Limited, which trades under the name of "Tempcover.com",
        (“Tempcover,” “we”, “us” or "our") wants to protect your privacy.
      </P>
      <H2>Scope</H2>
      <P>
        This privacy policy tells you about the kinds of data we obtain about
        you, how we use it, and why, so please make sure you read it. When we
        talk about our “services”, we mean all of the websites, apps, products,
        and services we provide through our brand(s) and by other means.
      </P>
      <P>
        We may provide further disclosures for some services, and these should
        be read in addition to this policy.
      </P>
      <P>
        Our services may contain links to external sites. We do not control
        these sites and encourage you to read their privacy policies.
      </P>
      <H2>About us</H2>
      <P>
        We are Tempcover Limited and we are the controller of the data that you
        share or that we collect about you. This means we oversee how your data
        is processed.
      </P>
      <P>
        Tempcover Limited is part of RVU Limited, a division of{' '}
        <a href='https://www.rvu.co.uk/rvu-group-companies' target='_blank'>
          ZPG Limited
        </a>
        . Any reference to the ZPG within this policy includes all or any of
        Tempcover Limited’s direct or indirect parent or subsidiary
        undertakings.
      </P>
      <P>
        You can contact our Group Data Protection Officer at{' '}
        <a href='mailto:dpo@tempcover.com'>dpo@tempcover.com</a>
        or via our postal address below. Please mark your letter: 'Tempcover.com
        Data Protection Officer'.
      </P>
      <P>
        From time to time, we change this policy. We will post any changes to
        this page, so please check it often.
      </P>
      <H2>What personal data do we collect?</H2>
      <P>
        When you use our services, we collect data. You give us data when using
        our services (such as your name or email address) and some data we
        collect automatically.
      </P>
      <P>
        We collect only the data necessary for specific purposes that the law
        allows.
      </P>
      <H3>Data you provide to us.</H3>
      <P>
        To use our services, you must share some of your data with us. For
        example, you may provide data to enter into a contract or competition,
        to use our services, purchase a product, to make a complaint, or ask for
        help. Typically, you might share things like:
      </P>
      <UL_C>
        <LI>Name or username</LI>
        <LI>Date of Birth</LI>
        <LI>Gender</LI>
        <LI>Email address</LI>
        <LI>Residential address</LI>
        <LI>Billing address</LI>
        <LI>Phone number</LI>
        <LI>Payment details</LI>
        <LI>Car make and model</LI>
        <LI>Driving licence</LI>
      </UL_C>
      <P>
        Where you provide us data you do so on your own behalf, or where
        providing us with someone else's data, that you have their consent to do
        so
      </P>
      <P>
        We may need certain sensitive data to provide some services (like health
        data). When we need to use your sensitive data, we may need to ask for
        your consent. If you do not consent to this use, you may not be able to
        use that service.
      </P>
      <P>
        You may also choose to share other data with us, such as comments,
        interests, likes and dislikes, and survey answers.
      </P>
      <H3>Data we collect about you.</H3>
      <P>
        When you use our services, we collect some data automatically. The data
        we collect will depend on the service you use, but it includes things
        like:
      </P>
      <UL_C>
        <LI>Cookies and other online IDs</LI>
        <LI>Website visit length, clicks, page views, and other actions</LI>
        <LI>ISP and IP address</LI>
        <LI>Device and browser data</LI>
        <LI>Emails from us you have opened and / or clicked</LI>
      </UL_C>
      <H3>Data we get from others.</H3>
      <P>If you use other ZPG services, we may get data about that use.</P>
      <P>
        We work with third parties, who help us provide and market the services
        we offer. Some of these provide us with the data we need to operate our
        services (for example, we may obtain data about your credit status or
        likely interests).
      </P>
      <P>If you use other ZPG services, we may get data about that use.</P>
      <P>
        Where our services allow you to look up data (for example, using house
        number and postcode to get property information), you may only use the
        service if all parties whose data you are searching agree. If you do not
        have this consent then you must not use the service.
      </P>
      <H3>Data we create.</H3>
      <P>
        We may combine the data we have to create new data. The data we create
        about you will depend on your use of our services, but it may include:
      </P>
      <UL_C>
        <LI>Approximate location</LI>
        <LI>Inferences about your interests and product preferences</LI>
        <LI>Interest cohorts</LI>
        <LI>Linked devices</LI>
      </UL_C>
      <P>
        <b>Please note:</b> We do not use the data we collect to infer sensitive
        data about you.
      </P>
      <H2>How do we use your data?</H2>
      <P>
        We use data for purposes connected with serving you and operating our
        business.
      </P>
      <P>
        Depending on your use of our services and the choices you make, we will
        use your data for the following.
      </P>
      <H3>Providing our services and carrying out your requests.</H3>
      <P>When providing our Services we may use your data (as applicable):</P>
      <UL_C>
        <LI>To check your identity and eligibility for our services.</LI>
        <LI>To register and secure your account.</LI>
        <LI>To help you find our best pricing and products.</LI>
        <LI>To direct you to the websites of our partners.</LI>
        <LI>To process transactions between you and us, or our partners.</LI>
        <LI>To track sales and get paid by our partners.</LI>
        <LI>
          To build profiles of your use of ZPG services, and personalise your
          service.
        </LI>
        <LI>To keep your data updated.</LI>
        <LI>
          To ask for the cancellation of your products with third parties (where
          possible), when you have asked us to.
        </LI>
        <LI>To provide customer service and support.</LI>
        <LI>To enable you to use social sharing tools.</LI>
        <LI>To complete data rights requests you have made.</LI>
      </UL_C>
      <H2>Improving and promoting the services of us and others.</H2>
      <P>
        To help improve and promote our services, and the services of our
        partners, we may use your data:
      </P>
      <UL_C>
        <LI>To carry out analysis and market research.</LI>
        <LI>
          To help present our services clearly and in the most effective way.
        </LI>
        <LI>To run competitions, incentives, and surveys.</LI>
        <LI>To help us and our partners create better products for you.</LI>
        <LI>To process any rewards we may offer to you.</LI>
        <LI>
          To send updates and service messages about your products and services.
        </LI>
        <LI>
          To let you know about our services and promotions by email, social
          media (e.g., Facebook), SMS, and using push notifications (where you
          agree).
        </LI>
        <LI>To let you know about other RVU and wider ZPG services.</LI>
        <LI>
          To build, train, and augment models which help us understand your
          likely interests and those of others.
        </LI>
        <LI>
          To target advertising to you, on this and other websites, based on
          data we have collected or created.
        </LI>
        <LI>
          To help us understand if and how our and others marketing works.
        </LI>
      </UL_C>
      <P>
        <b>Please note:</b> We do not use profiling in a way that could have
        legal or similar effects (like deciding whether and how much credit you
        might get).
      </P>
      <H3>Operating our business.</H3>
      <P>In the course of running our business we may use your data:</P>
      <UL_C>
        <LI>
          To do the things that are necessary for running our services and
          business.
        </LI>
        <LI>To help secure our business and prevent fraud.</LI>
        <LI>To create reports for internal or external purposes.</LI>
        <LI>
          To comply with the legal and regulatory obligations that apply to us.
        </LI>
        <LI>To audit our business and processes.</LI>
        <LI>
          To protect the interests of our business, and those of our partners.
        </LI>
        <LI>To help assess any assets we may buy or sell.</LI>
        <LI>
          For other purposes not described in this privacy policy that you
          consent to (from time to time) and as otherwise permitted by law.
        </LI>
      </UL_C>
      <P>
        <b>Please note:</b> We may combine your data with the data of others
        (e.g. a count of the total number of website users per day). This data
        we create by doing this cannot be used to single you out and is not
        personal.
      </P>
      <H2>Why can we use your data?</H2>
      <P>To use your data, we must have a valid reason (a "lawful basis").</P>
      <P>
        Sometimes we ask for your consent (e.g., to use some cookies) and
        sometimes, where you would expect us to use your data, we do not need to
        ask.
      </P>
      <P>Our reason for using your data is usually one of the following:</P>
      <H2>Contract</H2>
      <P>
        We must use your data to carry out a contract you are party to or take
        steps before you enter a contract.
      </P>
      <H2>Consent</H2>
      <P>
        You have given us consent to use your data for a certain reason (you can
        revoke your consent at any time).
      </P>
      <H2>Legal obligation</H2>
      <P>
        We (or another ZPG company) must use your data to follow the laws or
        regulations to which we are subject.
      </P>
      <H2>Legitimate interests</H2>
      <P>
        We have a legitimate interest in using your data. Usually this is to
        help us run, improve, promote, or protect our services and business.
      </P>
      <P>Examples of these interests include:</P>
      <UL_C>
        <LI>Sharing data with our suppliers so they can give you quotes.</LI>
        <LI>
          Sharing data with our suppliers, so you do not need to re-enter it.
        </LI>
        <LI>
          Sharing data with suppliers who help us offer our rewards and
          benefits.
        </LI>
        <LI>
          Providing email summaries of quote results, when you use our services.
        </LI>
        <LI>Contacting you to provide renewal quotes.</LI>
        <LI>
          Contacting you to do market research about our and other services.
        </LI>
        <LI>Auditing our processes to help keep our high standards.</LI>
        <LI>Market research, statistical analysis, and product development.</LI>
        <LI>Securing our services and keeping them online.</LI>
        <LI>Fraud prevention.</LI>
      </UL_C>
      <P>
        Sometimes, we may use your data when it is in the interest of another
        company, such as another ZPG brand, or the wider public good.
      </P>
      <H2>Who might we share your data with?</H2>
      <P>
        We share your data with third parties to provide our services. For
        example, we may share data with the partners whose products you have
        selected.
      </P>
      <P>
        We also share data with third parties whose services we use to run our
        business. For example, we pay for a third party to host our databases.
        Where we use third party services to run our business, our suppliers
        cannot use the personal data we share with them for their own purposes.
      </P>
      <UL_C>
        <LI>
          <H3>ZPG Companies</H3>
          <P>
            We work with other companies in the ZPG group to store your data
            securely and efficiently. Other ZPG companies may use your data to
            provide services that you ask for, like home management, and
            recommend other services based on your interests.
          </P>
        </LI>
      </UL_C>
      <P>
        We may also share your data with third parties for following reasons:
      </P>
      <UL_C>
        <LI>
          <H3>Product suppliers: to get quotes and help you get products.</H3>
          <P>
            We may share your data with product suppliers to help them (or
            others) arrange your quotes, and to help you to get products. To
            help them work out your quote and decide if their products are right
            for you, these suppliers may use data from other sources. Examples
            of these data sources include the electoral roll, county court
            judgments, the DVLA and credit reference agencies.
          </P>
          <P>
            Where our suppliers use credit reference agencies to help us provide
            you with prices, the checks may be logged in your file but they do
            not harm your credit score. However, if you apply for a product, the
            searches they do may be recorded and could affect your credit score.
            The privacy policies of suppliers set out their use of your data,
            and you should read them before you apply for their products.
          </P>
        </LI>
        <LI>
          <H3>Product suppliers: to help stop fraud.</H3>
          <P>To help prevent and detect fraud we:</P>
          <UL_C>
            <LI>Monitor the details on your application</LI>
            <LI>
              Work with partners, suppliers, other industry companies and fraud
              prevention agencies to share and receive relevant information
            </LI>
          </UL_C>
          <P>
            You must answer all questions honestly, accurately, and to the best
            of your knowledge. Failure to do so may result in you not being
            offered a product, incurring additional fees and charges, being left
            with an invalid policy, or seeing any future insurance claim being
            affected. You may also be liable for any third party costs in the
            event of a claim.
          </P>
        </LI>
        <LI>
          <H3>Product suppliers: to help create better products for you.</H3>
          <P>
            We may share data with product suppliers to help them carry out
            market research and create better products for you.
          </P>
        </LI>
        <LI>
          <H3>Our service providers: to help us run our business.</H3>
          <P>
            We may share your data with the business partners we use to provide
            our services. This could include using your address to get data
            about your property and help you find better products. We may also
            ask our survey partners to contact you, so that we can find out how
            to make our products better.
          </P>
        </LI>
        <LI>
          <H3>
            Advertising partners: to target our adverts to you and others.
          </H3>
          <P>
            We may share some of your data to our partners who use it to help us
            target our advertising to you and others. This means we try to show
            you adverts that we think are relevant to you and we try to stop
            showing you adverts that are not.
          </P>
        </LI>
        <LI>
          <H3>Analytics suppliers: to help us improve our services.</H3>
          <P>
            We may share data about your visit with analytics suppliers who help
            us improve our services for you and our business. This data is
            usually shared in a way that does not directly identify you.
          </P>
        </LI>
      </UL_C>
      <P>We may also share your personal data with third parties:</P>
      <UL_C>
        <LI>
          <H3>If we buy or sell any business or assets</H3>
          <P>
            If we buy or sell any business or assets, we may disclose your data
            to the prospective seller or buyer of that business or those assets,
            and its advisers. If we (or most of our assets) are bought, data
            held by it about its users will be one of the transferred assets.
          </P>
        </LI>
        <LI>
          <H3>For client businesses</H3>
          <P>
            If we collect data on behalf of our clients, we will share your data
            with them and you will be informed when we are doing this.
          </P>
        </LI>
        <LI>
          <H3>To obtain advice</H3>
          <P>
            We may disclose your data to obtain advice from lawyers and other
            professionals.
          </P>
        </LI>
        <LI>
          <H3>For audience measurement</H3>
          <P>
            We may share your data with companies who help us to learn about who
            is using our services, and how they are used.
          </P>
        </LI>
        <LI>
          <H3>To comply with the law</H3>
          <P>
            We may pass your data on when required to do so by law or in
            response to a request from law enforcement or another regulatory
            authority. We may disclose your data to enforce our agreements with
            you or to protect our rights, property, or safety, or that of our
            users or others. This includes sharing data for security and fraud
            prevention purposes.
          </P>
        </LI>
        <LI>
          <H3>In emergencies</H3>
          <P>
            If we believe someone is in danger, we reserve the right to share
            data to help protect them.
          </P>
        </LI>
        <LI>
          <H3>Anonymous data</H3>
          <P>
            We create and share general data about our consumers - you cannot be
            singled out within this data.
          </P>
        </LI>
      </UL_C>
      <H2>How do we store your personal data?</H2>
      <P>We take the protection of your data very seriously.</P>
      <P>
        We follow best practices to secure your data, and we only keep it when
        it is needed for the purposes it was collected. These periods vary based
        on the type of data and the services you have used.
      </P>
      <P>
        We may store or process your data outside of the United Kingdom, where
        local privacy laws do not provide as much protection. When we do that,
        we use approved data transfer safeguards and legal frameworks (for
        example, Standard Contractual Clauses) to help ensure that your data is
        protected. By sharing your data, you agree to this transfer, storage, or
        processing.
      </P>
      <P>
        Sending data over the internet is not completely secure. We will do our
        best to protect your data but we cannot guarantee its security, and
        sharing data with us is done at your own risk.
      </P>
      <H2>How do we use cookies?</H2>
      <P>
        Cookies are small text files and are widely used when you visit
        websites.
      </P>
      <P>
        They help us identify you from other users of our websites and apps
        (including when you use other websites). They help us make your use of
        our services as good as possible (for example, saving your login
        details). We also use cookies to help target our advertising on this and
        on other websites.
      </P>
      <P>
        You can adjust the settings in your browser to stop the use of cookies
        but some of our services may not work if you do so. To learn more about
        the cookies we use and why we use them, please read our{' '}
        <a href='https://www.tempcover.com/cookies' target='_blank'>
          Cookie Policy
        </a>
        .
      </P>
      <P>
        <b>Opting out of collection of data by third party advertisers</b>
      </P>
      <P>
        Our third party advertising providers may target you on our websites and
        apps or in other places. To make those ads more relevant and help
        measure their effectiveness, they may use cookies and similar tools.
        They record your use (like the pages you have visited) to try to work
        out what interests you. They use this data in the ways described in
        their own privacy policies.
      </P>
      <H2>Your data rights.</H2>
      <P>
        You have data rights which are granted to you under data protection law
        which you can exercise at any time. We manage all rights requests raised
        to us as the law requires. This means there may be legal reasons why we
        cannot complete all requests.
      </P>
      <P>
        <b>Right to withdraw consent:</b> Where you have given us consent to use
        your data, you may withdraw this at any time. For instance, you can ask
        us to stop using your data for email marketing by using the opt-out link
        in any marketing email we send you, or use our cookie preference centre
        to reject non-essential cookies
      </P>
      <P>
        <b>Right to delete:</b> You can ask us to delete the data we have about
        you. We must comply with this request, unless using your data is
        necessary:
      </P>
      <UL_C>
        <LI>to exercise the right of freedom of expression and information;</LI>
        <LI>
          to comply with a legal, regulatory, or contractual obligation which
          requires the use or retention of your data under UK law; or
        </LI>
        <LI>to exercise or defend legal claims.</LI>
      </UL_C>
      <P>
        <b>Right to update:</b> Although we make efforts to keep it correct,
        based on the latest data available to us, you can ask us to amend data
        that is incorrect or is not complete.
      </P>
      <P>
        <b>Right to limit data use:</b> In some situations, you can ask us to
        limit the use of your data.
      </P>
      <P>
        <b>Right to portability:</b> You can ask to share your data with another
        party when we use it because you have given us consent or when your data
        is used for the performance of a contract, and sharing your data does
        not affect the rights of others.
      </P>
      <P>
        <b>Right to object:</b> You can object to our use of your data, when we
        use it based on our legitimate interests or those of a third party. If
        this happens, we will not use your data, unless we can show legitimate
        reasons and an overriding interest for its use, or for the exercise or
        defence of legal claims.
      </P>
      <P>
        <b>Right of access:</b> You may ask us to give you a copy of the data
        that we hold about you. We will give you a copy of your data if you
        request it, once we have checked your identity (ID checks will vary
        based on the data we hold about you).
      </P>
      <P>
        <b>Right to complain:</b> You can file a complaint with the ICO
        (Information Commissioner’s Office), the United Kingdom's data
        protection authority. However, we’d prefer it if you first contact us to
        try and let us try to solve your problem – you can find our contact
        details below.
      </P>
      <P>
        We have one month to complete a rights request, which can be increased
        for certain reasons where the law allows. If you want to use one of your
        data rights, please email us at{' '}
        <a href='mailto:contactus@tempcover.com'>contactus@tempcover.com</a> or
        contact us using the address below.
      </P>
      <H2>Facebook pages</H2>
      <P>
        When you visit our Facebook page, Facebook gets the data you create as
        you engage with our content. For this data, Facebook Ireland and we act
        as joint controllers under these terms.
      </P>
      <H2>Contact us.</H2>
      <P>
        If you have questions about this privacy policy or the way in which we
        collect and use your data, you can contact us using the details below:
      </P>
      <P>
        Email:{' '}
        <a href='mailto:contactus@tempcover.com'>contactus@tempcover.com</a>
      </P>
      <P>
        Address: 2nd floor, Admiral House. Harlington Way, Fleet, Hampshire,
        GU51 4BB
      </P>
      <P>
        We hope that we will be able to resolve any questions or concerns you
        have. However, you may at any time raise your issue with the UK
        Information Commissioner.
      </P>
      <H2>Changes to this policy</H2>
      <P>
        This privacy policy was updated on 28 September 2023. We reserve the
        right to update this policy and will post any changes on this page, so
        please check back frequently.
      </P>
    </Grid>
  )
}

export default PrivacyPage
