import http from './http'

export default class confusedQuickQuoteApi {
  static getConfusedQuickQuote(endpoint) {
    return http.get(endpoint, {
      validateStatus: () => true,
      headers: {
        Accept:
          'application/tempcover.ConfusedQuickQuoteRequestRepresentation+json',
      },
    })
  }

  static getConfusedQuickQuoteResponse(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept:
          'application/tempcover.ConfusedQuickQuoteResponseRepresentation+json',
      },
    })
  }
}
