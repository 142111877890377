import styled from 'styled-components'

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
  background: ${(props) => props.theme.header.background};
  z-index: 3;

  @media (max-width: 1024px) {
    padding: 0 70px 0 20px;
  }
`

export const TeslaBrand = styled.div`
  &:after {
    content: ' ';
    clear: both;
  }
`

export const HeaderLinkStyled = styled.a`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  font-family: ${(props) => props.theme.labelFontFamily};
  text-decoration: none;
  padding: 0 10px;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
export const HeaderLinkWrapper = styled.div`
  float: right;
  display: flex;
  height: 70px;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
`
