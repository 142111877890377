import styled from 'styled-components'

const I = styled.i`
  color: ${(props) => props.color};
`

export const Primary = styled(I)`
  font-size: 200px;
`

export const Large = styled(I)`
  font-size: 300px;
`
export const Medium = styled(I)`
  font-size: 150px;
`
export const Small = styled(I)`
  font-size: 75px;
`

export const Smaller = styled(I)`
  font-size: 25px;
`
