/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'

import {
  H1,
  H2,
  P,
  LI,
  Centered_H1,
  Centered_H3,
  StaticPageHeader,
  CallUsNumber,
} from './styles'

const ClaimsPage = () => {
  return (
    <Grid desktop='100' mobile='100' tablet='100'>
      <H1>Claims</H1>
      <Centered_H1>
        <StaticPageHeader>
          What to do if you need to make a claim
        </StaticPageHeader>
      </Centered_H1>
      <Centered_H3>
        <StaticPageHeader>
          Some useful tips and advice should the worst happen
        </StaticPageHeader>
      </Centered_H3>

      <P>
        In the unfortunate event of an accident or incident involving your
        vehicle, our dedicated claims handling team are here to help.
      </P>

      <Centered_H1>
        <CallUsNumber>Call us – 0333 241 3392</CallUsNumber>
      </Centered_H1>
      <H2>What happens next?</H2>
      <P>
        Our dedicated claims handling team will explain everything you need to
        know about making a claim and guide you through the process step by
        step.
      </P>
      <P>
        If the accident was not your fault, they’ll collect details of the
        claim, and if applicable, arrange repairs and a replacement vehicle so
        you’re back on the road in no time.
      </P>
      <P>
        They can even help you reclaim uninsured losses such as your excess, and
        if you are injured during the accident we will support you to claim for
        personal injury.
      </P>

      <H2>What to immediately after the accident</H2>
      <ul>
        <LI>Stop at the scene – remember it’s an offence not to stop</LI>
        <LI>Turn on your hazard lights</LI>
        <LI>
          Check that everyone in the vehicle is safe and if anyone is injured,
          call an ambulance
        </LI>
        <LI>Keep calm and swap your details with the other parties involved</LI>
        <LI>
          Remember to take lots of photos, showing the damage to each vehicle,
          the registration numbers, the position of the vehicles and any road
          markings or signage
        </LI>
        <LI>
          Don’t apologise or admit responsibility at the scene or afterwards
          when speaking with the other party
        </LI>
        <LI>Take the details of anyone who witnessed the accident too.</LI>
        <LI>
          Call us as soon as you can – especially if you need the vehicle to be
          recovered
        </LI>
      </ul>

      <Centered_H1>
        <StaticPageHeader>
          For any claims related queries, contact our <br />
          claims team on{' '}
        </StaticPageHeader>
        <CallUsNumber>0333 241 3392</CallUsNumber>
      </Centered_H1>
    </Grid>
  )
}

export default ClaimsPage
