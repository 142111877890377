import React from 'react'
import Moment from 'moment'
import { BasicDate } from '../../components/common/BasicDate'
import { Label } from '../../components/common/Label'
import { ErrorMessage } from '../common/ErrorMessage'
import { FormControlWrapper } from '../FormControlWrapper'
import { FormControlValidIcon } from '../FormControlValidIcon'

class PassportExpiryDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validMinimumDate: null,
      validMaximumDate: null,
    }

    this.handleValidDates = this.handleValidDates.bind(this)
  }

  handleValidDates = () => {
    const now = Moment()
    this.setState({
      validMinimumDate: now,
      validMaximumDate: now.clone().add(15, 'years').format('YYYY-MM-DD'),
    })
  }

  render() {
    return (
      <>
        <FormControlWrapper>
          <FormControlValidIcon
            error={this.props.error}
            className='fas fa-check'
          ></FormControlValidIcon>
          <Label htmlFor='PassportExpiryDate'>Passport Expiry Date</Label>
          <BasicDate
            id='PassportExpiryDate'
            name='PassportExpiryDate'
            onBlur={this.props.handleDateSet}
            value={this.props.value}
            touched={!!this.props.value}
            error={this.props.error}
          />
        </FormControlWrapper>
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </>
    )
  }
}

export default PassportExpiryDate
