import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Vehicle from '../Vehicle'
import { isLoading } from '../App/actions'
import { initInvalidRegPage } from './actions'
import LoadingScreen from '../../components/LoadingScreen'
import { getSubDomain, handleQueryString } from '../../services/handlers'
import { H1 } from '../../components/common/H1'
import { createUnkownVehicle, setVehicleListener } from '../Vehicle/actions'
import { setUpsellEnabled } from '../UpsellDurationPage/action'
import { history } from '../../'
import config from '../../config'

export class InvalidRegPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  componentDidMount = () => {
    this.props.actions.initInvalidRegPage()
    this.props.actions.setUpsellEnabled(getSubDomain())
  }

  handleConfirm() {
    const { Duration, Branch, StartTime } = handleQueryString()
    let url = `/quote?registration=${this.props.vehicle.searched.RegistrationNumber}&start=${StartTime}&duration=${Duration}+days&branch=${Branch}`

    if (this.props.upsellEnabled.upsellEnabled) {
      // Set url for redirection to duration selection page
      url = `/duration?registration=${this.props.vehicle.searched.RegistrationNumber}&start=Immediately&branch=${Branch}`
    }

    this.props.actions.isLoading(true)
    if (this.props.vehicle.searched.VehicleFound) {
      this.props.actions.setVehicleListener(this.props.vehicle.searched)
      history.replace(url)
    } else {
      if (this.props.vehicle.searched.Make !== null)
        url = url.concat(`&make=${this.props.vehicle.searched.Make}`)
      if (this.props.vehicle.searched.Model !== null)
        url = url.concat(`&model=${this.props.vehicle.searched.Model}`)
      if (this.props.vehicle.searched.Abi !== null)
        url = url.concat(`&variant=${this.props.vehicle.searched.Abi}`)
      if (this.props.vehicle.searched.GrossWeight !== null)
        url = url.concat(`&weight=${this.props.vehicle.searched.GrossWeight}`)
      if (this.props.vehicle.searched.YearOfManufacture !== null)
        url = url.concat(
          `&year=${this.props.vehicle.searched.YearOfManufacture}`,
        )

      this.props.actions.createUnkownVehicle(url)
    }
  }

  render() {
    return (
      <>
        {this.props.loading ? (
          <LoadingScreen />
        ) : (
          <>
            <H1>Try again</H1>
            <Vehicle handleConfirm={this.handleConfirm} />
          </>
        )}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    vehicle: state.vehicle,
    loading: state.isLoading,
    upsellEnabled: state.upsellEnabled,
  }
}
export const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        isLoading,
        initInvalidRegPage,
        createUnkownVehicle,
        setVehicleListener,
        setUpsellEnabled,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchtoProps)(InvalidRegPage)
