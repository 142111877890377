const apiUrl = process.env.REACT_APP_API_URL
const webUrl = process.env.REACT_APP_WEB_URL
const stripeKey = process.env.REACT_APP_STRIPE_KEY
const reCaptchaKey = process.env.REACT_APP_ReCAPTCHA_KEY
const mockSubDomain = process.env.REACT_APP_MOCK_SUB_DOMAIN
const introducerSplitCharacter =
  process.env.REACT_APP_INTRODUCER_SPLIT_CHARACTER
const directWordpressUrl = process.env.REACT_APP_DIRECT_WORDPRESS_URL

const config = {
  BASE_API_URL: apiUrl,
  WEB_URL: webUrl,
  STRIPE_KEY: stripeKey,
  ReCAPTCHA_KEY: reCaptchaKey,
  MOCK_SUB_DOMAIN: mockSubDomain,
  INTRODUCER_SPLIT_CHARACTER: introducerSplitCharacter,
  APPLICATION_ID: 1,
  DIRECT_WORDPRESS_URL: directWordpressUrl,
  CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_MAX_GENERATION_DURATION,
  CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_MAX_LOAD_ATTEMPTS,
  CONFUSED_QUICK_QUOTE_RETRY_DELAY:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_RETRY_DELAY,
  CONFUSED_QUICK_QUOTE_REDIRECT_URL:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_REDIRECT_URL,
  CONFUSED_QUICK_QUOTE_REDIRECT_URL_STATIC:
    process.env.REACT_APP_CONFUSED_QUICK_QUOTE_REDIRECT_URL_STATIC,
}

export default config
