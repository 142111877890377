export const ConfusedQuickQuoteOptInValues = [
  {
    description: 'Yes, I would like an annual car insurance quote',
    displayOrder: 1,
    value: 'true',
  },
  {
    description: "No, I don't need an annual car insurance quote",
    displayOrder: 2,
    value: 'false',
  },
]
