import {
  REQUEST_LINKS_SUCCESS,
  CREATE_QUOTE_SUCCESS,
  REQUEST_QUOTE_SUCCESS,
  GET_QUOTE_SUMMARY_SUCCESS,
} from '../constants'

import {
  REQUEST_VEHICLE_SUCCESS,
  GET_MAKES_SUCCESS,
  GET_MODELS_SUCCESS,
  GET_VARIANTS_SUCCESS,
} from '../../Vehicle/constants'

import { REQUEST_LEGAL_DECLARATION_SUCCESS } from '../../LegalDeclarationModal/constants'

import initialState from '../../../initialState'
import {
  UPDATE_QUOTE_SUCCESS,
  REQUEST_IDENTITY_CHECK_SUCCESS,
  REQUEST_IDENTITY_CHECK_MORE_INFO,
  UPDATE_UNDERWRITER_SUCCESS,
} from '../../RiskDataCollectionPage/constants'
import { REQUEST_POLICY_SUCCESS } from '../../PolicyConfirmationPage/constants'

let links = []
const manageLinks = (originalState, links) => {
  let rels = new Set(links.map((link) => link.Rel))
  let newState = originalState
    .filter((state) => !rels.has(state.Rel))
    .concat(links)
  return newState
}

function linksReducer(state = initialState.links, action) {
  switch (action.type) {
    case REQUEST_LINKS_SUCCESS:
      links = action.links
      return manageLinks(state, links)
    case REQUEST_VEHICLE_SUCCESS:
      links = action.vehicle.Links
      return manageLinks(state, links)
    case CREATE_QUOTE_SUCCESS:
    case REQUEST_QUOTE_SUCCESS:
    case UPDATE_QUOTE_SUCCESS:
      links = action.quote.Links
      return manageLinks(state, links)
    case REQUEST_IDENTITY_CHECK_SUCCESS:
      links = action.response.Links
      return manageLinks(state, links)
    case GET_MAKES_SUCCESS:
      links = action.links
      return manageLinks(state, links)
    case GET_MODELS_SUCCESS:
      links = action.links
      return manageLinks(state, links)
    case GET_VARIANTS_SUCCESS:
      links = action.links
      return manageLinks(state, links)
    case REQUEST_IDENTITY_CHECK_MORE_INFO:
      links = action.links
      return manageLinks(state, links)
    case REQUEST_POLICY_SUCCESS:
      links = action.policy.Links
      return manageLinks(state, links)
    case REQUEST_LEGAL_DECLARATION_SUCCESS:
      links = action.legalDeclaration.Links
      return manageLinks(state, links)
    case UPDATE_UNDERWRITER_SUCCESS:
      links = action.response.Links
      return manageLinks(state, links)
    case GET_QUOTE_SUMMARY_SUCCESS:
      links = action.quoteSummary.Links
      return manageLinks(state, links)
    default:
      return state
  }
}

export default linksReducer
