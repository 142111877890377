import React from 'react'
import {
  ButtonLabel,
  MarketingButtons,
  MarketingInput,
  MarketingLabel,
  MarketingWrapper,
} from './styles'

export const MarketingPreferences = ({ marketingType, label, optIn, selected }) => {
  return (
    <>
      <MarketingWrapper>
        <MarketingLabel className='label'>
          <span>{label}</span>
        </MarketingLabel>
        <MarketingButtons>
          <MarketingInput
            id={`${marketingType}OptIn`}
            name={`${marketingType}OptIn`}
            type='radio'
            onChange={() => selected(true)}
            value={!!optIn}
            checked={!!optIn}
          />
          <ButtonLabel htmlFor={`${marketingType}OptIn`}>Yes</ButtonLabel>
          <MarketingInput
            id={`${marketingType}OptOut`}
            name={`${marketingType}OptOut`}
            type='radio'
            onChange={() => selected(false)}
            value={optIn === false}
            checked={optIn === false}
          />
          <ButtonLabel htmlFor={`${marketingType}OptOut`}>No</ButtonLabel>
        </MarketingButtons>
      </MarketingWrapper>
    </>
  )
}
