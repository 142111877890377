import {
  UPDATE_RISK_DATA,
  UPDATE_VALIDATION_RISK_DATA_COLLECTION,
  TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION,
  FORM_MANUALLY_EDITED,
  REQUEST_IDENTITY_CHECK_FAILURE,
  UPDATE_QUOTE_FAILURE,
  UPDATE_QUOTE_SUCCESS,
  REQUEST_IDENTITY_CHECK_MORE_INFO,
  CLEAR_IDENTITY_CHECK,
} from './constants'
import initialState from '../../initialState'
import { CREATE_QUOTE_SUCCESS, GET_APP_STARTUP_SUCCESS } from '../App/constants'
import {
  REQUEST_OCR_FRONT_SUCCESS,
  REQUEST_OCR,
} from '../DrivingLicenceCamera/constants'
import { REQUEST_QUOTE_SUCCESS } from '../App/constants'
import {
  toTitleCase,
  humanize,
  handleLicenceDurations,
} from '../../services/handlers'
import {
  REQUEST_POLICY_SUCCESS,
  GET_ANALYSIS_DATA_SUCCESS,
} from '../PolicyConfirmationPage/constants'
import { REQUEST_LEGAL_DECLARATION_SUCCESS } from '../LegalDeclarationModal/constants'
import {
  TOGGLE_ADDITIONAL_DRIVER,
  UPDATE_ADDITIONAL_DRIVER,
  UPDATE_ADDITIONAL_DRIVER_VALIDATION,
} from '../AdditionalDriver/constants'
import additionalDriverReducer from '../AdditionalDriver/reducer'

function riskDataCollectionReducer(state = initialState.riskData, action) {
  switch (action.type) {
    case UPDATE_RISK_DATA:
      return {
        ...state,
        ...action.riskData,
      }
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        QuoteId: action.quote.QuoteId.Value,
      }
    case REQUEST_OCR:
      return {
        ...state,
        Forename: '',
        Surname: '',
        DateOfBirth: null,
        DrivingLicenceNumber: '',
        LicenceHeldDuration: {
          value: null,
          description: null,
        },
        LicenceType: {
          value: 'FullUkLicence',
          description: 'Full UK licence',
        },
        AddressKey: '',
      }
    case REQUEST_OCR_FRONT_SUCCESS:
      return {
        ...state,
        Forename: toTitleCase(action.response.Forename.split(' ')[0]),
        Surname: toTitleCase(action.response.Surname),
        DateOfBirth:
          action.response.DateOfBirth !== ''
            ? action.response.DateOfBirth
            : null,
        DrivingLicenceNumber:
          action.response.DrivingLicenceNumber.split(' ')[0],
        LicenceHeldDuration: action.response.LicenceHeldDuration,
        LicenceType: {
          value: 'FullUkLicence',
          description: 'Full UK licence',
        },
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        Forename: action.policy.Forename,
        Surname: action.policy.Surname,
        PolicyStartDate: {
          value: action.policy.PolicyStart,
        },
        PolicyEndDate: action.policy.PolicyEnd,
        QuoteId: action.policyId,
      }
    case REQUEST_QUOTE_SUCCESS:
      const address = action.quote.QuoteAddresses.find(
        (a) => a.QuoteAddressType === 'MainAddress',
      )
      const driver = action.quote.QuoteDrivers.find((d) => d.Proposer)
      const additionalDriver = action.quote.QuoteDrivers.find(
        (d) => !d.Proposer,
      )
      const licenceHeldDuration = handleLicenceDurations(
        driver.DateOfBirth,
      ).filter((r) => r.value === driver.LicenceHeldDuration)[0]
      return {
        ...state,
        Duration: action.quote.PolicyDuration,
        DurationType: action.quote.PolicyDurationType,
        PolicyStartDate: {
          value: action.quote.PolicyStartDateTime,
          manuallyEdited: action.quote.UserSelectedPolicyStartDate,
        },
        QuoteStatus: action.quote.QuoteStatus,
        Title: driver.Title,
        Forename: driver.Forename,
        Surname: driver.Surname,
        DateOfBirth: driver.DateOfBirth,
        AddressKey: address.AddressKey,
        LicenceType: driver.LicenceType,
        LicenceHeldDuration: {
          value: driver.LicenceHeldDuration,
          description: licenceHeldDuration && licenceHeldDuration.description,
        },
        EmailAddress: action.quote.EmailAddress,
        Mobile: action.quote.TelephoneNumber,
        AdditionalDriver: {
          ...state.AdditionalDriver,
          ...additionalDriver,
          Required: additionalDriver !== undefined,
        },
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        Title: action.response.Title,
        LicenceHeldDuration: {
          value: action.response.LicenceHeldDuration,
          description: humanize(action.response.LicenceHeldDuration),
        },
        Duration: action.response.Duration,
        DurationType: action.response.DurationType,
        DateOfBirth: action.response.DateOfBirth,
      }
    case TOGGLE_ADDITIONAL_DRIVER:
      return {
        ...state,
        AdditionalDrivers: additionalDriverReducer(
          state.AdditionalDrivers,
          action,
        ),
      }
    case UPDATE_ADDITIONAL_DRIVER:
      return {
        ...state,
        AdditionalDrivers: additionalDriverReducer(
          state.AdditionalDrivers,
          action,
        ),
      }
    default:
      return state
  }
}

export function validationReducer(
  state = initialState.validation.riskDataCollectionPage,
  action,
) {
  switch (action.type) {
    case UPDATE_VALIDATION_RISK_DATA_COLLECTION:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.fields,
        },
      }
    case UPDATE_QUOTE_SUCCESS:
      return {
        ...state,
        AllFieldsValid: true,
      }
    case UPDATE_QUOTE_FAILURE:
      return {
        ...state,
        AllFieldsValid: false,
      }
    case TOGGLE_FORM_IS_VALID_RISK_DATA_COLLECTION:
      return {
        ...state,
        ...(state.ValidForm = action.form.valid),
      }
    case REQUEST_OCR:
    case REQUEST_OCR_FRONT_SUCCESS:
      return {
        ...state,
        IdentityCheck: null,
      }
    case FORM_MANUALLY_EDITED:
      return {
        ...state,
        ...(state.ManuallyEdited = true),
      }
    case REQUEST_LEGAL_DECLARATION_SUCCESS:
      return {
        ...state,
        IdentityCheck: null,
      }
    case REQUEST_IDENTITY_CHECK_FAILURE:
      return {
        ...state,
        ...(state.ValidIdentity = false),
      }
    case REQUEST_IDENTITY_CHECK_MORE_INFO:
      return {
        ...state,
        IdentityCheck: action.check,
      }

    case CLEAR_IDENTITY_CHECK:
      return {
        ...state,
        IdentityCheck: null,
        fields: {
          ...state.fields,
          DrivingLicenceNumberError: '',
          PassportLineOneError: '',
          PassportLineTwoError: '',
          PassportExpiryDateError: '',
        },
      }
    case REQUEST_QUOTE_SUCCESS:
      const driver = action.quote.QuoteDrivers.filter((d) => d.Proposer)[0]
      const additionalDrivers = action.quote.QuoteDrivers.filter(
        (d) => !d.Proposer,
      )
      const address = action.quote.QuoteAddresses.filter(
        (a) => a.QuoteAddressType === 'MainAddress',
      )[0]
      return {
        ...state,
        fields: {
          ...state.fields,
          TitleError: driver.Title === null ? null : '',
          ForenameError: driver.Forename === null ? null : '',
          SurnameError: driver.Surname === null ? null : '',
          DateOfBirthError: driver.DateOfBirth === null ? null : '',
          PostcodeError: address.Postcode === null ? null : '',
          AddressError: address.AddressKey === null ? null : '',
          EmailAddressError: action.quote.EmailAddress === null ? null : '',
          MobileError: action.quote.TelephoneNumber === null ? null : '',
          PolicyStartDateError:
            action.quote.PolicyStartDateTime === null ? null : '',
        },
        additionalDrivers: additionalDrivers.map((additionalDriver) => {
          return {
            TitleError: driver.Title === null ? null : '',
            ForenameError: additionalDriver.Forename === null ? null : '',
            SurnameError: additionalDriver.Surname === null ? null : '',
            DateOfBirthError: additionalDriver.DateOfBirth === null ? null : '',
            LicenceTypeError: '',
          }
        }),
      }
    case TOGGLE_ADDITIONAL_DRIVER:
      return {
        ...state,
        additionalDrivers: action.additionalDriverSelected
          ? [
              {
                TitleError: null,
                ForenameError: null,
                SurnameError: null,
                DateOfBirthError: null,
                LicenceTypeError: '',
              },
            ]
          : [],
      }
    case UPDATE_ADDITIONAL_DRIVER_VALIDATION:
      return {
        ...state,
        additionalDrivers: state.additionalDrivers.map(
          // eslint-disable-next-line array-callback-return
          (additionalDriverValidation, index) => {
            if (index === action.additionalDriverNumber) {
              return {
                ...additionalDriverValidation,
                ...action.fields,
              }
            }
          },
        ),
      }
    case GET_APP_STARTUP_SUCCESS:
      return {
        ...state,
        fields: {
          ...state.fields,
          PolicyStartDateError: action.startupData.CanFutureDatePolicies
            ? null
            : '',
        },
      }
    default:
      return state
  }
}

export default riskDataCollectionReducer
