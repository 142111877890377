import {
  CREATE_QUOTE_REL,
  GET_PRICE_REL,
  GET_MOTOR_QUOTE_REL,
  GET_CREATE_POLICY_NO_PAYMENT_REL,
} from '../constants'

export const createQuoteLink = (state) =>
  state.links.filter((link) => link.Rel === CREATE_QUOTE_REL)[0]

export const getPriceLink = (state) =>
  state.links.filter((link) => link.Rel === GET_PRICE_REL)[0]

export const getVehicleInfo = (state) => state.vehicle

export const getRiskData = (state) => state.riskData

export const getQuoteLink = (state) =>
  state.links.filter((link) => link.Rel === GET_MOTOR_QUOTE_REL)[0]

export const getAppData = (state) => state.appData

export const getCreatePolicyNoPaymentLink = (state) =>
  state.links.filter((link) => link.Rel === GET_CREATE_POLICY_NO_PAYMENT_REL)[0]
