import http from '../api/http'

export default class quoteApi {
  static createQuote(endpoint, riskData) {
    return http.post(endpoint, riskData)
  }

  static updateQuote(endpoint, quoteData, partialValidation) {
    return http.put(endpoint, quoteData, {
      headers: {
        Accept: 'application/tempcover.UpdateQuoteResultRepresentation+json',
        'Content-Type': partialValidation
          ? 'application/tempcover.UpdateQuoteDriveawayRepresentation+json'
          : 'application/tempcover.FinalUpdateQuoteDriveawayRepresentation+json',
      },
    })
  }

  static updateUnderwriter(endpoint, data) {
    return http.put(endpoint, data, {
      headers: {
        Accept:
          'application/tempcover.UpdateQuotingUnderwriterAndPremiumUnitsRepresentation+json',
      },
    })
  }

  static getPrice(endpoint, quoteData) {
    return http.post(endpoint, quoteData, {
      headers: {
        Accept: 'application/tempcover.PricingRepresentation+json',
      },
    })
  }

  static getQuoteSummary(endpoint) {
    return http.get(endpoint)
  }

  static getMotorQuote(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept:
          'application/tempcover.MotorQuoteDetailsDriveawayResultRepresentation+json',
      },
    })
  }

  static unlockQuote(endpoint, data) {
    return http.put(endpoint, data, {
      headers: {
        Accept: 'application/tempcover.DecryptedQuoteRepresentation+json',
      },
    })
  }
}
