import React from 'react'
import PropTypes from 'prop-types'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Wrapper, PriceBreakdown, Diamond } from './PaymentBreakdownStyles'
import { humanize } from '../../services/handlers'
library.add(faInfoCircle)

export class PaymentBreakdownIcon extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { showBreakdown: false }
    this.showBreakdown = this.showBreakdown.bind(this)
  }

  showBreakdown = () => {
    this.setState(
      Object.assign({}, this.state, {
        showBreakdown: !this.state.showBreakdown,
      }),
    )
  }

  render() {
    return (
      <Wrapper>
        <FontAwesomeIcon icon='info-circle' onClick={this.showBreakdown} id='PriceBreakdownButton' />
        {this.state.showBreakdown && (
          <PriceBreakdown id='PriceBreakdown'>
            <ul>
              <li>
                Insurance premium:{' '}
                <span>
                  &pound;
                  {this.props.price.InsurancePremium &&
                    this.props.price.InsurancePremium.toFixed(2)}
                </span>
              </li>
              <li>
                Insurance premium tax:{' '}
                <span>
                  &pound;
                  {this.props.price.Ipt && this.props.price.Ipt.toFixed(2)}
                </span>
              </li>
              {!!this.props.price.AdminFee && this.props.price.AdminFee > 0 && (
                <li>
                  Tempcover admin fee:{' '}
                  <span>
                    &pound;
                    {this.props.price.AdminFee &&
                      this.props.price.AdminFee.toFixed(2)}
                  </span>
                </li>
              )}
              {!!this.props.price.AffiliateAdminFee &&
                this.props.price.AffiliateAdminFee > 0 && (
                  <li>
                    {this.props.affiliate.AffiliateName} admin fee:{' '}
                    <span>
                      &pound;
                      {this.props.price.AffiliateAdminFee &&
                        this.props.price.AffiliateAdminFee.toFixed(2)}
                    </span>
                  </li>
                )}
              <li>
                Insurance provider:{' '}
                <span>{humanize(this.props.price.Underwriter)}</span>
              </li>
            </ul>
            <Diamond />
          </PriceBreakdown>
        )}
      </Wrapper>
    )
  }
}

PaymentBreakdownIcon.propTypes = {
  price: PropTypes.shape({
    InsurancePremium: PropTypes.number.isRequired,
    Ipt: PropTypes.number.isRequired,
    AdminFee: PropTypes.number.isRequired,
  }).isRequired,
}

export default PaymentBreakdownIcon
