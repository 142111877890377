export const GET_MARKETING_PREFERENCES_URL = 'GetMarketingPreferences'
export const SAVE_MARKETING_PREFERENCES_URL = 'UpdateMarketingPreferences'
export const GET_AA_MARKETING_PREFERENCES_URL =
  'GetAACarsMarketingCustomerOptIn'
export const SAVE_AA_MARKETING_PREFERENCES_URL =
  'UpdateAACarsMarketingCustomerOptIn'

export const CHECK_MARKETING_PREFERENCES_OPT_IN_STATE =
  'app/MarketingPreferencesContainer/CHECK_MARKETING_PREFERENCES_OPT_IN_STATE'
export const CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD =
  'app/MarketingPreferencesContainer/CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD'
export const CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS =
  'app/MarketingPreferencesContainer/CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS'
export const CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE =
  'app/MarketingPreferencesContainer/CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE'

export const SET_MARKETING_PREFERENCES_OPT_IN_STATE =
  'app/MarketingPreferencesContainer/SET_MARKETING_PREFERENCES_OPT_IN_STATE'
export const SET_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS =
  'app/MarketingPreferencesContainer/SET_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS'
export const SET_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE =
  'app/MarketingPreferencesContainer/SET_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE'

export const SAVE_MARKETING_PREFERENCES_OPT_IN_STATE =
  'app/MarketingPreferencesContainer/SAVE_MARKETING_PREFERENCES_OPT_IN_STATE'
export const SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS =
  'app/MarketingPreferencesContainer/SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS'
export const SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE =
  'app/MarketingPreferencesContainer/SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE'

export const RESET_MARKETING_PREFERENCES_OPT_IN_STATE =
  'app/MarketingPreferencesContainer/RESET_MARKETING_PREFERENCES_OPT_IN_STATE'
