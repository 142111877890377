import initialState from '../../initialState'
import {
  SEND_3D_SECURE_DATA_SUCCESS,
  CREATE_JUDO_PAYMENT_FAILURE,
  SEND_3D_SECURE_DATA,
} from '../JudoPay/constants'
import { FRAUD_FAILURE, SET_PAYMENT_IN_PROGRESS } from './constants'
import {
  PAYPAL_PAYMENT_ERROR_SUCCESS,
  CREATE_PAYPAL_PAYMENT,
} from '../PayPal/constants'
import { RESET_PAYMENT_OPTIONS } from './constants'
import { CREATE_APPLE_PAYMENT_FAILURE } from '../ApplePay/constants'
import { CREATE_STRIPE_PAYMENT_FAILURE } from '../Stripe/constants'

function paymentPageReducer(state = initialState.payment, action) {
  switch (action.type) {
    case SEND_3D_SECURE_DATA_SUCCESS:
      return {
        ...state,
        Secure: {
          ...state.Secure,
          ...action.response,
        },
      }
    case PAYPAL_PAYMENT_ERROR_SUCCESS:
    case CREATE_JUDO_PAYMENT_FAILURE:
    case CREATE_APPLE_PAYMENT_FAILURE:
    case CREATE_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        paymentError: action.errorMessage,
        paymentInProgress: false,
      }
    case FRAUD_FAILURE:
      return {
        ...state,
        paymentInProgress: false,
      }
    case CREATE_PAYPAL_PAYMENT:
    case SET_PAYMENT_IN_PROGRESS:
      return {
        ...state,
        paymentInProgress: true,
      }
    case RESET_PAYMENT_OPTIONS:
      return {
        ...state,
        paymentInProgress: false,
      }
    case SEND_3D_SECURE_DATA:
      return {
        ...state,
        paymentInProgress: false,
      }
    default:
      return state
  }
}

export default paymentPageReducer
