import React, { useEffect, useCallback } from 'react'
import { Grid } from 'unsemantic'
import styled from 'styled-components'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  requestIdentityCheck,
  updateQuote,
  updateRiskData,
  isButtonDisabled,
  enableAllButtons,
} from './actions'
import { RiskCollectionPathSelection } from '../../components/RiskCollectionPathSelection'
import PaymentPage from '../PaymentPage'
import DriverDetails from '../DriverDetails'
import DrivingLicence from '../DrivingLicence'
import { initRiskDataCollectionPage } from '../RiskDataCollectionPage/actions'
import { sendEmailQuote } from '../EmailQuoteModal/actions'
import MoreDetails from '../MoreDetails'
import Breadcrumb from '../../components/Breadcrumb'
import LegalDeclarationPage from '../LegalDeclarationPage'
import { Secure } from '../JudoPay/Secure'
import DrivingLicenceCamera from '../DrivingLicenceCamera'
import LoadingScreen from '../../components/LoadingScreen'
import { ROUTES } from '../../services/constants'
import config from '../../config'

const MainContainer = styled.div`
  text-align: center;
`

const RiskDataCollectionPage = ({
  actions,
  history,
  quoteId,
  loading,
  step,
  appData: { IsLoading, PartnerPays },
}) => {
  useEffect(() => {
    if (!IsLoading) {
      actions.initRiskDataCollectionPage()
    }
  }, [IsLoading])

  const handleContinueClick = useCallback(() => {
    actions.isButtonDisabled({ buttonName: 'driverDetails', disabled: true })
    actions.updateQuote({
      partialValidation: true,
      getPrice: true,
      callback: () => {
        actions.enableAllButtons()
        history.push(`${ROUTES.QUOTE_DRIVING_LICENCE}?q=${quoteId}`)
      },
    })
  })

  return (
    <Grid desktop='100' parent>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <MainContainer>
            <Grid
              desktop='90'
              desktop-prefix='5'
              tablet='70'
              tablet-prefix='15'
              mobile='100'
              parent
            >
              <Breadcrumb step={step} stepCount={PartnerPays ? 3 : 4} />
              <Switch>
                <Route
                  path={ROUTES.QUOTE_DRIVING_LICENCE_SCAN}
                  component={DrivingLicenceCamera}
                />
                <Route path={ROUTES.QUOTE_MORE_INFO} component={MoreDetails} />
                <Route
                  path={ROUTES.QUOTE_DRIVER_DETAILS}
                  render={() => {
                    return <DriverDetails onContinue={handleContinueClick} />
                  }}
                />
                <Route
                  path={ROUTES.QUOTE_PAYMENT_3D_SECURE}
                  component={Secure}
                />
                <Route path={ROUTES.QUOTE_PAYMENT} component={PaymentPage} />
                <Route
                  path={ROUTES.QUOTE_DRIVING_LICENCE}
                  component={DrivingLicence}
                />
                <Route
                  path={ROUTES.QUOTE_LEGAL_DECLARATION}
                  component={LegalDeclarationPage}
                />
                <Route
                  path={ROUTES.QUOTE_SELECT_PATH}
                  render={() => {
                    return <RiskCollectionPathSelection quoteId={quoteId} />
                  }}
                />
                {/* <Route path='/' render={() => {
                      window.history.back();
                  }} /> */}
              </Switch>
            </Grid>
          </MainContainer>
        </>
      )}
    </Grid>
  )
}

export const mapStateToProps = (state, ownProps) => {
  return {
    validForm: state.validation.riskDataCollectionPage.ValidForm,
    price: state.price,
    history: ownProps.history,
    location: ownProps.location,
    step: state.step,
    loading: state.isLoading,
    quoteId: state.riskData.QuoteId,
    theme: state.theme,
    appData: state.appData,
  }
}

export const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        initRiskDataCollectionPage,
        requestIdentityCheck,
        updateQuote,
        updateRiskData,
        sendEmailQuote,
        isButtonDisabled,
        enableAllButtons,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps,
)(RiskDataCollectionPage)

RiskDataCollectionPage.propTypes = {
  validForm: PropTypes.bool,
  legalDeclaration: PropTypes.shape({
    ShortText: PropTypes.string,
    FullText: PropTypes.string,
  }),
}
