import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { requestCallback } from '../../../actions'
import { REQUEST_CALLBACK_STATUS, CALLBACK_SOURCE } from '../../../constants'
import {
  StaticBanner,
  BannerTextPrimary,
  BannerTextSecondary,
  ConfusedLogo,
  ButtonWrapper,
  BannerButton,
  ButtonTextPrimary,
  SmallPrint,
} from '../styles'
import { ANNUAL_CALLBACK_PROVIDER } from '../../../../../constants'

const ConfusedStaticBanner = ({
  callbackRequest,
  riskData,
  actions: { requestCallback },
}) => {
  const generateWording = (difference, string) => {
    if (difference > 1) {
      return (string += 's')
    }
    return string
  }
  const expiryWording = useMemo(() => {
    const baseWording = 'Your temporary cover expires in less than'
    const now = moment.now()
    const policyEnd = moment(riskData.PolicyEndDate)
    let difference_days = policyEnd.diff(now, 'days')
    var difference_hours = policyEnd.diff(now, 'hours')
    var difference_minutes = policyEnd.diff(now, 'minutes')

    if (difference_days >= 1) {
      return generateWording(
        difference_days,
        `${baseWording} ${difference_days} day`,
      )
    }
    if (difference_hours >= 1) {
      return generateWording(
        difference_hours,
        `${baseWording} ${difference_hours} hour`,
      )
    }
    if (difference_minutes >= 15) {
      return generateWording(
        difference_minutes,
        `${baseWording} ${difference_minutes} minute`,
      )
    }
    if (difference_minutes > 0 && difference_minutes <= 15) {
      return `${baseWording} 15 minutes`
    }
    return `Your temporary cover has now expired`
  }, [riskData.PolicyEndDate])

  return (
    <>
      <StaticBanner id='ConfusedUpsellBanner'>
        <div>
          <BannerTextPrimary>{expiryWording}</BannerTextPrimary>
          <BannerTextSecondary>
            <strong>Save up to £561*</strong> and stay covered with annual car
            insurance from <ConfusedLogo alt='Confused.com' />
          </BannerTextSecondary>
        </div>
        <ButtonWrapper>
          <BannerButton
            id='ConfusedUpsellBannerButton'
            onClick={() =>
              requestCallback({
                linkType: CALLBACK_SOURCE.Website,
                provider: ANNUAL_CALLBACK_PROVIDER.Confused,
              })
            }
            disabled={
              callbackRequest.status === REQUEST_CALLBACK_STATUS.REQUESTING
            }
          >
            <div>
              <ButtonTextPrimary>GET AN ANNUAL QUOTE</ButtonTextPrimary>
            </div>
          </BannerButton>
        </ButtonWrapper>
      </StaticBanner>
      <SmallPrint id='ConfusedUpsellBannerSmallPrint'>
        *Based on data provided by Consumer Intelligence Ltd,
        www.consumerintelligence.com (December '23). 51% of car insurance
        customers could save £561.39
      </SmallPrint>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    callbackRequest: state.policy.callbackRequest,
    riskData: state.riskData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestCallback,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfusedStaticBanner)
