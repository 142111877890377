import {
  GET_APP_STARTUP_SUCCESS,
  GET_APP_STARTUP_FAILURE,
  REQUEST_QUOTE_SUCCESS,
} from '../constants'

import initialState from '../../../initialState'
import { REQUEST_VEHICLE_SUCCESS } from '../../Vehicle/constants'

function appDataReducer(state = initialState.appData, action) {
  switch (action.type) {
    case GET_APP_STARTUP_SUCCESS:
      return {
        ...state,
        ...action.startupData,
        IsLoading: false,
      }
    case GET_APP_STARTUP_FAILURE:
      return {
        ...state,
        IsLoading: true,
        Error: true,
      }
    case REQUEST_QUOTE_SUCCESS:
      if (!state.PartnerPays) {
        return {
          ...state,
          PartnerPays: action.quote.PartnerPays,
        }
      } else {
        return {
          ...state,
        }
      }
    case REQUEST_VEHICLE_SUCCESS:
      if (!state.PartnerPays) {
        return {
          ...state,
          PartnerPays: action.vehicle.PartnerPays,
        }
      } else {
        return {
          ...state,
        }
      }

    default:
      return state
  }
}

export default appDataReducer
