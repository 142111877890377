import styled from 'styled-components'

export const PreferenceWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 360px;
  margin-bottom: 32px;
  & > div:not(:last-child) {
    margin-right: 16px;
  }

  box-sizing: border-box;
`
export const MarketingWrapper = styled.div`
  vertical-align: middle;
`

export const ButtonLabel = styled.label``
export const MarketingInput = styled.input`
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;

  :checked + label,
  :not(:checked) + label {
    position: relative;
    display: inline-block;
    padding: 20px;
    width: 110px;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 1px;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    text-transform: uppercase;
    color: #fff;
    padding: 20px 0;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    border: 1px solid ${(props) => props.theme.marketingPreferencesColour};
  }

  :not(:checked) + label {
    background-color: ${({ disabled }) => (disabled ? '#dddddd' : '#fff')};
    color: ${({ theme, disabled }) =>
      disabled ? '#000' : theme.marketingPreferencesColour};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  :checked + label {
    background-color: ${(props) => props.theme.marketingPreferencesColour};
    color: #fff;
  }

  :checked + label .uil,
  :not(:checked) + label .uil {
    font-size: 24px;
    line-height: 24px;
    display: block;
    padding-bottom: 10px;
  }
`
export const MarketingButtons = styled.div`
  display: inline-block;
  label:first-of-type {
    border-radius: 12px 0 0 12px;
  }
  label:last-of-type {
    border-radius: 0 12px 12px 0;
    border-left: 0px !important;
  }
`
export const MarketingLabel = styled.label`
  font-size: 22px;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.headerFontColour};
  text-align: center;
  margin: 0;
  display: inline-block;
  width: 275px;

  @media (min-width: 555px) {
    transform: translateY(-65%);
  }
`
