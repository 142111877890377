import styled from 'styled-components'

export const H5 = styled.h5`
  font-size: ${(props) => props.theme.generalFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 400;
  font-size: 12pt;
  color: black;
  display: inline-block;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
`

export const CenteredH5 = styled(H5)`
  text-align: center;
  margin-bottom: 1em;
`
