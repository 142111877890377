import moment from 'moment'
import * as Yup from 'yup'
import { ANNUAL_INSURANCE_PROVIDER_ID } from '../constants'
import { LICENCE_HELD_DURATIONS, PRIVATE_CAR, TITLE_OPTIONS } from './constants'

export const SPLIT_POLICY_START_DATE_VALIDATION = Yup.object()
  .shape({
    manuallyEdited: Yup.bool(),
    date: Yup.string().when('manuallyEdited', {
      is: true,
      then: Yup.string().required('Please select a valid policy start time'),
    }),
    hour: Yup.string().when('manuallyEdited', {
      is: true,
      then: Yup.string().required('Please select a valid policy start time'),
    }),
    minute: Yup.string().when('manuallyEdited', {
      is: true,
      then: Yup.string().required('Please select a valid policy start time'),
    }),
  })
  .test(
    'PolicyStartDate.Future',
    'Please select a future policy start time',
    function () {
      const { date, hour, minute, manuallyEdited } = this.parent.PolicyStartDate
      if (Boolean(date && hour && minute && manuallyEdited)) {
        const combinedDate = moment(`${date}T${hour}:${minute}`)
        return combinedDate.isValid() && combinedDate > moment()
      }
      return true
    },
  )
export const CAN_FUTURE_DATE_SPLIT_POLICY_START_DATE_VALIDATION = Yup.object()
  .shape({
    manuallyEdited: Yup.bool(),
    date: Yup.string().when('manuallyEdited', {
      is: false,
      then: Yup.string().required('Please select a valid policy start time'),
    }),
    hour: Yup.string().when('manuallyEdited', {
      is: true,
      then: Yup.string().required('Please select a valid policy start time'),
    }),
    minute: Yup.string().when('manuallyEdited', {
      is: true,
      then: Yup.string().required('Please select a valid policy start time'),
    }),
  })
  .test(
    'PolicyStartDate.Future',
    'Please select a future policy start time',
    function () {
      const { date, hour, minute, manuallyEdited } = this.parent.PolicyStartDate
      if (Boolean(date && hour && minute && manuallyEdited)) {
        const combinedDate = moment(`${date}T${hour}:${minute}`)
        return combinedDate.isValid() && combinedDate > moment()
      }
      return true
    },
  )

export const VEHICLE_VALUE_VALIDATION = Yup.number().nullable()

export const FORENAME_VALIDATION = Yup.string()
  .nullable()
  .matches(
    /^[a-zA-Z]+(([’'\-\s][a-zA-Z])?[a-zA-Z]+)?(([’'\-\s][a-zA-Z])?[a-zA-Z]+)[\s]*$/,
    'Please enter a valid first name',
  )
  .min(2, 'Please enter a valid first name')
  .max(50, 'Please enter a valid first name')
  .required('Please enter a valid first name')

export const SURNAME_VALIDATION = Yup.string()
  .nullable()
  .matches(
    /^[a-zA-Z]+(([’'\-\s][a-zA-Z])?[a-zA-Z]+)?(([’'\-\s][a-zA-Z])?[a-zA-Z]+)[\s]*$/,
    'Please enter a valid surname',
  )
  .min(2, 'Please enter a valid surname')
  .max(50, 'Please enter a valid surname')
  .required('Please enter a valid surname')

export const DATE_OF_BIRTH_VALIDATION = (MinAge, MaxAge) =>
  Yup.object().shape({
    day: Yup.string()
      .length(2)
      .test('DateOfBirth.Day', (value) => {
        return (
          value &&
          !isNaN(parseInt(value)) &&
          parseInt(value) > 0 &&
          parseInt(value) <= 31
        )
      }),
    month: Yup.string()
      .length(2)
      .test('DateOfBirth.Month', (value) => {
        return (
          value &&
          !isNaN(parseInt(value)) &&
          parseInt(value) > 0 &&
          parseInt(value) <= 12
        )
      }),
    year: Yup.string()
      .length(4)
      .test('DateOfBirth.Year', (value) => {
        return (
          value &&
          !isNaN(parseInt(value)) &&
          parseInt(value) > 1900 &&
          parseInt(value) <= 2100
        )
      }),
    date: Yup.string()
      .length(19)
      .test(
        'DateOfBirth',
        `Sorry, we only provide insurance for customers aged between ${MinAge} and ${MaxAge} years old`,
        (value) => {
          let minimumDate = moment()
          let maximumDate = minimumDate.clone()
          let isValidDate = moment(value, 'YYYY-MM-DDTHH:mm:ss').isValid()
          maximumDate = maximumDate
            .subtract(MinAge, 'year')
            .format('YYYY-MM-DDTHH:mm:ss')
          minimumDate = minimumDate
            .subtract(MaxAge, 'year')
            .subtract(1, 'year')
            .format('YYYY-MM-DDTHH:mm:ss')
          let selectedDate = new Date(value)
          let minimumDateAsDate = new Date(minimumDate)
          let maximumDateAsDate = new Date(maximumDate)
          return (
            isValidDate &&
            selectedDate >= minimumDateAsDate &&
            selectedDate < maximumDateAsDate
          )
        },
      ),
  })

export const ADDRESS_VALIDATION = Yup.object().shape({
  PostcodeSearched: Yup.boolean().isTrue(
    'Please confirm your postcode and find your address to continue',
  ),
  Postcode: Yup.string()
    .nullable()
    .matches(
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i,
      'Please enter a valid postcode',
    )
    .min(5, 'Please enter a valid postcode')
    .max(7, 'Please enter a valid postcode')
    .required('Please enter a valid postcode'),
  AddressKey: Yup.string().nullable().required('Please select an address'),
})

export const LICENCE_TYPE_VALIDATION = Yup.string().required(
  'Please select a licence type',
)

const availableLicenceHelds = LICENCE_HELD_DURATIONS.map(({ value }) => value)
export const LICENCE_HELD_DURATION_VALIDATION = Yup.string()
  .oneOf(
    availableLicenceHelds,
    "Sorry, we need to know how long you've held your licence",
  )
  .required("Sorry, we need to know how long you've held your licence")

const availableTitleOptions = TITLE_OPTIONS.map(({ value }) => value)
export const TITLE_VALIDATION = Yup.string()
  .oneOf(availableTitleOptions, 'Please select a title')
  .required('Please select a title')

export const ADDITIONAL_DRIVER_VALIDATION = (MinAge, MaxAge) =>
  Yup.object().shape({
    Required: Yup.boolean(),
    Title: Yup.string().when('Required', {
      is: true,
      then: TITLE_VALIDATION,
    }),
    Forename: Yup.string().when('Required', {
      is: true,
      then: FORENAME_VALIDATION,
    }),
    Surname: Yup.string().when('Required', {
      is: true,
      then: SURNAME_VALIDATION,
    }),
    DateOfBirth: Yup.object().when('Required', {
      is: true,
      then: DATE_OF_BIRTH_VALIDATION(MinAge, MaxAge),
    }),
    LicenceType: Yup.string().when('Required', {
      is: true,
      then: LICENCE_TYPE_VALIDATION,
    }),
  })

export const EMAIL_VALIDATION = Yup.string()
  .nullable()
  .email('Please enter a valid email')
  .required('Please enter a valid email')

export const MOBILE_NUMBER_VALIDATION = Yup.string()
  .nullable()
  .matches(
    /^((07)[12345789]{1}\d{8})|((07624)\d{6})$/,
    'Please enter a valid mobile number',
  )
  .min(10, 'Please enter a valid mobile number')
  .max(11, 'Please enter a valid mobile number')
  .required('Please enter a valid mobile number')
export const CONFUSED_QUICK_QUOTE_VALIDATION = (
  annualInsuranceProvider,
  vehicle,
  enabled,
) =>
  annualInsuranceProvider === ANNUAL_INSURANCE_PROVIDER_ID.ConfusedRTP &&
  vehicle.selected.VehicleType === PRIVATE_CAR &&
  enabled
    ? Yup.string()
        .nullable()
        .required(
          "Please select whether you'd like an annual car insurance quote",
        )
    : Yup.string().nullable()

export const DRIVING_LICENCE_VALIDATION = (IdentityCheck) =>
  IdentityCheck === 'drivingLicence'
    ? Yup.string()
        .matches(
          /^[A-Z9]{5}\d{6}[A-Z9]{2}\d[A-Z]{2}$/,
          'Please enter a valid driving licence number',
        )
        .required('Please enter a valid driving licence number')
    : Yup.string()

export const PASSPORT_LINE_ONE_VALIDATION = (IdentityCheck) =>
  IdentityCheck === 'passport'
    ? Yup.string()
        .matches(
          /^([P|p])(<)([G|g][B|b][R|r])([A-Za-z]{2})[(A-Za-z)<]{37}$/,
          'Please ensure your passport line one is valid',
        )
        .required('Please ensure your passport line one is valid')
    : Yup.string()

export const PASSPORT_LINE_TWO_VALIDATION = (IdentityCheck) =>
  IdentityCheck === 'passport'
    ? Yup.string()
        .matches(
          /^([0-9]{10})([G|g][B|b][R|r])([0-9]{7})([Mm|Ff<])([0-9]{7})(([0-9<]){16})$/,
          'Please ensure your passport line two is valid',
        )
        .required('Please ensure your passport line two is valid')
    : Yup.string()

export const PASSPORT_EXPIRY_DATE_VALIDATION = (IdentityCheck) =>
  IdentityCheck === 'passport'
    ? Yup.string()
        .nullable()
        .required('Please ensure your passport expiry date is valid')
        .test(
          'PassportExpiryDate',
          'Please ensure your passport expiry date is valid',
          (value) => moment(value).isValid(),
        )
    : Yup.string().nullable()

export const POLICY_DURATION_VALIDATION = Yup.string()
  .nullable()
  .required('Please select a policy duration')

export const VEHICLE_REG_VALIDATION = Yup.string()
  .nullable()
  .min(2, 'Please enter a valid vehicle registration')
  .max(8, 'Please enter a valid vehicle registration')
  .matches(
    /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/,
    'Please enter a valid vehicle registration',
  )
  .required('Please enter a valid vehicle registration')

export const HOME_PAGE_VALIDATION = (BranchesDrivenByStocklist) =>
  Yup.object().shape({
    Registration: VEHICLE_REG_VALIDATION,
    Branch: BranchesDrivenByStocklist
      ? Yup.object().nullable()
      : Yup.object().nullable().required('Please select a branch'),
  })
