import {
  UPDATE_QUOTE_LINK_REL,
  REQUEST_IDENTITY_LINK_REL,
  UPDATE_UNDERWRITER_LINK_REL,
  REQUEST_IDENTITY_DRIVING_LICENCE_REL,
  REQUEST_IDENTITY_PASSPORT_REL,
} from './constants'

export const updateQuoteLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === UPDATE_QUOTE_LINK_REL)[0]

export const updateUnderwriterLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === UPDATE_UNDERWRITER_LINK_REL)[0]

export const getRiskData = (state) => state.riskData

export const getVehicle = (state) => state.vehicle

export const getValidation = (state) => state.validation.riskDataCollectionPage

export const identityCheckLinkSelector = (state) =>
  state.links.filter((link) => link.Rel === REQUEST_IDENTITY_LINK_REL)[0]

export const drivingLicenceCheckLink = (state) =>
  state.links.filter(
    (link) => link.Rel === REQUEST_IDENTITY_DRIVING_LICENCE_REL,
  )[0]

export const passportCheckLink = (state) =>
  state.links.filter((link) => link.Rel === REQUEST_IDENTITY_PASSPORT_REL)[0]

export const getQuoteId = (state) => state.riskData.QuoteId

export const getQuoteStatus = (state) => state.riskData.QuoteStatus

export const getQueryString = (state) => state.queryString

export const getSelectedScheme = (state) => state.scheme.selected
