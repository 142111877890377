import React from 'react'
import PropTypes from 'prop-types'
import { BaseRadioButton } from './BaseRadioButton'
import styled from 'styled-components'

const Label = styled.label`
  display: inline-block;
  border: ${(props) => (props.error ? '4px' : '1px')} solid
    ${(props) =>
      props.error
        ? props.theme.inputBorderColourError
        : props.theme.inputBorderColour};
  border-radius: 6px;
  padding: 10px 10px 10px 54px;
  cursor: pointer;
  font-weight: 500;
  font-family: freight-sans-pro;
  background-color: #fff;
  width: 100%;
  min-width: 145px;
  line-height: 1.5rem;
  text-align: left;
  box-sizing: border-box;
  font-size: 18px;
  

    select:first-child{
      padding-right: 1em;
    }

    :before, :after{
      content: '';
      position: absolute;
      top: 50%;
      left: 1.25em;
      height: 20px;
      width: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      cursor: pointer;
      pointer-events: none;
    }

    :before {
      border: 1px solid ${(props) => props.theme.durationOptions.primaryColour};
      transform: translateY(-50%) translateX(-50%);
      background: white;
    }

    :after {
      background: ${(props) => props.theme.durationOptions.primaryColour};
      transform: translateY(-50%) translateX(-50%) scale(0);
      transition: transform 0.3s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    min-width: 120px;
  }
`

const RadioWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  max-width: 360px;
`

export const RadioButton = ({
  id,
  name,
  value,
  onChange,
  label,
  checked,
  error,
}) => {
  return (
    <RadioWrapper>
      <BaseRadioButton
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        type='radio'
        checked={checked}
      ></BaseRadioButton>
      <Label htmlFor={id} error={error}>
        {label ? label : value}
      </Label>
    </RadioWrapper>
  )
}

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}
