import 'babel-polyfill'
import 'raf/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import queryString from 'query-string'
import * as serviceWorker from './serviceWorker'
import App from './containers/App'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import configureStore from './configureStore'
import '../node_modules/unsemantic/assets/stylesheets/unsemantic-grid-responsive-tablet.css'
import './app.css'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'
import defaultTheme from './themes/default'
import { getSubDomain } from './services/handlers'

const baseHistory = createBrowserHistory()

export const history = {
  ...baseHistory,
  push: (location, state) => {
    let parsed
    if (typeof location === 'string') {
      parsed = queryString.parseUrl(location)
      parsed.query = addMissingUtmTags(parsed.query)
      const url = queryString.stringifyUrl(parsed)
      baseHistory.push(url, state)
    } else {
      const { search } = location
      parsed = queryString.parse(search)
      parsed = addMissingUtmTags(parsed)
      const updatedSearch = queryString.stringify(parsed)
      baseHistory.push({ ...location, search: updatedSearch })
    }
  },
  replace: (location, state) => {
    let parsed
    if (typeof location === 'string') {
      parsed = queryString.parseUrl(location)
      parsed.query = addMissingUtmTags(parsed.query)
      const url = queryString.stringifyUrl(parsed)
      baseHistory.replace(url, state)
    } else {
      const { search } = location
      parsed = queryString.parse(search)
      parsed = addMissingUtmTags(parsed)
      const updatedSearch = queryString.stringify(parsed)
      baseHistory.replace({ ...location, search: updatedSearch })
    }
  },
}

export const addMissingUtmTags = (tags) => {
  const subDomain = getSubDomain()

  if (!tags.utm_campaign) {
    tags = { ...tags, utm_campaign: 'driveaway' }
  }
  if (!tags.utm_source) {
    tags = { ...tags, utm_source: subDomain.toLowerCase() }
  }
  if (store) {
    const state = store.getState()
    if (
      state &&
      state.driveaway &&
      state.driveaway.SelectedBranch &&
      state.driveaway.SelectedBranch.BranchName
    ) {
      if (
        !tags.utm_medium ||
        tags.utm_medium !== state.driveaway.SelectedBranch.BranchName
      ) {
        tags = {
          ...tags,
          utm_medium: state.driveaway.SelectedBranch.BranchName,
        }
      }
    }
  }

  return tags
}

const store = configureStore()
const MOUNT_NODE = document.getElementById('app')

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={defaultTheme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
