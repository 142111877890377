import React, { useState, useEffect } from 'react'
import {
  HomePageContainer,
  Headline,
  P2Light,
  P2,
  TeslaQuoteNextButton,
  CtobLink,
  NoWrapSpan,
  BranchContainer,
} from './styles'

import { history } from '../..'
import { Form, Formik } from 'formik'
import { HOME_PAGE_VALIDATION } from '../../services/validation'
import { connect } from 'react-redux'
import driveawayApi from '../../api/driveawayApi'

import BranchSelect from '../BranchSelect'
import HomePagePrice from '../../components/HomePagePrice'
import { RegInput } from './RegInput'
import { getSubDomain } from '../../services/handlers'

const HomePage = ({
  selectedBranch,
  appData,
  riskData,
  vehicle,
  driveaway,
}) => {
  const [branchUpdated, setBranchUpdated] = useState(false)
  const [isUpsellEnabled, setIsUpsellEnabled] = useState(false)
  const [upsellChecking, setUpsellChecking] = useState(true)
  const subDomain = getSubDomain()

  const upsellEnabled = async () => {
    try {
      const response = await driveawayApi.getUpsellEnabled(subDomain)

      setIsUpsellEnabled(response.data.UpsellEnabled)
      setUpsellChecking(false)
    } catch (err) {
      // check failed go back to the start of the journey
      setUpsellChecking(false)
    }
  }

  const handleStartQuote = (values) => {
    // Set default start quote page
    var url = `/quote?registration=${values.Registration}&duration=${appData.HomePagePricing.PolicyDuration}+days&start=Immediately`

    if (isUpsellEnabled) {
      // Set url for redirection to duration selection page
      url = `/duration?registration=${values.Registration}&start=Immediately`
    }

    if (values.Branch) {
      url = `${url}&branch=${values.Branch.BranchId}`
    }

    history.push(url)
  }

  const onRegInput = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase()
    e.target.value = e.target.value.replace(/\s/g, '')
  }

  useEffect(() => {
    upsellEnabled()
    if (driveaway.SelectedBranch) {
      setBranchUpdated(true)
    }
  }, [driveaway])

  return (
    <HomePageContainer>
      <Headline>
        {appData.PartnerPays ? 'Driveaway' : 'Affordable'} cover, unbelievably
        fast
      </Headline>
      <section id='MainCTA' className='grid-container'>
        <div className='grid-100 grid-parent'>
          <section id='Tesla' className='grid-container'>
            <Formik
              initialValues={{
                Registration: vehicle.selected.RegistrationNumber,
                Branch: driveaway.SelectedBranch,
              }}
              validationSchema={HOME_PAGE_VALIDATION(
                appData.BranchesDrivenByStocklist,
              )}
              onSubmit={(values) => {
                handleStartQuote(values)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                setFieldTouched,
                setFieldValue,
                submitCount,
                touched,
                values,
              }) => {
                const errorCount = Object.keys(errors || {}).length
                /* eslint-disable react-hooks/rules-of-hooks */
                const [globalSubmitCount, setGlobalSubmitCount] = useState(0)
                if (branchUpdated) {
                  if (values.Branch !== driveaway.SelectedBranch) {
                    setFieldValue('Branch', driveaway.SelectedBranch)
                  }
                  setBranchUpdated(false)
                }
                if (
                  errorCount > 0 &&
                  submitCount > 0 &&
                  globalSubmitCount !== submitCount
                ) {
                  setFieldTouched('Registration', !values.ShowVehicleBuilder)
                  setGlobalSubmitCount(submitCount)
                }
                return (
                  <Form id='TeslaForm'>
                    <div className='select-vehicle'>
                      <section id='FindByReg'>
                        <P2 noTopMargin>
                          To get started, tell us the reg of the vehicle you
                          want cover for
                        </P2>
                        <RegInput
                          id='Registration'
                          autoComplete='off'
                          name='Registration'
                          type='text'
                          autoCorrect='off'
                          value={values.Registration}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onInput={onRegInput}
                          ariaLabel='Registration'
                          tabIndex={1}
                          error={Boolean(
                            touched.Registration && errors.Registration,
                          )}
                          errorMessage={errors.Registration}
                        />
                      </section>
                      {!appData.BranchesDrivenByStocklist && (
                        <section id='BranchSection'>
                          <BranchContainer>
                            <BranchSelect
                              filterId='HomePage_BranchFilter'
                              name='BranchSelection'
                              dropdownId='HomePage_BranchDropdown'
                              value={values.Branch}
                              tabIndex={2}
                              onBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              error={Boolean(touched.Branch && errors.Branch)}
                              errorMessage={errors.Branch}
                            />
                          </BranchContainer>
                        </section>
                      )}
                      <HomePagePrice AppData={appData} />
                    </div>
                    {!appData.CanFutureDatePolicies && (
                      <section id='StartTime'>
                        <P2>
                          Only purchase cover on the day you receive your
                          vehicle, as the time and date cannot be changed once
                          purchased.
                        </P2>
                      </section>
                    )}
                    <div className='get-quote grid-100 grid-parent'>
                      <P2Light>
                        To process your application we will verify your identity
                        and may check your details with credit reference and
                        fraud prevention agencies. Entering incorrect data risks
                        invalidating your policy.{' '}
                        <NoWrapSpan>
                          I have read and agree to the{' '}
                          <CtobLink href='/terms-conditions' target='_blank'>
                            customer terms of business
                          </CtobLink>{' '}
                          and{' '}
                          <CtobLink href='/privacy-cookies' target='_blank'>
                            privacy policy
                          </CtobLink>
                          .
                        </NoWrapSpan>
                      </P2Light>
                      <TeslaQuoteNextButton
                        id='TeslaQuoteNextButton'
                        type='submit'
                        tabIndex={3}
                        disabled={appData.IsLoading && upsellChecking}
                        onMouseDown={(e) => {
                          e.preventDefault()
                        }}
                      >
                        Agree and{' '}
                        {appData.PartnerPays ? 'Continue' : 'get a quote'}
                      </TeslaQuoteNextButton>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </section>
        </div>
      </section>
    </HomePageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedBranch: state.driveaway.SelectedBranch,
    appData: state.appData,
    riskData: state.riskData,
    vehicle: state.vehicle,
    driveaway: state.driveaway,
  }
}

export default connect(mapStateToProps)(HomePage)
