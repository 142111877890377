import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  ContactUsPageContainer,
  Headline,
  SubHeadline,
  ByPostHeadline,
  P2,
  A,
  TextArea,
  SubmitButton,
  Label,
  Red,
  PaddingReset,
} from './styles'
import { Grid } from 'unsemantic'
import { bindActionCreators } from 'redux'
import {
  updateContactUsData,
  updateContactUsValidation,
  submitContactUsRequest,
} from './actions'
import MobileTelephone from '../../components/MobileTelephoneOld'
import { EnquiryType } from './EnquiryType'
import { Email } from '../../components/common/EmailOld'
import config from '../../config'
import FullName from '../../components/FullName'
import VehicleRegistration from '../../components/VehicleRegistration'
import { RegistrationWrapper } from '../../components/VehicleRegistration/styles'
import { FormControlValidIcon } from '../../components/FormControlValidIcon'
import { Input } from '../../components/common/Input'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { Message } from '../../components/Message'

const ContactUsPage = ({ actions, formData, validation }) => {
  const recaptcha = useRef()

  useEffect(() => {
    recaptcha.current.reset()
    actions.updateContactUsData({ ReCAPTCHA_Token: '' })
    actions.updateContactUsValidation({ ReCAPTCHAError: 'Invalid token' })
  }, [formData.error])

  const onChange = (recaptchaToken) => {
    actions.updateContactUsData({ ReCAPTCHA_Token: recaptchaToken })
    actions.updateContactUsValidation({
      ReCAPTCHAError:
        recaptchaToken && recaptchaToken.length > 0 ? '' : 'Invalid token',
    })
  }

  const handleSelect = (event) => {
    let message = ''
    if (!event.target.value) {
      message = 'Please select an enquiry type'
    }

    updateData(event, message)
  }

  const handleTextAreaChange = (event) => {
    let message = ''
    if (!event.target.value || event.target.value.length <= 5) {
      message = 'Please enter a comment or question'
    }

    updateData(event, message)
  }

  const updateData = (event, message) => {
    const fieldName = event.target.name
    const fieldValue = event.target.value

    actions.updateContactUsData({ [fieldName]: fieldValue })

    if (message === '' || message) {
      handleValidation(fieldName, message)
    }
  }

  const handleValidation = (field, message) => {
    actions.updateContactUsValidation({
      [`${field}Error`]: message,
    })
  }

  const handleSubmit = () => {
    if (validation.ValidForm) {
      actions.submitContactUsRequest()
    }
  }

  return (
    <ContactUsPageContainer>
      <Grid desktop='100' mobile='100' tablet='100'>
        <Headline>Contact us</Headline>
        <P2>
          Need to get in touch? Our helpful Tempcover staff will try their best
          to contact you as soon as they can to ensure that your question is
          answered as quickly as possible.
        </P2>
        <SubHeadline>Contact Us</SubHeadline>
        <P2>To contact us, please complete the form below.</P2>
        <P2>
          The team are available Monday to Friday, 9am to 5.30pm, excluding Bank
          Holidays. During these times, the team will endeavour to contact you
          within one hour of receiving your query. If a request is received
          outside of these hours, the team will read and action it the next
          working day.
        </P2>
        <Grid desktop='100' parent>
          <Grid desktop='45' tablet='45' mobile='100' parent>
            <FullName
              id='FullName'
              name='FullName'
              placeholder='Full name (required)'
              onChange={updateData}
              onBlur={updateData}
              handleValidation={handleValidation}
              value={formData.FullName}
              error={validation.fields.FullNameError}
            />
          </Grid>
          <Grid
            desktop='50'
            desktop-prefix='5'
            tablet='50'
            tablet-prefix='5'
            mobile='100'
            parent
          >
            <RegistrationWrapper>
              <FormControlValidIcon
                error={validation.fields.RegistrationNumberError}
                className='fas fa-check'
              ></FormControlValidIcon>
              <VehicleRegistration
                value={formData.RegistrationNumber}
                onChange={updateData}
                onBlur={updateData}
                error={validation.fields.RegistrationNumberError}
                placeholder='Vehicle registration (required)'
                id='ContactUs_RegistrationNumber'
                name='RegistrationNumber'
              />
            </RegistrationWrapper>
          </Grid>
        </Grid>
        <Grid desktop='100' parent>
          <Input
            id='PolicyNumber'
            name='PolicyNumber'
            placeholder='Policy number (if applicable)'
            onChange={updateData}
            onBlur={updateData}
            value={formData.PolicyNumber}
          />
        </Grid>
        <Grid desktop='100' parent>
          <Grid desktop='45' tablet='45' mobile='100' parent>
            <MobileTelephone
              id='MobileNumber'
              name='MobileNumber'
              placeholder='Telephone'
              onChange={updateData}
              onBlur={updateData}
              handleValidation={handleValidation}
              value={formData.MobileNumber}
              error={validation.fields.MobileNumberError}
            />
          </Grid>
          <Grid
            desktop='50'
            desktop-prefix='5'
            tablet='50'
            tablet-prefix='5'
            mobile='100'
            parent
          >
            <Email
              id='EmailAddress'
              name='EmailAddress'
              placeholder='Email (required)'
              onChange={updateData}
              onBlur={updateData}
              handleValidation={handleValidation}
              error={validation.fields.EmailAddressError}
              value={formData.EmailAddress}
            />
          </Grid>
        </Grid>
        <Grid desktop='100' parent>
          <Label>
            Enquiry type <Red>*</Red>
          </Label>
          <EnquiryType
            id='EnquiryType'
            name='EnquiryType'
            onChange={handleSelect}
            value={formData.EnquiryType}
          />
        </Grid>
        <Grid desktop='100' parent>
          <TextArea
            rows='9'
            cols='50'
            placeholder='Comment/Question (required)'
            name='CommentBox'
            id='CommentBox'
            onChange={handleTextAreaChange}
            value={formData.CommentBox}
          />
        </Grid>
        <Grid desktop='100' parent>
          <ReCAPTCHA
            sitekey={config.ReCAPTCHA_KEY}
            action='contact_us'
            onChange={onChange}
            ref={recaptcha}
          />
        </Grid>
        <Grid desktop='100' parent>
          <SubmitButton
            id='SubmitContactInformationButton'
            className={
              validation.ValidForm && !formData.isPosting ? '' : 'disabled'
            }
            onClick={() => handleSubmit()}
          >
            {formData.isPosting ? 'Please wait' : 'Submit'}
            <ButtonSpinner disabled={formData.isPosting} />
          </SubmitButton>
        </Grid>
        {formData.error && (
          <PaddingReset>
            <Message
              heading={`We're sorry.`}
              content='There was an error when submitting your message, please try again'
              type='error'
            />
          </PaddingReset>
        )}

        <P2>
          If you’re a broker, please email&nbsp;
          <A href='mailto:broker.support@tempcover.com'>
            broker.support@tempcover.com
          </A>
        </P2>
        <P2>
          If you wish to make a complaint, please email&nbsp;
          <A href='mailto:complaints@tempcover.com'>complaints@tempcover.com</A>
        </P2>
        <P2>
          Alternatively you can submit a complaint via the EU Online Dispute
          Resolution portal at:&nbsp;
          <A
            href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'
            target='_blank'
            rel='noopener noreferrer'
          >
            EU Online Dispute Resolution portal.
          </A>
        </P2>
        <P2>
          Please note, some enquiries can only be dealt with during Head Office
          opening hours, which are Monday to Friday, 9am to 5.30pm.
        </P2>
        <ByPostHeadline>By post</ByPostHeadline>
        <P2>You can also write to us at:</P2>
        <P2>
          Tempcover Ltd
          <br />
          Admiral House
          <br />
          Harlington Way
          <br />
          Fleet
          <br />
          Hampshire
          <br />
          GU51 4BB
        </P2>
      </Grid>
    </ContactUsPageContainer>
  )
}

export const mapStateToProps = (state) => {
  return {
    formData: state.contactUs,
    validation: state.validation.contactUsPage,
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateContactUsData,
        updateContactUsValidation,
        submitContactUsRequest,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage)
