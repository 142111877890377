import initialState from '../../initialState'
import { SUBMIT_DRIVER_DETAILS_FORM } from '../DriverDetails/constants'
import {
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE,
  SET_MARKETING_PREFERENCES_OPT_IN_STATE,
  RESET_MARKETING_PREFERENCES_OPT_IN_STATE,
  SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS,
} from './constants'

export function marketingPreferencesReducer(
  state = initialState.marketingPreferences,
  action,
) {
  switch (action.type) {
    case CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS:
      return {
        ...state,
        loaded: true,
        ...action.data,
        EmailAddressOptIn:
          action.data.EmailAddressOptIn == null
            ? true
            : action.data.EmailAddressOptIn,
        TelephoneNumberOptIn:
          action.data.TelephoneNumberOptIn == null
            ? true
            : action.data.TelephoneNumberOptIn,
        PreviousCustomer: action.data.EmailAddressOptIn != null,
      }
    case CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_FAILURE:
      return {
        ...state,
        loaded: false,
      }
    case SUBMIT_DRIVER_DETAILS_FORM:
      return {
        ...state,
        EmailAddressOptIn: action.formValues.EmailAddressOptIn,
        TelephoneNumberOptIn: action.formValues.TelephoneNumberOptIn,
      }
    case SET_MARKETING_PREFERENCES_OPT_IN_STATE:
      return {
        ...state,
        ...action.vals,
      }
    case RESET_MARKETING_PREFERENCES_OPT_IN_STATE:
      return {
        ...state,
        loaded: false,
        EmailAddress: null,
        EmailAddressOptIn: true,
        MobileNumber: null,
        TelephoneNumberOptIn: true,
      }
    case SAVE_MARKETING_PREFERENCES_OPT_IN_STATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default marketingPreferencesReducer
