import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {
  MobileNavWrapper,
  MobileNavButton,
  MobileNavButtonText,
  MobileNavButtonIcon,
  MobileNavLinksWrapper,
  MobileNavLinks,
} from './styles'
import { ROUTES } from '../../services/constants'

export const MobileNav = () => {
  const [isActive, toggleMenu] = useState(false)
  const location = useLocation()
  const componentRef = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  useEffect(() => {
    toggleMenu(false)
  }, [location])

  const handleLinkClick = () => {
    toggleMenu(false)
  }

  const handleClick = (e) => {
    if (componentRef && !componentRef.current.contains(e.target)) {
      toggleMenu(false)
    }
  }

  return (
    <MobileNavWrapper ref={componentRef}>
      <MobileNavButton onClick={() => toggleMenu(!isActive)}>
        <MobileNavButtonText>Menu</MobileNavButtonText>
        <MobileNavButtonIcon className={isActive ? 'active' : ''}>
          <span></span>
          <span></span>
          <span></span>
        </MobileNavButtonIcon>
      </MobileNavButton>
      <MobileNavLinksWrapper className={isActive ? 'active' : ''}>
        <MobileNavLinks>
          <a
            href={ROUTES.ELIGIBILITY}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => handleLinkClick()}
          >
            Eligibility
          </a>
        </MobileNavLinks>
        <MobileNavLinks>
          <a
            href={ROUTES.FAQ}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => handleLinkClick()}
          >
            FAQs
          </a>
        </MobileNavLinks>
        <MobileNavLinks>
          <a
            href={ROUTES.CLAIMS}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => handleLinkClick()}
          >
            Claims
          </a>
        </MobileNavLinks>
        <MobileNavLinks>
          <a
            href={ROUTES.CONTACT_US}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => handleLinkClick()}
          >
            Contact us
          </a>
        </MobileNavLinks>
        <MobileNavLinks>
          <a
            href={ROUTES.PRIVACY_AND_COOKIES}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => handleLinkClick()}
          >
            Privacy &amp; cookies
          </a>
        </MobileNavLinks>
        <MobileNavLinks>
          <a
            href={ROUTES.TERMS_AND_CONDITIONS}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => handleLinkClick()}
          >
            T &amp; Cs
          </a>
        </MobileNavLinks>
      </MobileNavLinksWrapper>
    </MobileNavWrapper>
  )
}
