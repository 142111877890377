import { call, put, select } from 'redux-saga/effects'

import {
  requestIdentityCheckSuccess,
  updateValidation,
  requestIdentityCheckMoreInfo,
  enableAllButtons,
} from '../actions'

import {
  getRiskData,
  identityCheckLinkSelector,
  drivingLicenceCheckLink,
  passportCheckLink,
} from '../selector'
import identityApi from '../../../api/IdentityApi'

import { history } from '../../../'

export function* identitySaga() {
  const identityCheckLink = yield select(identityCheckLinkSelector)
  const riskData = yield select(getRiskData)

  try {
    const identityLink = identityCheckLink.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId,
    )
    const identityCheck = yield call(identityApi.identityCheck, identityLink)
    switch (identityCheck.data.FraudWorkFlow) {
      case 'DriverLicenceCheck':
        yield put(
          requestIdentityCheckMoreInfo(
            identityCheck.data.Links,
            'drivingLicence',
          ),
        )
        if (riskData.DrivingLicenceNumber) {
          yield* drivingLicenceCheckSaga()
        } else {
          yield put(
            updateValidation({
              DrivingLicenceNumberError:
                'Please enter your driving licence number',
            }),
          )
          yield put(
            requestIdentityCheckMoreInfo(
              identityCheck.data.Links,
              'drivingLicence',
            ),
          )
          history.push(`/quote/driver-details/more-info?q=${riskData.QuoteId}`)
          yield put(enableAllButtons())
        }
        break
      case 'PassportCheck':
        yield put(
          requestIdentityCheckMoreInfo(identityCheck.data.Links, 'passport'),
        )
        if (
          riskData.PassportLineOne &&
          riskData.PassportLineTwo &&
          riskData.PassportExpiryDate
        ) {
          yield* passportCheckSaga()
        } else {
          yield put(
            updateValidation({
              PassportLineOneError: riskData.PassportLineOne
                ? ''
                : 'Please enter passport line one',
              PassportLineTwoError: riskData.PassportLineTwo
                ? ''
                : 'Please enter passport line two',
              PassportExpiryDateError: riskData.PassportExpiryDate
                ? ''
                : 'Please enter your passport expiry date',
            }),
          )

          history.push(`/quote/driver-details/more-info?q=${riskData.QuoteId}`)
          yield put(enableAllButtons())
        }
        break
      case 'Prepayment':
        yield put(requestIdentityCheckSuccess(identityCheck.data))
        break
      default:
        break
    }
  } catch (e) {
    history.push(`/error`)
    yield put(enableAllButtons())
  }
}

export function* drivingLicenceCheckSaga() {
  const licenceCheck = yield select(drivingLicenceCheckLink)
  const riskData = yield select(getRiskData)
  try {
    const licenceCheckLink = licenceCheck.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId,
    )
    const data = {
      LicenceNumber: riskData.DrivingLicenceNumber,
    }
    yield call(identityApi.drivingLicenceCheck, licenceCheckLink, data)
    yield call(identitySaga)
  } catch (e) {
    yield put(enableAllButtons())
    history.push(`/quote/driver-details?q=${riskData.QuoteId}`)
  }
}

export function* passportCheckSaga() {
  const passportCheck = yield select(passportCheckLink)
  const riskData = yield select(getRiskData)
  try {
    const passportCheckLink = passportCheck.Href.replace(
      /{[encryptedQuoteId}]*}/,
      riskData.QuoteId,
    )
    const data = {
      UkPassportLineOne: riskData.PassportLineOne,
      UkPassportLineTwo: riskData.PassportLineTwo,
      UkPassportExpiryDate: riskData.PassportExpiryDate,
    }
    yield call(identityApi.passportCheck, passportCheckLink, data)
    yield call(identitySaga)
  } catch (e) {
    yield put(enableAllButtons())
    history.push(`/quote/driver-details?q=${riskData.QuoteId}`)
  }
}
