import { takeLatest, select, call, put } from 'redux-saga/effects'
import { GET_INTRODUCER_BRANCHES } from './constants'
import { getIntroducerBranchesLinkSelector } from './selector'
import driveawayApi from '../../api/driveawayApi'
import {
  getIntroducerBranchesSuccess,
  getIntroducerBranchesFailure,
} from './actions'
import { getSubDomain } from '../../services/handlers'
import queryString from 'query-string'

export function* getIntroducerBranches() {
  try {
    const subDomain = getSubDomain()
    const { utm_medium: preSelectedBranch } = queryString.parse(
      window.location.search,
    )

    const { Href } = yield select(getIntroducerBranchesLinkSelector)
    let response = yield call(
      driveawayApi.getIntroducerBranches,
      Href.replace('{introducer}', subDomain),
    )

    if (
      preSelectedBranch &&
      response.data &&
      response.data.Branches &&
      response.data.Branches.length > 0
    ) {
      const filtered = response.data.Branches.filter(
        (b) => b.BranchName.toLowerCase() === preSelectedBranch.toLowerCase(),
      )

      if (filtered && filtered.length === 1) {
        response.data.Branches = filtered
      }
    }

    yield put(getIntroducerBranchesSuccess(response.data))
  } catch (e) {
    yield put(getIntroducerBranchesFailure())
  }
}

export default function* introducerSaga() {
  yield takeLatest(GET_INTRODUCER_BRANCHES, getIntroducerBranches)
}
