import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { P2 } from '../HomePage/styles'

export const BranchSelectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
`
export const BranchSelectHeader = styled(P2)`
  margin-top: 16px;
`

export const BranchFilter = styled.input`
  font-family: ${(props) =>
    props.driverDetailsPage
      ? 'freight-sans-pro,sans-serif'
      : 'Charles-Wright, Arial, san-serif'};
  font-size: ${(props) => (props.driverDetailsPage ? '18px' : '24px')};
  color: ${(props) => (props.driverDetailsPage ? '#264694' : '#000')};
  ::placeholder {
    color: #264694;
  }
  letter-spacing: ${(props) => (props.driverDetailsPage ? '0px' : '1px')};
  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: ${(props) => (props.driverDetailsPage ? '10px' : '0px')};
  text-align: ${(props) => (props.driverDetailsPage ? 'left' : 'center')};
  font-weight: ${(props) => (props.driverDetailsPage ? '300' : '500')};
  display: block;
  width: 100%;
  background: #fff;
  border: ${(props) =>
    props.driverDetailsPage ? '1px solid #264694' : '2px solid #264694'};
  border-radius: ${(props) => (props.isActive ? '6px 6px 0 0' : '6px')};
  height: 50px;
  box-sizing: border-box;
  -webkit-appearance: none;
`

export const BranchList = styled.ul`
  z-index: 1;
  list-style: none;
  position: absolute;
  width: 100%;
  background: #fff;
  border: ${(props) =>
    props.driverDetailsPage ? '1px solid #264694' : '2px solid #264694'};
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  top: 100%;
  padding: 0;
  box-sizing: border-box;
  border-top: none;
  ::-webkit-scrollbar {
    width: 10px;
    background-color: #e2e2e2;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a2bc1e;
  }

  ::-webkit-scrollbar-track {
    background-color: #e2e2e2;
  }
`

export const Branch = styled.li`
  cursor: pointer;
  font-size: 18px;
  padding: 4px 0;
  background-color: ${(props) => (props.highlighted ? '#E8F0FF' : 'none')};

  :hover {
    background-color: #e8f0ff;
  }
`

export const BranchDropdownChevron = styled(FontAwesomeIcon)`
  position: absolute;
  top: 15px;
  right: ${(props) => (props.driverDetailsPage ? '5px;' : '15px')};
  width: ${(props) => (props.driverDetailsPage ? '10px !important;' : '20px')};
  height: 20px;
  color: #264694;
  pointer-events: none;
`

export const BranchDropdownSpinner = styled(FontAwesomeIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  color: #264694;
  pointer-events: none;
`
