import {
  REQUEST_ANTI_FORGERY,
  REQUEST_ANTI_FORGERY_SUCCESS,
  REQUEST_ANTI_FORGERY_FAILURE,
  REQUEST_LINKS,
  REQUEST_LINKS_SUCCESS,
  REQUEST_LINKS_FAILURE,
  REQUEST_FEATURE_TOGGLES,
  REQUEST_FEATURE_TOGGLES_SUCCESS,
  REQUEST_FEATURE_TOGGLES_FAILURE,
  CREATE_QUOTE,
  CREATE_QUOTE_SUCCESS,
  CREATE_QUOTE_FAILURE,
  REQUEST_PRICE,
  REQUEST_PRICE_SUCCESS,
  REQUEST_PRICE_FAILURE,
  UPDATE_STEP,
  REQUEST_QUOTE,
  REQUEST_QUOTE_SUCCESS,
  REQUEST_QUOTE_FAILURE,
  IS_LOADING,
  REQUEST_OPEN_SCHEMES,
  REQUEST_OPEN_SCHEMES_SUCCESS,
  REQUEST_OPEN_SCHEMES_FAILURE,
  GET_QUOTE_SUMMARY_SUCCESS,
  SET_QUERY_STRING,
  GET_APP_STARTUP,
  GET_APP_STARTUP_SUCCESS,
  GET_APP_STARTUP_FAILURE,
  ACCEPT_LEGAL_DECLARATIONS,
  UPDATE_CONFUSED_QUICK_QUOTE_OPT_IN,
  REQUEST_CONFUSED_QUICK_QUOTE,
  REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS,
  REQUEST_CONFUSED_QUICK_QUOTE_FAILURE,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS,
  REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE,
} from './constants'

export function isLoading(isLoading) {
  return {
    type: IS_LOADING,
    isLoading,
  }
}

export function requestAntiForgery() {
  return {
    type: REQUEST_ANTI_FORGERY,
  }
}

export function requestAntiForgerySuccess() {
  return {
    type: REQUEST_ANTI_FORGERY_SUCCESS,
  }
}

export function requestAntiForgeryFailure(message) {
  return {
    type: REQUEST_ANTI_FORGERY_FAILURE,
    message,
  }
}

export function requestLinks() {
  return {
    type: REQUEST_LINKS,
  }
}

export function requestLinksSuccess(links) {
  return {
    type: REQUEST_LINKS_SUCCESS,
    links,
  }
}

export function requestLinksFailure(message) {
  return {
    type: REQUEST_LINKS_FAILURE,
    message,
  }
}

export function requestFeatureToggles() {
  return {
    type: REQUEST_FEATURE_TOGGLES,
  }
}

export function requestFeatureTogglesSuccess(featureToggles) {
  return {
    type: REQUEST_FEATURE_TOGGLES_SUCCESS,
    featureToggles,
  }
}

export function requestFeatureTogglesFailure(message) {
  return {
    type: REQUEST_FEATURE_TOGGLES_FAILURE,
    message,
  }
}

export function createQuote() {
  return {
    type: CREATE_QUOTE,
  }
}

export function createQuoteSuccess(quote) {
  return {
    type: CREATE_QUOTE_SUCCESS,
    quote,
  }
}

export function createQuoteFailure(message) {
  return {
    type: CREATE_QUOTE_FAILURE,
    message,
  }
}

export function requestPrice() {
  return {
    type: REQUEST_PRICE,
  }
}

export function requestPriceSuccess(price) {
  return {
    type: REQUEST_PRICE_SUCCESS,
    price,
  }
}

export function requestPriceFailure(message) {
  return {
    type: REQUEST_PRICE_FAILURE,
    message,
  }
}

export function requestQuote(quoteId) {
  return {
    type: REQUEST_QUOTE,
    quoteId,
  }
}

export function requestQuoteSuccess(quote) {
  return {
    type: REQUEST_QUOTE_SUCCESS,
    quote,
  }
}

export function requestQuoteFailure(message) {
  return {
    type: REQUEST_QUOTE_FAILURE,
    message,
  }
}

export function updateStep(step) {
  return {
    type: UPDATE_STEP,
    step,
  }
}

export function requestOpenSchemes() {
  return {
    type: REQUEST_OPEN_SCHEMES,
  }
}
export function requestOpenSchemesSuccess(openSchemes) {
  return {
    type: REQUEST_OPEN_SCHEMES_SUCCESS,
    openSchemes,
  }
}
export function requestOpenSchemesFailure() {
  return {
    type: REQUEST_OPEN_SCHEMES_FAILURE,
  }
}
export function getQuoteSummarySuccess(quoteSummary) {
  return {
    type: GET_QUOTE_SUMMARY_SUCCESS,
    quoteSummary,
  }
}

export function setQueryString(queryString) {
  return {
    type: SET_QUERY_STRING,
    queryString,
  }
}

export function getAppStartup() {
  return {
    type: GET_APP_STARTUP,
  }
}

export function getAppStartupSuccess(startupData) {
  return {
    type: GET_APP_STARTUP_SUCCESS,
    startupData,
  }
}

export function getAppStartupFailure() {
  return {
    type: GET_APP_STARTUP_FAILURE,
  }
}

export function acceptLegalDeclarations() {
  return {
    type: ACCEPT_LEGAL_DECLARATIONS,
  }
}

export const updateConfusedQuickQuoteOptIn = (optIn) => ({
  type: UPDATE_CONFUSED_QUICK_QUOTE_OPT_IN,
  optIn,
})

export const requestConfusedQuickQuote = () => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE,
})

export const requestConfusedQuickQuoteSuccess = (status) => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS,
  status,
})

export const requestConfusedQuickQuoteFailure = () => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_FAILURE,
})

export const requestConfusedQuickQuoteResponse = () => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE,
})

export const requestConfusedQuickQuoteResponseSuccess = (quickQuote) => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS,
  quickQuote,
})

export const requestConfusedQuickQuoteResponseFailure = () => ({
  type: REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE,
})
