/* eslint-disable import/no-anonymous-default-export */
export default {
  tokenData: {},
  links: [],
  features: {},
  step: 0,
  queryString: '',
  affiliateRef: null,
  correlationId: null,
  cameraEnabled: true,
  driveaway: {
    Branches: [],
    SelectedBranch: null,
    IsLoading: false,
  },
  vehicle: {
    searched: {
      VehicleId: null,
      RegistrationNumber: null,
      Make: '',
      Model: '',
      VehicleType: null,
      Abi: null,
      MakeAndModel: null,
      VehicleFound: null,
      VehicleBuilt: null,
      GrossWeight: '',
      YearOfManufacture: null,
      vehicleValue: {
        description: '',
        value: null,
      },
    },
    selected: {
      VehicleId: null,
      RegistrationNumber: null,
      Make: '',
      Model: '',
      VehicleType: null,
      Abi: null,
      MakeAndModel: null,
      vehicleValue: {
        description: '',
        value: null,
      },
    },
    error: false,
    scheme: null,
    makes: [],
    models: [],
    variants: [],
  },
  price: {
    InsurancePremium: 0,
    Ipt: 0,
    AdminFee: 0,
    AffiliateAdminFee: 0,
    TotalPrice: 0,
    excess: 0,
    commission: 0,
    Underwriter: null,
    UnderwriterId: null,
    EncryptedString: null,
    FreeGAPInsuranceCover: {
      FreeGAPInsuranceAvailable: false,
      Free30DayGAPInsuranceIncluded: false,
      FreeGapActivationJourneyIncluded: null,
    },
  },
  riskData: {
    Forename: '',
    Surname: '',
    AddressKey: '',
    DateOfBirth: null,
    Duration: null,
    DurationType: null,
    EmailAddress: '',
    Mobile: '',
    LicenceType: 'FullUkLicence',
    PolicyStartDate: {
      value: null,
      manuallyEdited: false,
    },
    PolicyEndDate: null,
    QuoteId: '',
    LicenceHeldDuration: {
      value: null,
      description: null,
    },
    DrivingLicenceNumber: '',
    PassportLineOne: '',
    PassportLineTwo: '',
    PassportExpiryDate: null,
    Title: '',
    AdditionalDriver: {
      Required: false,
      Title: '',
      Forename: '',
      Surname: '',
      DateOfBirth: null,
      LicenceType: 'FullUkLicence',
    },
  },
  address: {
    Postcode: '',
    SearchResults: [],
    FailedSearch: false,
    AddressLineOne: null,
    AddressLineTwo: null,
    AddressLineThree: null,
    AddressLineFour: null,
    SelectedAddress: null,
  },
  contactUs: {
    FullName: '',
    RegistrationNumber: '',
    PolicyNumber: '',
    MobileNumber: '',
    EmailAddress: '',
    EnquiryType: '',
    CommentBox: '',
    ReCAPTCHA_Token: '',
    isPosting: false,
    error: false,
  },
  validation: {
    riskDataCollectionPage: {
      fields: {
        TitleError: null,
        ForenameError: null,
        SurnameError: null,
        DateOfBirthError: null,
        PostcodeError: null,
        AddressError: null,
        EmailAddressError: null,
        MobileError: null,
        DatePassedTestError: '',
        LicenceHeldDurationError: '',
        DrivingLicenceNumberError: '',
        LicenceTypeError: '',
        PassportLineOneError: '',
        PassportLineTwoError: '',
        PassportExpiryDateError: '',
        PolicyStartDateError: '',
      },
      additionalDrivers: [],
      ValidForm: false,
      ManuallyEdited: false,
      IdentityCheck: '',
      AllFieldsValid: true,
    },
    policyConfirmationPage: {
      ValidForm: false,
    },
    contactUsPage: {
      fields: {
        FullNameError: null,
        RegistrationNumberError: null,
        PolicyNumberError: '',
        MobileNumberError: '',
        EmailAddressError: null,
        EnquiryTypeError: null,
        CommentBoxError: null,
        ReCAPTCHAError: null,
      },
      ValidForm: false,
    },
  },
  legalDeclaration: {
    ShortText: '',
    FullText: '',
    DisallowedOccupations: '',
    PolicyWordingUrl: '',
    InsuranceProductInformationUrl: '',
  },
  policy: {
    PolicyId: '',
    annualQuote: 0,
    annualLink: '',
    GapActivationIncluded: false,
    reasonForCover: {
      value: null,
      description: '',
    },
    PolicyDocuments: [],
    analysisSent: false,
    callbackRequest: {
      status: 0,
      httpCode: 0,
    },
  },
  payment: {
    Secure: {},
    paymentError: '',
    paymentButtonDisabled: false,
    paymentInProgress: false,
  },
  contactInformation: {
    emailError: null,
    mobileNumberError: null,
    emailSent: null,
  },
  isLoading: true,
  showLiveChat: true,
  formButtons: {
    driverDetails: {
      disabled: false,
    },
    driverLicence: {
      disabled: false,
    },
    legalDeclaration: {
      disabled: false,
    },
    paymentButton: {
      disabled: false,
    },
    addressSearch: {
      disabled: false,
    },
  },
  scheme: {
    open: [],
    selected: '',
  },
  appData: {
    TrustPilot: {
      Reviews: 15550,
      Stars: 4.5,
      Score: 4.6,
    },
    Affiliate: {
      IntroducerName: null,
      AffiliateType: null,
      AffiliateName: null,
      FcaNumber: null,
      AffiliateRef: null,
    },
    HomePagePricing: {
      Price: 0,
      MultiplePricing: false,
      PolicyDuration: 0,
    },
    Eligibility: {
      MinAge: null,
      MaxAge: null,
      MinVehicleValue: null,
      MaxVehicleValue: null,
      MaxVehicleAge: null,
    },
    PartnerPays: null,
    AnnualProviderId: null,
    BranchesDrivenByStocklist: false,
    CanFutureDatePolicies: null,
    EnableMarketingPreferences: false,
    IsLoading: true,
    Error: false,
  },
  marketingPreferences: {
    loaded: false,
    error: false,
    EmailAddressOptIn: false,
    TelephoneNumberOptIn: false,
  },
  confusedQuickQuote: {
    OptIn: null,
    RequestAccepted: null,
    RequestedDateTime: 0,
    Pricing: {},
    PricingLoaded: false,
    PricingLoadAttempts: 0,
  },
  upsellEnabled: false,
}
