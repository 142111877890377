import styled from 'styled-components'
import { Button } from '../../components/common/Button'

export const Wrapper = styled.div`
  border: 1px solid ${(props) => props.styleOverride.highlightColor};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  text-align: center;
  padding: 0 10px;
  margin: 0 10px;
`
export const ShiftBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`

export const AnnualInsurance = styled.span`
  color: ${(props) => props.styleOverride.highlightColor};
`

export const AnnuaLInsuranceLogo = styled.img`
  width: 50%;
  max-width: 200px;
  display: block;
  margin: 0 auto;
`

export const RequestCallbackButton = styled(Button)`
  background-color: #219e3e;
  display: block;
  margin: 10px auto 0 auto;
  padding: 10px 40px;
  height: auto;
`

export const AnnualInsuranceHeaderWrapper = styled.div`
  background-color: #fff;
  margin: 0 -10px;

  @media screen and (max-width: 768px) {
    background-color: ${(props) => props.styleOverride.backgroundColor};
  }
`

export const AnnualInsuranceHeader = styled.h2`
  color: ${(props) => props.styleOverride.highlightColor};

  @media screen and (max-width: 768px) {
    color: ${(props) => props.styleOverride.highlightColor};
    margin: 0;
    padding: 15px 0;
  }
`

export const ConfirmationWrapper = styled.div`
  border: 1px solid ${(props) => props.styleOverride.highlightColor};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  text-align: center;
  padding: 0 10px;
  margin: 5% 10px 0 5%;
  @media screen and (max-width: 768px) {
    margin: 5% 10px 0 5%;
  }
`
export const EmailConfirmationWrapper = styled.div`
  border: 1px solid #264694;
  border-radius: 6px;
  text-align: center;
  padding: 0 10px;
  margin: 5% 10px 0 5%;
  @media screen and (max-width: 768px) {
    margin: 5% 10px 0 5%;
  }
`

export const AnnualInsuranceConfirmationHeaderWrapper = styled.div`
  margin: 0 -10px;
`

export const AnnualInsuranceConfirmationHeader = styled.h2`
  color: ${(props) => props.styleOverride.highlightColor};
  font-size: 35px @media screen and (max-width: 768px) {
    color: ${(props) => props.styleOverride.highlightColor};
    margin: 0;
    padding: 15px 0;
  }
`
export const AnnualInsuranceText = styled.p`
  font-size: 22px;
`
