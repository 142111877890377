import logo from '../images/TempcoverBrandLogo.png'
var primaryColour = '#264B94'
const defaultTheme = {
  logo: logo,
  primaryColour: primaryColour,
  primaryTextColour: primaryColour,
  headlineColour: primaryColour,
  backgroundColour: '#E8F0FF',
  formControlBorderRadius: '6px',
  formControlHorizontalPadding: '10px',
  formControlBottomMargin: '10px',
  generalFontSize: '18px',
  smallerFontSize: '18px',
  formControlHeight: '45px',
  formControlValidIcon: {
    colour: '#fff',
    backgroundColour: primaryColour,
  },
  fontFamily: 'freight-sans-pro, sans-serif',
  marketingPreferencesColour: '#264B94',
  fontStyle: 'normal',
  fontWeight: '300',
  labelFontSize: '14px',
  mobileLabelFontSize: '10px',
  labelTextColour: '#000000',
  headerFontFamily: 'BrandonText-Bold',
  headerFontColour: primaryColour,
  labelFontFamily: 'BrandonText',
  disabledButtonColour: '#d2d2d2',
  buttonFontSize: '14px',
  buttonTextTransform: 'uppercase',
  buttonTextColour: '#FFFFFF',
  buttonTextAlign: 'center',
  linkFontSize: '12px',
  errorBackgroundColour: '#FFEDE8',
  infoBackgroundColour: '#5bc0de',
  errorBorderProperties: '2px solid #EF5831',
  infoBorderProperties: '2px solid #264694',
  errorIconColour: '#EF5831',
  infoIconColour: primaryColour,
  messageBorderRadius: '6px',
  regFont: 'Charles-Wright',
  validIcon: '\f00c',
  secondaryColour: '#A2BC1E',
  successColour: '#A2BC1E',
  toggleActiveColour: '#080',
  inputBorderColour: '#264b94',
  formFieldBackgroundColour: '#FFFFFF',
  button: {
    backgroundColour: primaryColour,
    disabledBackground: '#d2d2d2',
    disabledFont: '#fff',
    fontSize: '14px',
    textTransform: 'uppercase',
    textColour: '#FFFFFF',
    textAlign: 'center',
    hover: 'none',
    hoverColour: '#0e2a6e',
  },
  secondaryButton: {
    hoverColour: primaryColour,
  },
  progressionStep: {
    titleDesktopFontSize: '20px',
    titleFontSize: '18px',
    contentDesktopFontSize: '14px',
    contentFontSize: '12px',
    subContentFontSize: '10px',
    subContentExtraSmallFontSize: '8px',
    subContentIconDesktopSize: '15px',
    subContentIconSize: '15px',
  },
  GbIcon: '#fff',
  icon: {
    color: primaryColour,
    backgroundColor: '#E9F0FF',
  },
  staticHeaderColour: '#4658a3',
  callUsNumberColour: '#a4c733',
  breadcrumb: {
    activeColor: '#ffffff',
    activeBorderColor: '#264694',
    defaultColor: '#ffffff',
    defaultBorderColor: '#CCCCCC',
    defaultBarColor: '#CCCCCC',
    completeColor: '#264694',
    completeBorderColor: '#264694',
    completeBarColor: '#264694',
    circleSize: 20,
    circleMarginTop: 10,
  },
  spinner: {
    primaryColour: primaryColour,
    textColour: primaryColour,
  },
  drivingLicence: {
    primaryColour: primaryColour,
  },
  durationOptions: {
    primaryColour: primaryColour,
    secondaryColour: '#A2BC1E',
  },
  header: {
    background: primaryColour,
    borderBottom: 'none',
  },
  iconBackground: '#E9F0FF',
  footerInfoBackground: '#0e2a6e',
  mobileHamburgerMenu: '#0e2a6e',
  headerWrapperColour: primaryColour,
  footerWrapperBackground: '#264694',
  footerTextColour: '#a2bc1e',
  documentWrapperBackground: '#ccdcff',
  cookieTextColour: primaryColour,
  headerLinkStyled: {
    padding: '0px',
    hover: {
      textDecoration: 'underline',
      color: '#fff',
      backgroundColour: primaryColour,
      padding: '0px',
    },
  },
  gradient: {
    background: '#E8F0FF',
  },
}

export default defaultTheme
