import styled from 'styled-components'
import React from 'react'
import { BaseInput } from './BaseInput'
import { FormControlWrapper } from '../../FormControlWrapper'
import { ErrorMessage } from '../ErrorMessage'

export const UppercaseInputStyled = styled(BaseInput)`
  text-transform: uppercase;
  ::placeholder {
    text-transform: none;
  }
`

export const UppercaseInput = ({
  disallowSpaces,
  error,
  errorMessage,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  pattern,
  placeholder,
  style,
  type,
  value,
  ariaLabel,
}) => {
  const onInput = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase()
    if (disallowSpaces) {
      e.target.value = e.target.value.replace(/\s/g, '')
    }
  }

  return (
    <FormControlWrapper smallGap>
      {error && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
      <BaseInput
        placeholder={placeholder}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onInput={onInput}
        name={name}
        id={id}
        value={value}
        type={type}
        style={style}
        autoComplete='off'
        autoCorrect='off'
        pattern={pattern}
        aria-label={ariaLabel}
      />
    </FormControlWrapper>
  )
}
