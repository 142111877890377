import React from 'react'
import { Grid } from 'unsemantic'

import { H1, H2, P, Italic, LI } from './styles'

const HomeDeliveryEligibilityPage = ({
  appData: {
    Eligibility,
    HomePagePricing,
    Affiliate: { IntroducerName },
  },
}) => {
  const { MinAge, MaxAge, MinVehicleValue, MaxVehicleValue, MaxVehicleAge } =
    Eligibility

  return (
    <Grid desktop='100' mobile='100' tablet='100'>
      <H1>Eligibility</H1>
      <H2>Who we cover</H2>
      <P>
        We cover drivers who are buying a new vehicle and would like a few days
        of cover before arranging an annual policy.
      </P>
      <P>
        Policies are {HomePagePricing.PolicyDuration} days in duration and cover
        is fully comprehensive.
      </P>
      <H2>Eligibility criteria</H2>
      <ul>
        <LI>
          You must have purchased a vehicle from {IntroducerName} for
          {IntroducerName === 'Cazoo' || IntroducerName === 'cinch'
            ? ' collection or home delivery'
            : ' home delivery'}
          .
        </LI>
        <LI>
          You and any additional driver must be aged between {MinAge} and{' '}
          {MaxAge} years old.
        </LI>
        {IntroducerName === 'Cazoo' && (
          <LI>
            You and any additional driver must hold a full current and valid UK
            or EU driving licence for a minimum of 12 months.
          </LI>
        )}
        {IntroducerName === 'cinch' && (
          <>
            <LI>
              You and any additional driver must have held a current and valid
              Full UK or EU driving licence for a minimum of 1 year and are not
              currently disqualified from driving.
            </LI>
            <LI>
              You and any additional driver must have been a UK resident for at
              least the last 3 Years.
            </LI>
          </>
        )}
        {IntroducerName !== 'cinch' && IntroducerName !== 'Cazoo' && (
          <LI>
            You and any additional driver must hold a full current and valid UK
            or EU driving licence.
          </LI>
        )}
        <LI>
          The minimum vehicle value is {MinVehicleValue} and the maximum is{' '}
          {MaxVehicleValue}. The valuation is based on the price at which the
          vehicle has been sold to you.
        </LI>
        <LI>
          The vehicle must be {MaxVehicleAge} years old or less from the date of
          its first registration.
        </LI>
        <LI>
          If adding an additional driver, they must be your Spouse or Common Law
          Partner and you must both live at the same address.
        </LI>
      </ul>
      <Italic>
        Please ensure you pay close attention to the Proposer’s Declaration for
        the full eligibility criteria and any exclusions.
      </Italic>
    </Grid>
  )
}

export default HomeDeliveryEligibilityPage
