import { call, put, select, takeLatest } from 'redux-saga/effects'
import { UPDATE_QUOTE } from '../constants'

import quoteApi from '../../../api/quoteApi'

import {
  confirmRiskCollectionPage,
  enableAllButtons,
  updateQuoteFailure,
  updateQuoteSuccess,
  updateRiskData,
} from '../actions'

import { history } from '../../..'
import { getGapActivationIncluded } from '../../../components/GapModal/selector'
import { ANNUAL_INSURANCE_PROVIDER_ID } from '../../../constants'
import { SYSTEM_FEATURES } from '../../../services/constants'
import { requestConfusedQuickQuote } from '../../App/actions'
import { requestPrice } from '../../App/sagas/priceSaga'
import { getAppData } from '../../App/sagas/select'
import { getConfusedQuickQuote, getSystemFeatures } from '../../App/selector'
import { getDriveawayInfo } from '../../BranchSelect/selector'
import { getRiskData, getVehicle, updateQuoteLinkSelector } from '../selector'

export function* updateRiskDataSaga(action) {
  let riskData
  if (action.payload.riskData) {
    riskData = action.payload.riskData
    const { value, date, hour, minute, manuallyEdited } =
      riskData.PolicyStartDate
    riskData.PolicyStartDate = {
      manuallyEdited: manuallyEdited,
      value: value || `${date}T${hour}:${minute}`,
    }
    yield put(updateRiskData(riskData))
  } else {
    riskData = yield select(getRiskData)
  }
  try {
    const updateQuoteLink = yield select(updateQuoteLinkSelector)
    const vehicleInfo = yield select(getVehicle)
    const driveawayInfo = yield select(getDriveawayInfo)
    const freeGapActivationLinkIncluded = yield select(getGapActivationIncluded)

    const { OptIn } = yield select(getConfusedQuickQuote)

    const quoteData = {
      Title: riskData.Title,
      Forename: riskData.Forename,
      Surname: riskData.Surname,
      AddressKey: riskData.AddressKey,
      VehicleAbi: vehicleInfo.selected.Abi,
      Duration: riskData.Duration,
      Telephone: riskData.Mobile,
      Email: riskData.EmailAddress,
      DateOfBirth: riskData.DateOfBirth,
      VehicleId: vehicleInfo.selected.VehicleId,
      DurationType: riskData.DurationType,
      LicenceType: riskData.LicenceType,
      LicenceHeldDuration: 'NotSet',
      QuoteId: {
        Value: riskData.QuoteId,
      },
      BranchId:
        (driveawayInfo &&
          driveawayInfo.SelectedBranch &&
          driveawayInfo.SelectedBranch.BranchId) ||
        null,
      ConfusedQuickQuoteOptIn: OptIn,
      GapActivationIncluded: freeGapActivationLinkIncluded,
    }
    if (riskData.PolicyStartDate.manuallyEdited) {
      quoteData.PolicyStartDate = riskData.PolicyStartDate.value
      quoteData.UserSelectedPolicyStartDate = true
    }
    if (riskData.AdditionalDriver.Required) {
      quoteData.AdditionalDrivers = [
        {
          ...riskData.AdditionalDriver,
          Required: undefined,
        },
      ]
    }

    const partialValidation = action ? action.payload.partialValidation : true

    const quote = yield call(
      quoteApi.updateQuote,
      updateQuoteLink.Href,
      quoteData,
      partialValidation,
    )
    yield put(updateQuoteSuccess(quote.data))
    if (action) {
      if (action.payload.getPrice) {
        yield* requestPrice()
      }
      if (action.payload.requestConfusedQuickQuote) {
        var features = yield select(getSystemFeatures)
        var { AnnualInsuranceProvider } = yield select(getAppData)
        if (
          OptIn &&
          AnnualInsuranceProvider ===
            ANNUAL_INSURANCE_PROVIDER_ID.ConfusedRTP &&
          features[SYSTEM_FEATURES.UPSELL_CONFUSED_ENABLED]
        ) {
          yield put(requestConfusedQuickQuote())
        }
      }
      if (action.payload.confirmRiskData) {
        yield put(confirmRiskCollectionPage(action.payload.callback))
      }
      if (action.payload.requestConfusedQuickQuote) {
        if (OptIn) {
          yield put(requestConfusedQuickQuote())
        }
      }
      if (action.payload.callback) {
        action.payload.callback()
      }
    }
  } catch (e) {
    yield put(updateQuoteFailure(e.message))
    history.push(`/quote/driver-details?q=${riskData.QuoteId}`)
    yield put(enableAllButtons())
  }
}

export default function* fetchRiskDataSaga() {
  yield takeLatest(UPDATE_QUOTE, updateRiskDataSaga)
}
