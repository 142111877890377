import styled from 'styled-components'
import { Button } from '../../../../components/common/Button'
import { H2 } from '../../../../components/common/H2'

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  display: grid;
  grid-template-rows: auto;
  color: #ffffff;

  @media screen and (max-height: 750px) {
    position: static;
  }

  @media screen and (min-height: 370px) and (max-height: 375px) and (min-width: 800px) {
    // iphone X landscape adjustment (otherwise its something like 96px)
    margin-top: -116px;
  }
`

export const HeaderRowContainer = styled.div`
  display: grid;
  grid-template-columns: auto minmax(auto, 1200px) auto;
`
export const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: 20% minmax(auto, 293px) minmax(auto, 550px) auto;

  @media (max-width: 1024px) {
    grid-template-columns: 10% minmax(150px, 293px) minmax(150px, 550px) auto;
  }

  @media (max-width: 768px) {
    grid-template-columns: 10px minmax(150px, 293px) minmax(150px, 550px) auto;
  }
`
export const TextRow = styled.div`
  display: grid;
  grid-template-columns: minmax(10px, 1fr) minmax(auto, 524px) minmax(10px, 1fr);
  padding-top: 40px;
  background-color: #fff;
  background-image: linear-gradient(170deg, #0e2a6e 50%, #264694 0px);

  @media screen and (min-width: 320px) {
    padding-top: calc(40px + 20 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
    padding-top: 60px;
  }
`

export const ImageRow = styled.div`
  display: grid;
  grid-template-columns: minmax(10px, 1fr) minmax(auto, 524px) minmax(10px, 1fr);
  margin: 15px 0;
`

export const FlowCarImage = styled.img`
  width: 100%;
  max-width: 550px;
  position: relative;
  top: 50px;
  display: block;
  margin: 0 auto;
  min-height: 104px;
  height: 100%;
  object-position: left;
  object-fit: none;

  @media screen and (min-width: 320px) {
    top: calc(50px + 30 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
    top: 80px;
  }

  @media screen and (max-width: 980px) {
    object-fit: cover;
  }
`

export const FlowCarImageWrapper = styled.div``

export const ContentText = styled.p`
  font-size: 16px;

  @media screen and (min-width: 320px) {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
    font-size: 22px;
  }
`

export const ComplianceText = styled.p`
  font-size: 12px;
  max-width: 400px;
  margin: 14px auto;

  @media screen and (min-width: 320px) {
    font-size: calc(12px + 2 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
    font-size: 14px;
  }
`

export const PoweredByText = styled(ContentText)`
  font-weight: bold;
  display: inline-block;
  margin: 0;
  margin-right: 15px;
`

export const Header = styled(H2)`
  width: 100%;
  position: relative;
  text-align: left;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 15px;

  @media screen and (min-width: 320px) {
    font-size: calc(22px + 20 * ((100vw - 320px) / 680));
    margin-top: calc(0px + 40 * ((100vw - 320px) / 680));
  }
  @media screen and (min-width: 1000px) {
    font-size: 42px;
    margin-top: 40px;
  }
`

export const GetAQuoteButton = styled(Button)`
  background-color: #a2bc1e;
  width: 80%;
  max-width: 400%;
  margin: auto;
  display: block;
  height: 50px;
  color: #ffffff;

  :active {
    background-color: #6f8900;
  }

  :hover {
    background-color: #6f8900;
  }
`

export const FlowLogo = styled.img`
  max-height: 38px;
  max-width: 30%;
`

export const LVLogo = styled.img`
  height: 68px;
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

export const HeaderWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 20vh auto auto auto;
`

export const RedirectingHeader = styled(Header)`
  margin: 0;
  text-align: center;
`

export const RedirectingErrorText = styled(ContentText)`
  color: #264694;
`

export const Spinner = styled.span`
  font-size: 32px;
  color: #264694;
  text-align: center;
`

export const FooterWrapper = styled(Wrapper)`
  margin-top: -66px;
`

export const EmailConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  flex: 1 0 auto;
`

export const HR = styled.hr`
  margin-bottom: 20px;
`
