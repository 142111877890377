import {
  GET_MARKETING_PREFERENCES_URL,
  SAVE_MARKETING_PREFERENCES_URL
} from './constants'

export const getEmail = (state) => state.riskData.EmailAddress
export const getTelephoneNumber = (state) => state.riskData.Mobile
export const getEmailOptIn = (state) =>
  state.marketingPreferences.EmailAddressOptIn

export const getMarketingPreferenceLink = (state) =>
  state.links.filter((link) => link.Rel === GET_MARKETING_PREFERENCES_URL)[0]
export const saveMarketingPreferenceLink = (state) =>
  state.links.filter(
    (link) => link.Rel === SAVE_MARKETING_PREFERENCES_URL,
  )[0]
