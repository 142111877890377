import styled from 'styled-components'
import PropTypes from 'prop-types'

export const Button = styled.button`
  background: ${(props) =>
    props.disabled
      ? props.theme.disabledButtonColour
      : props.theme.primaryColour};
  color: ${(props) => props.theme.buttonTextColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  font-size: ${(props) => props.theme.buttonFontSize};
  text-transform: ${(props) => props.theme.buttonTextTransform};
  text-align: ${(props) => props.theme.buttonTextAlign};
  box-shadow: none;
  border: none;
  outline: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: ${(props) => props.theme.formControlHeight};
  height: ${(props) => props.theme.formControlHeight};
  :not([disabled]):hover {
    background-color: ${(props) => props.theme.secondaryButton.hoverColour};
  }
`

Button.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}
