import React from 'react'
import validator from 'validator'
import { ErrorMessage } from '../common/ErrorMessage'
import { UppercaseInput } from '../common/Input/UppercaseInput'
import { FormControlWrapper } from '../FormControlWrapper'
import { FormControlValidIcon } from '../FormControlValidIcon'

export class PassportLineTwo extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (event) => {
    this.handleValidation(event)
    this.props.onChange(event)
  }

  handleValidation = (event) => {
    let message = validator.matches(
      event.target.value.toUpperCase(),
      /^([0-9]{10})([G|g][B|b][R|r])([0-9]{7})([Mm|Ff<])([0-9]{7})(([0-9<]){16})$/,
    )
      ? ''
      : 'Please ensure your passport line two is valid'

    this.props.handleValidation(event.target.name, message)
  }
  render() {
    return (
      <>
        <FormControlWrapper>
          <FormControlValidIcon
            error={this.props.error}
            className='fas fa-check'
          ></FormControlValidIcon>
          <UppercaseInput
            placeholder='and the second line of my passport says...'
            onChange={this.handleChange}
            name='PassportLineTwo'
            error={this.props.error}
            value={this.props.value}
          />
        </FormControlWrapper>
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </>
    )
  }
}
