import { call, put, select } from 'redux-saga/effects'

import { requestLegalDeclarationSuccess } from './actions'
import {
  requestLegalDeclarationLink,
  getRiskData,
  getPriceData,
} from './selector'
import { history } from '../..'
import underwriterApi from '../../api/underwriterApi'
import { enableAllButtons } from '../../containers/RiskDataCollectionPage/actions'

export default function* legalDeclarationSaga() {
  const legalDeclarationLinkSelect = yield select(requestLegalDeclarationLink)
  const riskData = yield select(getRiskData)
  yield select(getPriceData)
  let legalDeclarationLink = legalDeclarationLinkSelect.Href

  const legalDeclaration = yield call(
    underwriterApi.getLegalDeclaration,
    legalDeclarationLink,
  )
  yield put(requestLegalDeclarationSuccess(legalDeclaration.data))
  history.push(`/quote/legal-declaration?q=${riskData.QuoteId}`) //TODO Need to fix up declartion routing, too many things going on prior to getting here
  yield put(enableAllButtons())
}
