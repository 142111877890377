import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Camera } from '../../components/Camera'
import { requestOcr, allowCamera } from './actions'
import LoadingScreen from '../../components/LoadingScreen'
import {
  LicenceMask,
  LicenceCameraWrapper,
  LicenceCameraButton,
  LicenceCameraHeader,
  ZoomingLicenceGuide,
} from './styles'

import { CameraAccessModal } from '../../components/CameraAccessModal'
import { CloseButtonDivStyled } from '../../components/common/NewModal/Modal.styles'

export class DrivingLicenceCamera extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.camera = React.createRef()
    this.onUserMediaError = this.onUserMediaError.bind(this)
    this.cameraAccessModalRef = React.createRef()
    this.capture = this.capture.bind(this)
    this.handlePhotos = this.handlePhotos.bind(this)
  }
  componentDidMount() {
    if (this.props.cameraAllowed) {
      this.cameraAccessModalRef.instance.show()
    }
  }
  capture() {
    this.setState({
      loading: true,
      cameraVisible: false,
    })
    let images = []
    this.handlePhotos((data) => {
      const image = data.replace('data:image/jpeg;base64,', '')
      images.push(image)
      this.props.actions.allowCamera(true)
      this.props.actions.requestOcr(images, 'Front')
    })
  }

  // handlePhotos() {
  //   const imageSrc = this.camera.current.getScreenshot();
  //   var scannedImage = new Image();
  //   scannedImage.src = imageSrc;
  //   return jimp.read(imageSrc).then(image => {
  //     return image
  //       .quality(60) // set JPEG quality
  //       .resize(image.getWidth() * 0.5, image.getHeight() * 0.5)
  //   })
  // }

  handlePhotos(callback) {
    const imageSrc = this.camera.current.getScreenshot()
    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')

    var image = new Image()

    image.onload = function (e) {
      canvas.width = image.width * 0.5 // target width
      canvas.height = image.height * 0.5 // target height
      ctx.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        0,
        0,
        canvas.width,
        canvas.height,
      )
      // create a new base64 encoding
      var resampledImage = new Image()
      resampledImage.src = canvas.toDataURL('image/jpeg', 0.6)
      callback(resampledImage.src)
    }
    image.src = imageSrc
  }

  closeAccessModal = () => {
    this.cameraAccessModalRef.instance.hide()
    this.setState({
      cameraVisible: true,
    })
  }

  onUserMediaError = () => {
    this.props.actions.allowCamera(false)
    this.props.history.push(`/quote/driver-details?q=${this.props.quoteId}`)
  }

  closeModal = () => {
    this.props.history.push(`/quote/select-path?q=${this.props.quoteId}`)
  }

  render() {
    const videoConstraints = {
      facingMode: 'environment',
    }

    return (
      <>
        {this.state.loading && <LoadingScreen />}
        {!this.state.loading && (
          <>
            <CameraAccessModal
              cameraAccessRef={(el) => (this.cameraAccessModalRef = el)}
              handleClick={this.closeAccessModal}
              closeModal={this.closeAccessModal}
            />
            <LicenceCameraWrapper>
              <LicenceCameraHeader>
                Please scan the text on the front of your licence
              </LicenceCameraHeader>
              <Camera
                cameraRef={this.camera}
                videoConstraints={videoConstraints}
                onUserMediaError={this.onUserMediaError}
                minHeight={1440}
                minWidth={2560}
              />
              <CloseButtonDivStyled onClick={this.closeModal}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='11.243'
                  height='11.243'
                  viewBox='0 0 11.243 11.243'
                >
                  <g
                    id='Group_45'
                    data-name='Group 45'
                    transform='translate(1157.121 -1076.828)'
                  >
                    <line
                      id='Line_19'
                      data-name='Line 19'
                      x2='7'
                      y2='7'
                      transform='translate(-1155 1078.949)'
                      fill='none'
                      stroke='#fff'
                      stroke-linecap='round'
                      stroke-width='3'
                    />
                    <line
                      id='Line_20'
                      data-name='Line 20'
                      x1='7'
                      y2='7'
                      transform='translate(-1155 1078.949)'
                      fill='none'
                      stroke='#fff'
                      stroke-linecap='round'
                      stroke-width='3'
                    />
                  </g>
                </svg>
              </CloseButtonDivStyled>
              <LicenceMask>
                {this.state.cameraVisible && (
                  <ZoomingLicenceGuide>
                    <div class='uk-icon'>UK</div>
                    <i class='fas fa-user-alt'></i>
                    <span class='driving-licence-header'>DRIVING LICENCE</span>
                    <div>
                      <span class='licence-text'>1.</span>
                      <span class='licence-text'>2.</span>
                      <span class='licence-text licence-text-top-margin-full'>
                        3.
                      </span>
                      <span class='licence-text'>4a.</span>
                      <span class='licence-text'>4b.</span>
                      <span class='licence-text'>5.</span>
                      <span class='licence-text licence-text-top-margin-half'>
                        7.
                      </span>
                      <span class='licence-text licence-text-top-margin-full'>
                        8.
                      </span>
                      <span class='licence-text licence-text-top-margin-full'>
                        9.
                      </span>
                    </div>
                  </ZoomingLicenceGuide>
                )}
              </LicenceMask>
              {this.state.cameraVisible && (
                <LicenceCameraButton
                  id='TeslaScanLicenceNextButton'
                  onClick={this.capture}
                >
                  Scan
                </LicenceCameraButton>
              )}
            </LicenceCameraWrapper>
          </>
        )}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    correlationId: state.correlation,
    quoteId: state.riskData.QuoteId,
    cameraAllowed: state.camera,
  }
}

export const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestOcr,
        allowCamera,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps,
)(DrivingLicenceCamera)
