import {
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE,
  CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD,
  SAVE_MARKETING_PREFERENCES_OPT_IN_STATE,
  RESET_MARKETING_PREFERENCES_OPT_IN_STATE,
} from './constants'

import { call, put, takeLatest, select } from 'redux-saga/effects'

import {
  getEmail,
  getMarketingPreferenceLink,
  getTelephoneNumber,
  saveMarketingPreferenceLink,
} from './selector'
import {
  checkMarketingOptInStateSuccess,
  checkMarketingOptInStateFailure,
  saveMarketingPreferencesSuccess,
  saveMarketingPreferencesFailure,
  resetMarketingPreferences,
} from './actions'
import { enableAllButtons } from '../RiskDataCollectionPage/actions'
import marketingPreferencesApi from '../../api/marketingPreferencesApi'
import { getQuoteId } from '../RiskDataCollectionPage/selector'

function* checkMarketingOptInState() {
  const email = yield select(getEmail)
  const telephoneNumber = yield select(getTelephoneNumber)
  if (email && telephoneNumber) {
    yield checkMarketingOptInStateAndForward({
      payload: { email, telephoneNumber },
    })
  }
}

function* checkMarketingOptInStateAndForward(action) {
  const {
    payload: { email, telephoneNumber },
  } = action

  if (email) {
    var url = (yield select(getMarketingPreferenceLink)).Href.replace(
      /{emailAddress}/,
      email,
    ).replace(/{telephoneNumber}/, telephoneNumber)
    try {
      const response = yield call(marketingPreferencesApi.getState, url)

      yield put(checkMarketingOptInStateSuccess({ ...response.data }))
      yield put(enableAllButtons())
    } catch (e) {
      yield put(checkMarketingOptInStateFailure(e.message))
    }
  }
}

function* resetMarketingPreferencesState() {
  yield put(resetMarketingPreferences)
}

export function* saveMarketingPreferences(marketingPreferences) {
  const {
    email,
    emailOptIn,
    telephoneNumber,
    telephoneNumberOptIn,
    affiliateId,
  } = marketingPreferences
  const quoteId = yield select(getQuoteId)

  const url = (yield select(saveMarketingPreferenceLink)).Href

  // TODO: Finalise Marketing Preferences Payload
  const data = {
    EncryptedQuoteId: quoteId,
    EmailAddress: email,
    EmailAddressOptIn: emailOptIn,
    TelephoneNumber: telephoneNumber,
    TelephoneNumberOptIn: telephoneNumberOptIn,
    AffiliateRef: affiliateId,
  }

  try {
    yield call(marketingPreferencesApi.update, url, data)

    yield put(saveMarketingPreferencesSuccess(data))
  } catch (e) {
    yield put(saveMarketingPreferencesFailure(e.message))
  }
}

export default function* marketingPreferencesSaga() {
  yield takeLatest(
    CHECK_MARKETING_PREFERENCES_OPT_IN_STATE,
    checkMarketingOptInState,
  )
  yield takeLatest(
    CHECK_MARKETING_PREFERENCES_OPT_IN_STATE_AND_FORWARD,
    checkMarketingOptInStateAndForward,
  )
  yield takeLatest(
    RESET_MARKETING_PREFERENCES_OPT_IN_STATE,
    resetMarketingPreferencesState,
  )
  yield takeLatest(
    SAVE_MARKETING_PREFERENCES_OPT_IN_STATE,
    saveMarketingPreferences,
  )
}
