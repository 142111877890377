export const REQUEST_VEHICLE = 'src/VehicleInformation/REQUEST_VEHICLE'

export const REQUEST_VEHICLE_SUCCESS =
  'src/VehicleInformation/REQUEST_VEHICLE_SUCCESS'
export const REQUEST_VEHICLE_FAILED =
  'src/VehicleInformation/REQUEST_VEHICLE_FAILED'

export const REQUEST_VEHICLE_LINK_REL =
  'VehicleLookupByRegistrationAndIntroducer'

export const UPDATE_SEARCHED_VEHICLE =
  'src/VehicleInformation/UPDATE_SEARCHED_VEHICLE'
export const SET_VEHICLE = 'src/VehicleInformation/SET_VEHICLE'
export const SET_VEHICLE_LISTENER =
  'src/VehicleInformation/SET_VEHICLE_LISTENER'
export const SET_VEHICLE_TYPE = 'src/VehicleInformation/SET_VEHICLE_TYPE'

export const GET_MAKES = 'src/VehicleInformation/GET_MAKES'
export const GET_MAKES_SUCCESS = 'src/VehicleInformation/GET_MAKES_SUCCESS'
export const GET_MAKES_FAILURE = 'src/VehicleInformation/GET_MAKES_FAILURE'
export const GET_MAKES_REL = 'VehicleMakes'

export const GET_MODELS = 'src/VehicleInformation/GET_MODELS'
export const GET_MODELS_SUCCESS = 'src/VehicleInformation/GET_MODELS_SUCCESS'
export const GET_MODELS_FAILURE = 'src/VehicleInformation/GET_MODELS_FAILURE'
export const GET_MODELS_REL = 'GetModels'

export const GET_VARIANTS = 'src/VehicleInformation/GET_VARIANTS'
export const GET_VARIANTS_SUCCESS =
  'src/VehicleInformation/GET_VARIANTS_SUCCESS'
export const GET_VARIANTS_FAILURE =
  'src/VehicleInformation/GET_VARIANTS_FAILURE'
export const GET_VARIANTS_REL = 'VehicleVariantByMakeAndModel'

export const CREATE_UNKNOWN_VEHICLE =
  'src/VehicleInformation/CREATE_UNKNOWN_VEHICLE'
export const CREATE_UNKNOWN_VEHICLE_SUCCESS =
  'src/VehicleInformation/CREATE_UNKNOWN_VEHICLE_SUCCESS'
export const CREATE_UNKNOWN_VEHICLE_FAILURE =
  'src/VehicleInformation/CREATE_UNKNOWN_VEHICLE_FAILURE'
export const CREATE_UNKNOWN_VEHICLE_REL = 'BuildVehicleManually'
export const CREATE_UNKNOWN_COMMERCIAL_VEHICLE_REL =
  'BuildCommercialVehicleManually'

export const UNKNOWN_VEHICLE_REG = 'src/VehicleInformation/UNKNOWN_VEHICLE_REG'
export const UNKNOWN_VEHICLE_REG_SUCCESS =
  'src/VehicleInformation/UNKNOWN_VEHICLE_REG_SUCCESS'
export const UNKNOWN_VEHICLE_REG_FAILURE =
  'src/VehicleInformation/UNKNOWN_VEHICLE_REG_FAILURE'

export const COMMERCIAL_VEHICLE = 'CommercialVehicle'
export const PRIVATE_CAR = 'PrivateCar'

export const SET_VEHICLE_SEARCHED_REG =
  'src/VehicleInformation/SET_VEHICLE_SEARCHED_REG'
export const SET_VEHICLE_ERROR = 'src/VehicleInformation/SET_VEHICLE_ERROR'

export const UPDATE_VEHICLE_VALUE =
  'src/VehicleInformation/UPDATE_VEHICLE_VALUE'
export const UPDATE_GROSS_WEIGHT = 'src/Van/UPDATE_GROSS_WEIGHT'
export const UPDATE_YEAR_OF_MANUFACTURE = 'src/Van/UPDATE_YEAR_OF_MANUFACTURE'

export const VEHICLE_TYPE_CHANGE = 'src/VehicleInformation/VEHICLE_TYPE_CHANGE'

export const COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT = 3500
