import http from '../api/http'

export default class vehicleApi {
  static getVehicle(endpoint) {
    return http.get(endpoint)
  }

  static getMakes(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept: 'application/tempcover.MakeRepresentation+json',
      },
    })
  }

  static getModels(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept: 'application/tempcover.ModelRepresentation+json',
      },
    })
  }

  static getVariants(endpoint) {
    return http.get(endpoint, {
      headers: {
        Accept: 'application/tempcover.VariantResultRepresentation+json',
      },
    })
  }

  static createUnknownVehicle(endpoint, unknownVehicle) {
    return http.post(endpoint, unknownVehicle)
  }
}
