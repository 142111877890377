import { GET_APP_STARTUP } from '../constants'

import { call, all, put, takeLatest, select } from 'redux-saga/effects'

import { getAppStartupSuccess, getAppStartupFailure } from '../actions'

import trustPilotApi from '../../../api/trustPilot'
import fetchAntiForgeryTokenSaga from './antiForgerySaga'
import fetchLinksSaga from './linksSaga'
import getOpenSchemeSaga from './schemeSaga'
import { getSubDomain, handleQueryString } from '../../../services/handlers'
import driveawayApi from '../../../api/driveawayApi'
import {
  getIntroducerLinkSelector,
  getDriveawayInfo,
  getIntroducerBranchLinkSelector,
} from '../../BranchSelect/selector'
import { setSelectedBranch } from '../../BranchSelect/actions'
import featureTogglesSaga from './featureTogglesSaga'

export function* startup() {
  try {
    yield* fetchAntiForgeryTokenSaga()
    yield* fetchLinksSaga()
    yield* getOpenSchemeSaga()
    yield* featureTogglesSaga()
    yield all([call(getMandatoryAppData), call(getSelectedBranch)])
  } catch (e) {
    yield put(getAppStartupFailure())
  }
}

function* getMandatoryAppData() {
  const subDomain = getSubDomain()

  const { Href } = yield select(getIntroducerLinkSelector)

  let [introducer, trustPilot] = yield all([
    call(driveawayApi.getIntroducer, Href.replace('{introducer}', subDomain)),
    call(trustPilotApi.getTrustPilotScore),
  ])
  const {
    IntroducerName,
    PartnerPays,
    CanFutureDatePolicies,
    IntroducerEligibility,
    HomePagePricingList,
    AnnualInsuranceProvider,
    BranchesDrivenByStocklist,
    EnableMarketingPreferences,
    AffiliateData: { AffiliateType, AffiliateName, FcaNumber, AffiliateRef },
  } = introducer.data
  const homePagePricing =
    HomePagePricingList &&
    HomePagePricingList.length > 0 &&
    HomePagePricingList[0]

  yield put(
    getAppStartupSuccess({
      TrustPilot: trustPilot.data,
      Affiliate: {
        IntroducerName,
        AffiliateType,
        AffiliateName,
        FcaNumber,
        AffiliateRef,
      },
      AnnualInsuranceProvider,
      BranchesDrivenByStocklist,
      PartnerPays,
      CanFutureDatePolicies,
      EnableMarketingPreferences,
      HomePagePricing: homePagePricing,
      Eligibility: IntroducerEligibility,
    }),
  )
}

function* getSelectedBranch() {
  const driveaway = yield select(getDriveawayInfo)
  const subDomain = getSubDomain()
  const branchId = handleQueryString().Branch

  const { Href } = yield select(getIntroducerBranchLinkSelector)

  if (!driveaway.SelectedBranch && branchId) {
    const branch = yield call(
      driveawayApi.getIntroducerBranch,
      Href.replace('{introducer}', subDomain).replace('{branchId}', branchId),
    )
    yield put(setSelectedBranch(branch.data))
  }
}

export default function* AppStartupSaga() {
  yield takeLatest(GET_APP_STARTUP, startup)
}
