import React from 'react'
import Moment from 'moment'
import { Select } from '../common/Select'
import { ErrorMessage } from '../common/ErrorMessage'
import { FormControlWrapper } from '../FormControlWrapper'
import { FormControlValidIcon } from '../FormControlValidIcon'

export class YearOfManufacture extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      validMaximumDate: Moment().format('YYYY'),
      validMinimmumDate: Moment([1920, 1, 1]).format('YYYY'),
      options: [],
    }
  }

  componentDidMount() {
    let currentYear = +this.state.validMaximumDate
    const yearOptions = []

    while (currentYear >= +this.state.validMinimmumDate) {
      yearOptions.push({
        id: currentYear.toString(),
        value: currentYear.toString(),
        selected: currentYear.toString() === this.state.validMaximumDate,
        description: currentYear.toString(),
      })
      currentYear--
    }

    this.setState({
      options: yearOptions,
    })
  }

  render() {
    return (
      <FormControlWrapper>
        <FormControlValidIcon
          error={this.props.error}
          className='fas fa-check'
        ></FormControlValidIcon>
        <Select
          id='YearOfManufacture'
          value={this.props.value || ''}
          options={this.state.options}
          error={this.props.error}
          onChange={this.props.handleDateSet}
          name='YearOfManufacture'
          placeholder='Select year of manufacture'
        />
        <ErrorMessage>{this.props.error}</ErrorMessage>
      </FormControlWrapper>
    )
  }
}
