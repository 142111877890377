import React, { useState, useEffect } from 'react'
import CookieConsentBanner from './CookieConsentBanner'
import { getCookie, setCookie } from '../../services/cookieService'
import cookieApi from '../../api/cookieApi'
import {
  gtmSnippetScriptString,
  fullstoryScriptString,
} from '../../services/external-js-constants'
import {
  COOKIE_MANAGEMENT,
  REQUIRED_COOKIES,
  COOKIE_INFORMATION_MODAL_NAME,
  COOKIE_MANAGEMENT_MODAL_NAME,
} from './CookieConsentBanner.constants'

const CookieConsentBannerContainer = () => {
  const [isHidden, setIsHidden] = useState(true)
  // https://dev.azure.com/tempcover/tempcover_dev/_wiki/wikis/tempcover_dev.wiki/598/Cookie-Version-History
  const COOKIE_VERSION = '1.4.0'
  const [isCookieInformationModalVisible, setCookieInformationModalVisible] =
    useState(false)
  const [isCookieManagementModalVisible, setCookieManagementModalVisible] =
    useState(false)

  const loadEssentialScripts = () => {
    const stripeScript = document.createElement('script')
    stripeScript.type = 'text/javascript'
    stripeScript.src = 'https://js.stripe.com/v3/'
    document.body.appendChild(stripeScript)

    const trustPilotScript = document.createElement('script')
    trustPilotScript.type = 'text/javascript'
    trustPilotScript.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    trustPilotScript.async = true
    document.body.appendChild(trustPilotScript)
  }

  const loadAnalyticsScripts = () => {
    const gtmSnippetScript = document.createElement('script')
    gtmSnippetScript.type = 'text/javascript'
    gtmSnippetScript.text = gtmSnippetScriptString
    document.body.appendChild(gtmSnippetScript)

    const trustboxScript = document.createElement('script')
    trustboxScript.type = 'text/javascript'
    trustboxScript.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    trustboxScript.async = true
    document.body.appendChild(trustboxScript)
  }

  const loadTrackingScripts = () => {
    const fullstoryScript = document.createElement('script')
    fullstoryScript.type = 'text/javascript'
    fullstoryScript.text = fullstoryScriptString
    document.body.appendChild(fullstoryScript)
  }

  useEffect(() => {
    const cookieManagementCookieString = getCookie(COOKIE_MANAGEMENT.name)
    if (cookieManagementCookieString) {
      const cookieManagementObject = JSON.parse(cookieManagementCookieString)
      if (cookieManagementObject.version === COOKIE_VERSION) {
        loadEssentialScripts()
        if (cookieManagementObject.trackingCookies) {
          loadAnalyticsScripts()
        }
        if (cookieManagementObject.recordingCookies) {
          loadTrackingScripts()
        }
      } else {
        setIsHidden(false)
        if (!localStorage.getItem('clientSessionId')) {
          cookieApi
            .createSession()
            .then((res) =>
              localStorage.setItem(
                'clientSessionId',
                res.data.SessionId.toString(),
              ),
            )
        }
      }
    } else {
      setIsHidden(false)
      if (!localStorage.getItem('clientSessionId')) {
        cookieApi
          .createSession()
          .then((res) =>
            localStorage.setItem(
              'clientSessionId',
              res.data.SessionId.toString(),
            ),
          )
      }
    }
  }, [])

  useEffect(() => {
    if (!isHidden) {
      document.querySelector('body').style.overflow = 'hidden'
    } else {
      document.querySelector('body').style.overflow = 'visible'
    }
  }, [isHidden])

  const handleClick = (
    saveAnalyticsCookies = true,
    saveTrackingCookies = true,
  ) => {
    REQUIRED_COOKIES.map((cookie) => {
      if (cookie.name === COOKIE_MANAGEMENT.name) {
        const cookieManagementValue = {
          trackingCookies: saveAnalyticsCookies,
          recordingCookies: saveTrackingCookies,
          version: COOKIE_VERSION,
        }
        const stringifiedCookieManagement = JSON.stringify(
          cookieManagementValue,
        )
        return setCookie(
          cookie.name,
          stringifiedCookieManagement,
          cookie.length,
        )
      } else {
        return setCookie(cookie.name, 'Yes', cookie.length)
      }
    })

    if (localStorage.getItem('clientSessionId')) {
      cookieApi.updateCookiePreferences(
        saveAnalyticsCookies,
        saveTrackingCookies,
      )
    }

    if (saveAnalyticsCookies) {
      loadAnalyticsScripts()
    }
    if (saveTrackingCookies) {
      loadTrackingScripts()
    }
    loadEssentialScripts()

    setIsHidden(true)
  }

  const handleLearnMoreClick = () => {
    setCookieInformationModalVisible(true)
  }

  const handleManageCookiesClick = () => {
    setCookieManagementModalVisible(true)
  }

  const handleModalClose = (modalName) => {
    switch (modalName) {
      case COOKIE_INFORMATION_MODAL_NAME:
        setCookieInformationModalVisible(false)
        break
      case COOKIE_MANAGEMENT_MODAL_NAME:
        setCookieManagementModalVisible(false)
        break
      default:
        break
    }
  }

  if (isHidden) {
    return null
  }

  return (
    <CookieConsentBanner
      onClick={handleClick}
      onLearnMoreClick={handleLearnMoreClick}
      isCookieInformationModalVisible={isCookieInformationModalVisible}
      isCookieManagementModalVisible={isCookieManagementModalVisible}
      onModalClose={handleModalClose}
      onManageCookiesClick={handleManageCookiesClick}
    />
  )
}

export default CookieConsentBannerContainer
