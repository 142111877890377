import React from 'react'
import Logo from '../../../images/ArnoldClark.png'
import {
  Wrapper,
  AnnualInsurance,
  AnnuaLInsuranceLogo,
  AnnualInsuranceHeader,
  AnnualInsuranceHeaderWrapper,
} from '../styles'
import Content from '../Content'

const ArnoldClarkProvider = ({ requestCallback, callbackRequest }) => {
  const style = { backgroundColor: '#264694', highlightColor: '#264694' }
  return (
    <Wrapper styleOverride={style}>
      <AnnualInsuranceHeaderWrapper styleOverride={style}>
        <AnnualInsuranceHeader styleOverride={style}>
          NEED ANNUAL INSURANCE?
        </AnnualInsuranceHeader>
      </AnnualInsuranceHeaderWrapper>
      <p>
        Now that your Temporary insurance is sorted, when it’s time to arrange
        your car insurance, it’s good to know you’re in expert hands.
      </p>
      <p>
        <AnnualInsurance styleOverride={style}>Arnold Clark</AnnualInsurance>{' '}
        has been providing insurance for over 20 years.
      </p>
      <p>
        Here are just a few of the reasons you’d take out a policy with them:
      </p>
      <ul style={{ textAlign: 'left' }}>
        <li>
          Receive a voucher booklet with over{' '}
          <strong>£300 worth of savings</strong>
        </li>
        <li>Insurance is carefully chosen from a leading panel of experts</li>
        <li>Cover can be tailored to meet your needs</li>
        <li>Choose from many 5-star Defaqto products</li>
      </ul>
      <p>
        <strong>
          Save time and money on your car insurance by speaking to{' '}
          <AnnualInsurance styleOverride={style}>Arnold Clark</AnnualInsurance>{' '}
          today! Click below to request a call back shortly.
        </strong>
      </p>
      <p>
        <em>
          By requesting a call back, you understand that we will pass your
          details to{' '}
          <AnnualInsurance styleOverride={style}>Arnold Clark</AnnualInsurance>{' '}
          for the purpose of providing you with a quotation for annual
          insurance.
        </em>
      </p>
      <Content
        callbackRequest={callbackRequest}
        requestCallback={requestCallback}
        annualInsuranceProvider='Arnold Clark'
      />
      <AnnuaLInsuranceLogo src={Logo} alt={'Arnold Clark Logo'} />
    </Wrapper>
  )
}

export default ArnoldClarkProvider
