import { put, select, takeLatest } from 'redux-saga/effects'

import { ACCEPT_LEGAL_DECLARATIONS } from '../App/constants'
import { getAppData, getCreatePolicyNoPaymentLink } from '../App/sagas/select'
import { history } from '../..'
import { ROUTES } from '../../services/constants'
import { getQuoteId } from '../RiskDataCollectionPage/selector'
import paymentApi from '../../api/paymentApi'
import {
  enableAllButtons,
  isButtonDisabled,
} from '../RiskDataCollectionPage/actions'

export function* acceptLegalDeclarationsSaga() {
  try {
    const { PartnerPays } = yield select(getAppData)
    const quoteId = yield select(getQuoteId)

    if (PartnerPays) {
      const data = {
        quoteId: {
          value: quoteId,
        },
      }

      yield put(
        isButtonDisabled({ buttonName: 'legalDeclaration', disabled: true }),
      )

      try {
        const noPaymentLink = yield select(getCreatePolicyNoPaymentLink)
        paymentApi.createNoPayment(noPaymentLink.Href, data).then(() => {
          history.push(
            `${ROUTES.POLICY_CONFIRMATION}?q=${quoteId}&t=${Date.now()}`,
          )
        })
      } catch (e) {
        yield put(enableAllButtons)
        history.push(`${ROUTES.QUOTE_DRIVING_LICENCE}?q=${quoteId}`)
      }
    } else {
      history.push(`${ROUTES.QUOTE_PAYMENT}?q=${quoteId}`)
    }
  } catch (e) {}
}

export default function* LegalDeclarationSaga() {
  yield takeLatest(ACCEPT_LEGAL_DECLARATIONS, acceptLegalDeclarationsSaga)
}
