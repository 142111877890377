import {
  GET_INTRODUCER,
  GET_INTRODUCER_SUCCESS,
  GET_INTRODUCER_BRANCHES,
  GET_INTRODUCER_BRANCHES_SUCCESS,
  GET_INTRODUCER_BRANCHES_FAILURE,
  SET_INTRODUCER_BRANCH,
} from './constants'

export function getIntroducer() {
  return {
    type: GET_INTRODUCER,
  }
}

export function getIntroducerSuccess(data) {
  return {
    type: GET_INTRODUCER_SUCCESS,
    data,
  }
}

export function getIntroducerBranches() {
  return {
    type: GET_INTRODUCER_BRANCHES,
  }
}

export function getIntroducerBranchesSuccess(data) {
  return {
    type: GET_INTRODUCER_BRANCHES_SUCCESS,
    data,
  }
}

export function getIntroducerBranchesFailure() {
  return {
    type: GET_INTRODUCER_BRANCHES_FAILURE,
  }
}

export function setSelectedBranch(branch) {
  return {
    type: SET_INTRODUCER_BRANCH,
    branch,
  }
}
