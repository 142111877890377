import {
  AFFILIATE_REF,
  REQUEST_QUOTE,
  REQUEST_QUOTE_SUCCESS,
} from '../constants'

import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  createQuoteLink,
  getRiskData,
  getVehicleInfo,
  getQuoteLink,
  getOpenSchemes,
} from '../selector'
import quoteApi from '../../../api/quoteApi'
import {
  createQuoteSuccess,
  createQuoteFailure,
  createQuote,
  requestQuoteSuccess,
  requestQuoteFailure,
  isLoading,
  getQuoteSummarySuccess,
  requestQuote,
} from '../actions'

import {
  getQuoteStatus,
  getQuoteId,
} from '../../RiskDataCollectionPage/selector'
import { history } from '../../../'
import { getPostcode } from '../../Address/selector'
import { formValidation } from '../../RiskDataCollectionPage/sagas/validation'
import { fetchAddresses } from '../../Address/saga'
import { updateRiskDataSaga } from '../../RiskDataCollectionPage/sagas/riskData'
import { updateRiskData } from '../../RiskDataCollectionPage/actions'
import { getQuoteSummaryLink } from '../selector'
import { getDriveawayInfo } from '../../BranchSelect/selector'
import { getSubDomain } from '../../../services/handlers'

export function* createQuoteSaga() {
  try {
    yield put(createQuote())

    const quoteLink = yield select(createQuoteLink)
    const vehicleInfo = yield select(getVehicleInfo)
    const riskData = yield select(getRiskData)
    const subDomain = getSubDomain()

    const data = {
      VehicleAbi: vehicleInfo.selected.Abi,
      VehicleId: vehicleInfo.selected.VehicleId,
      Duration: parseInt(riskData.Duration),
      DurationType: riskData.DurationType,
      SubDomain: subDomain,
    }
    const { SelectedBranch } = yield select(getDriveawayInfo)

    if (SelectedBranch) {
      data.BranchId = SelectedBranch.BranchId
    }
    let quoteEndpoint = quoteLink.Href.replace(
      /{[affiliateRef}]*}/,
      AFFILIATE_REF,
    )

    const response = yield call(quoteApi.createQuote, quoteEndpoint, data)
    yield put(createQuoteSuccess(response.data))
  } catch (e) {
    yield put(createQuoteFailure(e))
  }
}

export function* fetchQuote() {
  try {
    const quoteLink = yield select(getQuoteLink)
    const response = yield call(quoteApi.getMotorQuote, quoteLink.Href)
    yield put(requestQuoteSuccess(response.data))
  } catch (e) {
    yield put(requestQuoteFailure(e))
  }
}

export function* performSuccessActions() {
  const quoteStatus = yield select(getQuoteStatus)
  const quoteId = yield select(getQuoteId)
  if (quoteStatus === 'Complete') {
    history.push(`/policy/confirmation?q=${quoteId}`)
    return
  }
  const postcode = yield select(getPostcode)

  if (postcode) {
    yield* fetchAddresses({ postcode })
    yield* updateRiskDataSaga({
      payload: { partialValidation: true, getPrice: true },
    }) //bit hacky, need to only call if i have a address
  }
  yield* formValidation()

  if (window.location.pathname === '/quote/scan') {
    history.push(`/quote/select-path?q=${quoteId}`)
  } else if (
    window.location.pathname !== '/quote/driver-details' &&
    window.location.pathname !== '/quote/driving-licence' &&
    window.location.pathname !== '/quote/select-path' &&
    (window.location.pathname !== '/quote/payment' ||
      (window.location.pathname === '/quote/payment' &&
        window.location.search.indexOf('message') === -1))
  ) {
    history.push(`/quote/driving-licence?q=${quoteId}`)
  }

  yield put(isLoading(false))
}

export function* fetchQuoteSummary(quoteId) {
  yield put(
    updateRiskData({
      QuoteId: quoteId,
    }),
  )

  let quoteSummaryLink = yield select(getQuoteSummaryLink)
  const openSchemes = yield select(getOpenSchemes)
  const quoteSummary = yield call(
    quoteApi.getQuoteSummary,
    quoteSummaryLink.Href.replace('{encryptedQuoteId}', quoteId),
  )
  const { data } = quoteSummary
  if (openSchemes && openSchemes.some((s) => s.Scheme === data.Scheme)) {
    yield put(getQuoteSummarySuccess(data))
    yield put(requestQuote())
  } else {
    window.location.href = '/'
  }
}

export default function* fetchQuoteSaga() {
  yield takeLatest(REQUEST_QUOTE, fetchQuote)
  yield takeLatest(REQUEST_QUOTE_SUCCESS, performSuccessActions)
}
