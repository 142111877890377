import queryString from 'query-string'
import moment from 'moment'
import { LICENCE_HELD_DURATIONS } from './constants'
import config from '../config'

export const handleQueryString = () => {
  const parsedQueryString = queryString.parse(window.location.search)

  let policyDuration = 5
  var durationArray = (parsedQueryString.duration || '').split(' ')

  if (durationArray.length > 1) {
    policyDuration = durationArray[0]
  }

  let preQuoteInformation = {
    RegistrationNumber: parsedQueryString.registration,
    VehicleType: parsedQueryString.VehicleType,
    Duration: policyDuration,
    DurationType: 'days',
    UnknownVehicle: {
      Make: parsedQueryString.make,
      Model: parsedQueryString.model,
      Variant: parsedQueryString.variant,
      GrossWeight: parsedQueryString.weight,
      YearOfManufacture: parsedQueryString.year,
    },
    QuoteId: parsedQueryString.q,
    StartTime: parsedQueryString.start,
    Branch: parsedQueryString.branch,
  }
  return preQuoteInformation
}

export const handleLicenceDurations = (dateOfBirth) => {
  const assumedPassedDate = moment().subtract(17, 'years')
  const fullOptions = LICENCE_HELD_DURATIONS
  let filteredOptions = []
  let yearDiff = moment(assumedPassedDate).diff(dateOfBirth, 'years', true)

  fullOptions.forEach((option) => {
    if (yearDiff >= option.yearDiff) {
      filteredOptions.push(option)
    }
  })

  return filteredOptions
}

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const humanize = (value) => {
  return value.replace(/([a-z])(?=[A-Z]+)/g, '$1 ')
}

export const loadScript = (url, callback) => {
  let script = document.createElement('script')
  script.type = 'text/javascript'

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = function () {
      callback()
    }
  }

  script.src = url
  document.getElementsByTagName('body')[0].appendChild(script)
}

export const smoothScroll = (el) => {
  const pageYOffset = window.pageYOffset
  const halfViewportHeight =
    ((window.visualViewport && window.visualViewport.height) ||
      window.innerHeight) / 2
  const elementYOffset = el.getBoundingClientRect().top
  const relativeScrollDistance =
    pageYOffset + elementYOffset - halfViewportHeight - pageYOffset
  if (Math.abs(relativeScrollDistance) > 50) {
    const duration = 200
    const steps = 50
    const step = relativeScrollDistance / steps
    let yScrollPos = pageYOffset
    let currentStep = 0
    const loop = setInterval(() => {
      if (currentStep < steps) {
        yScrollPos += step
        currentStep++
        window.scrollTo(0, yScrollPos)
      } else {
        clearInterval(loop)
      }
    }, duration / steps)
  }
}

export const getSubDomain = () => {
  const { hostname } = getLocation()
  if (hostname === 'localhost') {
    return config.MOCK_SUB_DOMAIN.toLowerCase()
  }
  return hostname.split(config.INTRODUCER_SPLIT_CHARACTER)[0].toLowerCase()
}

export const getLocation = () => {
  return window.location
}
