import { UPDATE_VALIDATION_RISK_DATA_COLLECTION } from '../constants'
import { put, takeLatest, select } from 'redux-saga/effects'

import { toggleFormIsValid } from '../actions'

import { getValidation } from '../selector'
import { UPDATE_ADDITIONAL_DRIVER_VALIDATION } from '../../AdditionalDriver/constants'

export function* formValidation() {
  const validation = yield select(getValidation)
  const fields = validation.fields
  const additionalDrivers = validation.additionalDrivers
  const validRiskCollection = Object.keys(fields).every((key) => {
    return fields[key] === ''
  })

  let validAdditionalDrivers = true

  additionalDrivers.forEach((additionalDriver) => {
    validAdditionalDrivers = Object.keys(additionalDriver).every((key) => {
      return additionalDriver[key] === ''
    })
  })
  yield put(
    toggleFormIsValid({
      valid: validRiskCollection && validAdditionalDrivers,
    }),
  )
}

export function* formValidationSaga() {
  yield takeLatest(UPDATE_VALIDATION_RISK_DATA_COLLECTION, formValidation)
  yield takeLatest(UPDATE_ADDITIONAL_DRIVER_VALIDATION, formValidation)
}
