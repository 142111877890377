/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'

import { H1, H2, P, A, LI, Italic } from './styles'
import Accordion from '../Accordion'
import { ROUTES } from '../../services/constants'

const HomeDeliveryFaqsPage = ({
  appData: { Eligibility, Affiliate, PartnerPays, HomePagePricing },
  schemes,
}) => {
  const { MinAge, MaxAge, MinVehicleValue, MaxVehicleValue, MaxVehicleAge } =
    Eligibility
  const { IntroducerName } = Affiliate

  let vehicleTypes = []
  if (schemes.some((e) => e.Scheme.includes('PrivateCar'))) {
    vehicleTypes.push('private cars')
  }
  if (schemes.some((e) => e.Scheme.includes('CommercialVehicle'))) {
    vehicleTypes.push('commercial vehicles')
  }

  return (
    <Grid desktop='100' mobile='100' tablet='100'>
      <H1>FAQs</H1>
      <H2>Eligibility</H2>
      <Accordion Header='Who we cover'>
        <ul>
          <LI>
            You must have purchased a vehicle from {IntroducerName} for
            {IntroducerName === 'Cazoo' || IntroducerName === 'cinch'
              ? ' collection or home delivery'
              : ' home delivery'}
          </LI>
          <LI>
            You must be aged between {MinAge} and {MaxAge} years old.
          </LI>
          {IntroducerName !== 'cinch' && (
            <LI>
              You and any additional driver must hold a full current and valid
              UK or EU driving licence{' '}
              {IntroducerName === 'Cazoo' ? 'for a minimum of 12 months' : ''}.
            </LI>
          )}
          {IntroducerName === 'cinch' && (
            <>
              <LI>
                You must have held a current and valid Full UK or EU driving
                licence for a minimum of 1 year and are not currently
                disqualified from driving.
              </LI>
              <LI>
                You must have been a UK resident for at least the last 3 Years.
              </LI>
            </>
          )}
          <LI>
            The minimum vehicle value is {MinVehicleValue} and the maximum is{' '}
            {MaxVehicleValue}. The valuation is based on the price at which the
            vehicle has been sold to you.
          </LI>
          <LI>
            The vehicle must be {MaxVehicleAge} years old or less from the date
            of its first registration.
          </LI>
          <LI>
            If adding an additional driver, they must be your Spouse or Common
            Law Partner and you must both live at the same address.
          </LI>
        </ul>
        <Italic>
          Please ensure you pay close attention to the Proposer’s Declaration
          for the full eligibility criteria and any exclusions.
        </Italic>
      </Accordion>

      <H2>About your cover</H2>
      <Accordion Header='Can I take out multiple policies?'>
        <P>
          It is not possible to take out more than one policy. Our specific{' '}
          {HomePagePricing.PolicyDuration} day policies are designed to cover
          you to drive your new car after it has been delivered to you by the{' '}
          {IntroducerName} home delivery team. It is not possible to take
          another policy for the same vehicle once this first one has expired.
        </P>
      </Accordion>
      <Accordion Header='How do I insure a vehicle that is not listed?'>
        <P>
          We are only able to insure those vehicles found on our website at the
          time that you visit us.
        </P>
      </Accordion>
      <Accordion Header='Why am I unable to get a quote/policy?'>
        <P>
          While we’re confident we can offer cover to a wide range of drivers,
          occasionally you may find our insurer is unable to offer you a policy.
          This could be due to a number of factors such as the age of the driver
          and/or the age and/or value of the vehicle you wish to insure.
        </P>
        <Italic>
          Please pay close attention to the insurer’s declaration to ensure you
          can comply with their eligibility criteria.
        </Italic>
      </Accordion>
      <Accordion Header='Does the vehicle need to have a current MOT?'>
        <P>
          Yes, your vehicle must have a valid MOT. Please enquire with{' '}
          {IntroducerName} if you are unsure as to how long the MOT on your
          vehicle is valid for.
        </P>
      </Accordion>

      <H2>What we cover</H2>
      <Accordion Header='What vehicle can I insure?'>
        <P>
          With our home delivery policy it is only possible to insure
          {` ${vehicleTypes.join(' and ')} `}with a minimum value of{' '}
          {MinVehicleValue} and a maximum value of {MaxVehicleValue}. The
          valuation is based on the price at which the vehicle has been sold to
          you. The vehicle must be {MaxVehicleAge} years old or less from the
          date of its first registration.
        </P>
      </Accordion>
      <Accordion Header='Can I add an additional driver to the policy?'>
        <P>
          Yes, but they must be your Spouse or Common Law Partner and you must
          both live at the same address.
        </P>
      </Accordion>
      {!PartnerPays && (
        <>
          <H2>Paying for your policy</H2>
          <Accordion Header='How can I pay?'>
            <P>
              You can pay for your insurance via our secure payment system. The
              payment methods available to you will be presented at checkout. We
              accept the following payment methods:
            </P>
            <ul>
              <LI>Visa (credit/debit cards)</LI>
              <LI>MasterCard (credit/debit cards)</LI>
              <LI>Maestro (debit card)</LI>
            </ul>
          </Accordion>
          <Accordion Header='How do I know if my card payment has been accepted?'>
            <P>
              As soon as your transaction has been authorised you will be taken
              to our policy confirmation page. Here you will see your policy
              number and the date and time your cover is due to start and end.
              You’ll also be presented with your policy documents – you may want
              to take a screenshot of these, just in case you need to present
              them.
            </P>
          </Accordion>
          <Accordion Header='My card payment was declined, what can I do?'>
            <P>
              Try putting your details through again. If you continue to have a
              problem, please contact your bank for advice.
            </P>
          </Accordion>
          <Accordion Header='Why has my card payment been declined?'>
            <P>
              For data protection reasons, your card issuer will not disclose to
              us the reason for a card being declined. Please contact the card
              issuer who should be able to help.
            </P>
          </Accordion>
          <Accordion Header='Will I receive a receipt for my card payment?'>
            <P>
              Your policy confirmation email will detail the amount you have
              paid for the policy, inclusive of Insurance Premium Tax (IPT).
            </P>
          </Accordion>
          <Accordion Header='Can I pay the insurance on behalf of someone else?'>
            <P>
              Yes, though it is important that the actual policyholder is aware
              of the cover being purchased and agrees to all the points in the
              proposer’s declaration.
            </P>
          </Accordion>
        </>
      )}
      <H2>Receiving your documents</H2>
      <Accordion Header='How will I receive my documents?'>
        <P>
          Policy documents are sent to the email address provided on the policy
          immediately after purchase. Be sure to check your spam/junk folders in
          case our email ends up there. If you would like the documents posted
          out to you, please send your request to{' '}
          <A href='mailto://contactus@tempcover.com'>contactus@tempcover.com</A>
          , quoting your policy number.
        </P>
      </Accordion>
      <Accordion Header='Updating the Motor Insurance Database (MID)'>
        <P>
          Once you’ve purchased a temporary insurance policy, your insurance
          details will shortly be passed to the Motor Insurance Database (MID).
          While they are passed within the timescales required by the MID, due
          to the short-term nature of your policy, it is possible your policy
          may have expired before the details are loaded into the database.
        </P>
        <P>
          We recommend that you print your Insurance Certificate and have this
          with you as this remains valid proof of your insurance and legal
          entitlement to drive the vehicle.
        </P>
      </Accordion>

      <H2>Your policy &amp; making a claim</H2>
      <Accordion Header='Reporting an incident or making a claim'>
        <P>
          To report an incident or for any claim related queries, please contact
          our dedicated claims handling team on 0333 241 3392. Remember to quote
          your policy number which can be found on your insurance certificate
        </P>
      </Accordion>

      <H2>Cancelling/changing/renewing your policy?</H2>
      <Accordion Header='Can I cancel the policy?'>
        <P>
          You may cancel this policy at any time by getting in touch with us at{' '}
          <A href='mailto://contactus@tempcover.com'>contactus@tempcover.com</A>{' '}
          or completing the form on our{' '}
          <A href={ROUTES.CONTACT_US}>contact us</A> page.
        </P>
      </Accordion>
      <Accordion Header='Can I make changes to the policy?'>
        <P>
          No, due to the short-term nature of the policy we cannot make any
          changes to the policy once it has been purchased. You should only
          administer this policy once you have purchased your vehicle from{' '}
          {IntroducerName}.
        </P>
      </Accordion>
      <Accordion Header='Will I be able to renew my policy?'>
        <P>
          No, our specific {HomePagePricing.PolicyDuration} day home delivery
          policies are designed to cover you to drive your new vehicle once a
          member of the {IntroducerName} home delivery team have delivered the
          vehicle to you. It is not possible to take another policy for the same
          vehicle once this first one has expired.
        </P>
      </Accordion>
      <Accordion Header='How can I contact you?'>
        <P>
          Please visit our <A href={ROUTES.CONTACT_US}>contact us</A> page.
        </P>
      </Accordion>
    </Grid>
  )
}

export default HomeDeliveryFaqsPage
