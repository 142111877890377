import React, { useEffect, useState } from 'react'
import driveawayApi from '../../api/driveawayApi'
import { UpsellSummarySection } from '../../containers/QuoteSummary/UpsellSummarySectionStyles'
import VehicleReg from '../VehicleReg'
import { Button } from '../common/Button'
import queryString from 'query-string'
import { history } from '../..'
import { getSubDomain } from '../../services/handlers'
import { useDispatch } from 'react-redux'
import {
  initSetVehicleErrorCode,
  setVehicleRegistration,
} from '../../containers/UpsellDurationPage/action'

const UpsellDuration = ({ subdomain, vehicleReg }) => {
  const dispatch = useDispatch()

  const branch = queryString.parse(window.location.search).branch

  const [isLoading, setIsLoading] = useState(true)
  const [additionalDays, setAdditionalDays] = useState(0)
  const [upsellCost, setUpsellCost] = useState(0)
  const [noThanksFreeDuration, setNoThanksFreeDuration] = useState('')

  const [upsellPolicyDuration, setUpsellPolicyDuration] = useState(0)
  const [vehicleMakeModel, setVehicleMakeModel] = useState('')
  const [premiumSubdomain, setPremiumSubdomain] = useState('')
  const [freeDuration, setFreeDuration] = useState(0)
  const [upsellDuration, setUpsellDuration] = useState(0)
  const [upsellBranch, setUpsellBranch] = useState('')

  const upsellDetails = async () => {
    try {
      const response = await driveawayApi.getUpsellPricing(
        subdomain,
        branch !== undefined || branch !== '' ? branch : '0',
        vehicleReg,
      )
      // placed in if elses here instead of multiple ifs as the js is rendered before the page redirects away
      // no valid vehicle go to invalid reg page
      if (!response.data.VehicleValid) {
        dispatch(initSetVehicleErrorCode('Forbidden'))
        dispatch(setVehicleRegistration(vehicleReg))
      } else {
        // either no upsell or system feature off go home
        if (!response.data.Valid) {
          window.location.href = '/'
        } else {
          setPremiumSubdomain(response.data.PremiumSubdomain)
          setAdditionalDays(response.data.DifferenceDuration)
          setUpsellCost(response.data.TotalPrice)
          setUpsellPolicyDuration(response.data.UpsellDuration)
          setVehicleMakeModel(response.data.VehicleMakeAndModel)
          setFreeDuration(response.data.FreeDuration)
          setUpsellDuration(response.data.UpsellDuration)
          setUpsellBranch(response.data.BranchId)
          let noThanksFreeDuration =
            'free ' + response.data.FreeDuration + ' day'
          if (response.data.FreeDuration > 1) {
            noThanksFreeDuration += 's'
          }

          setNoThanksFreeDuration(noThanksFreeDuration)

          setIsLoading(false)
        }
      }
    } catch (err) {
      // check failed go back to the start of the journey
      window.location.href = '/'
    }
  }

  const handleDurationSelect = (durationSelected) => {
    let isUpsell = durationSelected === 'upsell'
    let registration = queryString.parse(window.location.search).registration
    let duration = isUpsell ? upsellDuration : freeDuration

    let newUrl = `/quote?registration=${registration}&duration=${duration}+days&start=Immediately`
    // NOTE: DA -  For local testing use http://sytnerselect.localtest.me:8069/

    if (isUpsell && upsellBranch) {
      newUrl += `&branch=${upsellBranch}`
    } else if (branch) {
      newUrl += `&branch=${branch}`
    }

    if (isUpsell) {
      newUrl =
        window.location.origin.replace(getSubDomain(), premiumSubdomain) +
        newUrl

      window.location.href = newUrl
    } else {
      history.push(newUrl)
    }
  }

  useEffect(() => {
    upsellDetails()
  }, [])

  return (
    <div>
      {!isLoading && (
        <div>
          <UpsellSummarySection
            id='upsellDurationContainer'
            showBorderOnMobile={true}
          >
            <p className='quote-summary-title vehicle'>Vehicle to be covered</p>
            <p className='quote-summary-text vehicle'>{vehicleMakeModel}</p>
            <VehicleReg reg={vehicleReg} />
            <div className='upsell-info'>
              <h2>
                Add an additional {additionalDays} days for only £
                {upsellCost.toFixed(2)}
              </h2>
              <p>
                Upgrade your policy to a {upsellPolicyDuration} day policy with
                our special offer.
              </p>
            </div>
            <Button
              type='button'
              id='proceedWithUpsell'
              className='upsell-button upsell-choice-btn'
              onClick={() => handleDurationSelect('upsell')}
            >
              Add {additionalDays} Days
            </Button>
            <Button
              type='button'
              id='continueWithFree'
              className='no-upsell-btn upsell-choice-btn'
              onClick={() => handleDurationSelect('free')}
            >
              No thanks, I only need a {noThanksFreeDuration} policy
            </Button>
          </UpsellSummarySection>
        </div>
      )}
    </div>
  )
}

export default UpsellDuration
