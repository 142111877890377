/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Grid } from 'unsemantic'
import { ROUTES } from '../../services/constants'
import { getLocation } from '../../services/handlers'

import { H1, H2, H3, P, A, OL_D, OL_A, LI, Blue, UL_C } from './styles'

const TermsAndConditionsPage = () => {
  const { origin } = getLocation()
  return (
    <Grid desktop='100' mobile='100' tablet='100'>
      <H1>Terms &amp; conditions</H1>
      <H2>Customer Terms of Business ("Terms of Business")</H2>
      <P>
        Tempcover Ltd ("Tempcover"), 2nd Floor Admiral House, Harlington Way,
        Fleet, Hampshire, GU51 4BB
      </P>
      <P>
        Tempcover and yourself agree to be bound by these Terms of Business when
        you purchase a policy from us. In addition to the contract that you have
        with the Insurer, you will have a separate contract with Tempcover
        Limited for the arrangement and administration of your insurance policy.
        This contract covers the specific insurance intermediary services that
        we provide directly to you.
      </P>
      <P>
        You should read the information contained within these Terms of Business
        before you purchase a policy from us to decide if the services that we
        provide are right for your needs.
      </P>

      <OL_D>
        <LI>
          <H3>
            <Blue>Who Regulates Us?</Blue>
          </H3>
          <P>
            Tempcover Ltd is authorised and regulated by the Financial Conduct
            Authority (FCA). The FCA is the independent watchdog that regulates
            financial services, including insurance. Our FCA number is 746985.
            You can check this at{' '}
            <A
              href='https://register.fca.org.uk/'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://register.fca.org.uk/
            </A>{' '}
            or by contacting the FCA on 0800 111 6768. Our permitted business is
            arranging and assisting in the administration and performance of a
            contract of insurance.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>What service will we provide you with?</Blue>
          </H3>
          <P>
            We provide access to short-term motor insurance policies under 1
            month in duration, in association with your vehicle purchase from
            one of our automotive retailer partners. We will also work with our
            specialist claims management partner to help you make a claim in the
            unfortunate event of an accident or incident involving the vehicle.
          </P>
          <P>
            We will not provide you with advice or a personal recommendation. We
            will help you make the right choice by providing information
            relevant to your insurance demands and needs. We can answer any
            questions you may have but we will not be able to advise you.
          </P>
          <P>
            By completing our on-line application, you have requested short-term
            motor insurance in accordance with the information that you have
            provided. The standard private car and commercial vehicle policies
            that we arrange and administer are designed for people who require
            short-term motor insurance cover on a comprehensive basis whilst
            driving in the United Kingdom.
          </P>
          <P>
            For more information relating to what we can and cannot cover,
            please review the Eligibility Criteria at:{' '}
            <A
              href={`${origin}${ROUTES.ELIGIBILITY}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {`${origin}${ROUTES.ELIGIBILITY}`}
            </A>
            .
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Who do we act for?</Blue>
          </H3>
          <P>
            As an Insurance Intermediary we work to match customers to
            appropriate insurance products. As a result, during our work there
            will be times when we're acting as your agent and other times when
            we're acting in the capacity of agent of the insurer.
          </P>
          <P>
            We will act for you when we complete all the preparatory work needed
            to set up your insurance policy. We will also be acting for you in
            the performance of the insurance contract such as when you notify
            claims to us. Our dedicated claims handling team will explain
            everything you need to know about making a claim and guide you
            through the process step by step.
          </P>
          <P>
            We will act for the insurer when we make their product available to
            you and assist in the administration of the policy of insurance. We
            will generally source the best price for you and present this to you
            alongside documentation for you to read to allow you to make an
            informed decision about your purchase.
          </P>
          <P>
            However, in certain circumstances we may act for and owe duties of
            care to other parties. We will inform you when these circumstances
            occur so you will be aware of any possible conflict of interest.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Whose products we offer</Blue>
          </H3>
          <P>
            We offer policies from a small range of insurers that have been
            carefully selected by Tempcover for the purposes of providing short
            term cover in association with the automotive retailer you have
            purchased your vehicle from.
          </P>
          <P>We work with the following insurers on this basis:</P>
          <UL_C>
            <LI>Ageas Insurance Limited</LI>
            <LI>First Underwriting Limited</LI>
            <LI>Watford Insurance Company Europe Limited</LI>
          </UL_C>
          <P>
            Whilst we take every care to check the financial stability of any
            insurer on our panel, we cannot be held responsible if that firm
            subsequently ceases to trade.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>What will you to have to pay for our services?</Blue>
          </H3>
          <P>
            Your policy may be provided to you free of charge as part of your
            vehicle purchase, or you may be requested to pay a fixed price,
            depending on the automotive retailer you are purchasing from. This
            will be confirmed to you in the communications you receive from your
            automotive retailer. If you are paying for the use of the policy,
            the total price you pay will always include an insurer premium and a
            Tempcover Fee.
          </P>
          <P>
            <u>Insurer Premium</u>
          </P>
          <UL_C>
            <LI>
              The insurer premium includes the costs of underwriting your
              insurance, any insurer charges, Insurance Premium Tax, and any
              commission Tempcover receives from the insurer for placing your
              business.
            </LI>
            <LI>
              Tempcover works with a panel of insurers. The individual
              arrangements we have in place with each insurer will determine how
              much commission Tempcover will receive, which for some insurers
              can be zero.
            </LI>
          </UL_C>
          <P>
            <u>Tempcover Fee</u>
          </P>
          <UL_C>
            <LI>
              The Tempcover fee covers our work preparatory to the conclusion of
              the policy and the provision of assistance in the arranging,
              administration and performance of the insurance contract.
            </LI>
            <LI>
              It is based on our costs for placing your business, conducting all
              required checks, policy demand at the time of application, and our
              costs for customer service and claims management activity.
            </LI>
            <LI>
              The required checks may include: identity validation check,
              driving licence check, passport check, vehicle registration
              look-up and postcode validation check.
            </LI>
            <LI>
              The amount of our Tempcover fee will always be presented to you
              before you purchase the policy.
            </LI>
          </UL_C>
          <P>
            The Tempcover fee will not exceed the following amounts for
            arranging and administering your insurance. These fees are payable
            directly to Tempcover under your separate contract.
          </P>
          <P>Maximum Tempcover Fee Payable:</P>
          <UL_C>
            <LI>
              <strong>Temporary Car Insurance</strong>: £10
            </LI>
            <LI>
              <strong>Temporary Van Insurance</strong>: £10
            </LI>
          </UL_C>
          <P>
            <u>Introducer Fee</u>
          </P>
          <UL_C>
            <LI>
              You may pay an introducer fee where the policy is purchased
              through one of our selected partners.
            </LI>
          </UL_C>
          <P>
            <u>Other Payments</u>
          </P>
          <UL_C>
            <LI>
              We may receive fees from our specialist claims management partner
              when we introduce customers to them for the provision of certain
              accident management services.
            </LI>
            <LI>
              From time to time, we may also participate in performance-based
              profit share agreements with insurers.
            </LI>
          </UL_C>
          <P>
            The quotation we offer is based on the fixed price agreed between
            Tempcover, the Insurer and the automotive retailer of your car
            purchase.
          </P>
          <P>
            We earn the entirety of our Tempcover fee when your risk is
            successfully placed and take our fee immediately upon receipt of
            payment unless agreed otherwise with your insurer. You are entitled
            at any time to request information about earnings that we receive as
            a result of placing your business. Remuneration is only earned on
            the basis that it does not detract from our obligation to act in
            your best interests at all times.
          </P>
          <P>
            We currently accept payment by Debit or Credit card. We require full
            payment before your policy can be completed and documents issued to
            you, unless your policy has been provided to you free of charge as
            part of your vehicle purchase, in which case no further payment is
            required.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>How will we handle your money?</Blue>
          </H3>
          <P>
            We act as agent of the insurer for the collection of premium and
            where applicable any refunds. This means that premiums are treated
            as being received by the insurer when received in our separate
            insurer trust account and the insurer will bear the risk for any
            losses that may arise from the failure of our firm. This means that
            the policy will start on the agreed start date irrespective of
            whether the insurer received payment of the premium. Any interest,
            or investment returns, earned on monies whilst in our possession
            will be retained by ourselves.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Your duty to disclose information</Blue>
          </H3>
          <P>
            When you enter into a contract of insurance wholly for non-business
            purposes you are under a legal duty to take reasonable care not to
            misrepresent information to insurers. If you misrepresent
            information deliberately, recklessly or carelessly respond to
            insurer questions then a claim under the policy may not be paid.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Changes, Cancellations and Refunds</Blue>
          </H3>
          <P>
            If your policy is for 30 days or less in duration, once purchased
            you will not be able to make any changes or additions to the policy
            and due to the short-term nature of the policy, you will not be
            entitled to a refund of any monies paid if you cancel your policy.
            This is in line with the rules set by the Financial Conduct
            Authority.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Claims</Blue>
          </H3>
          <P>
            In the unfortunate event of an accident or incident involving the
            vehicle, our dedicated claims handling team are here to help. For
            any claims related queries please call 0333 241 3392. As part of our
            service, we can help you to notify your claim correctly. If you are
            ever in any doubt as to what action to take in the event of a claim,
            please contact us on the number provided or email us at{' '}
            <A href='mailto:contactus@tempcover.com'>contactus@tempcover.com</A>
            .
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>What to do if you are unhappy about our service?</Blue>
          </H3>
          <P>
            Every effort is made to ensure that we provide you with a high level
            of customer service at all times. However, if you need to bring any
            matter to our attention or wish to register a complaint about
            Tempcover, please contact us:
          </P>
          <UL_C>
            <LI>
              in writing: to Director of Operations & Compliance, Tempcover
              Limited, 2nd Floor Admiral House, Harrington Way, Fleet,
              Hampshire, GU51 4BB;
            </LI>
            <LI>
              by email:{' '}
              <A href='mailto:complaints@tempcover.com'>
                complaints@tempcover.com
              </A>{' '}
              for the attention of the Director of Operations & Compliance; or
            </LI>
            <LI>
              by telephone: 0330 460 9633. This line is open 9am to 5.30pm
              Monday to Friday, excluding Bank Holidays and is used solely for
              the purpose of registering a complaint with Tempcover. Calls to
              our offices may be recorded for training and monitoring purposes.
            </LI>
          </UL_C>
          <P>
            To help us continually improve our service, and in the interests of
            security, your communications may be monitored and/or recorded.
            Following the complaints procedure does not affect your right to
            take legal action. If you cannot settle your complaint with us, you
            may be entitled to refer it to the Financial Ombudsman Service
            (“FOS”). Further information is available by contacting them on 0800
            023 4567 or by visiting their website on:{' '}
            <A
              href='https://www.financial-ombudsman.org.uk'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.financial-ombudsman.org.uk
            </A>
            .
          </P>
          <P>
            As you have purchased your policy online you may complain via the
            Online Dispute Resolution platform developed and operated by the
            European Commission at the following website:{' '}
            <A
              href='https://www.ec.europa.eu/odr'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.ec.europa.eu/odr
            </A>
            . However, in the majority of cases, this will result in your
            complaint being handled by the FOS.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>
              Are we covered by the Financial Services Compensation Scheme
              (FSCS)?
            </Blue>
          </H3>
          <P>
            Our obligations are covered by the Financial Services Compensation
            Scheme (FSCS). If we were unable to meet our obligations, you could
            be entitled to compensation from this scheme, depending on the type
            of insurance and the circumstances at the time. Further information
            about compensation scheme arrangements is available on the FSCS
            website:{' '}
            <A
              href='https://www.fscs.org.uk'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.fscs.org.uk
            </A>{' '}
            or telephone 0800 678 1100.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Privacy &amp; Confidentiality</Blue>
          </H3>
          <P>
            When you contact us for an insurance quotation we collect the
            relevant personal information needed by an insurer to understand
            your insurance needs and calculate the premium. All personal
            information about you will be treated as private and confidential.
            We are registered under the Data Protection Act 2018 and we
            undertake to comply with the Act in all our dealings with your
            personal data. Your personal information will be kept secure.
          </P>
          <P>
            For further information relating to your rights as a data subject
            please view our Privacy Notice at:{' '}
            <A
              href={`${origin}${ROUTES.PRIVACY_AND_COOKIES}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {`${origin}${ROUTES.PRIVACY_AND_COOKIES}`}
            </A>
            .
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Conflicts of Interest</Blue>
          </H3>
          <P>
            We have no close links with our insurers. We manage any conflicts of
            interest by monitoring the outcomes of our customers. There may be
            occasions when a potential conflict of interest arises. If this
            happens, we will inform you and obtain your consent before we carry
            out your instructions.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Your Insurer Schedule/Insurer Certificate of Insurance</Blue>
          </H3>
          <P>
            Your insurer policy schedule and insurer certificate of insurance,
            which form part of your contract with the insurer, will not be
            issued until we are in receipt of full payment of the policy
            premium. These will be issued to you via the policy confirmation
            email sent to the email address that you provide. You may also
            request a postal copy, free of charge. To request this please
            contact us on{' '}
            <A href='mailto:contactus@tempcover.com'>contactus@tempcover.com</A>
            .
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>
              Claims and Underwriting Exchange Register and Motor Insurance
              Anti-Fraud Register
            </Blue>
          </H3>
          <P>
            Insurers pass information to the Claims and Underwriting Exchange
            Register operated by Insurance Database Services Limited and the
            Motor Insurance Anti-Fraud and Theft Register compiled by the
            Association of British Insurers. The objective is to check
            information provided and to prevent fraudulent claims. Motor
            insurance details are also added to the Motor Insurance Database
            operated by the Motor Insurers' Information Centre (MIIC), which has
            been formed to help identify uninsured drivers and may be accessed
            by the police to help confirm who is insured to drive. In the event
            of an accident, this database may be used by insurers, MIIC and your
            motor insurer to identify relevant policy information. Other
            insurance related databases may also be added in the future.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Applicable law</Blue>
          </H3>
          <P>
            This Terms of Business document is subject to English Law and the
            jurisdiction of English Courts.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Version Control</Blue>
          </H3>
          <P>Last updated: 3rd February 2023</P>
        </LI>
      </OL_D>
      <H2>Website terms and conditions of use</H2>
      <OL_D>
        <LI>
          <H3>
            <Blue>Definition:</Blue>
          </H3>
          <P>
            “You/you”: In the case of Agents, “You/you” means the intermediary
            holding the agency. In the case customers dealing with us direct,
            “You/you” means the Policyholder and where applicable any additional
            drivers. “The Company”, “We”, “Us”, “Our” are all terms referring to
            Tempcover Ltd.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>IMPORTANT:</Blue>
          </H3>
          <P>
            These are the General Trading Terms of Tempcover Ltd. Please read
            these Trading Terms carefully. Your use of our Website will be
            subject to these Trading Terms. When you access and use our site,
            you agree to the Trading Terms. If you do not wish to adhere to the
            Trading Terms, you should not use our site.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>
              As a condition of your use of this Website you agree that you will
              NOT:
            </Blue>
          </H3>
          <OL_A>
            <LI>
              Reverse engineer or de-compile (whether in whole or in part) any
              software at the Website (save to the extent expressly permitted by
              applicable law).
            </LI>
            <LI>
              Disclose, publish, transfer or otherwise make available any of the
              contents of the Website or information learned by you whilst using
              the Website without prior written consent.
            </LI>
            <LI>
              Enter those parts of the Website that are protected by password
              unless you have a valid password.
            </LI>
            <LI>
              Remove any copyright, trademark or other intellectual property
              right notices contained in material on the Website.
            </LI>
            <LI>
              Make copies, modify or alter (including the creation of HTML links
              to or from the Website) all or any part of the Website or any
              materials contained on the Website without prior written
              agreement.
            </LI>
            <LI>
              Publish, post, upload, distribute, disseminate or otherwise
              transmit, information or pictures that are obscene or
              pornographic, threatening, menacing, racist, offensive,
              defamatory, libellous, slanderous or are otherwise unlawful on the
              Website.
            </LI>
            <LI>
              Hack into, deliver viruses or forward chain letters, surveys,
              contests, pyramid schemes or engage in any other behaviour
              intended to inhibit any other user from using and enjoying the
              Website or is otherwise likely to damage or destroy the reputation
              of the Website.
            </LI>
          </OL_A>
          <P>
            These are the General Trading Terms of Tempcover Ltd. Please read
            these Trading Terms carefully. Your use of our Website will be
            subject to these Trading Terms. When you access and use our site,
            you agree to the Trading Terms. If you do not wish to adhere to the
            Trading Terms, you should not use our site.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Ownership:</Blue>
          </H3>
          <P>
            The Website (including the software used to operate it) and the
            trademarks used in connection with the Website are owned by us. You
            are not granted any right or interest in these except as stated in
            these terms. We reserve the right to block access to the Website of
            any user at any time, either temporarily or permanently, if we deem
            in our absolute discretion that it is reasonable to do so.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Security/Confidentiality:</Blue>
          </H3>
          <P>
            The information you supply will be kept confidential to us and our
            associated and/or subsidiary companies and the Insurers with whom we
            deal, save or unless we are required by law to make any disclosure.
            We have taken care to protect any information that you provide to
            us. We have used encryption methods to keep your personal
            information and credit or debit card account details as secure as
            possible. To prevent fraud and to ensure that we provide our
            customers with the most competitive quotations, we carry out
            identity checks for every quotation which we issue. This will not
            affect your credit history but a note of the check may be recorded.
          </P>
          <P>
            If you are an agent then your agent login and password is unique and
            this helps us to protect your information. Agents will need their
            agent login and password to access their information on the website.
            Agents should therefore not disclose either their Agent login or
            password to anyone. We will accept no responsibility or liability if
            a third party obtains and uses any agency login or password. Agents
            must tell us immediately if they have lost their agency login or
            password, or if they believe a third party may have obtained either
            of them.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Domicile:</Blue>
          </H3>
          <P>
            Our products are available to UK residents and depending on the
            insurer cover may also be available for overseas visitors, excluding
            residents of the USA, Canada and Australia. By completing a request
            for a quotation and ticking the relevant box on the Proposers
            Declaration, you confirm that you comply to these conditions. The
            content of the site and the products and services offered by us
            comply with appropriate UK legislation and regulation.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Copyright:</Blue>
          </H3>
          <P>
            The copyright in the material contained in this Website belongs to
            Tempcover Ltd or its licensed source. We may give permission for any
            person to copy any part of this material, subject to the following
            conditions:
          </P>
          <OL_A>
            <LI>The material may not be used for any commercial purposes.</LI>
            <LI>
              The copies must retain any copyrights or other intellectual
              property notices contained in the original material.
            </LI>
            <LI>
              The products and technology or processes described in this Website
              may be subject to other intellectual property rights reserved by
              Tempcover Ltd or by other third parties (and no licence is granted
              in respect of those intellectual property rights).
            </LI>
            <LI>
              Images on this Website are protected by copyright and may not be
              reproduced or appropriated in any manner without the written
              permission of their respective owner(s).
            </LI>
          </OL_A>
          <P>
            Anyone wishing to copy any part of the site or any material
            contained therein should contact us for prior permission.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Terms of Business Agreement (TOBA):</Blue>
          </H3>
          <P>
            It is a requirement of any transaction with The Company that the
            Agent complies with the Terms of Business Agreement between the
            Company and the Agent. In the event of conflict between anything
            contained within these General Trading Terms and the TOBA, then the
            TOBA shall be read as the overriding document.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Changes to Terms and Records of Agreements:</Blue>
          </H3>
          <P>
            We reserve the right to change these terms and conditions of use at
            any time by posting changes on the Website. It is your
            responsibility to review the Website terms and conditions regularly
            to ensure you are aware of our latest terms and conditions of use.
            Your use of this Website after a change has been posted will be
            deemed to signify your acceptance of the modified terms and
            conditions. We recommend that you print off and retain for your
            records a copy of these terms and conditions of use from time to
            time and a copy of any terms and conditions of use relating to any
            product or service which you apply for on-line, together with any
            related application and/or proposal form duly completed and
            submitted to us (and received by us). Any amendment to any terms and
            conditions of use must be agreed in writing by us, or, if
            appropriate, by the relevant company with whom you contract.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Disclaimer:</Blue>
          </H3>
          <P>
            Whilst we have taken care in the preparation of the contents of this
            Website, this Website and the information, names, images, pictures,
            logos, icons regarding or relating to us or any of our Group and/or
            associated and/or sister companies, or the products and services of
            the same (or to third party products and services), are provided on
            an “as existing” basis without any representation or endorsement
            being made and without any warranty of any kind, whether express or
            implied, including but not limited to, any implied warranties of
            satisfactory quality, fitness for a particular purpose,
            non-infringement, compatibility, security and accuracy. To the
            extent permitted by law, all such terms and warranties are hereby
            excluded. In no event will we and/or our group, sister or associated
            companies be liable (whether in contract or tort, including
            negligence or breach of statutory duty or otherwise) for any losses
            sustained and arising out of or in connection with use of this
            Website including, without limitation, indirect or consequential
            losses, loss of profit, loss of data or special loss. We do not
            represent that the information contained in this Website is
            accurate, comprehensive, verified or complete, and shall accept no
            liability for the accuracy or completeness of the information
            contained in the Website or for any reliance placed by any person or
            party (including but not limited to any third party) on the
            information contained herein. We do not warrant that the functions
            or materials accessible from or contained in this Website will be
            uninterrupted or error free, that defects will be corrected or that
            this Website or the server that makes it available are virus or bug
            free or represent the full functionality, accuracy or reliability of
            the materials. If any of these Terms and Conditions of Use (or any
            terms and conditions relating to a product or service referred to in
            this Website) should be determined to be illegal, invalid or
            otherwise unenforceable by reason of the laws or any state or
            country in which such terms and conditions of use are intended to be
            effective, then to the extent of such illegality, invalidity or
            un-enforceability, and in relation to such state or country only,
            such terms or conditions of use shall be deleted and severed from
            the rest of the relevant terms and conditions of use and the
            remaining terms and conditions shall survive, remain in full force
            and effect and continue to be binding and enforceable. Nothing in
            these terms and conditions shall exclude our liability for death or
            personal injury resulting from our negligence.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Headings:</Blue>
          </H3>
          <P>
            Headings used in these terms and conditions of use are for
            convenience only and shall not affect their interpretation.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Jurisdiction and Law:</Blue>
          </H3>
          <P>
            We make no representation that any product or service referred to on
            the Website is appropriate for use, or available in other locations.
            The information and other materials contained in this Website may
            not satisfy the laws of any other country and those who choose to
            access this site from other locations are responsible for compliance
            with local laws if and to the extent local laws are applicable. The
            phone numbers provided only apply to phone calls made from within
            the UK. These Terms and Conditions and any terms and conditions of
            use relating to products or services described in this Website shall
            be governed by and construed in accordance with the laws of England
            and Wales. Disputes arising in relation to the same shall, unless
            otherwise expressly agreed, be subject to the exclusive jurisdiction
            of the courts of England and Wales.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Links to other Websites:</Blue>
          </H3>
          <P>
            Certain (hypertext) links may lead you to Websites that are not
            under the control of Tempcover Ltd. When you activate any of these
            links, you will leave our Website and we have no control over and
            will accept no responsibility or liability in respect of the
            material on any such other Website. By allowing links with third
            party Websites we do not intend to solicit business or offer any
            security to any person in any country, directly or indirectly.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Monitoring of Phone Calls/Emails:</Blue>
          </H3>
          <P>
            Entirely subject to our Privacy Policy, telephone calls using the
            telephone numbers provided on this Website and email correspondence
            with us at the email addresses accessible through, or discernible
            from, this Website may be recorded or monitored. By using such
            communication methods you are consenting to the recording or
            monitoring of the same.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Product Terms and Conditions:</Blue>
          </H3>
          <P>
            If you apply for any product or service detailed on this Website,
            these Terms and Conditions of use should be read in conjunction with
            any other terms and conditions of use which relate to any such
            product or service and, in the event of any contradiction between
            these Terms and Conditions of use and the specific terms and
            conditions relating to such produce or service, the latter shall
            prevail. For the purposes of these Terms and Conditions of use,
            product(s) and service(s) shall include, without limitation, any
            insurance or financial service.
          </P>
        </LI>
        <LI>
          <H3>
            <Blue>Trademarks:</Blue>
          </H3>
          <P>
            The images, logos and names on this Website identify Tempcover Ltd.
            Nothing contained in this Website shall be deemed to confer on any
            person any licence or right on the part of the companies mentioned
            above or any third party with respect to any such image, logo or
            name.
          </P>
        </LI>
      </OL_D>
    </Grid>
  )
}

export default TermsAndConditionsPage
