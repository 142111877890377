import {
  CREATE_STRIPE_PAYMENT,
  CREATE_STRIPE_PAYMENT_FAILURE,
  GET_PRICE_INFO,
} from './constants'

export function createStripePayment(payload) {
  return {
    type: CREATE_STRIPE_PAYMENT,
    payload,
  }
}

export function createStripePaymentFailure(errorMessage) {
  return {
    type: CREATE_STRIPE_PAYMENT_FAILURE,
    errorMessage,
  }
}

export function getPriceInfo(payload) {
  return {
    type: GET_PRICE_INFO,
    payload,
  }
}
