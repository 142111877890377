import React from 'react'
import { ReactComponent as LogoImage } from '../../images/logo.svg'
import { ReactComponent as AACarsLogo } from '../../images/affiliates/AACars.svg'
import { ReactComponent as MotorpointLogo } from '../../images/affiliates/Motorpoint.svg'
import { ReactComponent as BigMotoringWorldLogo } from '../../images/affiliates/BigMotoringWorldLogo.svg'
import sytnerLogo from '../../images/affiliates/SytnerSelectLogo.png'

import {
  BrandLogo,
  BrandLogoAlign,
  Link,
  AssociationText,
  WhiteLabelImage,
} from './styles'
import { AFFILIATE_TYPES } from './constants'

const CorrectLogo = ({ Affiliate }) => {
  switch (Affiliate.AffiliateType) {
    case AFFILIATE_TYPES.NON_BRANDED:
      return (
        <Link href='/' aria-label='Homepage'>
          <NonBrandedLogo DisplayName={Affiliate.IntroducerName} />
        </Link>
      )
    case AFFILIATE_TYPES.WHITE_LABEL:
      return (
        <Link href='/' aria-label='Homepage'>
          <WhiteLabelLogo
            url={require(`../../images/affiliates/${Affiliate.AffiliateRef}.png`)}
            DisplayName={Affiliate.AffiliateRef}
          />
        </Link>
      )
    case AFFILIATE_TYPES.SEMI_BRANDED:
      switch (Affiliate.AffiliateRef.toUpperCase()) {
        case 'AACARS':
          return (
            <>
              <Link href='/' aria-label='Homepage'>
                <LogoImage className='hide-strapline' id='TempcoverLogo' />
              </Link>
              <Link href='/' aria-label='Homepage'>
                <AACarsLogo id='AACarsLogo' alt='AA Cars Logo' />
              </Link>
            </>
          )
        case 'BIGMOTORINGWORLD':
          return (
            <>
              <Link href='/' aria-label='Homepage'>
                <LogoImage className='hide-strapline' id='TempcoverLogo' />
              </Link>
              <Link href='/' aria-label='Homepage'>
                <BigMotoringWorldLogo
                  id='BigMotoringWorldLogo'
                  alt='BigMotoringWorld Logo'
                />
              </Link>
            </>
          )
        case 'MOTORPOINTINSTORE':
          return (
            <>
              <Link href='/' aria-label='Homepage'>
                <LogoImage className='hide-strapline' id='TempcoverLogo' />
              </Link>
              <Link href='/' aria-label='Homepage'>
                <MotorpointLogo id='Motorpoint Logo' alt='Motorpoint Logo' />
              </Link>
            </>
          )
        case 'MOTORPOINT':
          return (
            <>
              <Link href='/' aria-label='Homepage'>
                <LogoImage className='hide-strapline' id='TempcoverLogo' />
              </Link>
              <Link href='/' aria-label='Homepage'>
                <MotorpointLogo id='Motorpoint Logo' alt='Motorpoint Logo' />
              </Link>
            </>
          )
        case 'SYTNERSELECT':
          return (
            <>
              <Link href='/' aria-label='Homepage'>
                <LogoImage className='hide-strapline' id='TempcoverLogo' />
              </Link>
              <Link href='/' aria-label='Homepage'>
                <img
                  src={sytnerLogo}
                  id='Sytner Select Logo'
                  alt='Sytner Select Logo'
                />
              </Link>
            </>
          )
        default:
          return (
            <Link href='/' aria-label='Homepage'>
              <DirectLogo />
            </Link>
          )
      }
    default:
      return (
        <Link href='/' aria-label='Homepage'>
          <DirectLogo />
        </Link>
      )
  }
}

const NonBrandedLogo = ({ DisplayName }) => {
  return (
    <>
      <LogoImage className='hide-strapline' />
      <AssociationText>In association with {DisplayName}</AssociationText>
    </>
  )
}

const DirectLogo = () => {
  return <LogoImage alt='Tempcover Logo' id='TempcoverLogo' />
}

const WhiteLabelLogo = ({ url }) => {
  return (
    <WhiteLabelImage
      src={url}
      onError={(e) => {
        e.target.onerror = null
        e.target.src = LogoImage
      }}
      alt='Tempcover Logo'
    />
  )
}

const Logo = ({ AppData }) => {
  const { Affiliate } = AppData
  return (
    <BrandLogo>
      <BrandLogoAlign>
        <CorrectLogo Affiliate={Affiliate} />
      </BrandLogoAlign>
    </BrandLogo>
  )
}
export default Logo
