import defaultTheme from '../themes/default'
import confusedTheme from '../themes/confused'
import brightsideTheme from '../themes/brightside'
import honchoTheme from '../themes/honcho'

import { CONFUSED_REF, BRIGHTSIDE_REF, HONCHO_REF } from './constants'

class themeService {
  static getTheme(affiliateRef) {
    switch (affiliateRef) {
      case CONFUSED_REF:
        return confusedTheme
      case BRIGHTSIDE_REF:
        return brightsideTheme
      case HONCHO_REF:
        return honchoTheme
      default:
        return defaultTheme
    }
  }
}

export default themeService
