import initialState from '../../initialState'
import { UPSEll_SET_UPSELL_ENABLED_SUCCESS } from './constants'

function upsellDurationReducer(state = initialState, action) {
  switch (action.type) {
    case UPSEll_SET_UPSELL_ENABLED_SUCCESS:
      return {
        ...state,
        upsellEnabled: action.enabled,
      }
    default:
      return state
  }
}

export default upsellDurationReducer
