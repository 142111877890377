import { SEND_EMAIL_QUOTE, UPDATE_EMAIL_RISK_DATA } from './constants'
import { call, put, takeLatest, select } from 'redux-saga/effects'

import { getRiskData, sendEmailSelector, getPriceData } from './selector'
import EmailQuoteApi from '../../api/emailQuoteApi'
import {
  sendEmailQuoteFailure,
  sendEmailQuoteSuccess,
  updateEmailRiskDataSuccess,
} from './actions'
import { updateRiskDataSaga } from '../RiskDataCollectionPage/sagas/riskData'

function* sendEmailQuote() {
  try {
    const riskData = yield select(getRiskData)
    const priceData = yield select(getPriceData)
    const emailLink = yield select(sendEmailSelector)
    var premium = priceData.TotalPrice.toFixed(2)

    const data = {
      Email: riskData.EmailAddress,
      QuoteId: {
        value: riskData.QuoteId,
      },
      QuoteReminders: [
        {
          Premium: premium,
          Underwriter: priceData.Underwriter,
        },
      ],
    }
    const response = yield call(
      EmailQuoteApi.sendQuoteEmail,
      emailLink.Href,
      data,
    )
    yield* updateRiskDataSaga({
      payload: { partialValidation: false, getPrice: true },
    })
    yield put(sendEmailQuoteSuccess(response.data))
  } catch (e) {
    yield put(sendEmailQuoteFailure(e))
  }
}

function* updateEmailRiskData() {
  try {
    yield* updateRiskDataSaga({
      payload: { partialValidation: false, getPrice: true },
    })
    yield put(updateEmailRiskDataSuccess())
  } catch (e) {
    yield put(sendEmailQuoteFailure(e))
  }
}

export default function* emailQuoteSaga() {
  yield takeLatest(SEND_EMAIL_QUOTE, sendEmailQuote)
  yield takeLatest(UPDATE_EMAIL_RISK_DATA, updateEmailRiskData)
}
