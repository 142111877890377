import styled from 'styled-components'
import { BaseInput } from './BaseInput'

export const DateInput = styled(BaseInput)`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  padding: 0px 10px;
  color: rgb(38, 75, 148);
  font-size: 18px;
  width: 100%;
  font-family: freight-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  box-sizing: border-box;
  border: none;
  height: 100%;
  margin: 0px;
  text-align: center;
  outline: none;
  :focus {
    border: 1px solid #000;
    outline: 1px solid #000;
  }
`
