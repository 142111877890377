import React from 'react'
import { faCog, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import {
  CookieConsentFullScreenWrapper,
  CookieConsentBannerWrapper,
  CookieConsentButton,
  CookieConsentHeader,
  CookieConsentText,
  CookieConsentLink,
  ManageCookieButton,
  ButtonIcon,
  CookieButtonWrapper,
} from './CookieConsentBanner.styles'
import CookiePolicyModal from '../CookiePolicyModal'
import CookieManagementArea from '../CookieManagementArea'
import {
  COOKIE_INFORMATION_MODAL_NAME,
  COOKIE_MANAGEMENT_MODAL_NAME,
} from './CookieConsentBanner.constants'

const CookieConsentBanner = ({
  onClick,
  onLearnMoreClick,
  isCookieInformationModalVisible,
  isCookieManagementModalVisible,
  onModalClose,
  onManageCookiesClick,
}) => {
  const handleLearnMoreCookies = (e) => {
    e.preventDefault()
    onLearnMoreClick()
  }

  const handleManageCookies = () => {
    onManageCookiesClick()
  }

  return (
    <>
      <CookieConsentFullScreenWrapper>
        <CookieConsentBannerWrapper>
          <CookieConsentHeader>Cookie notice</CookieConsentHeader>
          <CookieConsentText>
            We use essential cookies to make our website work. We’d also like to
            use non-essential cookies to help us improve our website by
            collecting and analysing information on how you use our website and
            for advertising purposes. You can agree to accept all cookies by
            clicking 'Accept all cookies' or you can change your preferences by
            clicking 'Manage Cookies' below. For more information about the
            cookies we use, see our {/* TODO: Style this to look like a link */}
            <CookieConsentLink href={null} onClick={handleLearnMoreCookies}>
              cookies policy
            </CookieConsentLink>
            .
          </CookieConsentText>
          <CookieButtonWrapper>
            <ManageCookieButton onClick={handleManageCookies}>
              Manage Cookies
              <ButtonIcon icon={faCog} />
            </ManageCookieButton>
            <CookieConsentButton
              id='CookieConsentButton'
              type='button'
              onClick={() => onClick()}
            >
              Accept all cookies
              <ButtonIcon icon={faArrowCircleRight} />
            </CookieConsentButton>
          </CookieButtonWrapper>
        </CookieConsentBannerWrapper>
      </CookieConsentFullScreenWrapper>
      <CookiePolicyModal
        isVisible={isCookieInformationModalVisible}
        onClose={() => onModalClose(COOKIE_INFORMATION_MODAL_NAME)}
      />
      <CookieManagementArea
        onLearnMoreClick={onLearnMoreClick}
        onCookieSaveClick={onClick}
        isVisible={isCookieManagementModalVisible}
        onClose={() => onModalClose(COOKIE_MANAGEMENT_MODAL_NAME)}
      />
    </>
  )
}

export default CookieConsentBanner
