import {
  UPDATE_VALIDATION_CONTACT_US_PAGE,
  SUBMIT_CONTACT_US_REQUEST,
} from './constants'

import {
  toggleContactUsFormIsValid,
  submitContactUsDataSuccess,
  submitContactUsDataFailure,
} from './actions'

import { put, takeLatest, select, call } from 'redux-saga/effects'

import { getValidation, getContactUsFormData } from './selector'

import contactUsApi from '../../api/contactUsApi'
import { getSubDomain } from '../../services/handlers'
import { history } from '../..'
import { ROUTES } from '../../services/constants'

function* formValidation() {
  const validation = yield select(getValidation)
  const data = yield select(getContactUsFormData)
  const fields = validation.fields
  const validForm = Object.keys(fields).every((key) => {
    return fields[key] === ''
  })
  yield put(
    toggleContactUsFormIsValid({
      valid:
        validForm && !!data.ReCAPTCHA_Token && data.ReCAPTCHA_Token.length > 0,
    }),
  )
}

function* submitContactUsData() {
  try {
    const data = yield select(getContactUsFormData)
    const subdomain = getSubDomain()

    var payload = {
      FullName: data.FullName,
      RegistrationNumber: data.RegistrationNumber,
      PolicyNumber: data.PolicyNumber,
      TelephoneNumber: data.MobileNumber,
      Email: data.EmailAddress,
      Comment: data.CommentBox,
      ContactUsEnquiryType: data.EnquiryType,
      ReCaptcha: data.ReCAPTCHA_Token,
      Introducer: subdomain,
    }

    yield call(
      contactUsApi.createContactUsRequest,
      'api/contactUs/create',
      payload,
    )

    yield put(submitContactUsDataSuccess())
    yield* formValidation()
    history.push(ROUTES.CONTACT_US_THANK_YOU)
  } catch (e) {
    yield put(submitContactUsDataFailure())
  }
}

export default function* contactUsPageSaga() {
  yield takeLatest(UPDATE_VALIDATION_CONTACT_US_PAGE, formValidation)
  yield takeLatest(SUBMIT_CONTACT_US_REQUEST, submitContactUsData)
}
