import React from 'react'
import PropTypes from 'prop-types'
import { P, SectionWrapper } from './styles.js'
import { Grid, GridContainer, GridClear } from 'unsemantic'
import VehicleReg from '../../../components/VehicleReg'
import styled from 'styled-components'

const Wrapper = styled(SectionWrapper)`
  padding-bottom: 10px;
`

const VehicleRegSection = ({ reg }) => {
  return (
    <GridContainer>
      <Wrapper>
        <Grid desktop='20' tablet='20' mobile='30'>
          <P>Vehicle Reg</P>
        </Grid>
        <Grid desktop='80' tablet='80' mobile='70'>
          <VehicleReg reg={reg} />
        </Grid>
        <GridClear />
      </Wrapper>
    </GridContainer>
  )
}

VehicleRegSection.propTypes = {
  reg: PropTypes.string,
}

export default VehicleRegSection
