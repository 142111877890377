import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import config from '../../config'
import useComponentVisible from '../../hooks/useComponentVisible'
import { GapMoreInfoItem } from '../GapModal/GapMoreInfoItem'
import {
  GapMoreInfoBody,
  GapMoreInfoText,
  GapMoreInfoTitle,
  GapMoreInfoWrapper,
  LinkContainer,
  PaymentPageLinkContainer,
  Wrapper,
} from '../GapModal/styles'
import { GapLinkText } from './styles'

export const GapActivationMoreInfo = () => {
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false)
  const documentStartDate = new Date().toISOString().slice(0, 10)
  const handleClick = useCallback(() => {
    setIsComponentVisible(true)
  }, [isComponentVisible])
  const scheme = useSelector((state) => state.scheme.selected)
  const GapMoreInfo = () => {
    return (
      <>
        <GapMoreInfoTitle>More info about GAP Insurance</GapMoreInfoTitle>
        <GapMoreInfoBody>
          <div>
            <GapMoreInfoText>
              GAP Insurance protects the lifetime value of your vehicle by
              topping up your main insurer&apos;s payout in the unfortunate
              event your vehicle is written off or stolen (and not recovered).
            </GapMoreInfoText>
            <GapMoreInfoText>
              <b>Why would you need GAP Insurance?</b>
              <br />
              If your vehicle is declared a total loss, your motor insurer will
              payout the value of your vehicle at the time of your claim. Given
              most vehicles lose up to 60% of their original value during their
              first 3 years, that payout can be less than expected and usually,
              a lot less than you originally paid for your vehicle.
            </GapMoreInfoText>
            <GapMoreInfoText>
              <b>How does it work?</b>
              <br />
              Our Free 30 Days GAP Insurance works by topping up a motor
              insurer&apos;s payout, covering the shortfall, up to the
              vehicle&apos;s original value when your GAP policy was issued.
              This will allow you to get the additional funds needed to purchase
              a replacement vehicle or payoff any outstanding finance you might
              owe.
            </GapMoreInfoText>
            <GapMoreInfoText>
              <b>
                After the 30 days free period, it&apos;s your choice whether you
                extend as this policy will expire. We will email you details on
                how you can do this.
              </b>
            </GapMoreInfoText>
            <GapMoreInfoText>
              To be valid for a claim, the following must apply:
              <ol style={{ listStyle: 'disc' }}>
                <li>
                  You must be the registered owner and, or keeper of the vehicle
                </li>
                <li>The vehicle must be under 8 years old</li>
                <li>Less than 70,000 recorded miles</li>
                <li>Insured value of less than £75,000.</li>
                <li>Be UK specification and not modified</li>
              </ol>
            </GapMoreInfoText>
            <GapMoreInfoText>See links below for details:</GapMoreInfoText>
          </div>
          <ul>
            <GapMoreInfoItem
              content='GAP Insurance IPID'
              doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/Free30DayGAPIPID/versions/${documentStartDate}`}
            />
            <GapMoreInfoItem
              content='GAP Insurance Policy Wording'
              doc={`${config.BASE_API_URL}/api/scheme/${scheme}/documents/Free30DayGAPPolicyWording/versions/${documentStartDate}`}
            />
          </ul>
        </GapMoreInfoBody>
      </>
    )
  }

  return (
    <Wrapper ref={ref}>
      <GapLinkText>
        <LinkContainer id={`addon-detailslink-gap`} onClick={handleClick}>
          Click Here
        </LinkContainer>{' '}
        to find out more.
      </GapLinkText>
      {isComponentVisible && (
        <GapMoreInfoWrapper id={`addon-detailsmodal-gap`}>
          <GapMoreInfo></GapMoreInfo>
          <hr />
          <div>
            <PaymentPageLinkContainer
              id={`addon-closedetails-gap`}
              onClick={() => setIsComponentVisible(false)}
            >
              Close
            </PaymentPageLinkContainer>
          </div>
        </GapMoreInfoWrapper>
      )}
    </Wrapper>
  )
}
export default GapActivationMoreInfo
