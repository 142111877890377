import styled from 'styled-components'
import { H1 } from '../../components/common/H1'
import { H2 } from '../../components/common/H2'
import { H3 } from '../../components/common/H3'
import { P } from '../../components/common/P'
import MobileTelephone from '../../components/MobileTelephone'
import { Button } from '../../components/common/Button'

export const ContactUsPageContainer = styled.div`
  text-align: left;

  span {
    width: 45px;
    height: 45px;
    font-size: 20px;
    line-height: 45px;
    top: 0;
  }

  #ContactUs_RegistrationNumber {
    font-size: 18px;
    text-align: left !important;
    padding: 0 0 0 55px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 48px;
      height: 45px;
    }
  }

  #ContactUs_RegistrationNumber::placeholder {
    font-size: 18px;
    text-align: left !important;
    padding: 0px;
  }
`

export const Headline = styled(H1)`
  font-family: 'BrandonText-Bold', san-serif !important;
  font-size: 32px;
  max-width: none;
  color: #333;
  display: block;
  font-weight: 700;
  margin: 0.67em 0;
  text-align: left;
`

export const SubHeadline = styled(H2)`
  font-family: 'BrandonText', san-serif !important;
  font-size: 24px;
  max-width: none;
  display: block;
  font-weight: 400;
  margin: 0.83em 0;
  text-align: left;
`

export const ByPostHeadline = styled(H3)`
  font-family: 'BrandonText', san-serif !important;
  color: #264694;
  font-size: 1.17em;
  max-width: none;
  display: block;
  font-weight: 400;
  margin: 1em 0;
  text-align: left;
`

export const P2 = styled(P)`
  font-family: 'BrandonText', Arial, sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  display: block;
  text-size-adjust: 100%;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

export const Telephone = styled(MobileTelephone)`
  text-align: center !important;
`

export const A = styled.a`
  font-family: 'BrandonText', Arial, sans-serif !important;
  text-decoration: none;
  text-size-adjust: 100%;
  font-size: 18px;
  font-weight: 400;
  color: ${(props) => props.theme.primaryColour};
  @media (max-width: 1024px) {
    font-size: 18px;
  }
`

export const TextArea = styled.textarea`
  height: 160px;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  font-family: 'freight-sans-pro', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  border: 1px solid ${(props) => props.theme.primaryColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  box-sizing: border-box;

  ::placeholder {
    text-transform: none;
    letter-spacing: 0;
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 18px;
  }
`

export const SubmitButton = styled(Button)`
  font-family: 'BrandonText', sans-serif;
  font-size: 22px;
  font-weight: 400;
  width: 100%;
  max-width: 395px;
  margin: 15px 0 0;

  &.disabled {
    background: #777;
    cursor: not-allowed;
    pointer-events: none;
  }

  :hover {
    background-color: #0e2a6e;
  }
`

export const Label = styled.label`
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  font-family: 'BrandonText', sans-serif;
`

export const Red = styled.span`
  color: #790000;
`

export const PaddingReset = styled.div`
  margin: 0 -10px;
`
