import {
  REQUEST_CALLBACK,
  REQUEST_CALLBACK_SUCCESS,
  REQUEST_CALLBACK_FAILURE,
} from './constants'

export function requestCallback(data) {
  return {
    type: REQUEST_CALLBACK,
    data,
  }
}

export function requestCallbackSuccess() {
  return {
    type: REQUEST_CALLBACK_SUCCESS,
  }
}

export function requestCallbackFailure(payload) {
  return {
    type: REQUEST_CALLBACK_FAILURE,
    payload,
  }
}
