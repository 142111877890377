import {
  REQUEST_LEGAL_DECLARATION,
  REQUEST_LEGAL_DECLARATION_SUCCESS,
} from './constants'

export function requestLegalDeclaration(callback) {
  return {
    type: REQUEST_LEGAL_DECLARATION,
    callback,
  }
}

export function requestLegalDeclarationSuccess(legalDeclaration) {
  return {
    type: REQUEST_LEGAL_DECLARATION_SUCCESS,
    legalDeclaration,
  }
}
