import styled from 'styled-components'

export const SummaryBorderMobile = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.primaryColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  background: #fff;
`

export const SummaryBorderLeftMobile = styled.div`
  border-left: 1px solid ${(props) => props.theme.primaryColour};
`

export const SummaryBorderBottomMobile = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.primaryColour};
`

export const SummarySection = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.primaryColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  background: #fff;
  margin: 10px 0;
  padding: 20px;
  cursor: pointer;
  text-align: left;

  & .quote-summary-title {
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 14px;
    margin: 0;

    &.vehicle {
      text-align: center;
    }
  }

  & .quote-summary-text {
    font-size: 22px;
    color: ${(props) => props.theme.primaryColour};
    margin: 0;

    &.vehicle {
      text-align: center;
    }
  }

  & .registration {
    position: relative;
    font-family: ${(props) => props.theme.regFont};
    color: #000;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 40px;
    line-height: 48px;
    margin: 10px 0 0 0;
    text-align: center;
    border: 1px solid ${(props) => props.theme.primaryColour};
    border-radius: ${(props) => props.theme.formControlBorderRadius};
    height: 50px;
    box-sizing: border-box;

    & .gb-icon {
      position: absolute;
      left: -1px;
      top: -1px;
      width: 50px;
      height: 50px;
      font-family: 'BrandonText-Bold', sans-serif;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 1px;
      line-height: 50px;
      background-color: ${(props) => props.theme.primaryColour};
      color: #fff;
      border-radius: ${(props) => props.theme.formControlBorderRadius};
      box-sizing: border-box;
    }
  }

  & .fa-pencil-alt {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #ccc;
    cursor: pointer;
  }

  & .upsell-info {
    background-color: ${(props) => props.theme.primaryColour};
    color: #fff;
    padding: 10px;
    border-radius: ${(props) => props.theme.formControlBorderRadius};
    margin: 20px 0 10px 0;
    text-align: center;
  }

  & .upsell-choice-btn {
    width: 100%;
    margin: 10px 0;
  }

  & .upsell-button {
    background-color: #779e00;
    font-size: 24px

    &:hover {
      background-color: #779e00;
    }
  }

  & .no-upsell-btn {
    background-color: #fff;
    color: ${(props) => props.theme.primaryColour};
    border: 1px solid ${(props) => props.theme.primaryColour};

    &:hover {
      background-color: #fff;
    }
  }
  @media (max-width: 767px) {
    padding: 6px;
    margin: 0;
    border: ${(props) =>
      props.showBorderOnMobile
        ? `1px solid ${props.theme.primaryColour}`
        : 'none'};

    & .quote-summary-title {
      font-size: 10px;
    }
    & .quote-summary-text {
      font-size: 12px;
    }
    & .registration {
      font-size: 24px;
      line-height: 28px;
      height: 30px;
      padding-left: 28px;

      & .gb-icon {
        width: 30px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
      }
    }
    & .fa-pencil-alt {
      top: 8px;
      right: 8px;
      font-size: 12px;
    }
  }
`

export const PolicyDurationSummary = styled(SummarySection)`
  cursor: unset;
`
export const SummarySectionSmall = styled(SummarySection)`
  margin-right: 15px;
  cursor: default;
`
