import styled from 'styled-components'
import { H1 } from '../../components/common/H1'
import { P } from '../../components/common/P'

import { Button } from '../../components/common/Button'

export const Headline = styled(H1)`
  font-family: 'BrandonText-Bold', Arial, san-serif;
  font-size: 44px;
  max-width: none;
  color: ${(props) => props.theme.headlineColour};
  padding: 10px;
  font-weight: 700;
  margin: auto;

  @media (max-width: 1024px) {
    font-size: 30px;
  }

  @media (max-width: 320px) {
    font-size: 18px;
  }
`

export const CtobLink = styled.a`
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  color: ${(props) => props.theme.primaryColour};
  :hover {
    text-decoration: underline;
  }
`
export const BranchContainer = styled.div`
  max-width: 395px;
  margin: auto;
`

export const NoWrapSpan = styled.span`
  white-space: nowrap;

  @media (max-width: 1024px) {
    white-space: normal;
  }
`

export const TeslaForm = styled.form`
  padding: 10px 0;
`

export const P2 = styled(P)`
  font-family: 'freight-sans-pro', sans-serif !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  margin-top: ${(props) => (props.noTopMargin ? '0' : 'auto')};

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`

export const P2Light = styled(P2)`
  font-weight: 400;
  margin-top: 0;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`

export const P3 = styled(P)`
  font-family: 'BrandonText', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  padding: 0 120px;

  @media (max-width: 1024px) {
    padding: 0;
  }
`

export const RegWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryColour};
  position: relative;
  width: 100%;
  max-width: 395px;
  margin: auto;
  display: inline-block;
  border-radius: 6px;
`

export const HomePageContainer = styled.div`
  text-align: center;
  margin: 0 -10px;
`

export const RegInputStyling = styled.input`
  font-family: 'Charles-Wright', Arial, san-serif;
  font-size: 24px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 50px;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  display: block;
  width: 100%;
  background: #fff;
  border: ${(props) => props.theme.infoBorderProperties};
  border-radius: 6px;
  height: 50px;
  box-sizing: border-box;
  -webkit-appearance: none;
`

export const GBIcon = styled.div`
  font-family: 'BrandonText', sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.theme.primaryColour};
  color: #fff;
  border-radius: 6px;
`

export const TeslaQuoteNextButton = styled(Button)`
  font-family: 'BrandonText', sans-serif;
  font-size: 22px;
  font-weight: 400;
  width: 100%;
  max-width: 395px;
  margin: 15px 0 0;

  &.disabled {
    background: #777;
    cursor: not-allowed;
    pointer-events: none;
  }

  :hover {
    background-color: ${(props) => props.theme.button.hoverColour};
  }

  @media (max-width: 767px) {
    font-size: 18px;
  }
`

export const RegValidation = styled.p`
  position: absolute;
  top: 97%;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 14px;
  color: #ff0508;
  font-family: 'freight-sans-pro', sans-serif !important;
  font-weight: 600;
`
