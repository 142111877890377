import React from 'react'
import Modal from '../common/NewModal'
import Toggle from '../common/Toggle'
import {
  CookieManagementButton,
  CookieManagementButtonWrapper,
} from './CookieManagementArea.styles'
import { ReactComponent as TickIcon } from '../../images/Green-Tick.svg'

const CookieManagementModal = ({
  isVisible,
  onClose,
  optionalCookies,
  toggleOptionalCookies,
  onClick,
}) => {
  const handleClose = () => {
    onClose()
  }
  return (
    <Modal
      hasHeaderBorder
      id='CookieManagementModal'
      header={'Manage your cookies'}
      isVisible={isVisible}
      onClose={handleClose}
      shouldModalHandleOverflow={false}
      allowOutsideClose={false}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            flexBasis: 0,
          }}
        >
          {/* Giving it the same with as the toggles */}
          <div style={{ display: 'flex', justifyContent: 'center', width: 56 }}>
            <TickIcon height={30} width={30} />
          </div>
          <span style={{ marginLeft: 6 }}>On</span>
        </div>
        <div style={{ flexGrow: 3, flexBasis: 0 }}>
          <p style={{ fontSize: 12, marginLeft: 8 }}>
            We use essential cookies which are necessary to ensure our website
            works correctly.
          </p>
        </div>
      </div>
      {optionalCookies.map((cookie) => (
        <div
          key={cookie.name}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <Toggle
              checked={cookie.value}
              onChange={() => toggleOptionalCookies(cookie.id)}
              uncheckedIcon={null}
              extraText={cookie.value ? 'Yes' : ' No'}
            />
          </div>
          <div style={{ flexGrow: 3, flexBasis: 0 }}>
            <p style={{ fontSize: 12, marginLeft: 8 }}>{cookie.description}</p>
          </div>
        </div>
      ))}
      <CookieManagementButtonWrapper>
        <CookieManagementButton
          id='CookieManagementButton'
          type='button'
          onClick={onClick}
        >
          Save cookie preferences
        </CookieManagementButton>
      </CookieManagementButtonWrapper>
    </Modal>
  )
}

export default CookieManagementModal
