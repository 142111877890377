import styled from 'styled-components'

export const RiskDataValue = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-family: ${(props) => props.theme.labelFontFamily};
  text-align: left;
  display: block;
  float: left;
  margin: 2px 5px 2px 0;
  clear: both;
  color: #000000;

  a {
    padding: 10px 0;
    display: block;
    color: ${(props) => props.theme.primaryColour};
  }
`
