import { takeLatest, put, call } from 'redux-saga/effects'
import { setVehicleError, setVehicleSearchedReg } from '../Vehicle/actions'
import { history } from '../../'
import driveawayApi from '../../api/driveawayApi'
import { setUpsellEnabledSuccess } from './action'

import {
  UPSELL_SET_VEHICLE_ERROR_CODE,
  UPSEll_SET_VEHICLE_REGISTRATION,
  UPSEll_SET_UPSELL_ENABLED,
} from './constants'

function* setVehicleCodeError({ errorCode }) {
  yield put(setVehicleError(errorCode))

  history.replace({
    pathname: '/vehicle/invalid-reg',
    search: window.location.search,
    state: { error: errorCode },
  })
}

function* setVehicleRegistrationNumber({ registration }) {
  yield put(setVehicleSearchedReg(registration, ''))
}

export function* setUpsellEnabledSaga({ subdomain }) {
  const upsellEnabled = yield call(driveawayApi.getUpsellEnabled, subdomain)
  yield put(setUpsellEnabledSuccess(upsellEnabled.data.UpsellEnabled))
}

export default function* upsellDurationSaga() {
  yield takeLatest(UPSELL_SET_VEHICLE_ERROR_CODE, setVehicleCodeError)
  yield takeLatest(
    UPSEll_SET_VEHICLE_REGISTRATION,
    setVehicleRegistrationNumber,
  )
  yield takeLatest(UPSEll_SET_UPSELL_ENABLED, setUpsellEnabledSaga)
}
