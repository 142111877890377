import React from 'react'
import { BaseInput } from './BaseInput'
import { FormControlWrapper } from '../../FormControlWrapper'
import { ErrorMessage } from '../ErrorMessage'
import { toTitleCase } from '../../../services/handlers'

export const Input = ({
  id,
  placeholder,
  errorMessage,
  error,
  onChange,
  onBlur,
  onFocus,
  name,
  value,
  type,
  style,
  pattern,
  titleCase,
  setFieldValue,
}) => {
  const onInput = (e) => {
    if (titleCase) {
      e.target.value = toTitleCase('' + e.target.value)
    }
  }

  const handleBlur = (e) => {
    if (setFieldValue) {
      setFieldValue(name, ('' + e.target.value).trim())
    }
    onBlur(e)
  }

  return (
    <FormControlWrapper>
      {error && errorMessage && (
        <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>
      )}
      <BaseInput
        placeholder={placeholder}
        error={error}
        onInput={onInput}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        name={name}
        id={id}
        value={value}
        type={type}
        style={style}
        autoComplete='off'
        autoCorrect='off'
        pattern={pattern}
      />
    </FormControlWrapper>
  )
}
