import styled from 'styled-components'

export const BaseRadioButton = styled.input`
  opacity: 0;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  left: 1em;
  top: calc(50% - 0.75em);
  line-height: normal;

  :checked + label {
    background: rgb(232, 240, 255);
  }

  :checked + label:after {
    opacity: 1;
    background-color: #234397;
    border: 9px solid #234397;
    transform: translateY(-50%) translateX(-50%) scale(0.6);
  }
`
