import styled from 'styled-components'
import { SummarySection } from '../../containers/QuoteSummary/SummarySectionStyles'

export const UpsellSummarySection = styled(SummarySection)`
  cursor: default;

  & .upsell-choice-btn,
  & .upsell-button,
  & .no-upsell-btn {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin-top: 15px;
  }
`
