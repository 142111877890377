import styled from 'styled-components'
import { H1 as Common_H1 } from '../common/H1'
import { H2 as Common_H2 } from '../common/H2'
import { H3 as Common_H3 } from '../common/H3'
import { A as Common_A } from '../common/A'
import { P as Common_P } from '../common/P'

export const H1 = styled(Common_H1)`
  font-family: 'BrandonText-Bold', san-serif !important;
  font-size: 32px;
  max-width: none;
  color: #333;
  display: block;
  font-weight: 700;
  margin: 0.67em 0;
  text-align: left;
`

export const H2 = styled(Common_H2)`
  font-family: 'BrandonText', san-serif !important;
  font-size: 24px;
  max-width: none;
  display: block;
  font-weight: 400;
  margin: 0.83em 0;
  text-align: left;
`

export const BlueH2 = styled.h2`
  font-size: 22px;
  font-family: ${(props) => props.theme.headerFontFamily};
  color: ${(props) => props.theme.headerFontColour};
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0;
`

export const H3 = styled(Common_H3)`
  font-family: 'BrandonText-Bold', san-serif !important;
  font-size: 18px;
  max-width: none;
  color: #333;
  display: block;
  font-weight: 400;
  text-align: left;
`

export const P = styled(Common_P)`
  font-family: 'BrandonText', Arial, sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  display: block;
  text-size-adjust: 100%;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const A = styled(Common_A)`
  font-family: 'BrandonText', Arial, sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  display: inline-block;
  text-size-adjust: 100%;
  text-decoration: none;
  border-bottom: none;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const Italic = styled(P)`
  font-style: italic;
`

export const OL_D = styled.ol`
  list-style-type: decimal;
`

export const OL_A = styled.ol`
  list-style-type: lower-alpha;
`

export const UL_C = styled.ul`
  list-style-type: circle;
`

export const LI = styled.li`
  font-family: 'BrandonText', Arial, sans-serif !important;
  font-size: 18px;
  color: #333;
`

export const Centered_H1 = styled(H1)`
  text-align: center;
  font-weight: bolder;
`

export const Centered_H3 = styled(H3)`
  text-align: center;
  font-weight: bolder;
`

export const Blue = styled.span`
  color: #4658a3;
`
export const StaticPageHeader = styled.span`
  color: ${(props) => props.theme.staticHeaderColour};
`

export const CallUsNumber = styled.span`
  color: ${(props) => props.theme.callUsNumberColour};
`

export const Green = styled.span`
  color: #a4c733;
`
