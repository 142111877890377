import styled from 'styled-components'

export const H3 = styled.h3`
  font-size: ${(props) => props.theme.generalFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  color: #000;
  text-align: center;
`
export const BlueH3 = styled.h3`
  font-size: ${(props) => props.theme.generalFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.headerFontColour};
  font-weight: 600;
  text-align: center;
`
