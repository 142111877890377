export const REQUEST_OCR = 'src/DrivingLicenceCamera/REQUEST_OCR'
export const REQUEST_OCR_FRONT_SUCCESS =
  'src/DrivingLicenceCamera/REQUEST_OCR_FRONT_SUCCESS'
export const REQUEST_OCR_REAR_SUCCESS =
  'src/DrivingLicenceCamera/REQUEST_OCR_REAR_SUCCESS'

export const REQUEST_OCR_ADDRESS =
  'src/DrivingLicenceCamera/REQUEST_OCR_ADDRESS'
export const REQUEST_OCR_ADDRESS_SUCCESS =
  'src/DrivingLicenceCamera/REQUEST_OCR_ADDRESS_SUCCESS'
export const REQUEST_OCR_ADDRESS_FAILURE =
  'src/DrivingLicenceCamera/REQUEST_OCR_ADDRESS_FAILURE'

export const ALLOW_CAMERA = 'src/App/ALLOW_CAMERA'
