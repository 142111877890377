import React, { Component } from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  createStripePayment,
  createStripePaymentFailure,
  getPriceInfo,
} from './actions'
import {
  isButtonDisabled,
  enableAllButtons,
} from '../RiskDataCollectionPage/actions'
import { ButtonSpinner } from '../../components/common/ButtonSpinner'
import { StripePaymentButton } from './styles'
import { fraudFailure, setPaymentInProgress } from '../PaymentPage/actions'
import LoadingScreen from '../../components/LoadingScreen'
import './stripe.css'

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  }
}

class CheckoutForm extends Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.state = {
      cardElement: null,
      loading: true,
    }
  }

  async submit(e) {
    if (this.state.cardElement) {
      this.props.actions.createStripePaymentFailure('')
      this.props.actions.isButtonDisabled({
        buttonName: 'paymentButton',
        disabled: true,
      })
      this.props.actions.getPriceInfo({
        stripeActions: this.props.stripe,
        actions: this.props.actions,
        stripeElement: this.state.cardElement,
      })
    }
  }

  handleReady = (element) => {
    this.setState({ cardElement: element, loading: false })
  }

  render() {
    return (
      <>
        {this.state.loading && <LoadingScreen message='Loading' />}
        <CardElement
          {...createOptions}
          hidePostalCode={true}
          onReady={this.handleReady}
        />
        {!this.state.loading && (
          <StripePaymentButton
            id='StripePaymentButton'
            onClick={this.submit}
            disabled={this.props.paymentButtonDisabled}
            data-email={this.props.riskData.EmailAddress}
          >
            {' '}
            Pay £{this.props.price.TotalPrice.toFixed(2)}{' '}
            <ButtonSpinner disabled={this.props.paymentButtonDisabled} />
          </StripePaymentButton>
        )}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    price: state.price,
    riskData: state.riskData,
    paymentButtonDisabled: state.formButtons.paymentButton.disabled,
    quote: state.quote,
  }
}

export const mapDispatchtoProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        createStripePayment,
        isButtonDisabled,
        enableAllButtons,
        createStripePaymentFailure,
        fraudFailure,
        setPaymentInProgress,
        getPriceInfo,
      },
      dispatch,
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps,
)(injectStripe(CheckoutForm))
