import { UPDATE_ADDITIONAL_DRIVER, TOGGLE_ADDITIONAL_DRIVER } from './constants'
import initialState from '../../initialState'

function additionalDriverReducer(
  state = initialState.riskData.AdditionalDrivers,
  action,
) {
  switch (action.type) {
    case TOGGLE_ADDITIONAL_DRIVER:
      return action.additionalDriverSelected
        ? [
            ...state,
            {
              Forename: '',
              Surname: '',
              DateOfBirth: null,
              LicenceType: 'FullUkLicence',
            },
          ]
        : []
    case UPDATE_ADDITIONAL_DRIVER:
      // eslint-disable-next-line array-callback-return
      return state.map((additionalDriver, index) => {
        if (index === action.additionalDriverNumber) {
          return {
            ...additionalDriver,
            ...action.fields,
          }
        }
      })
    default:
      return state
  }
}

export default additionalDriverReducer
