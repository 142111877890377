import styled from 'styled-components'

export const MobileNavWrapper = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`

export const MobileNavButton = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
  height: 65px;
  background: ${(props) => props.theme.mobileHamburgerMenu};
  text-align: center;
  padding: 0;
  margin: 0;
  text-shadow: none;
  border-radius: 0;
  cursor: pointer;
  z-index: 4;
`

export const MobileNavButtonText = styled.span`
  margin: 0;
  color: #fff;
  font-family: 'freight-sans-pro', sans-serif;
  font-size: 12px;
  position: absolute;
  top: 37px;
  left: 0;
  right: 0;
  text-shadow: none;
  font-weight: 700;
  line-height: 1.188em;
`

export const MobileNavButtonIcon = styled.span`
  position: absolute;
  display: block;
  width: 16px;
  height: 12px;
  top: 20px;
  left: 50%;
  margin: 0 0 0 -8px;

  span {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #fff;
    transition: opacity 0.25s ease, transform 0.25s ease, top 0.25s ease,
      margin 0.25s ease;
  }

  span:nth-child(2) {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    background: 0 0;
  }

  span:nth-child(2)::before {
    content: ' ';
    display: block;
    height: 2px;
    width: 5px;
    border-radius: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
  }

  span:nth-child(2)::after {
    content: ' ';
    display: block;
    height: 2px;
    width: 5px;
    border-radius: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
  }

  span:last-child {
    top: auto;
    bottom: 0;
  }

  &.active span:first-child {
    transform: rotate(45deg);
    top: 50%;
    margin-top: -1px;
  }

  &.active span:nth-child(2) {
    opacity: 0;
  }

  &.active span:last-child {
    transform: rotate(-45deg);
    top: 50%;
    margin-top: -1px;
  }
`

export const MobileNavLinksWrapper = styled.ul`
  position: absolute;
  top: -200px;
  width: 100%;
  background: #e5e5e5;
  padding: 8px 0;
  list-style: none;
  margin: 0;
  transition: top 0.5s;
  z-index: 3;

  &.active {
    top: 65px;
  }
`

export const MobileNavLinks = styled.li`
  display: block;
  text-align: left;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;

  a {
    text-decoration: none;
    color: #333;
    padding: 8px 10px;
    font-family: 'freight-sans-pro', sans-serif;
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
    display: block;
  }

  :hover {
    text-decoration: underline;
    background-color: #c5c5c5;
  }
`
