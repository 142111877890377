import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HOURS, MINUTES, NAMED_DAYS } from './constants'
import {
  DualInput,
  DualInputWrapper,
  FieldLabel,
  IndentedLine,
  PolicyStartTimeWrapper,
  TimeSeparator,
  TimeWrapper,
} from './styles'
import { RadioButton } from '../RadioButton'
import { Select } from '../Select'
import { ErrorMessage } from '../ErrorMessage'

const createDates = (date, days) => {
  let dates = []
  for (let index = 0; index <= days; index++) {
    const newDate = date.clone().add(index, 'days')
    const desc = newDate.format('ddd, Do MMMM YYYY')
    const addition = NAMED_DAYS[index]

    dates.push({
      id: index,
      value: newDate.format('YYYY-MM-DD'),
      description: addition ? `${desc} (${addition})` : desc,
    })
  }
  return dates
}

const SplitPolicyStartTimePicker = ({
  id,
  name,
  value,
  errors,
  touched,
  canFutureDate,
  setFieldValue,
  setFieldTouched,
}) => {
  const [state, setState] = useState(value)

  useEffect(() => {
    const { date, hour, minute } = state

    const manuallyEdited = Boolean(date || hour || minute)

    setFieldValue('PolicyStartDate', {
      date: manuallyEdited ? date : '',
      hour: manuallyEdited ? hour : '',
      minute: manuallyEdited ? minute : '',
      manuallyEdited: manuallyEdited,
    })
  }, [state])

  const dates = createDates(moment(), 30)
  const dateInPastError =
    Boolean(errors) && (typeof errors === 'string' || errors instanceof String)

  const updateDate = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleBlur = (e) => {
    setFieldTouched(`PolicyStartDate.${e.target.name}`)
  }

  return (
    <>
      {(Boolean(errors.date && touched.date) ||
        Boolean(errors.hour && touched.hour) ||
        Boolean(errors.minute && touched.minute)) && (
        <ErrorMessage id={`${name}Error`}>
          {errors.date || errors.hour || errors.minute}
        </ErrorMessage>
      )}
      {dateInPastError && (
        <ErrorMessage id={`${name}Error`}>{errors}</ErrorMessage>
      )}
      {!canFutureDate && (
        <>
          <DualInputWrapper>
            <DualInput>
              <RadioButton
                id={`${id}_Immediately`}
                name={name}
                label='Immediately'
                value='1'
                checked={!Boolean(value.manuallyEdited)}
                error={Boolean(errors.date && touched.date)}
                type='radio'
                onChange={(e) => {
                  if (e.target.checked) {
                    setState({
                      date: '',
                      hour: '',
                      minute: '',
                    })
                  }
                }}
              />
            </DualInput>
            <DualInput>
              <RadioButton
                id={`${id}_ChangeStartTime`}
                name={name}
                label='Change start time'
                value='1'
                checked={Boolean(value.manuallyEdited)}
                error={Boolean(errors.date && touched.date)}
                type='radio'
                onChange={(e) => {
                  if (e.target.checked) {
                    setState({
                      ...state,
                      date: moment().format('YYYY-MM-DD'),
                    })
                  }
                }}
              />
            </DualInput>
          </DualInputWrapper>
          {value.manuallyEdited && (
            <IndentedLine>
              <PolicyStartTimeWrapper>
                <FieldLabel>
                  What time do you need the cover to start?
                </FieldLabel>
                <TimeWrapper>
                  <Select
                    id={`${id}_Hour`}
                    placeholder=' HH '
                    name='hour'
                    ariaLabel='Change Start Hour'
                    value={state.hour}
                    options={HOURS}
                    onChange={updateDate}
                    onBlur={handleBlur}
                    error={
                      Boolean(touched.hour && errors.hour) || dateInPastError
                    }
                  />
                  <TimeSeparator>:</TimeSeparator>
                  <Select
                    id={`${id}_Minute`}
                    placeholder=' MM '
                    name='minute'
                    ariaLabel='Change Start Minute'
                    value={state.minute}
                    options={MINUTES}
                    onChange={updateDate}
                    onBlur={handleBlur}
                    error={
                      Boolean(touched.minute && errors.minute) ||
                      dateInPastError
                    }
                  />
                </TimeWrapper>
              </PolicyStartTimeWrapper>
            </IndentedLine>
          )}
        </>
      )}
      {canFutureDate && (
        <Select
          id={`${id}_Date`}
          placeholder='Select day...'
          name='date'
          value={state.date}
          options={dates}
          onChange={updateDate}
          onBlur={handleBlur}
          error={Boolean(errors.date && touched.date)}
          tinyGap
        />
      )}
      {state.date && canFutureDate && (
        <IndentedLine>
          <PolicyStartTimeWrapper>
            <FieldLabel>What time do you need the cover to start?</FieldLabel>
            <TimeWrapper>
              <Select
                id={`${id}_Hour`}
                placeholder=' HH '
                name='hour'
                value={state.hour}
                options={HOURS}
                onChange={updateDate}
                onBlur={handleBlur}
                error={Boolean(touched.hour && errors.hour) || dateInPastError}
              />
              <div>
                <TimeSeparator>:</TimeSeparator>
              </div>
              <Select
                id={`${id}_Minute`}
                placeholder=' MM '
                name='minute'
                value={state.minute}
                options={MINUTES}
                onChange={updateDate}
                onBlur={handleBlur}
                error={
                  Boolean(touched.minute && errors.minute) || dateInPastError
                }
              />
            </TimeWrapper>
          </PolicyStartTimeWrapper>
        </IndentedLine>
      )}
    </>
  )
}

export default SplitPolicyStartTimePicker
