import styled from 'styled-components'
import { SummaryBorderMobile } from '../QuoteSummary/SummarySectionStyles'
import { Button } from '../../components/common/Button'
import { BaseSelect } from '../../components/common/Select/BaseSelect'
import { H1 } from '../../components/common/H1'
import { H3 } from '../../components/common/H3'

export const MainHeading = styled(H1)`
  color: ${(props) => props.theme.primaryColour};
`

export const I = styled.i`
  color: ${(props) => props.theme.callUsNumberColour};
  font-size: 55px;
`
export const IconWrapper = styled.div`
  text-align: center;
`

export const P = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: ${(props) => props.theme.labelFontSize};
  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.mobileLabelFontSize};
  }
`

export const A = styled.a`
  text-decoration: none;
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  color: ${(props) => props.theme.primaryColour};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const DocumentsWrapper = styled(SummaryBorderMobile)`
  background-color: ${(props) => props.theme.documentWrapperBackground};
  display: block;
  margin: auto;
  max-width: 420px;
  width: 100%;
`

export const SendReasonForCoverButton = styled(Button)`
  width: 124px;
  @media (max-width: 768px) {
    width: 75px;
  }
`

export const SendReasonForCoverSelect = styled(BaseSelect)`
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const SuccessfulReasonForCover = styled(H3)`
  color: ${(props) => props.theme.successColour};
`

export const DocumentPostText = styled.p`
  font-size: 12px;
  text-align: center;
`
