export const YEAR_IN_SECONDS = 365 * 24 * 60 * 60
export const MONTH_IN_SECONDS = 30 * 24 * 60 * 60
export const DAY_IN_SECONDS = 24 * 60 * 60
export const MINUTE_IN_SECONDS = 60

export const COOKIE_MANAGEMENT = {
  name: 'tcCookieManagement',
  length: YEAR_IN_SECONDS,
}

export const COOKIE_SESSION = {
  name: 'tcSesssion',
  length: MINUTE_IN_SECONDS * 30,
}

export const COOKIE_STRIPE_SID = {
  name: '__stripe_sid',
  length: MINUTE_IN_SECONDS * 30,
}

export const COOKIE_STRIPE_MID = {
  name: '__stripe_mid',
  length: YEAR_IN_SECONDS,
}

export const COOKIE_FS_UID = {
  name: 'fs_uid',
  length: MONTH_IN_SECONDS * 6,
}

export const COOKIE_GA = {
  name: '_ga',
  length: YEAR_IN_SECONDS * 2,
}

export const COOKIE_GA_LI = {
  name: '_gali',
  length: 30,
}

export const COOKIE_GTM_AU = {
  name: '_gcl_au',
  length: YEAR_IN_SECONDS,
}

export const COOKIE_GTM_VISIT = {
  name: '_uetvid',
  length: DAY_IN_SECONDS * 16,
}

export const COOKIE_GTM_VISIT_SID = {
  name: '_uetsid',
  length: DAY_IN_SECONDS,
}

export const COOKIE_GA_ID = {
  name: '_gid',
  length: DAY_IN_SECONDS,
}

export const COOKIE_GA_THROTTLE_REQUEST = {
  name: '_dc_gtm_UA-3063651-14',
  length: MINUTE_IN_SECONDS,
}

export const COOKIE_GOOG_OPTIMIZE_VISIT = {
  name: 'Google Optimize (_gaexp,_opt_awcid, _opt_awmid, _opt_awgid, _opt_awkid, _opt_utmc)',
  length: DAY_IN_SECONDS,
}

export const REQUIRED_COOKIES = [COOKIE_MANAGEMENT, COOKIE_SESSION]

export const ANALYTICAL_COOKIES = [
  COOKIE_GA,
  COOKIE_GA_LI,
  COOKIE_GTM_AU,
  COOKIE_GTM_VISIT,
  COOKIE_GTM_VISIT_SID,
  COOKIE_GA_ID,
  COOKIE_GA_THROTTLE_REQUEST,
  COOKIE_GOOG_OPTIMIZE_VISIT,
]

export const RECORDING_COOKIES = [COOKIE_FS_UID]

export const COOKIE_INFORMATION_MODAL_NAME = 'CookieInformation'
export const COOKIE_MANAGEMENT_MODAL_NAME = 'CookieManagement'
