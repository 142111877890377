import styled from 'styled-components'

export const I = styled.i`
  color: ${(props) => props.theme.primaryColour};
  font-size: ${(props) => props.theme.progressionStep.subContentIconSize};
  padding-right: 5px;
  vertical-align: top;
`

export const TextWrapStyle = styled.div`
  max-width: none;

  @media screen and (max-width: 768px) {
    max-width: 110px;
  }
`
