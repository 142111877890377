import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DrivingLicenceNumber from '../../components/DrivingLicenceNumber'
import { Label } from '../../components/common/Label'
import { Grid } from 'unsemantic'
import {
  updateRiskData,
  updateValidation,
  updateQuote,
  drivingLicenceCheck,
  passportCheck,
} from '../RiskDataCollectionPage/actions'
import QuoteSummary from '../QuoteSummary'
import { PassportLineOne } from '../../components/PassportLineOne'
import { PassportLineTwo } from '../../components/PassportLineTwo'
import PassportExpiryDate from '../../components/PassportExpiryDate'
import DrivingLicenceExample from '../../images/driving-licence-example.png'
import PassportExample from '../../images/passport-example.gif'
import { MoreDetailsHelperImage, MoreDetailsButton } from './styles'
import { Message } from '../../components/Message'

export class MoreDetails extends React.Component {
  constructor(props) {
    super(props)
    this.handleValidation = this.handleValidation.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleContinueClick = this.handleContinueClick.bind(this)
    this.handleDateSet = this.handleDateSet.bind(this)
    this.isValid = this.isValid.bind(this)
  }

  componentDidMount() {
    if (!this.props.validation.IdentityCheck) {
      this.props.history.push(
        `/quote/driving-licence?q=${this.props.riskData.QuoteId}`,
      )
    }
  }
  componentDidUpdate() {
    if (this.isValid()) {
      this.moreDetailsSubmit.scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleValidation = (field, message) => {
    this.props.actions.updateValidation({
      [`${field}Error`]: message,
    })
  }
  handleDateSet = (event, instance) => {
    const fieldName = instance.settings.id
    this.handleValidation(fieldName, '')
    this.props.actions.updateRiskData({ [fieldName]: instance.getVal() })
  }
  handleChange = (event) => {
    const fieldName = event.target.name
    let fieldValue = event.target.value

    this.props.actions.updateRiskData({ [fieldName]: fieldValue })
  }

  handleContinueClick = () => {
    this.props.validation.IdentityCheck === 'drivingLicence'
      ? this.props.actions.drivingLicenceCheck()
      : this.props.actions.passportCheck()
  }

  isValid = () => {
    return (
      this.props.riskData.DrivingLicenceNumber ||
      (this.props.riskData.PassportLineOne &&
        this.props.riskData.PassportLineTwo &&
        this.props.riskData.PassportExpiryDate)
    )
  }
  render() {
    return (
      <div>
        <QuoteSummary />
        <Message
          heading='We need more information about you'
          content="Some of your details didn't quite match up. Please can you provide us some more info below."
          type='error'
        />
        {this.props.validation.IdentityCheck === 'drivingLicence' && (
          <>
            <MoreDetailsHelperImage src={DrivingLicenceExample} />
            <Grid desktop='100'>
              <Label>Driving licence number</Label>
              <DrivingLicenceNumber
                onChange={this.handleChange}
                value={this.props.riskData.DrivingLicenceNumber}
                handleValidation={this.handleValidation}
                error={this.props.validation.fields.DrivingLicenceNumberError}
              />
            </Grid>
          </>
        )}
        {this.props.validation.IdentityCheck === 'passport' && (
          <Grid desktop='100'>
            <MoreDetailsHelperImage src={PassportExample} />
            <Label>Passport Line One</Label>
            <PassportLineOne
              onChange={this.handleChange}
              value={this.props.riskData.PassportLineOne}
              handleValidation={this.handleValidation}
              error={this.props.validation.fields.PassportLineOneError}
            />
            <Label>Passport Line Two</Label>
            <PassportLineTwo
              onChange={this.handleChange}
              value={this.props.riskData.PassportLineTwo}
              handleValidation={this.handleValidation}
              error={this.props.validation.fields.PassportLineTwoError}
            />
            <Label>Passport Expiry Date</Label>
            <PassportExpiryDate
              handleDateSet={this.handleDateSet}
              value={this.props.riskData.PassportExpiryDate}
              error={this.props.validation.fields.PassportExpiryDateError}
            />
          </Grid>
        )}

        {this.isValid() && (
          <Grid desktop='100'>
            <MoreDetailsButton
              id='TeslaSubmitDrivingLicenceNextButton'
              ref={(el) => {
                this.moreDetailsSubmit = el
              }}
              onClick={this.handleContinueClick}
              disabled={!this.props.validation.ValidForm}
            >
              Continue
            </MoreDetailsButton>
          </Grid>
        )}
      </div>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    riskData: state.riskData,
    validation: state.validation.riskDataCollectionPage,
    location: state.location,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateValidation,
        updateRiskData,
        updateQuote,
        drivingLicenceCheck,
        passportCheck,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreDetails)
