import { IS_LOADING } from '../constants'

import initialState from '../../../initialState'

export function isLoadingReducer(state = initialState.isLoading, action) {
  switch (action.type) {
    case IS_LOADING:
      return action.isLoading
    default:
      return state
  }
}
