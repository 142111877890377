import React from 'react'
import { LinkContainer } from './styles'
export const GapMoreInfoItem = ({ content, doc }) => {
  return (
    <li>
      <span>
        <LinkContainer type='application/pdf' href={doc} target='_blank'>
          {content} <i className='fa fa-external-link-alt fa-xs' />
        </LinkContainer>
      </span>
    </li>
  )
}
