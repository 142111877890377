import { call, put } from 'redux-saga/effects'
import featureTogglesApi from '../../../api/featureTogglesApi'
import config from '../../../config'
import {
  requestFeatureTogglesSuccess,
  requestFeatureTogglesFailure,
  requestFeatureToggles,
} from '../actions'

export default function* featureTogglesSaga() {
  const applicationId = config.APPLICATION_ID
  try {
    yield put(requestFeatureToggles())
    const features = yield call(
      featureTogglesApi.getFeatureToggles,
      applicationId,
    )
    yield put(requestFeatureTogglesSuccess(features.data))
  } catch (e) {
    yield put(requestFeatureTogglesFailure(e.message))
  }
}
