import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { requestCallback } from './actions'
import ArnoldClarkProvider from './AnnualInsuranceProviders/ArnoldClark'
import APlanProvider from './AnnualInsuranceProviders/APlan'
import HonchoProvider from './AnnualInsuranceProviders/Honcho/Honcho'
import { SYSTEM_FEATURES } from '../../services/constants'
import { ANNUAL_INSURANCE_PROVIDER_ID } from '../../constants'
import ConfusedRtpProvider from './AnnualInsuranceProviders/Confused'

const AnnualUpsell = ({
  callbackRequest,
  annualInsuranceProvider,
  features,
  actions: { requestCallback },
}) => {
  switch (annualInsuranceProvider) {
    case ANNUAL_INSURANCE_PROVIDER_ID.ArnoldClark:
      return (
        <ArnoldClarkProvider
          callbackRequest={callbackRequest}
          requestCallback={requestCallback}
        />
      )
    case ANNUAL_INSURANCE_PROVIDER_ID.APlan:
      return (
        <APlanProvider
          callbackRequest={callbackRequest}
          requestCallback={requestCallback}
        />
      )
    case 'Honcho':
      if (features[SYSTEM_FEATURES.UPSELL_HONCHO_ENABLED]) {
        return (
          <HonchoProvider
            callbackRequest={callbackRequest}
            requestCallback={requestCallback}
          />
        )
      }
    // eslint-disable-next-line no-fallthrough
    case ANNUAL_INSURANCE_PROVIDER_ID.ConfusedRTP:
      return <ConfusedRtpProvider />
    default:
      return <></>
  }
}

const mapStateToProps = (state) => {
  return {
    callbackRequest: state.policy.callbackRequest,
    annualInsuranceProvider: state.appData.AnnualInsuranceProvider,
    features: state.features || {},
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        requestCallback,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnualUpsell)
