import {
  REQUEST_APPLE_PAY_MERCHANT_SESSION,
  CREATE_APPLE_PAYMENT,
  CREATE_APPLE_PAYMENT_FAILURE,
} from './constants'

export function requestApplePayMerchantSession(config, callback) {
  return {
    type: REQUEST_APPLE_PAY_MERCHANT_SESSION,
    config,
    callback,
  }
}

export function createApplePayment(token, callback) {
  return {
    type: CREATE_APPLE_PAYMENT,
    token,
    callback,
  }
}

export function createApplePayFailure(errorMessage) {
  return {
    type: CREATE_APPLE_PAYMENT_FAILURE,
    errorMessage,
  }
}
