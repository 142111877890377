import styled from 'styled-components'
import greyBackground from './greyBackground.png'
import { Button as CommonButton } from '../../../../components/common/Button'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 982px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-rows: auto 59px;
`

export const TopRowWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 298px auto;
  background-repeat: no-repeat;
  background-image: url(${greyBackground});
  background-position: bottom right;

  @media (max-width: 585px) {
    grid-template-columns: 100% auto;
  }
`

export const TopRowInnerWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
`

export const BottomRowWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 65px;
  background-color: #0b004b;
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
`

export const Image = styled.img`
  margin-top: auto;
`

export const ImagePlayer = styled(Image)`
  display: none;

  @media (max-width: 585px) {
    display: block;
  }
`

export const ImageLaptop = styled(Image)`
  grid-column-start: 1;
  display: none;

  @media (min-width: 890px) and (max-width: 1024px) {
    display: block;
  }
`

export const ImageSkating = styled(Image)`
  grid-column-start: 2;
  display: none;

  @media (min-width: 700px) and (max-width: 1024px) {
    display: block;
  }
`
export const ImageCar = styled(Image)`
  grid-column-start: 3;
  display: none;

  @media (min-width: 586px) {
    display: block;
  }
`

export const Button = styled(CommonButton)`
  width: 121px;
  height: 36px;
  border-radius: 3px;
  background-color: #6d44ff;
  margin-top: 21px;
  padding-top: 7px;
`

export const PaddingLeft = styled.div`
  padding-left: 20px;

  @media (max-width: 350px) {
    padding-left: 0px;
  }
`
