import styled from 'styled-components'
import { H4 } from '../../components/common/H4'
import { P } from '../../components/common/P'

export const ToggleLabel = styled(P)`
  margin-top: 0;
  text-align: left;
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 15px;
  }
  @media (max-width: 767px) {
    text-align: center;
    font-size: 13px;
  }
`

export const ToggleCheckedIcon = styled.span`
  font-family: ${(props) => props.theme.labelFontFamily};
  color: ${(props) => props.theme.buttonTextColour};
  font-size: 14px;
  margin-left: 6px;
`
export const MarketingPurposesText = styled(H4)`
  margin-bottom: 20px;
`

export const MarketingProviderLink = styled.a`
  color: ${(props) => props.theme.primaryTextColour};
  font-size: 18px;
  text-decoration: underline;
  :visited {
    color: ${(props) => props.theme.primaryTextColour};
  }
`

export const ToggleUncheckedIcon = styled.span`
  font-family: ${(props) => props.theme.labelFontFamily};
  color: ${(props) => props.theme.buttonTextColour};
  font-size: 14px;
  margin-left: 3px;
`

export const ToggleWrapper = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 1em;
  }
`

export const StyledFieldset = styled.fieldset`
  border: 0;
  padding: 0;
`

export const StyledLegend = styled.legend`
  text-align: left;
  padding-top: 16px;
  font-size: 14px;
`
