import React from 'react'
import { BlueH3 } from '../common/H3'
import { CenteredH5 } from '../common/H5'
import { Select } from '../common/Select'
import { BlueH2 } from '../StaticPages/styles'
import { ConfusedQuickQuoteOptInValues } from './ConfusedQuickQuoteOptIn.constants'
import { ErrorMessage } from '../common/ErrorMessage'

const ConfusedQuickQuoteOptIn = ({
  onChange,
  errorMessage,
  name,
  errors,
  value,
}) => (
  <>
    <BlueH2>Annual car insurance quote</BlueH2>
    <BlueH3>
      Would you like an annual car insurance quote from Confused.com for this
      vehicle?
    </BlueH3>
    <CenteredH5>
      We&apos;ve partnered with Confused.com so that if you have a Confused.com
      account and have received a car insurance quote in the last 36 months, we
      can show you a personalised quote for this vehicle after you have
      purchased your temporary insurance policy.
    </CenteredH5>
    {errors && <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>}
    <Select
      id='ConfusedQuickQuoteOptIn'
      name='ConfusedQuickQuoteOptIn'
      onChange={onChange}
      options={ConfusedQuickQuoteOptInValues}
      value={value}
      placeholder='Please select if you would like an annual car insurance quote'
    />
  </>
)

export default ConfusedQuickQuoteOptIn
