import { call, put, select } from 'redux-saga/effects'
import schemeApi from '../../../api/schemeApi'
import {
  requestOpenSchemes,
  requestOpenSchemesSuccess,
  requestOpenSchemesFailure,
} from '../actions'
import { getOpenSchemesSelector } from '../selector'
import { getSubDomain } from '../../../services/handlers'
export default function* getOpenSchemeSaga() {
  try {
    yield put(requestOpenSchemes())
    const { Href } = yield select(getOpenSchemesSelector)

    const subDomain = getSubDomain()

    const response = yield call(
      schemeApi.getOpenSchemes,
      Href.replace('{introducer}', subDomain),
    )
    yield put(requestOpenSchemesSuccess(response.data))
  } catch (e) {
    yield put(requestOpenSchemesFailure(e))
  }
}
