import {
  GET_QUOTE_SUMMARY_SUCCESS,
  REQUEST_OPEN_SCHEMES_SUCCESS,
} from '../constants'
import initialState from '../../../initialState'
import { REQUEST_VEHICLE_SUCCESS, SET_VEHICLE } from '../../Vehicle/constants'
function schemeReducer(state = initialState.scheme, action) {
  switch (action.type) {
    case GET_QUOTE_SUMMARY_SUCCESS:
      return {
        ...state,
        selected: action.quoteSummary.Scheme,
      }
    case REQUEST_OPEN_SCHEMES_SUCCESS:
      return {
        ...state,
        open: action.openSchemes.Schemes,
      }
    case REQUEST_VEHICLE_SUCCESS:
    case SET_VEHICLE:
      const scheme = state.open.find((s) =>
        s.Scheme.includes(action.vehicle.VehicleType),
      )
      return {
        ...state,
        selected: !!scheme ? scheme.Scheme : undefined,
      }
    default:
      return state
  }
}
export default schemeReducer
