import React from 'react';

const TrustBox = () => {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);
    return (
        <div
            ref={ref}
            data-locale="en-GB"
            className="trustpilot-widget"
            data-template-id="5406e65db0d04a09e042d5fc"
            data-businessunit-id="4fca033e0000640005160149"
            data-style-height="40px"
            data-style-width="100%"
            data-theme="dark"
        >
            <a href="https://uk.trustpilot.com/review/tempcover.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
        </div>
    );
};
export default TrustBox;