import styled from 'styled-components'
import React from 'react'

export const ButtonSpinnerWrapper = styled.span`
  position: relative;
  display: ${(props) => (props.disabled ? 'inline-block' : 'none')};
  margin-left: 5px;
  -webkit-transition: opacity 0.25s, max-width 0.45s;
  -moz-transition: opacity 0.25s, max-width 0.45s;
  -o-transition: opacity 0.25s, max-width 0.45s;
  transition: opacity 0.25s, max-width 0.45s;
`

export const ButtonSpinner = ({ disabled }) => {
  return (
    <ButtonSpinnerWrapper disabled={disabled}>
      <i className='fa fa-spinner fa-spin' />
    </ButtonSpinnerWrapper>
  )
}
