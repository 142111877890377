import React from 'react'
import Proptypes from 'prop-types'
import { Select } from '../common/Select'
import { TITLE_OPTIONS } from './constants'
import { ErrorMessage } from '../common/ErrorMessage'

export class Title extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }
  handleChange = (event) => {
    this.props.onChange(event)
  }

  render() {
    return (
      <>
        {this.props.error && (
          <ErrorMessage id={`${this.props.name}Error`}>
            {this.props.errorMessage}
          </ErrorMessage>
        )}
        <Select
          id={this.props.id}
          placeholder='Please select a title'
          options={TITLE_OPTIONS}
          name={this.props.name}
          onChange={this.handleChange}
          value={this.props.value}
        />
      </>
    )
  }
}

Title.propTypes = {
  name: Proptypes.string.isRequired,
  onChange: Proptypes.func.isRequired,
  value: Proptypes.string,
}
