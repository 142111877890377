import styled from 'styled-components'

export const ProgressionHeaderImage = styled.div`
  background: ${(props) => props.theme.white};
  margin: 0;
  padding: 0 10px;

  picture {
    max-width: 100%;
    display: block;

    img {
      max-width: 100%;
      vertical-align: top;
    }
  }
`
