import styled from 'styled-components'

export const VehicleButtonWrapper = styled.div`
  text-align: center;
`

export const VehicleParagraph = styled.p`
  font-family: ${(props) => props.theme.labelFontFamily};
  font-size: ${(props) => props.theme.labelFontSize};
  text-align: center;
`
