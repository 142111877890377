import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from 'unsemantic'
import { Wrapper } from '../../containers/DrivingLicence/Wrapper'
import { H2 } from '../common/H2'
import { setGapActivationLinkIncludedState } from './actions'
import GapActivationMoreInfo from './gapActivationMoreInfo'
import {
  GapActivationButton,
  GapActivationContainer,
  GapActivationGrid,
  GapActivationRemoveButton,
  GapActivationText,
  GapActivationWrapper,
  GapSmallPrint,
} from './styles'

const GapActivationModal = () => {
  const dispatch = useDispatch()
  const gapIncluded = useSelector(
    (state) =>
      state.price.FreeGAPInsuranceCover.FreeGapActivationJourneyIncluded,
  )
  const [gapSelected, setGapSelected] = useState(gapIncluded)
  const handleClick = () => {
    setGapSelected(!gapSelected)
    dispatch(setGapActivationLinkIncludedState(!gapSelected))
  }
  const GapButtonComponent = () => {
    if (gapSelected) {
      return (
        <GapActivationRemoveButton
          id={`addon-remove-gap`}
          onClick={() => {
            handleClick()
          }}
        >
          REMOVE GAP INSURANCE
        </GapActivationRemoveButton>
      )
    }

    return (
      <>
        <GapActivationButton
          id={`addon-addbtn-gap`}
          onClick={() => {
            handleClick()
          }}
        >
          ADD GAP INSURANCE
        </GapActivationButton>
      </>
    )
  }
  return (
    <GapActivationContainer>
      <Grid desktop='100' tablet='100' mobile='100'>
        <Wrapper>
          <GapActivationWrapper>
            <H2>You could be eligible for 30 days free Gap insurance!</H2>
            <GapActivationText>
              We are able to give you a 30-day, no commitment, free GAP policy.
              This means, in the event that your vehicle is written off or
              stolen, you can claim back the difference between what your
              insurance company pays out, and what you paid for the vehicle. If
              you add this, we’ll email you so you can activate your free gap
              policy with our partner, MotorEasy.
            </GapActivationText>
            <GapActivationMoreInfo></GapActivationMoreInfo>
            <GapActivationGrid>
              <GapButtonComponent></GapButtonComponent>
              <GapSmallPrint>
                *Vehicle must have less than 100,000 miles.
              </GapSmallPrint>
            </GapActivationGrid>
          </GapActivationWrapper>
        </Wrapper>
      </Grid>
    </GapActivationContainer>
  )
}

export default GapActivationModal
