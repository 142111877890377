import styled from 'styled-components'
import { Button } from '../common/Button'
import { H3 } from '../common/H3'
import { P } from '../common/P'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CookieConsentFullScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  pointer-events: visible;
  z-index: 1000000;
`

export const CookieConsentBannerWrapper = styled.div`
  position: fixed;
  left: 0;
  background-color: #f2f2f2;
  bottom: 0;
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 64px;
  @media (max-width: 768px) {
    padding: 8px 32px;
  }
`

export const CookieConsentHeader = styled(H3)`
  font-size: 32px;
  align-self: flex-start;
  margin: 0;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
    font-size: 24px;
  }
`

export const CookieConsentText = styled(P)`
  align-self: flex-start;
  width: 100%;
  font-size: 16px;
  margin: 0;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
    font-size: 12px;
  }
`

export const CookieConsentLink = styled.a`
  color: #069;
  text-decoration: underline;
  cursor: pointer;
`

export const CookieButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const CookieConsentButton = styled(Button)`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 125px;
  }
`

export const ManageCookieButton = styled(CookieConsentButton)`
  color: ${(props) => props.theme.primaryColour};
  background: ${(props) =>
    props.disabled
      ? props.theme.disabledButtonColour
      : props.theme.buttonTextColour};
  border: 1px solid ${(props) => props.theme.primaryColour};
  &:hover {
    color: ${(props) => props.theme.buttonTextColour};
  }
`

export const ButtonIcon = styled(FontAwesomeIcon)`
  margin-left: 8px;
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`
