import React from 'react'
import { Button } from '../common/Button'
import { H1 } from '../common/H1'
import styled from 'styled-components'
import { ROUTES } from '../../services/constants'
import { P2 } from '../../containers/HomePage/styles'

const Heading = styled(H1)`
  text-align: center;
`
const P = styled(P2)`
  text-align: center;
`

const HomeButton = styled(Button)`
  min-width: 150px;
  display: block;
  margin: 0 auto;
`

const ErrorPage = () => {
  return (
    <>
      <Heading>
        We're sorry, we're currently experiencing an issue with this page.{' '}
      </Heading>

      <P>
        We're busy fixing the problem, so please bear with us and try again
        later.
      </P>
      <HomeButton
        id='HomePageButton'
        onClick={() => {
          window.location.href = ROUTES.HOME_PAGE
        }}
      >
        Home
      </HomeButton>
    </>
  )
}

ErrorPage.propTypes = {}

export default ErrorPage
