import styled from 'styled-components'
import { ReactComponent as motorEasyLogo } from '../../images/affiliates/motoreasy-logo.svg'
import { GapButton } from '../GapModal/styles'
export const GapActivationConfirmationContainer = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.inputBorderColour};
  border-radius: ${(props) => props.theme.formControlBorderRadius};
  background: #fff;
  margin: 10px 0;
  padding: 20px 20px 0 20px;
  text-align: left;
`
export const GapHeader = styled.div`
  display: flex;
`

export const GapTitle = styled.h3`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 22px;
  line-height: 1em;
  color: #264b94;
  margin: 0;
  justify-self: start;
  padding: 0 0 15px;
  text-wrap: nowrap;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const GapText = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 14px;
  line-height: 1.2em;
  text-align: start;
  margin: 0;
  padding-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`

export const GapModalStyles = styled.div`
  text-align: left;
`

export const MotorEasyLogo = styled(motorEasyLogo)`
  height: 30px;
  @media (max-width: 768px) {
    height: 17px;
    padding-left: 8px;
  }
`
export const GapActivationButton = styled(GapButton)`
  width: 100%;
  margin-bottom: 16px;
`
