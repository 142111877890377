import React from 'react'
import { H1 } from '../common/H1'
import styled from 'styled-components'
import { getSubDomain } from '../../services/handlers'

const Heading = styled(H1)`
  text-align: left;
  @media only screen and (min-width: 600px) {
    margin-bottom: 4rem;
  }
`
const Wrapper = styled.div`
  text-align: left;
  margin: 0 auto;
`

const Block = styled.div`
  @media only screen and (min-width: 600px) {
    padding-bottom: 3rem;
  }
`

const P = styled.p`
  padding-bottom: 1rem;
`

const isCazoo = getSubDomain().toLowerCase() === 'cazoo'

const HoldingPage = () => {
  return (
    <Wrapper>
      <Heading>Site down for essential maintenance</Heading>
      {!isCazoo ? (
        <Block>
          <P>Please bear with us while we upgrade our site.</P>
          <P>
            If you would like us to call you when we are back up and running
            please email{' '}
            <a href='mailto:contactus@tc247.co.uk'>contactus@tc247.co.uk</a> and
            one of our team will get back to you.
          </P>
        </Block>
      ) : (
        <Block>
          <P>
            Tempcover is currently down for a bit of maintenance. We'll be back
            up and running again tomorrow morning at 6am.
          </P>
          <P>Thanks for your patience.</P>
        </Block>
      )}
    </Wrapper>
  )
}

HoldingPage.propTypes = {}

export default HoldingPage
