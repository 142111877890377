import React from 'react'
import { useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { UpdateButton } from './PolicyStartTimeModal.styles'
import { createSplitPolicyStartDateObject } from '../../services/split-policy-start-date'
import {
  CAN_FUTURE_DATE_SPLIT_POLICY_START_DATE_VALIDATION,
  SPLIT_POLICY_START_DATE_VALIDATION,
} from '../../services/validation'
import Modal from '../common/NewModal'
import SplitPolicyStartTimePicker from '../common/SplitPolicyStartTimePicker'

const PolicyStartTimeModal = ({
  isVisible,
  hideModal,
  handleDateTimeSet,
  canFutureDate,
}) => {
  const policyDate = useSelector((state) => state.riskData.PolicyStartDate)

  return (
    <Modal
      id='policyDateTimePicker'
      isVisible={isVisible}
      onClose={hideModal}
      header='When would you like the policy to start?'
      headerAlignment='center'
      fullscreen={window.innerWidth <= 500}
    >
      <Formik
        initialValues={{
          PolicyStartDate: createSplitPolicyStartDateObject(
            policyDate,
            canFutureDate,
          ),
        }}
        validationSchema={Yup.object().shape({
          PolicyStartDate: canFutureDate
            ? CAN_FUTURE_DATE_SPLIT_POLICY_START_DATE_VALIDATION
            : SPLIT_POLICY_START_DATE_VALIDATION,
        })}
        onSubmit={(values, actions) => {
          const { value, date, hour, minute, manuallyEdited } =
            values.PolicyStartDate
          const startDate = {
            manuallyEdited: manuallyEdited,
            value: value || `${date}T${hour}:${minute}`,
          }
          handleDateTimeSet(startDate)
          if (!values.manuallyEdited && actions) {
            actions.setFieldValue('PolicyStartDate', '', false)
          }
        }}
      >
        {({ errors, setFieldTouched, setFieldValue, touched, values }) => {
          return (
            <Form>
              <SplitPolicyStartTimePicker
                id='PolicyStartDateModal'
                name='PolicyStartDateModal'
                value={values.PolicyStartDate}
                errors={errors.PolicyStartDate || {}}
                touched={touched.PolicyStartDate || {}}
                canFutureDate={canFutureDate}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
              <UpdateButton id='policyStartDoneButton' type='submit'>
                Done
              </UpdateButton>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default PolicyStartTimeModal
