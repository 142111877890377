import React from 'react'
import { Wrapper } from './styles'
import { Grid } from 'unsemantic'
import TrustBox from '../TrustBox'

const TrustPilot = ({ AppData }) => {
  const {
    PartnerPays,
  } = AppData
  return (
    <>
      {PartnerPays ? (
        <></>
      ) : (
        <Wrapper>
          <Grid desktop-hide>
            <TrustBox />
          </Grid>
          <Grid mobile-hide tablet-hide>
            <TrustBox />
          </Grid>
        </Wrapper>
      )}
    </>
  )
}
TrustPilot.propTypes = {}
export default TrustPilot
