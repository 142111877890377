import { REQUEST_PRICE_SUCCESS } from '../constants'

import { SET_GAP_ACTIVATION_LINK_INCLUDED_STATE } from '../../../components/GapActivationModal/constants'
import { SET_GAP_INCLUDED_STATE } from '../../../components/GapModal/constants'
import initialState from '../../../initialState'
import {
  GET_ANALYSIS_DATA_SUCCESS,
  REQUEST_POLICY_SUCCESS,
} from '../../PolicyConfirmationPage/constants'

function priceReducer(state = initialState.price, action) {
  switch (action.type) {
    case REQUEST_PRICE_SUCCESS:
      return {
        ...state,
        InsurancePremium: action.price.InsurancePremium,
        Ipt: action.price.Ipt,
        AdminFee: action.price.AdminFee,
        AffiliateAdminFee: action.price.AffiliateAdminFee,
        Underwriter: action.price.Underwriter,
        UnderwriterId: action.price.UnderwriterId,
        TotalPrice: action.price.TotalPrice,
        EncryptedString: action.price.ValidationData
          ? action.price.ValidationData.Value
          : null,
        excess: action.price.CompulsoryExcess,
        FreeGAPInsuranceCover: {
          Free30DayGAPInsuranceIncluded:
            action.price.FreeGAPInsurancePricing.FreeGAPInsuranceIncluded ||
            false,
          FreeGAPInsuranceAvailable:
            action.price.FreeGAPInsurancePricing.FreeGAPInsuranceAvailable ||
            false,
          FreeGapActivationJourneyIncluded:
            action.price.FreeGAPInsurancePricing
              .FreeGapActivationJourneyIncluded || false,
        },
      }
    case SET_GAP_INCLUDED_STATE:
      return {
        ...state,
        FreeGAPInsuranceCover: {
          ...state.FreeGAPInsuranceCover,
          Free30DayGAPInsuranceIncluded: action.gapIncluded,
        },
      }
    case SET_GAP_ACTIVATION_LINK_INCLUDED_STATE:
      return {
        ...state,
        FreeGAPInsuranceCover: {
          ...state.FreeGAPInsuranceCover,
          FreeGapActivationJourneyIncluded: action.gapIncluded,
        },
      }
    case REQUEST_POLICY_SUCCESS:
      return {
        ...state,
        TotalPrice: action.policy.Premium,
      }
    case GET_ANALYSIS_DATA_SUCCESS:
      return {
        ...state,
        TotalPrice: action.response.Premium,
        Ipt: action.response.Ipt,
        Underwriter: action.response.Underwriter,
        commission: action.response.Commission,
      }
    default:
      return state
  }
}

export default priceReducer
