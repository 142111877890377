import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Button } from '../../components/common/Button'
import { bindActionCreators } from 'redux'
import { updateStep } from '../App/actions'
import { Grid } from 'unsemantic'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CheckoutForm from '../Stripe/Card'
import { ErrorContainer } from '../../components/ErrorContainer'
import queryString from 'query-string'
import { ErrorIcon } from '../../components/Message/ErrorIcon'
import { MessageHeader } from '../../components/Message/MessageHeader'
import { MessageContent } from '../../components/Message/MessageContent'
import { ClearFix } from '../../components/common/ClearFix'
import config from '../../config'
import LoadingScreen from '../../components/LoadingScreen'
import { H1 } from '../../components/common/H1'
import { P } from '../../components/common/P'
import { StripePaymentWrapper } from './styles'

export const PaymentPageButton = styled(Button)`
  width: 100%;
`

export const PaymentButton = styled(PaymentPageButton)`
  display: inline-block;
`

/* eslint-disable react/prefer-stateless-function */
export class PaymentPage extends React.Component {
  constructor(props) {
    super(props)

    this.emailQuoteModalRef = React.createRef()
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.actions.updateStep(3)
  }

  render() {
    const parsedQueryString = queryString.parse(window.location.search)
    let error = false
    if (parsedQueryString.message) {
      error = parsedQueryString.message
    }
    return this.props.payment.paymentInProgress ? (
      <LoadingScreen />
    ) : (
      <StripeProvider apiKey={config.STRIPE_KEY}>
        <>
          <H1>Payment</H1>
          {this.props.payment.paymentError && (
            <ErrorContainer>
              <Grid desktop='20' tablet='20' mobile='20'>
                <ErrorIcon className='fa fa-exclamation-triangle'></ErrorIcon>
              </Grid>
              <Grid desktop='80' tablet='80' mobile='80'>
                <MessageHeader>Payment Error</MessageHeader>
                <MessageContent>
                  {this.props.payment.paymentError}
                </MessageContent>
              </Grid>
              <ClearFix />
            </ErrorContainer>
          )}
          {error && (
            <ErrorContainer>
              <Grid desktop='20' tablet='20' mobile='20'>
                <ErrorIcon className='fa fa-exclamation-triangle'></ErrorIcon>
              </Grid>
              <Grid desktop='80' tablet='80' mobile='80'>
                <MessageHeader>Payment Error</MessageHeader>
                <MessageContent>{error}</MessageContent>
              </Grid>
              <ClearFix />
            </ErrorContainer>
          )}
          <P>
            This policy meets the demands &amp; needs of someone needing to
            insure their vehicle for a short period. You accept that once a
            policy has been purchased it is not possible to amend any details
            and is non-refundable. Please view our{' '}
            <a
              href='/our-refund-policy'
              target='_blank'
              rel='noopener noreferrer'
            >
              Refund Policy
            </a>
            .
          </P>
          <Grid desktop='100' mobile='100' tablet='100'>
            <Elements>
              <StripePaymentWrapper>
                <CheckoutForm />
              </StripePaymentWrapper>
            </Elements>
          </Grid>
        </>
      </StripeProvider>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    location: ownProps.location,
    payment: state.paymentPage,
  }
}
export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        updateStep,
      },
      dispatch,
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage)
