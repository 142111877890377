export const REQUEST_CALLBACK = 'src/AnnualUpsell/REQUEST_CALLBACK'
export const REQUEST_CALLBACK_SUCCESS =
  'src/AnnualUpsell/REQUEST_CALLBACK_SUCCESS'
export const REQUEST_CALLBACK_FAILURE =
  'src/AnnualUpsell/REQUEST_CALLBACK_FAILURE'
export const REQUEST_CALLBACK_REL = 'RequestAnnualCallback'

export const MINIMUM_DURATION_CONFIRMATION_PAGE = 3000

export const CALLBACK_SOURCE = {
  Website: 'Website',
  Email: 'Email',
}

export const REQUEST_CALLBACK_STATUS = {
  WAITING: 0,
  REQUESTING: 1,
  SUCCESS: 2,
  ERROR: 3,
}
