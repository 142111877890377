import styled from 'styled-components'

export const FooterWrapper = styled.div`
  background: ${(props) => props.theme.footerWrapperBackground};
  position: relative;
  display: grid;
  grid-template-columns: 1fr 400px 800px 1fr;
  width: 100%;

  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.18;
    letter-spacing: 1px;
    color: #fff;
    margin: 0 0 11px;
    text-align: left;
  }

  nav ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
  }

  nav ul li {
    line-height: 1.3;
    font-family: 'BrandonText', sans-serif;
  }

  nav ul li a {
    font-size: 16px;
    line-height: 1.2;
    font-family: 'freight-sans-pro', sans-serif;
    vertical-align: top;
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 1220px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;

    h3 {
      font-size: 16px;
    }
  }
`

export const FooterNavContainer = styled.div`
  padding: 30px 10px;
  grid-column: 2;

  @media (max-width: 1220px) {
    grid-column: 1;
    padding: 30px 20px;
  }
`

export const FooterInfoContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: grid;
  grid-template-columns: 25% 25% 50%;
  grid-template-rows: auto;

  @media (max-width: 767px) {
    grid-template-columns: 15% 35% 50%;
  }
`

export const FooterNavWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 30px 0 30px auto;
`

export const FooterInfoWrapper = styled.div`
  padding: 30px 10px;
  background-color: ${(props) => props.theme.footerInfoBackground};

  @media (max-width: 767px) {
    padding: 10px;
  }
`
export const WhiteLabelFooterInfoWrapper = styled.div`
  display: block;
  background-color: ${(props) => props.theme.footerInfoBackground};
  @media (max-width: 1220px) {
    padding: 0px;
  }
  @media (max-width: 767px) {
    padding: 10px;
  }
`
export const WhiteLabelFooterCenterWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-left: 10px;
  padding-right: 10px;
`
export const WhiteLabelFooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const WhiteLabelFooterNavContainer = styled.div`
  text-align: center;
  color: '#fff';
  @media (max-width: 1220px) {
    grid-column: 1;
  }
`

export const WhiteLabelText = styled.p`
  padding: 30px 10px 10px 10px
  font-size: 16px;
  font-family: 'freight-sans-pro', sans-serif;
  font-weight: 500;
  text-align: middle;
  margin: 0;
  color: #ffffff;
  @media (max-width: 767px) {
    font-size: 12px;
  }
`

export const WhiteLabelTextSmaller = styled(WhiteLabelText)`
  padding: 10px 10px;
  font-size: 12px;
`

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 32px;
  text-align: center;
  line-height: 32px;

  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    line-height: 15px;
  }
`

export const FooterImage = styled.img`
  width: 63px;
  height: auto;
  vertical-align: top;
  margin-left: 45%;
  margin-left: calc(50% - 31px);
  max-width: 100%;

  @media (max-width: 767px) {
    margin-left: 0;
  }
`

export const FooterImageWrapper = styled.div`
  padding: 0 10px;
`

export const AddressSection = styled.section``

export const AddressWrapper = styled.div`
  margin: 0 auto;
  display: block;

  @media (max-width: 767px) {
    width: 110px;
  }
`

export const Address = styled.address`
  color: #fff;
  font-size: 16px;
  line-height: 1.4375;
  font-style: normal;
  font-family: 'freight-sans-pro', sans-serif;
  margin: 0;

  p {
    color: #fff;
    font-size: 16px;
    line-height: 1.4375;
    font-style: normal;
    font-family: 'freight-sans-pro', sans-serif;
    margin: 0;
  }

  p:first-child {
    font-weight: 700;
  }

  @media (max-width: 767px) {
    p {
      font-size: 12px;
    }
  }
`

export const LegalParagraph = styled.p`
  font-size: 12px;
  font-family: 'freight-sans-pro', sans-serif;
  font-weight: 500;
  text-align: left;
  margin: 0;
  color: #ffffff;
  margin-top: 40px;
  grid-column: 1 / span 3;

  ::before {
    content: ' ';
    display: block;
    width: 100%;
    height: 2px;
    background: #a2bc1e;
    margin-bottom: 19px;
  }
`

export const SocialSection = styled.section`
  .social-icons {
    text-align: center;
    margin: 0;
    padding: 5px 0 0;
    list-style: none;
  }

  .social-icons li {
    display: inline-block;
    padding-right: 5px;
  }

  .social-icons a {
    background: #fff;
    border-radius: 32px;
  }

  .social-icons svg {
    color: #0e2a6e;
    width: 18px;
    height: 18px;
  }

  h2 {
    text-align: center;
  }

  @media (max-width: 767px) {
    .social-icons svg {
      width: 10px;
      height: 10px;
    }
  }
`

export const FooterContainer = styled.div`
  z-index: 2;
  width: 100%;
`

export const DarkBlueBackground = styled.div`
  background-color: ${(props) => props.theme.footerInfoBackground};
`
