import React from 'react'
import { ErrorMessage } from '../../components/common/ErrorMessage'
import { GBIcon } from '../../components/VehicleReg/styles'
import { RegInputStyling, RegWrapper } from './styles'

export const RegInput = ({
  id,
  placeholder,
  errorMessage,
  error,
  onChange,
  onBlur,
  onFocus,
  onInput,
  name,
  value,
  type,
  style,
  ariaLabel,
  pattern,
}) => {
  return (
    <>
      {error && errorMessage && (
        <ErrorMessage id={`${name}Error`}>{errorMessage}</ErrorMessage>
      )}
      <RegWrapper className='reg-wrapper'>
        <GBIcon className='gb-icon'>GB</GBIcon>
        <RegInputStyling
          placeholder={placeholder}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onInput={onInput}
          name={name}
          id={id}
          value={value}
          type={type}
          style={style}
          aria-label={ariaLabel}
          autoComplete='off'
          autoCorrect='off'
          pattern={pattern}
        />
      </RegWrapper>
    </>
  )
}
