import styled from 'styled-components'

export const P = styled.p`  
   font-family: ${(props) => props.theme.labelFontFamily}
   font-size:${(props) => props.theme.labelFontSize}    
   @media (max-width: 768px) {
      font-size:${(props) => props.theme.mobileLabelFontSize}   
   }
`

export const Span = styled.span`  
   font-size:22px 
   font-weight:bold
   color:${(props) => props.theme.primaryColour}   
   @media (max-width: 768px) {
      font-size:16px
   }
`

export const SectionWrapper = styled.div`
  border-bottom: solid 1px #cccccc;
`
