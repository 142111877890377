export const LOAD_THEME_SUCCESS = 'src/App/LOAD_THEME_SUCCESS'

export const REQUEST_ANTI_FORGERY = 'src/App/REQUEST_ANTI_FORGERY'
export const REQUEST_ANTI_FORGERY_SUCCESS =
  'src/App/REQUEST_ANTI_FORGERY_SUCCESS'
export const REQUEST_ANTI_FORGERY_FAILURE =
  'src/App/REQUEST_ANTI_FORGERY_FAILURE'

export const REQUEST_LINKS = 'src/App/REQUEST_LINKS'
export const REQUEST_LINKS_SUCCESS = 'src/App/REQUEST_LINKS_SUCCESS'
export const REQUEST_LINKS_FAILURE = 'src/App/REQUEST_LINKS_FAILURE'

export const REQUEST_FEATURE_TOGGLES = 'src/App/REQUEST_FEATURE_TOGGLES'
export const REQUEST_FEATURE_TOGGLES_SUCCESS =
  'src/App/REQUEST_FEATURE_TOGGLES_SUCCESS'
export const REQUEST_FEATURE_TOGGLES_FAILURE =
  'src/App/REQUEST_FEATURE_TOGGLES_FAILURE'

export const CREATE_QUOTE = 'src/App/CREATE_QUOTE'
export const CREATE_QUOTE_SUCCESS = 'src/App/CREATE_QUOTE_SUCCESS'
export const CREATE_QUOTE_FAILURE = 'src/App/CREATE_QUOTE_FAILURE'

export const REQUEST_PRICE = 'src/App/REQUEST_PRICE'
export const REQUEST_PRICE_SUCCESS = 'src/App/REQUEST_PRICE_SUCCESS'
export const REQUEST_PRICE_FAILURE = 'src/App/REQUEST_PRICE_FAILURE'

export const REQUEST_QUOTE = 'src/App/REQUEST_QUOTE'
export const REQUEST_QUOTE_SUCCESS = 'src/App/REQUEST_QUOTE_SUCCESS'
export const REQUEST_QUOTE_FAILURE = 'src/App/REQUEST_QUOTE_FAILURE'

export const REQUEST_OPEN_SCHEMES = 'src/app/REQUEST_OPEN_SCHEMES'
export const REQUEST_OPEN_SCHEMES_SUCCESS =
  'src/app/REQUEST_OPEN_SCHEMES_SUCCESS'
export const REQUEST_OPEN_SCHEMES_FAILURE =
  'src/app/REQUEST_OPEN_SCHEMES_FAILURE'
export const GET_QUOTE_SUMMARY_SUCCESS = 'src/app/GET_QUOTE_SUMMARY_SUCCESS'

export const CREATE_QUOTE_REL = 'CreateQuote'
export const GET_PRICE_REL = 'GetQuotePrice'
export const REQUEST_DRIVEAWAY_OPEN_SCHEMES_REL = 'GetDriveawayOpenSchemes'
export const GET_MOTOR_QUOTE_REL = 'GetMotorQuoteDetails'
export const GET_QUOTE_SUMMARY_REL = 'GetQuoteSummary'
export const GET_CREATE_POLICY_NO_PAYMENT_REL = 'NoPaymentPaymentRequest'

export const AFFILIATE_REF = 'tempcover'

export const SET_QUERY_STRING = 'src/App/SET_QUERY_STRING'

export const UPDATE_STEP = 'src/App/UPDATE_STEP'
export const IS_LOADING = 'src/App/IS_LOADING'
export const SHOW_LIVE_CHAT = 'src/App/SHOW_LIVE_CHAT'

export const GET_APP_STARTUP = 'src/App/GET_APP_STARTUP'
export const GET_APP_STARTUP_SUCCESS = 'src/App/GET_APP_STARTUP_SUCCESS'
export const GET_APP_STARTUP_FAILURE = 'src/App/GET_APP_STARTUP_FAILURE'

export const ACCEPT_LEGAL_DECLARATIONS = 'src/App/ACCEPT_LEGAL_DECLARATIONS'
export const UPDATE_CONFUSED_QUICK_QUOTE_OPT_IN =
  'src/App/UPDATE_CONFUSED_QUICK_QUOTE_OPT_IN'

export const REQUEST_CONFUSED_QUICK_QUOTE_REL = 'RequestConfusedQuickQuote'
export const REQUEST_CONFUSED_QUICK_QUOTE =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE'
export const REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_SUCCESS'
export const REQUEST_CONFUSED_QUICK_QUOTE_FAILURE =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_FAILURE'

export const REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_REL =
  'RequestConfusedQuickQuoteResponse'
export const REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE'
export const REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_SUCCESS'
export const REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE =
  'src/App/REQUEST_CONFUSED_QUICK_QUOTE_RESPONSE_FAILURE'
